import React, { useState } from 'react';

const Header = ({ headers, onSorting }) => {
	const [sortingField, setSortingField] = useState('');
	const [sortingOrder, setSortingOrder] = useState('asc');

	const onSortingChange = (field) => {
		const order =
			field === sortingField && sortingOrder === 'asc' ? 'desc' : 'asc';

		setSortingField(field);
		setSortingOrder(order);
		onSorting(field, order);
	};

	return (
		<thead>
			<tr>
				<th
					style={{
						fontWeight: '600',
						textAlign: 'left',
						backgroundColor: '#f2f2f2',
						whiteSpace: 'nowrap'
					}}
				>
					S.No
				</th>
				{/* Serial number header */}
				{headers.map(({ name, field, sortable, classKey }) => (
					<th
						className={classKey}
						style={{
							fontWeight: '600',
							textAlign: 'left',
							backgroundColor: '#f2f2f2',
							whiteSpace: 'nowrap'
						}}
						key={name}
						onClick={() => (sortable ? onSortingChange(field) : null)}
					>
						{name}
					</th>
				))}
			</tr>
		</thead>
	);
};

export default Header;
