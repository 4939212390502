import { useContext, useEffect, useState } from "react";
import { FaGreaterThan } from "react-icons/fa";
import { Link, useNavigate, useParams } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import AlertContext from "../../../context/Alert/AlertContext";
import axiosPrivate from "../../../hooks/axiosPrivate";
import "../Logistic/logistic.css";
import LoaderContext from "../../../context/loader/LoaderContext";

const ViewAccount = () => {
  const { setIsLoading } = useContext(LoaderContext);
  const { split_id, order_id } = useParams();
  const { alertMessage, setAlertMessage } = useContext(AlertContext);
  const [orderData, setOrderData] = useState({});
  const navigate = useNavigate();
  const [totalAmount, setTotalAmount] = useState(0);

  useEffect(() => {
    setIsLoading(true);
    axiosPrivate
      .post("accounting/view/split/items", {
        order_id: order_id,
        split_id: split_id,
      })
      .then((response) => {
        setOrderData(response?.data?.data);
        if (response.data.data.order_items.length > 0) {
          let total = 0;
          for (let i = 0; i < response.data.data.order_items.length; i++) {
            total +=
              parseInt(response.data.data.order_items[i].dispatch_quantity) *
              parseInt(response.data.data.order_items[i].rate);
          }
          setTotalAmount(total);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
      });
  }, []);
  return (
    <>
      <div className="section-body ">
        <div className="container-fluid">
          {/* hearder breadcrumb */}
          <div className="card-header">
            <strong className="top-left-heading"> Splited Order Items</strong>

            <ol className="headerroutes breadcrumb m-0 bg-none ">
              <li className="item color-gray">|</li>
              <li className="item">
                <Link to="/admin/dashboard" className="route-heading item mx-2">
                  Dashboard
                </Link>
              </li>
              <li className="item">
                <FaGreaterThan className="grater-than-route" />
              </li>
              <li className="route-heading item mx-2 mt-1">
                <Link to="/admin/account">Account</Link>
              </li>
              <li className="item">
                <FaGreaterThan className="grater-than-route" />
              </li>

              <li className="route-heading item mx-2 mt-1">
                {" "}
                Splited Order Items
              </li>
            </ol>
          </div>

          <div className="tab-content ">
            <div
              className="tab-pane fade show active"
              id="user-list"
              role="tabpanel"
            >
              <div className="card">
                <div className="card-body">
                  <div className="row p-2">
                    <div className="col-md-6">
                      <strong>Dealer Name &nbsp;: </strong>
                      <strong>&ensp;{orderData?.order?.name}</strong>
                    </div>
                    <div className="col-md-6">
                      <strong>Order No &nbsp;: </strong>
                      <strong>&ensp;#{orderData?.order?.order_no}</strong>
                    </div>
                    <div className="col-md-6">
                      <label>Transporter Name &nbsp;: </label>
                      <span>
                        &ensp;
                        {orderData.order_items != undefined &&
                        orderData.order_items.length > 0
                          ? orderData.order_items[0].transporter_name
                          : ""}
                      </span>
                    </div>
                    <div className="col-md-6">
                      <label>Vehicle No &nbsp;: </label>
                      <span>
                        &ensp;
                        {orderData.order_items != undefined &&
                        orderData.order_items.length > 0
                          ? orderData.order_items[0].vehicle_no
                          : ""}
                      </span>
                    </div>
                    <div className="col-md-6">
                      <label>Driver Name &nbsp;: </label>
                      <span>
                        &ensp;
                        {orderData.order_items != undefined &&
                        orderData.order_items.length > 0
                          ? orderData.order_items[0].driver_name
                          : ""}
                      </span>
                    </div>
                    <div className="col-md-6">
                      <label>Driver No &nbsp;: </label>
                      <span>
                        &ensp;
                        {orderData.order_items != undefined &&
                        orderData.order_items.length > 0
                          ? orderData.order_items[0].driver_phone
                          : ""}
                      </span>
                    </div>
                    <div className="col-md-6">
                      <label>State &nbsp;: </label>
                      <span>
                        &ensp;
                        {orderData.order_items != undefined &&
                        orderData.order_items.length > 0
                          ? orderData.order_items[0].shipping_state
                          : ""}
                      </span>
                    </div>
                    <div className="col-md-6">
                      <label>City &nbsp;: </label>
                      <span>
                        &ensp;
                        {orderData.order_items != undefined &&
                        orderData.order_items.length > 0
                          ? orderData.order_items[0].shipping_city
                          : ""}
                      </span>
                    </div>
                    <div className="col-md-6">
                      <label>Remark &nbsp;: </label>
                      <span>
                        &ensp;
                        {orderData.order_items != undefined &&
                        orderData.order_items.length > 0
                          ? orderData?.order?.remark
                          : ""}
                      </span>
                    </div>
                    <div className="col-md-6">
                      <label>Area Manger Remark &nbsp;: </label>
                      <span>
                        &ensp;
                        {orderData.order_items != undefined &&
                        orderData.order_items.length > 0
                          ? orderData?.order?.area_manager_remark
                          : ""}
                      </span>
                    </div>
                  </div>

                  <div className="p-2">
                    <strong style={{ fontWeight: "700", fontSize: "18px" }}>
                      Order Items
                    </strong>
                    <table className="table">
                      <thead>
                        <tr>
                          <th>Product</th>
                          <th>Dispatch Quantity</th>
                          <th>Price</th>
                          <th>Shipping Address</th>
                          <th>Shipping Zip</th>
                          <th>Amount</th>
                        </tr>
                      </thead>
                      <tbody>
                        {orderData?.order_items?.map((order_item) => {
                          return (
                            <tr
                              key={`${order_item.product_name}-${order_item.id}`}
                            >
                              <td>{order_item.product_name}</td>
                              <td>{order_item.dispatch_quantity}</td>
                              <td>{parseInt(order_item.rate)}</td>
                              <td>{order_item.shipping_address}</td>
                              <td>{order_item.shipping_zip}</td>
                              <td>
                                {parseInt(order_item.dispatch_quantity) *
                                  parseInt(order_item.rate)}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                      <tfoot>
                        <tr>
                          <td colSpan={5}>Total Amount</td>
                          <td>{totalAmount}</td>
                        </tr>
                      </tfoot>
                    </table>
                  </div>

                  <div className="col-12 submit-btn my-2">
                    <hr className="mt-4" />
                    <div className="table-responsive">
                      <table className="table table-striped"></table>
                    </div>
                    <button
                      type="button"
                      className="btn btn-secondary"
                      data-dismiss="modal"
                      onClick={() => navigate(`/admin/account`)}
                    >
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewAccount;
