import { Fragment, useContext, useEffect, useState } from "react";
import {
  AiOutlineArrowDown,
  AiOutlineSetting,
  AiOutlineUnorderedList,
} from "react-icons/ai";
import { BiCategoryAlt, BiMessageAltEdit, BiSitemap } from "react-icons/bi";
import { BsImages, BsPatchQuestion, BsTruck } from "react-icons/bs";
import { CgUserList } from "react-icons/cg";
import { CiImageOn } from "react-icons/ci";
import {
  FaNetworkWired,
  FaRegHandshake,
  FaShippingFast,
  FaUserCircle,
} from "react-icons/fa";
import { FiBox, FiTarget, FiUsers } from "react-icons/fi";
import { GiFireFlower } from "react-icons/gi";
import { GoTasklist } from "react-icons/go";
import { RiCoupon2Line, RiUserSettingsLine } from "react-icons/ri";
import { RxHome } from "react-icons/rx";
import { TbCoinRupee, TbReceiptTax, TbReport } from "react-icons/tb";
import { SiAdobeaudition } from "react-icons/si";
import { LiaFileInvoiceSolid } from "react-icons/lia";
import { TiMessages } from "react-icons/ti";
import { Link, useNavigate } from "react-router-dom";
import userPng from "../../../assets/images/user.png";
import AuthContext from "../../Auth/AuthContext";
import AuditLogs from "../../../Pages/E-Commerce/audit-logs";
import { FaTimes } from "react-icons/fa";
import { Sidebar } from "primereact/sidebar";
import { Button } from "primereact/button";
import { IoIosPricetags } from "react-icons/io"; 
import "./SideBar.css";

const SideBar = () => {
  const navigate = useNavigate();
  const { setUser } = useContext(AuthContext);
  const { user, authPermissions } = useContext(AuthContext);
  const [permissions, setPermissions] = useState([]);
  const [userId, setUserId] = useState("");
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    let user = localStorage.getItem("userDetails");
    let user_id = JSON.parse(user);
    setUserId(user_id?.id);
  }, []);

  return (
    <Fragment>
      <div id="header_top" className="header_top false">
        <div className="container">
          <div className="hleft">
            <Link to="/">
              <a aria-current="page" className="header-brand active">
                <img src="/favicons/favicon-32x32.png" />
              </a>
            </Link>
          </div>
          <div id="responsiveDrop" className="hright">
            <div>
              <div
                className="dropdown dropResponsive d-flex tex-center"
                id="userIcon"
              >
                <Link
                  to={`/admin/userprofile/${userId}`}
                  className="nav-link"
                  style={{ marginLeft: "3px" }}
                  // data-toggle="dropdown"
                >
                  <FaUserCircle size={25} style={{ color: "white" }} />
                </Link>
                <div style={{ marginTop: "1rem" }}>
                  <p className="text-center " onClick={() => setVisible(true)}>
                    {" "}
                    <i
                      className="fa  fa-align-left text-white"
                      style={{ fontSize: "20px" }}
                    ></i>
                  </p>
                </div>
              </div>
              <div>
                <Sidebar
                  visible={visible}
                  onHide={() => setVisible(false)}
                  style={{ background: "rgb(218,232,243)" }}
                >
                  <div>
                    <h5 className="brand-name">
                      {process.env.REACT_APP_APP_NAME}
                    </h5>
                    <nav id="left-sidebar-nav" className="sidebar-nav">
                      <div className="">
                        <ul className="metismenu">
                          <li className="">
                            <span className="g_heading ">Directories</span>
                          </li>
                          {/* Nm india list */}
                          <li className="text-white">
                            <span className="">
                              <a
                                aria-current="page"
                                className="list-a"
                                href="/events"
                              >
                                {/* <i className="icon-rocket"></i> */}
                                <AiOutlineArrowDown
                                  className="mr-2 "
                                  size={18}
                                />
                                Pragati
                                <i className="caret-left"></i>
                              </a>
                            </span>
                            <ul className="collapse collapse collapse collapse collapse collapse collapse collapse collapse collapse">
                              <li
                                className=""
                                onClick={() => setVisible(false)}
                              >
                                <span className="">
                                  <Link
                                    className="list-a"
                                    to="/admin/dashboard"
                                  >
                                    <RxHome className="mr-2" size="16" />
                                    Dashboard
                                  </Link>
                                </span>
                              </li>
                              {/* NM Staff Start */}
                              {authPermissions.includes("Staff-List") ===
                              true ? (
                                <li
                                  className=""
                                  onClick={() => setVisible(false)}
                                >
                                  <span className="">
                                    <Link className="list-a" to="/admin/users">
                                      <FiUsers className="mr-2" size="16" />
                                      Users
                                    </Link>
                                  </span>
                                </li>
                              ) : null}
                              {/* NM Staff End  */}

                              {/* role & permission */}
                              {authPermissions.includes(
                                "Role & Permission-List"
                              ) === true ? (
                                <li
                                  className=""
                                  onClick={() => setVisible(false)}
                                >
                                  <span className="">
                                    <Link className="list-a" to="/admin/roles">
                                      <CgUserList className="mr-2" size="16" />
                                      Roles & Permissions
                                    </Link>
                                  </span>
                                </li>
                              ) : null}

                              {/* Dealer Start */}
                              {authPermissions.includes("Dealer-List") ===
                              true ? (
                                <li
                                  className=""
                                  onClick={() => setVisible(false)}
                                >
                                  <span className="">
                                    <Link className="list-a" to="/admin/dealer">
                                      <FaRegHandshake
                                        className="mr-2"
                                        size="16"
                                      />
                                      Dealers
                                    </Link>
                                  </span>
                                </li>
                              ) : null}
                              {/* Dealer End */}

                              {/* Product Start */}
                              {authPermissions.includes("Product-List") ===
                              true ? (
                                <li
                                  className=""
                                  onClick={() => setVisible(false)}
                                >
                                  <span className="">
                                    <Link
                                      className="list-a"
                                      to="/admin/product"
                                    >
                                      <FiBox className="mr-2" size="16" />
                                      Products
                                    </Link>
                                  </span>
                                </li>
                              ) : null}
                              {/* Product End */}

                              {/* Role & Permission Start */}
                              {/* Role & Permission End */}

                              {/* <li className="">
										<span className="">
											<Link className="list-a" to="/admin/permission">
												Permission
											</Link>
										</span>
									</li>

									<li className="">
										<span className="">
											<Link className="list-a" to="/admin/userrole">
												User Role
											</Link>
										</span>
									</li>

									<li className="">
										<span className="">
											<Link className="list-a" to="/admin/rolepermission">
												Role Permission
											</Link>
										</span>
									</li> */}
                              {/* Order Start */}
                              {authPermissions.includes("Order-List") ===
                              true ? (
                                <li
                                  className=""
                                  onClick={() => setVisible(false)}
                                >
                                  <span className="">
                                    <Link className="list-a" to="/admin/order">
                                      <GoTasklist className="mr-2" size="16" />
                                      Orders
                                    </Link>
                                  </span>
                                </li>
                              ) : null}
                              {/* Order End */}
                              {/* Logistic Start */}
                              {authPermissions.includes("Logistic-List") ===
                              true ? (
                                <li
                                  className=""
                                  onClick={() => setVisible(false)}
                                >
                                  <span className="">
                                    <Link
                                      className="list-a"
                                      to="/admin/logistics"
                                    >
                                      <BiSitemap className="mr-2" size="16" />{" "}
                                      Logistics
                                    </Link>
                                  </span>
                                </li>
                              ) : null}
                              {/* Logistic End */}

                              {/* Dispatch Start */}
                              {authPermissions.includes("Dispatch-List") ===
                              true ? (
                                <li
                                  className=""
                                  onClick={() => setVisible(false)}
                                >
                                  <span className="">
                                    <Link
                                      className="list-a"
                                      to="/admin/dispatch"
                                    >
                                      <FaShippingFast
                                        className="mr-2"
                                        size="16"
                                      />
                                      Dispatched
                                    </Link>
                                  </span>
                                </li>
                              ) : null}

                              {/* Dispatch End */}

                              {/* Account Start */}
                              {authPermissions.includes("Account-List") ===
                              true ? (
                                <li
                                  className=""
                                  onClick={() => setVisible(false)}
                                >
                                  <span className="">
                                    <Link
                                      className="list-a"
                                      to="/admin/account "
                                    >
                                      <TbCoinRupee className="mr-2" size="16" />{" "}
                                      Accounts
                                    </Link>
                                  </span>
                                </li>
                              ) : null}
                              {/* Account End */}

                              {/* Price Circular Start */}
                              {authPermissions.includes("Price Circular-List") ===
                              true ? (
                                <li
                                  className=""
                                  onClick={() => setVisible(false)}
                                >
                                  <span className="">
                                    <Link
                                      className="list-a"
                                      to="/admin/price-circular"
                                    >
                                      <IoIosPricetags className="mr-2" size="16" />{" "}
                                      Price Circular
                                    </Link>
                                  </span>
                                </li>
                              ) : null}
                              {/* Price Circular End */}

                              {/* Settings Start */}
                              {/* {authPermissions.includes('Settings-List') === true ? (
										<li className="">
											<span className="">
												<Link className="list-a" to="/admin/settings">
													<AiOutlineSetting className="mr-2" size="16" />
													Settings
												</Link>
											</span>
										</li>
									) : null} */}

                              {/* Season Start */}
                              {authPermissions.includes("Season-List") ===
                              true ? (
                                <li
                                  className=""
                                  onClick={() => setVisible(false)}
                                >
                                  <span className="">
                                    <Link className="list-a" to="/admin/season">
                                      <GiFireFlower
                                        className="mr-2"
                                        size="16"
                                      />
                                      Season
                                    </Link>
                                  </span>
                                </li>
                              ) : null}
                              {/* Season End */}
                              {/* Sales Target Start */}
                              {authPermissions.includes("Sales Target-List") ===
                              true ? (
                                <li
                                  className=""
                                  onClick={() => setVisible(false)}
                                >
                                  <span className="">
                                    <Link
                                      className="list-a"
                                      to="/admin/sales-target"
                                    >
                                      <FiTarget className="mr-2" size="16" />
                                      Sales Targets
                                    </Link>
                                  </span>
                                </li>
                              ) : null}
                              {/* Sales Target End */}
                              {authPermissions.includes("Master Report-List") ==
                              true ? (
                                <li
                                  className=""
                                  onClick={() => setVisible(false)}
                                >
                                  <span className="">
                                    <Link
                                      className="list-a"
                                      to="/admin/reports"
                                    >
                                      <TbReport className="mr-2" size="16" />
                                      Reports
                                    </Link>
                                  </span>
                                </li>
                              ) : null}

                              {authPermissions.includes("Audit Logs-List") ===
                              true ? (
                                <li
                                  className=""
                                  onClick={() => setVisible(false)}
                                >
                                  <span className="">
                                    <Link
                                      className="list-a"
                                      to="/admin/audit-logs"
                                    >
                                      {/* <TbReport className="mr-2" size="16" /> */}
                                      <SiAdobeaudition
                                        className="mr-2"
                                        size="16"
                                      />
                                      Audit Logs
                                    </Link>
                                  </span>
                                </li>
                              ) : null}

                              {authPermissions.includes("Invoices-List") ===
                              true ? (
                                <li
                                  className=""
                                  onClick={() => setVisible(false)}
                                >
                                  <span className="">
                                    <Link
                                      className="list-a"
                                      to="/admin/invoices"
                                    >
                                      {/* <TbReport  /> */}
                                      <LiaFileInvoiceSolid
                                        className="mr-2"
                                        size="16"
                                      />
                                      Invoices
                                    </Link>
                                  </span>
                                </li>
                              ) : null}
                              {/* Settings End */}
                            </ul>
                          </li>

                          {/* E-commerce list Start */}
                          {authPermissions.includes("Ecommerce-List") ===
                          true ? (
                            <>
                              <li className="">
                                <span className="">
                                  <a
                                    aria-current="page"
                                    className="list-a"
                                    href="/events"
                                  >
                                    {/* <i className="icon-cup"></i>  */}
                                    <AiOutlineArrowDown
                                      className="mr-2"
                                      size={18}
                                    />
                                    E-Commerce
                                    <i className="caret-left"></i>
                                  </a>
                                </span>
                                <ul className="collapse collapse collapse collapse collapse collapse collapse">
                                  <li
                                    className=""
                                    onClick={() => setVisible(false)}
                                  >
                                    <span className="">
                                      <Link
                                        className="list-a"
                                        to="/admin/Ecom-orders"
                                      >
                                        <GoTasklist
                                          className="mr-2"
                                          size="16"
                                        />
                                        Orders
                                      </Link>
                                    </span>
                                  </li>

                                  <li
                                    className=""
                                    onClick={() => setVisible(false)}
                                  >
                                    <span className="">
                                      <Link
                                        className="list-a"
                                        to="/admin/return-order"
                                      >
                                        <GoTasklist
                                          className="mr-2"
                                          size="16"
                                        />
                                        Return Orders
                                      </Link>
                                    </span>
                                  </li>

                                  <li
                                    className=""
                                    onClick={() => setVisible(false)}
                                  >
                                    <span className="">
                                      <Link
                                        className="list-a"
                                        to="/admin/customer"
                                      >
                                        <FiUsers className="mr-2" size="16" />
                                        Customers
                                      </Link>
                                    </span>
                                  </li>

                                  <li
                                    className=""
                                    onClick={() => setVisible(false)}
                                  >
                                    <span className="">
                                      <Link
                                        className="list-a"
                                        to="/admin/productdetail"
                                      >
                                        <FiBox className="mr-2" size="16" />
                                        Product Details
                                      </Link>
                                    </span>
                                  </li>

                                  {/* Category Start  */}
                                  {authPermissions.includes("Category-List") ===
                                  true ? (
                                    <li
                                      className=""
                                      onClick={() => setVisible(false)}
                                    >
                                      <span className="">
                                        <Link
                                          className="list-a"
                                          to="/admin/category"
                                        >
                                          <BiCategoryAlt
                                            className="mr-2"
                                            size="16"
                                          />
                                          Categories
                                        </Link>
                                      </span>
                                    </li>
                                  ) : null}

                                  <li
                                    className=""
                                    onClick={() => setVisible(false)}
                                  >
                                    <span className="">
                                      <Link
                                        className="list-a"
                                        to="/admin/blogtype"
                                      >
                                        <TiMessages
                                          className="mr-2"
                                          size="16"
                                        />
                                        Blog Categories
                                      </Link>
                                    </span>
                                  </li>

                                  <li
                                    className=""
                                    onClick={() => setVisible(false)}
                                  >
                                    <span className="">
                                      <Link className="list-a" to="/admin/blog">
                                        <BiMessageAltEdit
                                          className="mr-2"
                                          size="16"
                                        />
                                        Blogs
                                      </Link>
                                    </span>
                                  </li>

                                  <li
                                    className=""
                                    onClick={() => setVisible(false)}
                                  >
                                    <span className="">
                                      <Link
                                        className="list-a"
                                        to="/admin/gallery"
                                      >
                                        <BsImages className="mr-2" size="16" />
                                        Gallery
                                      </Link>
                                    </span>
                                  </li>

                                  {/* <li className="">
												<span className="">
													<Link className="list-a" to="/admin/settings">
														<AiOutlineSetting className="mr-2" size="16" />
														Settings
													</Link>
												</span>
											</li> */}

                                  <li
                                    className=""
                                    onClick={() => setVisible(false)}
                                  >
                                    <span className="">
                                      <Link
                                        className="list-a"
                                        to="/admin/enquiry"
                                      >
                                        <BsPatchQuestion
                                          className="mr-2"
                                          size="16"
                                        />
                                        Inquiries
                                      </Link>
                                    </span>
                                  </li>

                                  <li
                                    className=""
                                    onClick={() => setVisible(false)}
                                  >
                                    <span className="">
                                      <Link
                                        className="list-a"
                                        to="/admin/career"
                                      >
                                        <FaNetworkWired
                                          className="mr-2"
                                          size="16"
                                        />
                                        Careers
                                      </Link>
                                    </span>
                                  </li>

                                  <li
                                    className=""
                                    onClick={() => setVisible(false)}
                                  >
                                    <span className="">
                                      <Link
                                        className="list-a"
                                        to="/admin/coupon"
                                      >
                                        <RiCoupon2Line
                                          className="mr-2"
                                          size="16"
                                        />
                                        Coupons
                                      </Link>
                                    </span>
                                  </li>
                                </ul>
                              </li>
                            </>
                          ) : null}

                          {/* Website Templates */}
                          {authPermissions.includes("Website-List") == true ? (
                            <li className="">
                              <span className="">
                                <a
                                  aria-current="page"
                                  className="list-a"
                                  href="/events"
                                >
                                  {/* <i className="icon-cup"></i>  */}
                                  <AiOutlineArrowDown
                                    className="mr-2"
                                    size={18}
                                  />
                                  Website
                                  <i className="caret-left"></i>
                                </a>
                              </span>
                              <ul className="collapse collapse collapse collapse collapse collapse collapse">
                                <li
                                  className=""
                                  onClick={() => setVisible(false)}
                                >
                                  <span className="">
                                    <Link
                                      className="list-a"
                                      to="/admin/menu-listing"
                                    >
                                      <TbReceiptTax
                                        className="mr-2"
                                        size="16"
                                      />
                                      Menus
                                    </Link>
                                  </span>
                                </li>
                                <li
                                  className=""
                                  onClick={() => setVisible(false)}
                                >
                                  <span className="">
                                    <Link
                                      className="list-a"
                                      to="/admin/home-template"
                                    >
                                      <TbReceiptTax
                                        className="mr-2"
                                        size="16"
                                      />
                                      Home Template
                                    </Link>
                                  </span>
                                </li>
                                <li
                                  className=""
                                  onClick={() => setVisible(false)}
                                >
                                  <span className="">
                                    <Link
                                      className="list-a"
                                      to="/admin/about-template"
                                    >
                                      <TbReceiptTax
                                        className="mr-2"
                                        size="16"
                                      />
                                      About Template
                                    </Link>
                                  </span>
                                </li>
                                <li
                                  className=""
                                  onClick={() => setVisible(false)}
                                >
                                  <span className="">
                                    <Link
                                      className="list-a"
                                      to="/admin/leadership-template"
                                    >
                                      <TbReceiptTax
                                        className="mr-2"
                                        size="16"
                                      />
                                      Leadership Template
                                    </Link>
                                  </span>
                                </li>
                                <li
                                  className=""
                                  onClick={() => setVisible(false)}
                                >
                                  <span className="">
                                    <Link
                                      className="list-a"
                                      to="/admin/blog-listing"
                                    >
                                      <TbReceiptTax
                                        className="mr-2"
                                        size="16"
                                      />
                                      Blog Template
                                    </Link>
                                  </span>
                                </li>
                                <li
                                  className=""
                                  onClick={() => setVisible(false)}
                                >
                                  <span className="">
                                    <Link
                                      className="list-a"
                                      to="/admin/news-events-template"
                                    >
                                      <TbReceiptTax
                                        className="mr-2"
                                        size="16"
                                      />
                                      News & Events Template
                                    </Link>
                                  </span>
                                </li>
                              </ul>
                            </li>
                          ) : null}
                        </ul>
                      </div>
                    </nav>
                  </div>
                </Sidebar>
                {/* <p className="text-center " onClick={() => setVisible(true)}>
                  {" "}
                  <i
                    className="fa  fa-align-left text-white"
                    style={{ fontSize: "20px" }}
                  ></i>
                </p> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default SideBar;
