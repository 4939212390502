import { useFormik } from "formik";
import React, { useContext, useEffect, useState } from "react";
import { FaGreaterThan } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { showErrorToast } from "../../../Toster";
import AlertContext from "../../../context/Alert/AlertContext";
import axiosPrivate from "../../../hooks/axiosPrivate";
import classes from "./Blog.module.css";
const validationRules = {
  title: Yup.string().required("Blog title is required"),
  slug: Yup.string().required("Slug is required"),
  description: Yup.string().required("Detail description is required"),
  short_description: Yup.string().required("Short description is required"),
  featured_image: Yup.string().required("Featured image is required"),
  alt: Yup.string().required("Featured image alt is required"),
  meta_title: Yup.string().required("Meta title is required"),
  keywords: Yup.string().required("Keywords is required"),
  meta_description: Yup.string().required("Meta description is required"),
  og_title: Yup.string().required(" OG title is required"),
  og_image: Yup.string().required("OG image is required"),
  og_alt: Yup.string().required("OG alt is required"),
  og_description: Yup.string().required("OG description is required"),
  blog_type: Yup.string().required("Blog category is required"),
  publish_date: Yup.string().required("Publish date is required"),
  tags: Yup.string().required("Tags is required"),
  status: Yup.string().required("Status is required"),
};

const AddBlog = () => {
  const { setAlertMessage } = useContext(AlertContext);
  const navigate = useNavigate();
  const [blogTypeList, setBlogTypeList] = useState([]);

  // get all  Blog type
  useEffect(() => {
    axiosPrivate.get("blog-type").then((blogtype) => {
      setBlogTypeList(blogtype?.data?.data);
    });
  }, []);

  // formik(add)
  const formik = useFormik({
    initialValues: {
      title: "",
      slug: "",
      description: "",
      short_description: "",
      featured_image: "",
      alt: "",
      meta_title: "",
      keywords: "",
      meta_description: "",
      og_title: "",
      og_image: "",
      og_alt: "",
      og_description: "",
      blog_type: "",
      publish_date: "",
      tags: "",
      status: "",
    },
    validationSchema: Yup.object(validationRules),
    onSubmit: async (values, { setSubmitting, setFieldError }) => {
      if (values.status === "Draft") {
        var status = 0;
      } else if (values.status === "Pending") {
        var status = 1;
      } else if (values.status === "Published") {
        var status = 2;
      }
      var formData = new FormData();

      formData.append("title", values.title);
      formData.append("slug", values.slug);
      formData.append("description", values.description);
      formData.append("short_description", values.short_description);
      formData.append("featured_image", values.featured_image);
      formData.append("alt", values.alt);
      formData.append("meta_title", values.meta_title);
      formData.append("keywords", values.keywords);
      formData.append("meta_description", values.meta_description);
      formData.append("og_title", values.og_title);
      formData.append("og_image", values.og_image);
      formData.append("og_alt", values.og_alt);
      formData.append("og_description", values.og_description);
      formData.append("blog_type", values.blog_type);
      formData.append("publish_date", values.publish_date);
      formData.append("tags", values.tags);
      formData.append("status", status);

      axiosPrivate
        .post("blogs/create", formData)
        .then((response) => {
          setAlertMessage({ message: "New  Blog Successfully" });
          navigate("/admin/blog");
        })
        .catch((error) => {
          if (typeof error.response.data.errors == "array") {
            showErrorToast(error.response.data.errors[0].msg);
          } else {
            showErrorToast(error.response.data.errors.msg);
          }
        });
    },
  });

  return (
    <div>
      <div className="section-body ">
        <div className="container-fluid">
          {/* hearder breadcrumb */}
          <div className="card-header">
            <strong className="top-left-heading">Add Blog</strong>

            <ol className="headerroutes breadcrumb m-0 bg-none ">
              <li className="item color-gray">|</li>
              <li className="item">
                <Link to="/admin/dashboard" className="route-heading item mx-2">
                  Dashboard
                </Link>
              </li>
              <li className="item">
                <FaGreaterThan className="grater-than-route" />
              </li>
              <li className="route-heading item mx-2 mt-1">
                <Link to="/admin/blog">Blogs</Link>
              </li>
              <li className="item">
                <FaGreaterThan className="grater-than-route" />
              </li>
              <li className="route-heading item mx-2 mt-1">Add Blog </li>
            </ol>
          </div>

          <div className="tab-content ">
            <form onSubmit={formik.handleSubmit}>
              <div
                className="tab-pane fade show active"
                id="user-add"
                role="tabpanel"
              >
                <div className="card">
                  <div className="card-body">
                    <div className="row clearfix">
                      {/*   blog   Title */}
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <label>
                          Blog Title:<span className="required">*</span>
                        </label>
                        <div className="form-group">
                          <input
                            id="title"
                            name="title"
                            type="text"
                            className="form-control"
                            placeholder="Blog Title "
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.title}
                          />
                          {formik.touched.title && formik.errors.title ? (
                            <div className={classes.invalidDataError}>
                              {formik.errors.title}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      {/*   blog Slug */}
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <label>
                          Slug:<span className="required">*</span>
                        </label>
                        <div className="form-group">
                          <input
                            id="slug"
                            name="slug"
                            type="text"
                            className="form-control"
                            placeholder="Slug Name"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.slug}
                          />
                          {formik.touched.slug && formik.errors.slug ? (
                            <div className={classes.invalidDataError}>
                              {formik.errors.slug}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      {/*  short Description */}
                      <div className="col-lg-12 col-md-12 col-sm-12">
                        <label>
                          Short Description:
                          <span className="required">*</span>
                        </label>
                        <div className="form-group">
                          <textarea
                            id="short_description"
                            name="short_description"
                            type="text"
                            className="form-control"
                            placeholder=" Short Description "
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.short_description}
                          />
                          {formik.touched.short_description &&
                          formik.errors.short_description ? (
                            <div className={classes.invalidDataError}>
                              {formik.errors.short_description}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      {/* Detail  Description */}
                      <div className="col-lg-12 col-md-12 col-sm-12">
                        <label>
                          Detail Description:
                          <span className="required">*</span>
                        </label>
                        <div className="form-group">
                          <textarea
                            id="description"
                            name="description"
                            type="text"
                            className="form-control"
                            placeholder="Description "
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.description}
                          />
                          {formik.touched.description &&
                          formik.errors.description ? (
                            <div className={classes.invalidDataError}>
                              {formik.errors.description}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      {/*Featured Image*/}
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <label>
                          Featured Image:<span className="required">*</span>
                        </label>
                        <div className="form-group">
                          <input
                            id="featured_image"
                            name="featured_image"
                            type="file"
                            className="form-control"
                            placeholder="Blog Title "
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.featured_image}
                          />
                          {formik.touched.featured_image &&
                          formik.errors.featured_image ? (
                            <div className={classes.invalidDataError}>
                              {formik.errors.featured_image}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      {/*Featured   images alt*/}
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <label>
                          Featured Image Alt:<span className="required">*</span>
                        </label>
                        <div className="form-group">
                          <input
                            id="alt"
                            name="alt"
                            type="text"
                            className="form-control"
                            placeholder="Featured Image Alt"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.alt}
                          />
                          {formik.touched.alt && formik.errors.alt ? (
                            <div className={classes.invalidDataError}>
                              {formik.errors.alt}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      {/*Meta Title heading*/}
                      <div className="col-lg-12 col-md-12 col-sm-12 ">
                        <p
                          className="my-3"
                          style={{ fontWeight: "700", fontSize: "20px" }}
                        >
                          Meta Section
                        </p>
                      </div>

                      {/*Meta Title*/}
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <label>
                          Meta Title:<span className="required">*</span>
                        </label>
                        <div className="form-group">
                          <input
                            id="meta_title"
                            name="meta_title"
                            type="text"
                            className="form-control"
                            placeholder="Meta Title"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.meta_title}
                          />
                          {formik.touched.meta_title &&
                          formik.errors.meta_title ? (
                            <div className={classes.invalidDataError}>
                              {formik.errors.meta_title}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      {/*Keywords*/}
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <label>
                          Keywords:<span className="required">*</span>
                        </label>
                        <div className="form-group">
                          <input
                            id="keywords"
                            name="keywords"
                            type="text"
                            className="form-control"
                            placeholder="Keywords"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.keywords}
                          />
                          {formik.touched.keywords && formik.errors.keywords ? (
                            <div className={classes.invalidDataError}>
                              {formik.errors.keywords}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      {/*  meta Description */}
                      <div className="col-lg-12 col-md-12 col-sm-12">
                        <label>
                          Meta Description:
                          <span className="required">*</span>
                        </label>
                        <div className="form-group">
                          <textarea
                            id="meta_description"
                            name="meta_description"
                            type="text"
                            className="form-control"
                            placeholder="Meta Description "
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.meta_description}
                          />
                          {formik.touched.meta_description &&
                          formik.errors.meta_description ? (
                            <div className={classes.invalidDataError}>
                              {formik.errors.meta_description}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      {/* Og  Section heading*/}
                      <div className="col-lg-12 col-md-12 col-sm-12">
                        <p
                          className="my-3"
                          style={{ fontWeight: "700", fontSize: "20px" }}
                        >
                          OG Section
                        </p>
                      </div>

                      {/* Og Title*/}
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <label>
                          Og Title:<span className="required">*</span>
                        </label>
                        <div className="form-group">
                          <input
                            id="og_title"
                            name="og_title"
                            type="text"
                            className="form-control"
                            placeholder="Og Title"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.og_title}
                          />
                          {formik.touched.og_title && formik.errors.og_title ? (
                            <div className={classes.invalidDataError}>
                              {formik.errors.og_title}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      {/* Og  Image*/}
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <label>
                          Og Image:<span className="required">*</span>
                        </label>
                        <div className="form-group">
                          <input
                            id="og_image"
                            name="og_image"
                            type="file"
                            className="form-control"
                            placeholder="Og  Image"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.og_image}
                          />
                          {formik.touched.og_image && formik.errors.og_image ? (
                            <div className={classes.invalidDataError}>
                              {formik.errors.og_image}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      {/* Og  Alt*/}
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <label>
                          Og Alt:<span className="required">*</span>
                        </label>
                        <div className="form-group">
                          <input
                            id="og_alt"
                            name="og_alt"
                            type="text"
                            className="form-control"
                            placeholder="Og   Alt"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.og_alt}
                          />
                          {formik.touched.og_alt && formik.errors.og_alt ? (
                            <div className={classes.invalidDataError}>
                              {formik.errors.og_alt}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      {/*  Og Description */}
                      <div className="col-lg-12 col-md-12 col-sm-12">
                        <label>
                          Og Description:
                          <span className="required">*</span>
                        </label>
                        <div className="form-group">
                          <textarea
                            id="og_description"
                            name="og_description"
                            type="text"
                            className="form-control"
                            placeholder="Og Description "
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.og_description}
                          />
                          {formik.touched.og_description &&
                          formik.errors.og_description ? (
                            <div className={classes.invalidDataError}>
                              {formik.errors.og_description}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      {/*  Blog  Category */}

                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <label>
                          Blog Category:<span className="required">*</span>
                        </label>

                        <div className="form-group">
                          <select
                            className="form-control show-tick"
                            id="role"
                            name="blog_type"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.blog_type}
                          >
                            <option>Select Blog Type</option>
                            {blogTypeList.map((role, key) => (
                              <option key={key + role.id} value={role.id}>
                                {role.name}
                              </option>
                            ))}
                          </select>
                          {formik.touched.blog_type &&
                          formik.errors.blog_type ? (
                            <div className={classes.invalidDataError}>
                              {formik.errors.blog_type}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      {/*  publish date*/}
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <label>
                          Publish Date:<span className="required">*</span>
                        </label>
                        <div className="form-group">
                          <input
                            id="publish_date"
                            name="publish_date"
                            type="date"
                            className="form-control"
                            placeholder="Publish Data"
                            onChange={formik.handleChange}
							max="9999-12-31"
                            min={new Date().toISOString().slice(0, 10)}
                            onBlur={formik.handleBlur}
                            value={formik.values.publish_date}
                          />
                          {formik.touched.publish_date &&
                          formik.errors.publish_date ? (
                            <div className={classes.invalidDataError}>
                              {formik.errors.publish_date}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      {/*Tags*/}
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <label>
                          Tags:<span className="required">*</span>
                        </label>
                        <div className="form-group">
                          <input
                            id="tags"
                            name="tags"
                            type="text"
                            className="form-control"
                            placeholder="Tags"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.tags}
                          />
                          {formik.touched.tags && formik.errors.tags ? (
                            <div className={classes.invalidDataError}>
                              {formik.errors.tags}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      {/*   status */}

                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <label>
                          Status:<span className="required">*</span>
                        </label>

                        <div className="form-group">
                          <select
                            className="form-control show-tick"
                            id="status"
                            name="status"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.status}
                          >
                            <option value="" disabled selected>
                              Select Status
                            </option>
                            <option>Draft</option>
                            <option>Pending</option>
                            <option>Published</option>
                          </select>

                          {formik.touched.status && formik.errors.status ? (
                            <div className={classes.invalidDataError}>
                              {formik.errors.status}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      {/* button close and submitF */}
                      <div className="col-12 submit-btn">
                        <button
                          type="button"
                          className="btn btn-secondary"
                          data-dismiss="modal"
                          onClick={() => navigate("/admin/blog")}
                        >
                          Close
                        </button>
                        <button type="submit" className="btn btn-primary ml-2">
                          Submit
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddBlog;
