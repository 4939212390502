import { useEffect, useState } from 'react';
import { FaGreaterThan } from 'react-icons/fa';
import { GrView } from 'react-icons/gr';
import { Link } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { showErrorToast } from '../../../Toster';
import DataTable2 from '../../../context/components/DataTable2';
import axiosPrivate from '../../../hooks/axiosPrivate';


const Enquiry = () => {
	const [enquiryState, setEnquiryState] = useState([]);

	const headers = [
		{ name: 'NAME', field: 'name', sortable: true, classKey: '' },
		{ name: 'CONTACT NO.', field: 'mobile', sortable: true, classKey: '' },
		{ name: 'EMAIL', field: 'email', sortable: true, classKey: '' },

		{ name: 'SUBJECT', field: 'subject', sortable: true, classKey: '' },

		{ name: 'ACTION', field: 'action', classKey: '' },
	];
	const searchItems = ['name', 'email', 'mobile'];

	// get All businessenquiry
	useEffect(() => {
		const options = {
			headers: { 'content-type': 'application/json' },
		};
		axiosPrivate
			.get('businessenquiry', options)
			.then((enquiry) => {
				const enquiryData = enquiry?.data?.data?.map((value, key) => {
					let buttons = [];

					if (true)
						buttons.push(
							<Link
								key={`viewButton##${key}`}
								type="button"
								to={`/admin/enquiry/view/${value.id}`}
								className="btn btn-icon"
								title="View"
							>
								<GrView style={{ color: 'blue' }} size={18} />
							</Link>
						);

					value['action'] = buttons.length > 0 ? buttons : '-';
					return value;
				});

				setEnquiryState(enquiryData);
			})
 	}, []);

	return (
		<>
			<div className="section-body">
				<div className="container-fluid">
					{/* hearder breadcrumb */}
					<div className="card-header">
						<strong className="top-left-heading">Inquiries</strong>

						<ol className="headerroutes breadcrumb m-0 bg-none ">
							<li className="item color-gray">|</li>
							<li className="item">
								<Link to="/admin/dashboard" className="route-heading item mx-2">
									Dashboard
								</Link>
							</li>
							<li className="item">
								<FaGreaterThan className="grater-than-route" />
							</li>
							<li className="route-heading item mx-2 mt-1">Inquiries</li>
						</ol>
					</div>

					<div className="tab-content">
						<div
							className="tab-pane fade show active"
							id="enquiry-list"
							role="tabpanel"
						>
							<div className="card">
								
								<div className="card-body">
									<div className="table-responsive">
										{
											<DataTable2
												lists={enquiryState}
												headers={headers}
												searchItems={searchItems}
											/>
										}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Enquiry;
