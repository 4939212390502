import { useFormik } from 'formik';
import React, { useContext, useEffect, useState } from 'react';
import { FaGreaterThan } from 'react-icons/fa';
import { Link, useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { showErrorToast } from '../../../Toster';
import AlertContext from '../../../context/Alert/AlertContext';
import axiosPrivate from '../../../hooks/axiosPrivate';
import classes from './ProductDetail.module.css';

const imageURL = process.env.REACT_APP_COMMON_FILE_URL;
//  validation
const validationRules = {
	name: Yup.string().required('Product name is required'),
	category_id: Yup.string().required('Category is required'),
	description: Yup.string().required('Product description is required'),
	image: Yup.mixed().required('Product image is required'),
	alt: Yup.string().required('Image alt is required'),
	gst_applicable: Yup.string().required('Gst applicable is required'),
	slug: Yup.string().required('Product slug is required'),
};

const EditProductDetail = () => {
	let { id } = useParams();

	const { setAlertMessage } = useContext(AlertContext);
	const navigate = useNavigate();
	const [productList, setProductList] = useState([]);
	const [productPrice, setProductPrice] = useState('');
	const [getData, setGetData] = useState({});
	const [categoryList, setCategoryList] = useState([]);

	//   product detail get by id
	useEffect(() => {
		axiosPrivate
			.get(`product-details/${id}`)
			.then((response) => {
				if (response.data.code === 200) {
					formik.setFieldValue('name', response.data.data?.name);
					formik.setFieldValue('category_id', response.data.data?.category_id);
					formik.setFieldValue('description', response.data.data?.description);
					formik.setFieldValue('image', response.data.data.product_images[0]?.image);
					formik.setFieldValue('alt', response.data.data.product_images[0]?.alt);
					formik.setFieldValue('gst_applicable', response.data.data?.gst_applicable);
					formik.setFieldValue('gst', response.data.data?.gst);
					formik.setFieldValue('meta_title', response.data.data?.meta_title);
					formik.setFieldValue('meta_description', response.data.data?.meta_description);
					formik.setFieldValue('meta_keywords', response.data.data?.meta_keywords);
					formik.setFieldValue('structured_data', response.data.data?.structured_data);
					formik.setFieldValue('slug', response.data.data?.slug);
				}
				formik.setErrors({});
			})
	}, []);

	useEffect(() => {
		if (getData != '') {
			formik.setValues({ ...getData });
		}
	}, [getData]);

	// product list
	useEffect(() => {
		//Product List start
		axiosPrivate
			.post('stocks')
			.then((products) => {
				setProductList(products?.data?.data);
			})
		//Product List end
	}, []);

	//  category list
	useEffect(() => {
		//category List start
		axiosPrivate
			.get('categories/get')
			.then((category) => {
				setCategoryList(category?.data?.data);
			})
		//category List end
	}, []);

	// product list onChanege .......set price
	const productListOnChange = (event) => {
		const eventProductVal = event.target.value;
		const selectedProduct = productList.find(
			(product) => product.id === eventProductVal
		);
		if (selectedProduct) {
			const productRate = selectedProduct.rate;
			setProductPrice(productRate);
			formik.setFieldValue('rate', productRate); // Update formik.values.rate
		}
	};

	//update data to api (after check validation)
	const formik = useFormik({
		initialValues: {
			name: '',
			category_id: '',
			description: '',
			image: '',
			alt: '',
			gst_applicable: '',
			gst: '',
			meta_title: '',
			meta_description: '',
			meta_keywords: '',
			structured_data: '',
			slug : ''
		},
		validationSchema: Yup.object(validationRules),
		onSubmit: async (values, { setSubmitting, setFieldError }) => {
			var formData = new FormData();
			formData.append('name', values.name);
			formData.append('category_id', values.category_id);
			formData.append('description', values.description);
			if(typeof(values.image) == 'object') {
				formData.append('image', values.image);
				formData.append('alt', values.alt);
			}
			formData.append('gst_applicable', values.gst_applicable);
			formData.append('gst', values.gst ?? 0);
			formData.append('meta_title', values.meta_title);
			formData.append('meta_description', values.meta_description);
			formData.append('meta_keywords', values.meta_keywords);
			formData.append('structured_data', values.structured_data);
			formData.append('slug', values.slug);

			axiosPrivate
				.post(`product-details/update/${id}?_method=PUT`, formData)

				.then((response) => {
					// setAlertMessage({ message: 'Product Detail  Update successfully' });
					navigate(`/admin/productdetail/edit/variant/${id}`);
				})
				.catch((error) => {
					if (typeof (error.response.data.message) == 'array') {
						showErrorToast(error.response.data.message[0]);
					} else {
						showErrorToast(error.response.data.message);
					}
				});
		},
	});

	const onPageNameBlur = (event) => {
		const { name, value } = event.target;

		if (name == 'name') {
			let slug = value
				.toLowerCase()
				.trim()
				.replace(/[^\w\s-]/g, '')
				.replace(/[\s_-]+/g, '-')
				.replace(/^-+|-+$/g, '');
			formik.setFieldValue('slug', slug);
		}

		formik.handleBlur(event);
	}


	const nameFilter = (event) => {
		const inputValue = event.target.value;
		if (/^[A-Za-z\s'-]+$/.test(inputValue) || inputValue === "") {
		  formik.setFieldValue(event.target.name, inputValue);
		}
	  };
	return (
		<>
			<div className="section-body">
				<div className="container-fluid">
					{/* hearder breadcrumb */}
					<div className="card-header">
						<strong className="top-left-heading">Edit Product Details</strong>

						<ol className="headerroutes breadcrumb m-0 bg-none ">
							<li className="item color-gray">|</li>
							<li className="item">
								<Link to="/admin/dashboard" className="route-heading item mx-2">
									Dashboard
								</Link>
							</li>
							<li className="item">
								<FaGreaterThan className="grater-than-route" />
							</li>
							<li className="route-heading item mx-2 mt-1">
								<Link to="/admin/productdetail"> Product Details</Link>
							</li>

							<li className="item">
								<FaGreaterThan className="grater-than-route" />
							</li>
							<li className="route-heading item mx-2 mt-1">
								Edit Product Details
							</li>
						</ol>
					</div>
					<div className="tab-content">
						<form onSubmit={formik.handleSubmit}>
							<div
								className="tab-pane fade show active"
								id="productdetail-add"
								role="tabpanel"
							>
								<div className="card">
									
									<div className="card-body">
										<div className="row clearfix">

											<div className="col-lg-4 col-md-4 col-sm-12">
												<div className="form-group">
													<label>
														category List:<span className="required">*</span>
													</label>
													<select
														id="category_id"
														className="form-control"
														name="category_id"
														onChange={formik.handleChange}
														onBlur={formik.handleBlur}
														value={formik.values.category_id}
													>
														<option value="">Select Product </option>
														{categoryList.map((role, index) => (
															<option key={index} value={role.id}>
																{role.name}
															</option>
														))}
													</select>
													{formik.touched.category_id &&
														formik.errors.category_id ? (
														<div className={classes.invalidDataError}>
															{formik.errors.category_id}
														</div>
													) : null}
												</div>
											</div>

											{/* Product list */}
											<div className="col-lg-4 col-md-4 col-sm-12">
												<div className="form-group">
													<label>
														Product Name:<span className="required">*</span>
													</label>

													<input
														id="name"
														name="name"
														type="text"
														className="form-control"
														placeholder="Product Name"
														onChange={nameFilter}
														onBlur={(event) => onPageNameBlur(event)}
														value={formik.values.name}
													/>
													{formik.touched.name &&
														formik.errors.name ? (
														<div className={classes.invalidDataError}>
															{formik.errors.name}
														</div>
													) : null}

													{/* <select
														id="name"
														className="form-control"
														name="name"
														onChange={(event) => {
															productListOnChange(event);
															formik.handleChange(event);
														}}
														onBlur={formik.handleBlur}
														value={formik.values.name}
													>
														<option value="">Select Product </option>
														{productList.map((role) => (
															<option key={role.id} value={role.id}>
																{role.tally_name}
															</option>
														))}
													</select>
													{formik.touched.name &&
													formik.errors.name ? (
														<div className={classes.invalidDataError}>
															{formik.errors.name}
														</div>
													) : null} */}
												</div>
											</div>
											<div className="col-lg-4 col-md-4 col-sm-12">
												<div className="form-group">
													<label>
														Product Slug:<span className="required">*</span>
													</label>

													<input
														id="slug"
														name="slug"
														type="text"
														className="form-control"
														placeholder="Product Slug"
														onChange={formik.handleChange}
														onBlur={formik.handleBlur}
														value={formik.values.slug}
														readOnly
													/>
													{formik.touched.slug &&
														formik.errors.slug ? (
														<div className={classes.invalidDataError}>
															{formik.errors.slug}
														</div>
													) : null}
												</div>
											</div>
											<div className="col-lg-4 col-md-4 col-sm-12">
												<div className="form-group">
													<label>
														GST Applicable:<span className="required">*</span>
													</label>
													<select
														id="gst_applicable"
														className="form-control"
														name="gst_applicable"
														onChange={formik.handleChange}
														onBlur={formik.handleBlur}
														value={formik.values.gst_applicable}
													>
														<option value="" disabled>Select Option</option>
														<option value="yes">Yes</option>
														<option value="no">No</option>
													</select>
													{formik.touched.gst_applicable && formik.errors.gst_applicable ? (
														<div className={classes.invalidDataError}>
															{formik.errors.gst_applicable}
														</div>
													) : null}
												</div>
											</div>
											<div className="col-lg-4 col-md-4 col-sm-12">
												<label>
													GST (%):
												</label>
												<div className="form-group">
													<input
														id="gst"
														name="gst"
														type="text"
														className="form-control"
														placeholder="Enter GST"
														onChange={formik.handleChange}
														onBlur={formik.handleBlur}
														value={formik.values.gst}
													/>
													{/* {formik.touched.rate && formik.errors.rate ? (
														<div className={classes.invalidDataError}>
															{formik.errors.rate}
														</div>
													) : null} */}
												</div>
											</div>

											{/*  Product image */}
											<div className="col-lg-4 col-md-4 col-sm-12">
												<label>
													Product Image:<span className="required">*</span>
												</label>
												<div className="form-group">
													<input
														id="image"
														name="image"
														type="file"
														accept="image/*"
														className="form-control"
														placeholder="Image *"
														onChange={(e) =>
															formik.setFieldValue(
																'image',
																e.target.files[0]
															)
														}
														onBlur={formik.handleBlur}
													/>
													<Link to={process.env.REACT_APP_COMMON_FILE_URL + 'product-details/' + formik.values.image} target='_blank' style={{ textDecoration: "underline" }}>
														Uploaded Image
													</Link>
													{formik.touched.image && formik.errors.image ? (
														<div className={classes.invalidDataError}>
															{formik.errors.image}
														</div>
													) : null}
												</div>
											</div>

											{/*  image alt */}
											<div className="col-lg-4 col-md-4 col-sm-12">
												<label>
													Image Alt:<span className="required">*</span>
												</label>
												<div className="form-group">
													<input
														id="alt"
														name="alt"
														type="text"
														className="form-control"
														placeholder="Image Alt "
														onChange={formik.handleChange}
														onBlur={formik.handleBlur}
														value={formik.values.alt}
													/>
													{formik.touched.alt && formik.errors.alt ? (
														<div className={classes.invalidDataError}>
															{formik.errors.alt}
														</div>
													) : null}
												</div>
											</div>

											{/*  product Description */}
											<div className="col-lg-8 col-md-8 col-sm-12">
												<label>
													Product Description:
													<span className="required">*</span>
												</label>
												<div className="form-group">
													<textarea
														id="description"
														name="description"
														type="text"
														className="form-control"
														placeholder="Product Description "
														onChange={formik.handleChange}
														onBlur={formik.handleBlur}
														value={formik.values.description}
													/>
													{formik.touched.description &&
														formik.errors.description ? (
														<div className={classes.invalidDataError}>
															{formik.errors.description}
														</div>
													) : null}
												</div>
											</div>
											<div className="col-lg-4 col-md-4 col-sm-12">
												<label>
													Meta Title:
												</label>
												<div className="form-group">
													<input
														id="meta_title"
														name="meta_title"
														type="text"
														className="form-control"
														placeholder="Meta Title"
														onChange={formik.handleChange}
														onBlur={formik.handleBlur}
														value={formik.values.meta_title}
													/>
												</div>
											</div>
											<div className="col-lg-4 col-md-4 col-sm-12">
												<label>
													Meta Keywords:
												</label>
												<div className="form-group">
													<input
														id="meta_keywords"
														name="meta_keywords"
														type="text"
														className="form-control"
														placeholder="Meta Keywords"
														onChange={formik.handleChange}
														onBlur={formik.handleBlur}
														value={formik.values.meta_keywords}
													/>
												</div>
											</div>
											<div className="col-lg-4 col-md-4 col-sm-12">
												<label>
													Meta Description:
												</label>
												<div className="form-group">
													<input
														id="meta_description"
														name="meta_description"
														type="text"
														className="form-control"
														placeholder="Meta Description"
														onChange={formik.handleChange}
														onBlur={formik.handleBlur}
														value={formik.values.meta_description}
													/>
												</div>
											</div>
											<div className="col-lg-4 col-md-4 col-sm-12">
												<label>
													Structured Data:
												</label>
												<div className="form-group">
													<input
														id="structured_data"
														name="structured_data"
														type="text"
														className="form-control"
														placeholder="Structured Data"
														onChange={formik.handleChange}
														onBlur={formik.handleBlur}
														value={formik.values.structured_data}
													/>
												</div>
											</div>

											{/* close and submit button */}
											<div className="col-12 submit-btn">
												<hr className="mt-4" />
												<button
													type="button"
													className="btn btn-secondary"
													data-dismiss="modal"
													onClick={() => navigate('/admin/productdetail')}
												>
													Close
												</button>
												<button
													type="submit"
													className="btn btn-primary float-right ml-2"
												>
													Update
												</button>
											</div>
										</div>
									</div>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
		</>
	);
};

export default EditProductDetail;
