import React, { useEffect, useMemo, useState } from 'react';
import ClimbingBoxLoader from 'react-spinners/ClimbingBoxLoader';
import { Pagination, Search, TableHeader } from '../DataTable';
import Header from '../Header';

const DataTable2 = ({ lists, headers, searchItems, placeholder }) => {
	const [comments, setComments] = useState([]);
	const [loader, showLoader, hideLoader] = useState(true);
	const [totalItems, setTotalItems] = useState(0);
	const [currentPage, setCurrentPage] = useState(1);
	const [search, setSearch] = useState('');
	const [sorting, setSorting] = useState({ field: '', order: '' });
	const [itemsPerPage, setItemsPerPage] = useState(10);
	const filterListLengths = [10, 25, 50, 100];
	let [color, setColor] = useState('#ffffff');

	const override = {
		display: 'block',
		margin: '0 auto',
		borderColor: 'red',
	};

	useEffect(() => {
		setComments(lists);
	}, [lists]);

	const listData = useMemo(() => {
		let computedData = comments;

		if (search && searchItems.length > 0) {
			computedData = computedData.filter((filteData) =>
				searchItems.some((field) =>
					filteData[field]?.toLowerCase().includes(search?.toLowerCase())
				)
			);
		}

		setTotalItems(computedData.length);

		//Sorting comments
		if (sorting.field) {
			const reversed = sorting.order === 'asc' ? 1 : -1;
			computedData = computedData.sort(
				(a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
			);
		}

		//Current Page slice
		return computedData.slice(
			(currentPage - 1) * itemsPerPage,
			(currentPage - 1) * itemsPerPage + itemsPerPage
		);
	}, [comments, currentPage, search, sorting, itemsPerPage]);

	const changeItemsPerPage = (e) => {
		setItemsPerPage(e.target.value);
	};

	return (
		<>
			<div className="row">
				<div className="col mb-3  text-center">
					<div className="row mb-4">
						<div className="col-md-6 col-sm-12">
							<Search
								onSearch={(value) => {
									setSearch(value);
									setCurrentPage(1);
								}}

								placeholder={placeholder}
							/>
						</div>

						<div className="col-sm-12 col-md-6 d-flex flex-row-reverse mt-2">
							<div className="float-left  ml-3">
								<label className="mr-2 ">Show</label>
								<select
									name="list_length"
									className="form-select "
									onChange={changeItemsPerPage}
									
								>
									{filterListLengths.map((value, key) => {
										
										return (
											<option key={++key} value={value}>
												{value}
											</option>
										);
									})}
								</select>
								<span className="ml-2 ">entries</span>
							</div>
						</div>
					</div>

					{/* <table className="table table-striped table-hover table-vcenter text-nowrap mb-0 "> */}
					<div className="table-responsive" style = {{border:'1px solid #000'}}>
					<table className="table table-hover table-fixed" style = {{border:'none'}}>
						<TableHeader
							headers={headers}
							onSorting={(field, order) => setSorting({ field, order })}
						/>
						<tbody>
							{listData.map((comment, idx) => {
								return (
									<tr key={idx}>
										<td style={{ textAlign: 'left'}}>{(idx + ((currentPage-1) * 10)) + 1}</td>
										{/* Serial number data */}
										{headers.map((col, colIdx) =>
											col.field !== 'image' ? (
												<td
													key={`${idx}-${colIdx}`}
													style={{ textAlign: 'left'}}
												>
													{comment[col.field]}
												</td>
											) : (
												<td
													key={`${idx}-${colIdx}`}
													style={{ textAlign: 'left'}}
												>
													<img
														src={comment[col.field]}
														style={{ width: '90px', height: '90px' }}
													/>
												</td>
											)
										)}
									</tr>
								);
							})}
						</tbody>
					</table>
                    </div>
					{/* <Pagination
						total={totalItems}
						itemsPerPage={itemsPerPage}
						currentPage={currentPage}
						onPageChange={(page) => setCurrentPage(page)}
					/> */}
				</div>
			</div>
			<div className="col-sm-12 col-md-12 d-flex justify-content-center align-items-center">
				<Pagination
					total={totalItems}
					itemsPerPage={itemsPerPage}
					currentPage={currentPage}
					onPageChange={(page) => setCurrentPage(page)}
				/>
			</div>{' '}
		</>
	);
};

export default DataTable2;
