import React, { useState } from 'react';
import { BiSearch } from 'react-icons/bi';
import { Link } from 'react-router-dom';
import BlogDetail from './BlogDetail';
import BlogAccordion from './BlogAccordion';
import moment from 'moment';

function BlogPage(props) {
    
    const [visible, setVisible] = useState(false);
    const [searchIcon, setSearchIcon] = useState(true);

    const handleSearch = (e) => {
        setSearchIcon(false);
        if ((e.target.value).length === 0) {
            setSearchIcon(true);
        }
    }

    return (
        <>
            <div className='about-container' id='about-us'>
                <div className='about-banner-outer-container'>
                    <div className='about-banner-inner-container'>
                        <h1 className='about-heading'>{props?.pageData?.pagename}</h1>
                        <p className='about-home-text'>Home/<span className='about-us-text'>{props?.pageData?.pagename}</span></p>
                    </div>
                </div>
            </div>
            {/* Accordion */}
                <BlogAccordion handleSearch={handleSearch} searchIcon={searchIcon} />

                <section className='blog-container'>
                    {/* left section */}
                    <div className='blog-menu'>
                        <div className='blog-search-container'>
                            <input type="search" name="blog-search" id="blog-search" placeholder='Enter keyword' className='blog-search' onChange={(e) => { handleSearch(e) }} />
                            <BiSearch className={`blog-search-icon ${searchIcon ? 'show' : 'hide'}`} />
                        </div>
                        <>
                            <h3 className='blog-category-heading'>Categories</h3>
                            <hr className='blog-menu-line' />
                            <ul className='blog-list'>
                                <Link to='/blogs'><li>Commercial Farming(4)</li></Link>
                                <Link to='/blogs'><li>Mixed Farming(3)</li></Link>
                                <Link to='/blogs'> <li>Plantation Farming(5)</li></Link>
                            </ul>
                        </>

                        <>
                            <h3 className='blog-category-heading'>Recent posts</h3>
                            <hr />
                            <div className='blog-menu-detail'>
                                <div className='blog-menu-img'>
                                    <img src="/img/blogs/blog-1.webp" alt="blog Image" srcSet="" loading='lazy' />
                                </div>
                                <div className='blog-post'>
                                    <p className='blog-date'>feb 01</p>
                                    <Link to='/'><h4 className='blog-detail'>Lorem ipsum dolor sit Lorem ipsum dolor sit.</h4></Link>
                                </div>
                            </div>

                            <hr />
                            <div className='blog-menu-detail'>
                                <div className='blog-menu-img'>
                                    <img src="/img/blogs/blog-2.webp" alt="blog Image" srcSet="" loading='lazy' />
                                </div>
                                <div className='blog-post'>
                                    <p className='blog-date'>feb 01</p>
                                    <Link to='/'><h4 className='blog-detail'>Lorem ipsum dolor sit Lorem ipsum dolor sit.</h4></Link>
                                </div>
                            </div>

                            <hr />
                            <div className='blog-menu-detail'>
                                <div className='blog-menu-img'>
                                    <img src="/img/blogs/blog-3.webp" alt="blog Image" srcSet="" loading='lazy' />
                                </div>
                                <div className='blog-post'>
                                    <p className='blog-date'>feb 01</p>
                                    <Link to='/'><h4 className='blog-detail'>Lorem ipsum dolor sit Lorem ipsum dolor sit.</h4></Link>
                                </div>
                            </div>
                        </>

                    </div >

                    {/* right section */}
                    <div className='blogs row'>
                        {/* blog-1 */}
                        {props?.pageData?.blogSection.map((res) =>
                            <div className='posted-blog'>
                                <div className='blog-post-img'>
                                    <button className='img-link-heading' onClick={() => setVisible(true)}>Commercial Farming</button>
                                    <img src="img/blogs/blog-4.webp" alt="Blog" loading='lazy' />
                                    <span className='img-dark-hover' />
                                </div>
                                <div className='blog-post-deatil'></div>

                                <div className='blog-post-detail'>
                                    <div className='blog-post-detail-p '>
                                        <p>
                                            {moment(res?.blogDate).format('DD MMM YYYY')}
                                            {/* {res?.blogDate} */}
                                        </p>
                                        <p className='date-line' />
                                    </div>
                                    <h3 className='blog-post-detail-title'>
                                        {res?.blogHeading}
                                    </h3>
                                    <p className='about-blog-post'>{res?.blogContent}</p>
                                    <button className='blog-read-more-btn' onClick={() => setVisible(true)}>Read more</button>
                                </div>
                            </div>
                        )}
                    </div>
                </section>

        </>
    )
}

export default React.memo(BlogPage);
