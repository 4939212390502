import React from "react";
import axiosPrivate from "../hooks/axiosPrivate";
import { useEffect } from "react";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { date } from "yup";
import moment from "moment";

const NewsEvents = (props) => {
  const jsonData1 = props;
  let jsonData = jsonData1.pageData;
  // let jsonData = {
  //   pageName: "News & Events",
  //   banner_image: "img/product-banner.png",
  //   news_events_section: [
  //     {
  //       img: "assets/images/news&Events.png",
  //       event_heading: "News & Events Title",
  //       event_sub_heading: "Lorem Ipsum",
  //       event_content:
  //         "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
  //     },
  //     {
  //       img: "assets/images/news&Events.png",
  //       event_heading: "News & Events Title",
  //       event_sub_heading: "Lorem Ipsum",
  //       event_content:
  //         "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
  //     },
  //   ],
  // };

  function getFormattedDate(date){
    return moment(date).format('DD/MM/YYYY')
  }

  return (
    <>
      <div className="product-page">
        <div className="banner-image">
          <img src={jsonData?.banner_image} />
        </div>
        <div className="product-content">
          <div className="text-center">
            <p>{jsonData?.pageName}</p>
            <span>
              Home/<span style={{ color: "#50AE31" }}>{jsonData?.pageName}</span>
            </span>
          </div>
        </div>
      </div>
      {jsonData?.news_events_section?.map((res, index) =>
        index % 2 == 0 ? (
          <>
            <div className="news-events">
              <div className="image_section">
                <div className="d-block d-lg-none">
                  <p className="event_heading">{res.event_heading}</p>
                  <span className="border_bottom"></span>
                </div>
                <div className="news_image">
                  <img src={res.img} />
                  <p className="news_date">{getFormattedDate(res.date)}</p>
                </div>
              </div>

              <div className="image_content">
                <div className="d-none d-lg-block">
                  <p className="event_heading">{res.event_heading}</p>
                  <span className="border_bottom"></span>
                </div>
                <h4 className="event_sub_heading">{res.event_sub_heading}</h4>
                <p className="event_content"> {res.event_content}</p>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="news-events news-events-odd">
              <div className="image_content content-odd">
                <div className="d-none d-lg-block">
                    <p className="event_heading">{res.event_heading}</p>
                    <span className="border_bottom"></span>
                </div>
                <h4 className="event_sub_heading">{res.event_sub_heading}</h4>
                <p className="event_content"> {res.event_content}</p>
              </div>
              <div className="image_section image-odd">
                <div className="news_image ">
                  <img src={res.img} />
                  <p className="news_date news_date_odd">{getFormattedDate(res.date)}</p>
                </div>
              </div>
              <div className="d-block d-lg-none w-100 text-center">
                <p className="event_heading">{res.event_heading}</p>
                <span className="border_bottom"></span>
              </div>
            </div>
          </>
        )
      )}
    </>
  );
};

export default React.memo(NewsEvents);
