import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'

 function AboutUsSection(props) {

    return (
        <div className='nm_home'>
            <div className='about-section'>
                <div className='about-content-container d-flex align-items-center justify-content-around'>
                    {/* Image */}
                    <div className='about-section-img'>
                        <img src={props?.data?.secondSectionBanner} alt="NM India Biotech" loading='lazy' />
                    </div>
                    {/* NM India Biotech */}
                    <div className='about-section-content'>
                        <h2 className='aboutUs-heading1'>{props?.data?.aboutheading}</h2>
                        <h3 className='aboutUs-heading2'>{props?.data?.aboutsubheading}</h3>
                        <p className='about-section-content-p'>{props?.data?.aboutcontent}</p>

                        {/* Section-1 */}

                        <div className="d-flex section-height row">
                            {props?.data?.category?.map((res, index) =>
                                <div className='d-flex flex-sm-row flex-column align-items-center section-margin col-6 px-0' key={index}>
                                    <div className='chemical-icon-div'>
                                        <img src={res?.categoryicon} className='chemical-icon' alt="Chemical free" loading='lazy' />
                                    </div>
                                    <div className='mx-0 mx-sm-2'>
                                        <h4 className='mb-0 aboutus-sub-heading text-sm-start'>{res?.categoryheading}</h4>
                                        <p className='mb-0 aboutus-sub-heading-para text-sm-start'>{res?.categorysubheading}</p>
                                    </div>
                                </div>
                            )}

                            {/* <div className='d-flex flex-sm-row flex-column align-items-center col-6 px-0'>
                                <div className='chemical-icon-div'>
                                    <img src="img/image3.png" className='chemical-icon' alt="Chemical free" srcSet="" loading='lazy' />
                                </div>
                                <div className='mx-2'>
                                    <h4 className='mb-0 aboutus-sub-heading text-sm-start text-center'>Soil & Human Health</h4>
                                    <p className='mb-0 aboutus-sub-heading-para text-sm-start'>Smart Agriculture</p>
                                </div>
                            </div>

                            <div className='d-flex flex-sm-row flex-column align-items-center col-6 px-0'>
                                <div className='chemical-icon-div'>
                                    <img src="img/image4.png" className='chemical-icon' alt="Chemical free" srcSet="" loading='lazy' />
                                </div>

                                <div className='mx-2'>
                                    <h4 className='mb-0 aboutus-sub-heading text-center text-sm-start'>Profitability</h4>
                                    <p className='mb-0 aboutus-sub-heading-para text-sm-start'>Increase Efficiency</p>
                                </div>
                            </div>

                            <div className='d-flex flex-sm-row flex-column align-items-center col-6 px-0'>
                                <div className='chemical-icon-div'>
                                    <img src="img/image5.png" className='chemical-icon' alt="Chemical free" srcSet="" loading='lazy' />
                                </div>
                                <div className='mx-2'>
                                    <h4 className='mb-0 aboutus-sub-heading text-center text-sm-start'>Premium</h4>
                                    <p className='mb-0 aboutus-sub-heading-para text-sm-start'>Quality</p>
                                </div>
                            </div> */}
                        </div>

                        {/* Section-2 */}

                        {/* <div className="d-flex section-height">
                            <div className='d-flex flex-sm-row flex-column align-items-center col-6 px-0'>
                                <div className='chemical-icon-div'>
                                    <img src="img/image4.png" className='chemical-icon' alt="Chemical free" srcSet="" loading='lazy' />
                                </div>

                                <div className='mx-2'>
                                    <h4 className='mb-0 aboutus-sub-heading text-center text-sm-start'>Profitability</h4>
                                    <p className='mb-0 aboutus-sub-heading-para text-sm-start'>Increase Efficiency</p>
                                </div>
                            </div>

                            <div className='d-flex flex-sm-row flex-column align-items-center col-6 px-0'>
                                <div className='chemical-icon-div'>
                                    <img src="img/image5.png" className='chemical-icon' alt="Chemical free" srcSet="" loading='lazy' />
                                </div>
                                <div className='mx-2'>
                                    <h4 className='mb-0 aboutus-sub-heading text-center text-sm-start'>Premium</h4>
                                    <p className='mb-0 aboutus-sub-heading-para text-sm-start'>Quality</p>
                                </div>
                            </div>
                        </div>*/}
                    </div>
                </div>
            </div>
        </div >
    )
}
export default React.memo(AboutUsSection)