import { useFormik } from 'formik';
// import Multiselect from 'multiselect-react-dropdown';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import AlertContext from '../../../context/Alert/AlertContext';
import TopNavBar from '../../../context/components/Pages/Users/TopNavBar';
import axiosPrivate from '../../../hooks/axiosPrivate';
import classes from './Rolepermission.module.css';

const validationRules = {};

const AddRolepermission = () => {
	const { setAlertMessage } = useContext(AlertContext);
	const navigate = useNavigate();
	const [roles, setRoles] = useState([]);
	const [allPermissions, setallPermissions] = useState([]);
	const [selectData, setSelectData] = useState([]);
	const [sendPermission, setSendPermission] = useState([]);
 
	const data = async () => {
		const empty = [];
		for (let i = 0; i < allPermissions.length; i++) {
			empty.push(allPermissions[i].permission_name);
		}
		// {roles.length >0 && roles.map((val) => {
		setSendPermission(empty);
		// })}
	};
 
	useEffect(() => {
		data();
	}, [allPermissions]);

	useEffect(() => {
		axiosPrivate
			.get('roles')
			.then((user_roles) => {
				setRoles(user_roles?.data?.data);
			})
			.catch((error) => {
			});
	}, []);

	useEffect(() => {
		axiosPrivate
			.get('permissions')
			.then((permissions) => {
 				setallPermissions(permissions?.data?.data);
			})
			.catch((error) => {
			});
	}, []);

	const formik = useFormik({
		initialValues: {
			id: '',
		},
		validationSchema: Yup.object(validationRules),
		onSubmit: async (values, { setSubmitting, setFieldError }) => {
			var data = [];
			allPermissions.map((role) => {
				if (selectData.includes(role.permission_name)) {
					data.push(role.id);
				}
			});
 			var values = {
				role_id: values.id,
				permission_id: data,
			};
			axiosPrivate
				.post('role-permissions/create', values)
				.then((response) => {
					setAlertMessage({
						message: 'New role permission created successfully',
					});
					navigate('/admin/rolepermission');
				})
				.catch((error) => {
					if (error.response) {
						if (error.response.status == 422) {
							for (let i = 0; i < error.response.data.errors.length; i++) {
								setFieldError(
									error.response.data.errors[i].param,
									error.response.data.errors[i].msg
								);
							}
						}
					}
				});
			//   alert(JSON.stringify(values, null, 2));
		},
	});

	return (
		<div>
			<div className="section-body mt-3">
				<div className="container-fluid">
					<div className="tab-content mt-3">
						<form onSubmit={formik.handleSubmit}>
							<div
								className="tab-pane fade show active"
								id="user-add"
								role="tabpanel"
							>
								<div className="card">
									<div className="card-header">
										<strong>Add Role and Permission</strong>
									</div>
									<div className="card-body">
										<div className="row clearfix">
											<div className="col-md-12 col-sm-12">
												<div className="form-group">
													<select
														className="form-control show-tick"
														id="id"
														name="id"
														onChange={formik.handleChange}
														onBlur={formik.handleBlur}
														value={formik.values.id}
                                                        required
													>
														<option>Select Role Type</option>
														{roles.map((role, key) => (
															<option key={key + role.name} value={role.id}>
																{role.name}
															</option>
														))}
													</select>
												</div>
											</div>

											<div className="col-md-12 col-sm-12">
												<div className="form-group">
													{/* <Multiselect
														isObject={false}
														options={sendPermission}
														displayValue="name"
														showCheckbox
														onRemove={(eve) => {
														}}
														name="roles"
														value={roles}
														onSelect={(eve) => setSelectData(eve)}
                                                        required
													/> */}

													{/* {formik.touched.department_id &&
												formik.errors.department_id ? (
													<div className={classes.invalidDataError}>
														{formik.errors.department_id}
													</div>
												) : null} */}
												</div>
											</div>
											<div className="col-12">
												<button type="submit" className="btn btn-primary">
													Add
												</button>
												<button
													type="button"
													className="btn btn-secondary ml-2"
													data-dismiss="modal"
													onClick={() => navigate('/admin/rolepermission')}
												>
													CLOSE
												</button>
											</div>
										</div>
									</div>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	);
};

export default AddRolepermission;
