import { useFormik } from "formik";
import React, { useContext, useEffect } from "react";
import { FaGreaterThan } from "react-icons/fa";
import { Link, useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import { showErrorToast } from "../../../Toster";
import { showSuccessToast } from "../../../Toster";
import axiosPrivate from "../../../hooks/axiosPrivate";
import classes from "./priceCircularStyle.module.css";
import LoaderContext from "../../../context/loader/LoaderContext";

//mobile validation check rajex

const AddEditPriceCircular = () => {
  const { setIsLoading } = useContext(LoaderContext);
  const navigate = useNavigate();

  let { id } = useParams();

  // validation
  var validationRules = {
    product_name: Yup.string()
      .max(120, "Product name must not exceed 120 characters")
      .required("Name is required"),
    packing_size: Yup.string().required("Packing size is required"),
    billing_price: Yup.string().required("Billing price is required"),
    mrp_per_unit: Yup.string().required("MRP is required"),
    state_code: Yup.string().required("State code is required"),
  };
  // add data to api (after check validation)
  const formik = useFormik({
    initialValues: {
      product_name: "",
      packing_size: "",
      billing_price: "",
      mrp_per_unit: "",
      state_code: "",
    },
    validationSchema: Yup.object(validationRules),

    onSubmit: async (values, { setSubmitting, setFieldError }) => {
      setIsLoading(true);
      if (id) {
        axiosPrivate
          .post(`price-circular/update/${id}`, values)
          .then((response) => {
            showSuccessToast("Price Circular Updated Successfully");
            navigate("/admin/price-circular");
          })
          .catch((error) => {
            if (typeof error?.response?.data?.errors == "object") {
              showErrorToast(error?.response?.data?.errors[0]?.msg);
            } else {
              showErrorToast(error?.response?.data?.errors[0]?.msg);
            }
          })
          .finally(() => {
            setIsLoading(false);
          });
      } else {
        axiosPrivate
          .post("price-circular/create", values)
          .then((response) => {
            showSuccessToast("Price Circular Created Successfully");
            navigate("/admin/price-circular");
          })
          .catch((error) => {
            if (typeof error?.response?.data?.errors == "object") {
              showErrorToast(error?.response?.data?.errors[0]?.msg);
            } else {
              showErrorToast(error?.response?.data?.errors[0]?.msg);
            }
          })
          .finally(() => {
            setIsLoading(false);
          });
      }
    },
  });

  const numberFilter = (event) => {
    const inputValue = event.target.value;

    const numericValue = inputValue.replace(/\D/g, "");
    formik.setFieldValue(event.target.name, numericValue);
  };

  const nameFilter = (event) => {
    const inputValue = event.target.value;
    if (/^[A-Za-z\s'-]+$/.test(inputValue) || inputValue === "") {
      formik.setFieldValue(event.target.name, inputValue);
    }
  };

  useEffect(() => {
    if (id) {
      setIsLoading(true);
      axiosPrivate
        .post(`price-circular/get/${id}`)
        .then((response) => {
          formik.setValues(response.data?.data[0]);
        })
        .catch((error) => {})
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [id]);

  return (
    <>
      <div className="section-body">
        <div className="container-fluid">
          {/* hearder breadcrumb */}
          <div className="card-header">
            <strong className="top-left-heading">Add Price Circular</strong>

            <ol className="headerroutes breadcrumb m-0 bg-none ">
              <li className="item color-gray">|</li>
              <li className="item">
                <Link to="/admin/dashboard" className="route-heading item mx-2">
                  Dashboard
                </Link>
              </li>
              <li className="item">
                <FaGreaterThan className="grater-than-route" />
              </li>
              <li className="route-heading item mx-2 mt-1">
                <Link to="/admin/users">Price Circular</Link>
              </li>
              <li className="item">
                <FaGreaterThan className="grater-than-route" />
              </li>

              <li className="route-heading item mx-2 mt-1">
                Add Price Circular
              </li>
            </ol>
          </div>
          <div className="tab-content">
            <form onSubmit={formik.handleSubmit}>
              <div
                className="tab-pane fade show active"
                id="user-add"
                role="tabpanel"
              >
                <div className="card">
                  <div className="card-body">
                    <div className="row clearfix">
                      {/* name */}
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <label>
                          Name:<span className="required">*</span>
                        </label>
                        <div className="form-group">
                          <input
                            id="product_name"
                            name="product_name"
                            type="text"
                            className="form-control"
                            placeholder="Product Name *"
                            onChange={(event) => {
                              nameFilter(event);
                            }}
                            onBlur={formik.handleBlur}
                            value={formik.values.product_name}
                          />
                          {formik.touched.product_name &&
                          formik.errors.product_name ? (
                            <div className={classes.invalidDataError}>
                              {formik.errors.product_name}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      {/* packing size */}
                      <div className="col-md-6 col-sm-12">
                        <label>
                          Packing Size:
                          <span className="required">*</span>
                        </label>

                        <div className="form-group">
                          <input
                            id="packing_size"
                            name="packing_size"
                            type="text"
                            className="form-control"
                            placeholder="Packing Size *"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.packing_size}
                            maxLength={10}
                          />
                          {formik.touched.packing_size &&
                          formik.errors.packing_size ? (
                            <div className={classes.invalidDataError}>
                              {formik.errors.packing_size}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      {/* billing price */}
                      <div className="col-md-6 col-sm-12">
                        <label>
                          Billing Price:
                          <span className="required">*</span>
                        </label>

                        <div className="form-group">
                          <input
                            id="billing_price"
                            name="billing_price"
                            type="text"
                            className="form-control"
                            placeholder="Billing Price *"
                            onChange={(event) => {
                              numberFilter(event);
                            }}
                            onBlur={formik.handleBlur}
                            value={formik.values.billing_price}
                            maxLength={10}
                          />
                          {formik.touched.billing_price &&
                          formik.errors.billing_price ? (
                            <div className={classes.invalidDataError}>
                              {formik.errors.billing_price}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      {/* MRP per unit */}
                      <div className="col-md-6 col-sm-12">
                        <label>
                          MRP Per Unit:
                          <span className="required">*</span>
                        </label>

                        <div className="form-group">
                          <input
                            id="mrp_per_unit"
                            name="mrp_per_unit"
                            type="text"
                            className="form-control"
                            placeholder="MRP Per Unit *"
                            onChange={(event) => {
                              numberFilter(event);
                            }}
                            onBlur={formik.handleBlur}
                            value={formik.values.mrp_per_unit}
                            maxLength={10}
                          />
                          {formik.touched.mrp_per_unit &&
                          formik.errors.mrp_per_unit ? (
                            <div className={classes.invalidDataError}>
                              {formik.errors.mrp_per_unit}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      {/* state code */}
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <label>
                          State Code:<span className="required">*</span>
                        </label>
                        <div className="form-group">
                          <input
                            id="state_code"
                            name="state_code"
                            type="text"
                            className="form-control"
                            placeholder="State Code *"
                            onChange={(event) => {
                              nameFilter(event);
                            }}
                            onBlur={formik.handleBlur}
                            value={formik.values.state_code}
                          />
                          {formik.touched.state_code &&
                          formik.errors.state_code ? (
                            <div className={classes.invalidDataError}>
                              {formik.errors.state_code}
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    <div className="row clearfix">
                      {/* button close and submit */}
                      <div className="col-md-12 col-sm-12 submit-btn">
                        <div className="form-group">
                          <button
                            type="button"
                            className="btn btn-secondary"
                            data-dismiss="modal"
                            onClick={() => navigate("/admin/price-circular")}
                          >
                            Close
                          </button>
                          <button
                            type="submit"
                            className="btn btn-primary ml-2"
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddEditPriceCircular;
