import React, { useContext, useEffect, useState } from "react";
import { FaGreaterThan } from "react-icons/fa";
import { GrAddCircle, GrSubtractCircle } from "react-icons/gr";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as Yup from "yup";
import { showErrorToast } from "../../../Toster";
import AlertContext from "../../../context/Alert/AlertContext";
import axiosPrivate from "../../../hooks/axiosPrivate";
import classes from "./Logistic.module.css";
import PreviewLogisticItem from "./PreviewLogisticItem";
import { Dialog } from "primereact/dialog";
import styles from "./Logistic.module.css"
const vehicleNoRegex =
  /^[A-Z]{2}[ -][0-9]{1,2}(?: [A-Z])?(?: [A-Z]*)? [0-9]{4}$/;

// validation
const validationRules = {
  transporter_name: Yup.string().required("Transporter name is required"),
  driver_name: Yup.string().required("Driver name is required"),
  driver_phone: Yup.string()
    .test(
      "starts-not-with-0-to-5",
      "Driver phone no is not valid",
      function (value) {
        if (!value) return true;
        return !/^[0-5]/.test(value);
      }
    )
    .required("Driver phone no is required"),
  lr_no: Yup.string().required("LR no is required"),
  freight: Yup.string().required("Freight is required"),
  freight_amount: Yup.string().required("Freight amount is required"),
  vehicle_no: Yup.string().required("Vehicle no is required"),
  order_logistic_items: Yup.array()
    .of(
      Yup.object().shape({
        // logistic_quantity: Yup.string().required('Dispatch Quantity is required'),
        // dispatch_user_id: Yup.string().required('Dispatch user is required'),
        // dispatch_date: Yup.date().required('Dispatch date is required'),
      })
    )
    .min(1),
};

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const AddLogisticItems = () => {
  let query = useQuery();
  const [slug, setSlug] = useState(query.get("slug"));
  const dearlerDropDown = true;

  const navigate = useNavigate();
  const [products, setProducts] = useState([]);
  const [getData, setGetData] = useState({});
  const [itemDetail, setItemDetail] = useState([]);
  const [dispatchUserList, setDispatchUserList] = useState([]);
  const [order, setOrder] = useState([]);
  const [shippingAddress, setShippingAddress] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [eventForDelete, setEventForDelete] = useState();
  const [orderId, setOrderId] = useState("");
  const [isPreview, setIsPreview] = useState(false);
  const { alertMessage, setAlertMessage } = useContext(AlertContext);

  const [userData, setUserData] = useState([]);

  useEffect(() => {
    axiosPrivate
      .post("users")
      .then((res) => setUserData(res?.data?.data))
      .catch((error) => {});
  }, []);

  const accounts = userData.filter((data) => data?.roles == "Accounts");
  const accountId = accounts.map((data) => data.id);

  // getting order items information start
  useEffect(() => {
    //Logistic items start
    axiosPrivate
      .get(`/logistics/list/items/${slug}`)
      .then((response) => {
        setOrderId(response?.data?.data?.data[0].order_id);
        if (response.data.data.data != undefined) {

          const finalData = response?.data?.data?.data?.filter((item) => item?.dispatch_status !== 2)
          formik.setFieldValue(
            "order_logistic_items",
            finalData
          );
        }
      })

      .catch((err) => {});

    //Logistic items end
  }, [slug]);
  // getting order items information end

  //getting dispatch team start
  useEffect(() => {
    axiosPrivate.get(`users/get/user/dispatch_team`).then((response) => {
      setDispatchUserList(response?.data?.data);
    });
  }, []);
  //getting dispatch team end

  // getting product list start
  useEffect(() => {
    axiosPrivate.get("orders/get/products/list").then((order) => {
      setProducts(order?.data?.data);
    });
  }, []);
  //getting product list end

  useEffect(() => {
    if (alertMessage != null) {
      toast(alertMessage.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      setAlertMessage(null);
    }
  }, [alertMessage]);

  const formik = useFormik({
    initialValues: {
      order_id: "",
      transporter_name: "",
      driver_name: "",
      driver_phone: "",
      vehicle_no: "",
      lr_no: "",
      freight: "",
      freight_amount: 0,
      status: "with_dispatch",
      order_logistic_items: [
        {
          remaining_quantity: 0,
          dispatch_quantity: 0,
          logistic_quantity: "",
          dispatch_user_id: "",
        },
      ],
    },
    validationSchema: Yup.object(validationRules),
    onSubmit: async (values, { setSubmitting, setFieldError }) => {
      var newValues = {
        logistics: values.order_logistic_items,
        driver_name: values.driver_name,
        transporter_name: values.transporter_name,
        driver_phone: values.driver_phone,
        vehicle_no: values.vehicle_no,
        lr_no: values.lr_no,
        freight: values.freight,
        freight_amount: values.freight_amount,
        status: values.status,
      };
      setIsPreview(true)
    },
  });

  const quantityAddHandler = (event, index) => {
    const numRejex = /^[0-9\b]+$/;

    if (
      event.target.value === "" ||
      (numRejex.test(event.target.value) && event.target.value.length <= 10)
    ) {
      formik.handleChange(event);
    }
    return true;
  };


  const numberFilter = (event) => {
    const inputValue = event.target.value;

    const numericValue = inputValue.replace(/\D/g, "");
    formik.setFieldValue(event.target.name, numericValue);
  };

  const nameFilter = (event) => {
    const inputValue = event.target.value;
    if (/^[A-Za-z\s'-]+$/.test(inputValue) || inputValue === "") {
      formik.setFieldValue(event.target.name, inputValue);
    }
  };
  return (
    <>
    <Dialog
          header="Preview Logistic Item"
          visible={isPreview}
          style={{ width: "80% !important", height: "75% !important" }}
          onHide={() => {
            if (!isPreview) return;
            setIsPreview(false);
          }}
          className={styles.dialogModel}
        >
          <PreviewLogisticItem
            previewData={formik.values}
            setPreview={setIsPreview}
            orderId={orderId}
          />
        </Dialog>
        <div className="section-body">
      <div className="container-fluid">
        {/* hearder breadcrumb */}
        <div className="card-header">
          <strong className="top-left-heading">Add Logistic Item</strong>

          <ol className="headerroutes breadcrumb m-0 bg-none ">
            <li className="item color-gray">|</li>
            <li className="item">
              <Link to="/admin/dashboard" className="route-heading item mx-2">
                Dashboard
              </Link>
            </li>
            <li className="item">
              <FaGreaterThan className="grater-than-route" />
            </li>
            <li className="route-heading item mx-2 mt-1">
              <Link to="/admin/logistics">Logistics</Link>
            </li>
            <li className="item">
              <FaGreaterThan className="grater-than-route" />
            </li>

            <li className="route-heading item mx-2 mt-1">Add Logistics Item</li>
          </ol>
        </div>

        <div className="tab-content ">
          <form onSubmit={formik.handleSubmit}>
            <div
              className="tab-pane fade show active"
              id="user-add"
              role="tabpanel"
            >
              <div className="card">
                {/* table */}
                <div className={`card-body ${classes.tableWrapper}`}>
                  <div className="row">
                    {/* Transporter name */}
                    <div className="col-md-4">
                      <label>
                        Transporter Name<span className="required">*</span>
                      </label>
                      <input
                        id="transporter_name"
                        type="text"
                        className="form-control"
                        name={`transporter_name`}
                        placeholder="Enter Transporter Name"
                        value={formik.values.transporter_name}
                        onChange={(event) => {
                          const regex = /^[A-Za-z0-9\s]*$/; // Allow only alphanumeric characters
                          let value = event.target.value;
                          if (regex.test(value)) {
                            formik.setFieldValue("transporter_name", value); // Update Formik value if valid
                          }
                        }}
                      />
                      {formik.touched.transporter_name &&
                      formik.errors.transporter_name != undefined ? (
                        <div className={classes.invalidDataError}>
                          {formik.errors.transporter_name}
                        </div>
                      ) : null}
                    </div>
                    {/* Driver name */}
                    <div className="col-md-4">
                      <label>
                        Driver Name<span className="required">*</span>
                      </label>
                      <input
                        id="driver_name"
                        type="text"
                        className="form-control"
                        name={`driver_name`}
                        placeholder="Enter Driver Name"
                        value={formik.values.driver_name}
                        onChange={(event) => {
                          nameFilter(event);
                        }}
                      />
                      {formik.touched.driver_name &&
                      formik.errors.driver_name != undefined ? (
                        <div className={classes.invalidDataError}>
                          {formik.errors.driver_name}
                        </div>
                      ) : null}
                    </div>
                    {/* Driver phone */}
                    <div className="col-md-4">
                      <label>
                        Driver Phone No.<span className="required">*</span>
                      </label>
                      <input
                        id="driver_phone"
                        type="text"
                        maxLength={10}
                        className="form-control"
                        name={`driver_phone`}
                        placeholder="Enter Driver Phone No."
                        value={formik.values.driver_phone}
                        onChange={(event) => {
                          numberFilter(event);
                        }}
                      />
                      {formik.touched.driver_phone &&
                      formik.errors.driver_phone != undefined ? (
                        <div className={classes.invalidDataError}>
                          {formik.errors.driver_phone}
                        </div>
                      ) : null}
                    </div>
                    {/* Vehicle No. */}
                    <div className="col-md-4 mt-1 mb-3">
                      <label>
                        Vehicle No.<span className="required">*</span>
                      </label>
                      <input
                        id="vehicle_no"
                        type="text"
                        className="form-control"
                        name={`vehicle_no`}
                        placeholder="Enter Vehicle No(RJ06XX0000)."
                        value={formik.values.vehicle_no}
                        autoCapitalize={true}
                        onChange={(event) => {
                          const regex = /^[A-Za-z0-9]*$/; // Allow only alphanumeric characters
                          let value = event.target.value.toUpperCase(); // Convert to uppercase
                          if (regex.test(value)) {
                            formik.setFieldValue("vehicle_no", value); // Update Formik value if valid
                          }
                        }}
                      />
                      {formik.touched.vehicle_no &&
                      formik.errors.vehicle_no != undefined ? (
                        <div className={classes.invalidDataError}>
                          {formik.errors.vehicle_no}
                        </div>
                      ) : null}
                    </div>

                    <div className="col-md-4 mt-1 mb-3">
                      <label>
                        LR No <span className="required">*</span>
                      </label>
                      <input
                        id="lr_no"
                        type="text"
                        className="form-control"
                        name={`lr_no`}
                        placeholder="LR No"
                        value={formik.values.lr_no}
                        onChange={(event) => {
                          formik.handleChange(event); // Call handleChange with the event
                        }}
                      />
                      {formik.touched.lr_no && formik.errors.lr_no ? (
                        <div className={classes.invalidDataError}>
                          {formik.errors.lr_no}
                        </div>
                      ) : null}
                    </div>
                    <div className="col-md-4 mt-1 mb-3">
                      <label>
                        Freight <span className="required">*</span>
                      </label>
                      <select
                        id="freight"
                        className="form-control"
                        name={`freight`}
                        value={formik.values.freight}
                        onChange={(event) => {
                          formik.handleChange(event);
                        }}
                        onBlur={formik.handleBlur}
                      >
                        <option value="">Select Freight Type</option>
                        <option key={"billing"} value={"billing"}>
                          Billing
                        </option>
                        <option key={"to_pay "} value={"to_pay"}>
                          To Pay
                        </option>
                      </select>
                      {formik.touched.freight && formik.errors.freight ? (
                        <div className={classes.invalidDataError}>
                          {formik.errors.freight}
                        </div>
                      ) : null}
                    </div>
                    {formik?.values?.freight === "to_pay" && (
                      <>
                        <div className="col-md-4 mt-1 mb-3">
                          <label>
                            Freight Amount <span className="required">*</span>
                          </label>
                          <input
                            id="freight_amount"
                            type="text"
                            className="form-control"
                            name={`freight_amount`}
                            placeholder="Freight Amount"
                            value={formik.values.freight_amount}
                            onChange={(event) => {
                              numberFilter(event);
                            }}
                            maxLength={10}
                          />
                          {formik.touched.freight_amount &&
                          formik.errors.freight_amount ? (
                            <div className={classes.invalidDataError}>
                              {formik.errors.freight_amount}
                            </div>
                          ) : null}
                        </div>
                      </>
                    )}
                  </div>
                  <div style={{ "overflow-x": "scroll" }}>
                    <table className="table table-hover">
                      <thead>
                        {/* table heading */}
                        <tr>
                          <th>
                            <label>Order No:</label>
                          </th>
                          <th>
                            <label>Product:</label>
                          </th>
                          <th>
                            <label>Remaining Quantity:</label>
                          </th>
                          <th>
                            <label>Dispatch Quantity:</label>
                          </th>
                          <th>
                            <label>Dispatch User:</label>
                          </th>
                          <th>
                            <label>Dispatch Date:</label>
                          </th>
                          <th>
                            <label>
                              {/* Action:<span className="required">*</span> */}
                            </label>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {formik.values.order_logistic_items
                          ?.map((order, index) => (
                            <tr key={index}>
                              {/* Order No */}
                              <td>
                                <span>#{order?.group?.order_no}</span>
                              </td>
                              {/* Product dropdown */}
                              <td>
                                {(() => {
                                  let label = "";
                                  for (let i = 0; i < products.length; i++) {
                                    if (products[i].id == order?.stock_id) {
                                      label = (
                                        <label>{products[i].tally_name}</label>
                                      );
                                    }
                                  }
                                  return label;
                                })()}
                              </td>
                              {/* Quantity Remaining */}
                              <td>
                                <label>{order?.remaining_quantity}</label>
                              </td>
                              {/* Quantity */}
                              <td>
                                <input
                                  id="logistic_quantity"
                                  type="number"
                                  className="form-control"
                                  name={`order_logistic_items[${index}].logistic_quantity`}
                                  placeholder="Enter Quantity"
                                  value={order?.logistic_quantity}
                                  onChange={(event) => {
                                    if (
                                      event.target.value <=
                                      order
                                        .remaining_quantity
                                    ) {
                                      quantityAddHandler(event, index);
                                    }
                                  }}
                                />
                                {formik.touched &&
                                formik.errors.order_logistic_items !=
                                  undefined &&
                                formik.errors.order_logistic_items.length > 0 &&
                                formik.errors.order_logistic_items[index] !=
                                  undefined &&
                                formik.errors.order_logistic_items[index]
                                  .logistic_quantity ? (
                                  <div className={classes.invalidDataError}>
                                    {
                                      formik.errors.order_logistic_items[index]
                                        .logistic_quantity
                                    }
                                  </div>
                                ) : null}
                              </td>
                              {/* dispatch user dropdown */}
                              <td>
                                <select
                                  id="dispatch_user_id"
                                  className="form-control"
                                  name={`order_logistic_items[${index}].dispatch_user_id`}
                                  value={
                                    order
                                      .dispatch_user_id
                                  }
                                  onChange={(event) => {
                                    formik.handleChange(event);
                                  }}
                                  onBlur={formik.handleBlur}
                                >
                                  <option value="">Select User</option>
                                  {dispatchUserList.map((dispatch_user_id) => (
                                    <option
                                      key={dispatch_user_id.id}
                                      value={dispatch_user_id.id}
                                    >
                                      {dispatch_user_id.name}
                                    </option>
                                  ))}
                                </select>
                                {formik.touched &&
                                formik.errors.order_logistic_items !=
                                  undefined &&
                                formik.errors.order_logistic_items.length > 0 &&
                                formik.errors.order_logistic_items[index] !=
                                  undefined &&
                                formik.errors.order_logistic_items[index]
                                  .dispatch_user_id != undefined ? (
                                  <div className={classes.invalidDataError}>
                                    {
                                      formik.errors.order_logistic_items[index]
                                        .dispatch_user_id
                                    }
                                  </div>
                                ) : null}
                              </td>
                              {/* Dispatch date */}
                              <td>
                                <input
                                  id={`order_logistic_items[${index}].dispatch_date`}
                                  type="date"
                                  max="9999-12-31"
                                  min={new Date().toISOString().slice(0, 10)}
                                  className="form-control order_date_test"
                                  onBlur={formik.handleBlur}
                                  name={`order_logistic_items[${index}].dispatch_date`}
                                  value={
                                    order
                                      ?.dispatch_date != "" &&
                                    order
                                      ?.dispatch_date != undefined
                                      ? new Date(
                                          formik.values.order_logistic_items[
                                            index
                                          ].dispatch_date
                                        )
                                          .toISOString()
                                          .slice(0, 10)
                                      : ""
                                  }
                                  onChange={(event) => {
                                    formik.handleChange(event); // Call handleChange with the event
                                  }}
                                />
                                {formik.touched &&
                                formik.errors.order_logistic_items !=
                                  undefined &&
                                formik.errors.order_logistic_items.length > 0 &&
                                formik.errors.order_logistic_items[index] !=
                                  undefined &&
                                formik.errors.order_logistic_items[index]
                                  .dispatch_date != undefined ? (
                                  <div className={classes.invalidDataError}>
                                    {
                                      formik.errors.order_logistic_items[index]
                                        .dispatch_date
                                    }
                                  </div>
                                ) : null}
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="col-12 submit-btn my-2" style={{paddingBottom:'40px', paddingRight:'20px'}}>
                  <div className="table-responsive">
                    <table className="table table-striped"></table>
                  </div>
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-dismiss="modal"
                    onClick={() => navigate(`/admin/logistics`)}
                  >
                    Close
                  </button>
                  <button type="submit" className="btn btn-primary ml-2">
                    Preview
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    
    </>
    
  );
};

export default AddLogisticItems;
