import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";

const DispatchStatusChart = ({ dispatch }) => {
  const [series, setSeries] = useState([0, 0]);

  
  useEffect(() => {
    let pendingCount = 0;
    let dispatchedCount = 0;

    // dispatch.forEach((item) => {
    //   if (item.splited_group_items[0]?.dispatch_status === 1) {
    //     confirmedCount++;
    //   }
    //   if (item.splited_group_items[0]?.dispatch_status === 0) {
    //     pendingCount++;
    //   }
    //   if (item.merged_group_items[0]?.dispatch_status === 1) {
    //     confirmedCount++;
    //   }
    //   if (item.merged_group_items[0]?.dispatch_status === 0) {
    //     pendingCount++;
    //   }
    // });

    dispatch.forEach((item) => {
      item?.merged_group_items?.forEach((item) => {
        item?.group_items?.forEach((item) =>{
          if (item?.dispatch_status === 1) {
            dispatchedCount++;
          }
          if (item?.dispatch_status === 0) {
            pendingCount++;
          }
        })
      })
      
      item?.splited_group_items?.forEach((item) => {
          if (item?.dispatch_status === 1) {
            dispatchedCount++;
          }
          if (item?.dispatch_status === 0) {
            pendingCount++;
          }
      })
    });

    setSeries([pendingCount, dispatchedCount]);
  }, [dispatch]);

  const [options] = useState({
    chart: {
      width: 380,
      type: "pie",
    },
    labels: ["Pending", "Dispatched"],
    legend: {
      position: "bottom",
      markers: {
        fillColors: ["#f9c500", "#719924"],
      },
    },
    fill: {
      colors: ["#fccc55", "#719924"],
    },
    dataLabels: {
      enabled: false,
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: "100%",
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  });

  return (
    <div>
      <div id="chart">
        <ReactApexChart
          options={options}
          series={series}
          type="pie"
          width="100%"
        />
      </div>
    </div>
  );
};

export default DispatchStatusChart;
