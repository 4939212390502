import { useFormik } from 'formik';
import React, { useContext, useEffect, useState } from 'react';
import { FaGreaterThan } from 'react-icons/fa';
import { Link, useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { showErrorToast } from '../../../Toster';
import AlertContext from '../../../context/Alert/AlertContext';
import axiosPrivate from '../../../hooks/axiosPrivate';
import classes from './addCategoryStyle.module.css';

var formData = new FormData();

//  validation
const validationRules = {
	name: Yup.string()
		.max(120, 'Name must not exceed 120 characters')
		.required('Category name is required'),
	slug: Yup.string()
		.max(120, 'Slug must not exceed 120 characters')
		.required('Category slug is required'),
	description: Yup.string().required('Category description is required'),
	image: Yup.mixed().required('Category image is required'),
	alt_title: Yup.string().required('Image alt is required'),
	meta_description: Yup.string().required('Meta description is required'),
	structured_data: Yup.string().required('Schema is required'),
};

const EditCategory = () => {
	let { id } = useParams();
	const { setAlertMessage } = useContext(AlertContext);
	const navigate = useNavigate();
	const [defaultValue, setDefaultVlaue] = useState({});

	// get category data by id
	useEffect(() => {
		axiosPrivate
			.get(`categories/${id}`)
			.then((response) => {
				if (response.data.code === 200) {
					const parsedImage = JSON.parse(response.data.data.image);

					setDefaultVlaue({
						...response.data.data,
						image: parsedImage.file,
						alt_title: parsedImage.alt,
					});
				}
			})
			.catch((error) => {
				if (error.response) {
					//response status is an error code
					showErrorToast('Get Category Data By Id Internal server error');
				}
			});
	}, []);

	// set byDefault dala in input
	useEffect(() => {
		if (defaultValue != '') {
			formik.setValues({ ...defaultValue });
		}
	}, [defaultValue]);

	//  update and send data to api (after check validation)
	const formik = useFormik({
		initialValues: {
			name: '',
			slug: '',
			description: '',
			image: '',
			alt_title: '',
			meta_title: '',
			meta_keywords: '',
			meta_description: '',
			structured_data: '',
		},
		enableReinitialize: true,
		validationSchema: Yup.object(validationRules),
		onSubmit: async (values, { setSubmitting, setFieldError }) => {
			formData.append('name', values.name);
			formData.append('slug', values.slug);
			formData.append('description', values.description);
			formData.append('image', values.image);
			formData.append('alt_title', values.alt_title);
			formData.append('meta_title', values.meta_title);
			formData.append('meta_keywords', values.meta_keywords);
			formData.append('meta_description', values.meta_description);
			formData.append('structured_data', values.structured_data);
			axiosPrivate
				.post(`categories/update/${id}?_method=PUT`, formData)
				.then((response) => {
					setAlertMessage({ message: 'Category updated successfully' });
					navigate('/admin/category');
				})
				.catch((error) => {
					showErrorToast('Update Category Data Internal server error');

					if (error.response) {
						if (error.response.status == 422) {
							for (let i = 0; i < error.response.data.errors.length; i++) {
								setFieldError(
									error.response.data.errors[i].param,
									error.response.data.errors[i].msg
								);
							}
						}
					}
				});
		},
	});


	const nameFilter = (event) => {
		const inputValue = event.target.value;
		if (/^[A-Za-z\s'-]+$/.test(inputValue) || inputValue === "") {
		  formik.setFieldValue(event.target.name, inputValue);
		}
	  };

	return (
		<div className="section-body mt-3">
			<div className="container-fluid">
				{/* hearder breadcrumb */}
				<div className="card-header">
					<strong className="top-left-heading"> Edit Category</strong>

					<ol className="headerroutes breadcrumb m-0 bg-none ">
						<li className="item color-gray">|</li>
						<li className="item">
							<Link to="/admin/dashboard" className="route-heading item mx-2">
								Dashboard
							</Link>
						</li>
						<li className="item">
							<FaGreaterThan className="grater-than-route" />
						</li>
						<li className="route-heading item mx-2 mt-1">
							<Link to="/admin/category"> Categories </Link>
						</li>
						<li className="item">
							<FaGreaterThan className="grater-than-route" />
						</li>
						<li className="route-heading item mx-2 mt-1">Edit Category</li>
					</ol>
				</div>

				<div className="tab-content mt-3">
					<form onSubmit={formik.handleSubmit}>
						<div
							className="tab-pane fade show active"
							id="category-add"
							role="tabpanel"
						>
							<div className="card">
								<div className="card-body">
									<div className="row clearfix">
										{/* Category Name */}
										<div className="col-lg-6 col-md-6 col-sm-12">
											<label>
												Category Name:<span className="required">*</span>
											</label>
											<div className="form-group">
												<input
													id="name"
													name="name"
													type="text"
													className="form-control"
													placeholder="Name *"
													onChange={nameFilter}
													onBlur={formik.handleBlur}
													value={formik.values.name}
												/>
												{formik.touched.name && formik.errors.name ? (
													<div className={classes.invalidDataError}>
														{formik.errors.name}
													</div>
												) : null}
											</div>
										</div>

										{/* Category Slug */}
										<div className="col-lg-6 col-md-6 col-sm-12">
											<label>
												Category Slug:<span className="required">*</span>
											</label>
											<div className="form-group">
												<input
													id="slug"
													name="slug"
													type="text"
													className="form-control"
													placeholder="Slug *"
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													value={formik.values.slug}
												/>
												{formik.touched.slug && formik.errors.slug ? (
													<div className={classes.invalidDataError}>
														{formik.errors.slug}
													</div>
												) : null}
											</div>
										</div>

										{/* Category Description */}
										<div className="col-lg-12 col-md-12 col-sm-12">
											<label>
												Category Description:
												<span className="required">*</span>
											</label>
											<div className="form-group">
												<textarea
													id="description"
													name="description"
													type="text"
													className="form-control"
													placeholder="Description *"
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													value={formik.values.description}
												/>
												{formik.touched.description &&
												formik.errors.description ? (
													<div className={classes.invalidDataError}>
														{formik.errors.description}
													</div>
												) : null}
											</div>
										</div>

										{/* Category Image */}
										<div className="col-lg-6 col-md-6 col-sm-12">
											<label>
												Category Image:<span className="required">*</span>
											</label>
											<div className="form-group">
												<input
													id="image"
													name="image"
													type="file"
													accept="image/*"
													className="form-control"
													placeholder="Image *"
													onChange={(e) =>
														formik.setFieldValue(
															'image',
															e.currentTarget.files[0]
														)
													}
													onBlur={formik.handleBlur}
													value={formik.values.file}
												/>
												{formik.touched.image && formik.errors.image ? (
													<div className={classes.invalidDataError}>
														{formik.errors.image}
													</div>
												) : null}
											</div>
										</div>

										{/* Category Image Alt */}
										<div className="col-lg-6 col-md-6 col-sm-12">
											<label>
												Image Alt:<span className="required">*</span>
											</label>
											<div className="form-group">
												<input
													id="alt_title"
													name="alt_title"
													type="text"
													className="form-control"
													placeholder="Image Alt"
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													value={formik.values.alt_title}
												/>
												{formik.touched.alt_title && formik.errors.alt_title ? (
													<div className={classes.invalidDataError}>
														{formik.errors.alt_title}
													</div>
												) : null}
											</div>
										</div>
										{/* Meta title */}
										<div className="col-lg-6 col-md-6 col-sm-12">
											<label>
												Meta Title:<span className="required">*</span>
											</label>
											<div className="form-group">
												<input
													id="meta_title"
													name="meta_title"
													type="text"
													className="form-control"
													placeholder="Meta Title"
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													value={formik.values.meta_title}
												/>
											</div>
										</div>

										{/* Meta Keywords */}
										<div className="col-lg-6 col-md-6 col-sm-12">
											<label>
												Meta Keyword:<span className="required">*</span>
											</label>
											<div className="form-group">
												<input
													id="meta_keywords"
													name="meta_keywords"
													type="text"
													className="form-control"
													placeholder="Meta Keywords "
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													value={formik.values.meta_keywords}
												/>
											</div>
										</div>

										{/* Meta Description */}
										<div className="col-lg-12 col-md-12 col-sm-12">
											<label>
												Meta Description:<span className="required">*</span>
											</label>
											<div className="form-group">
												<textarea
													id="meta_description"
													name="meta_description"
													type="text"
													className="form-control"
													placeholder="Meta Description "
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													value={formik.values.meta_description}
												/>
												{formik.touched.meta_description &&
												formik.errors.meta_description ? (
													<div className={classes.invalidDataError}>
														{formik.errors.meta_description}
													</div>
												) : null}
											</div>
										</div>

										{/* Structured data & Schema */}
										<div className="col-lg-12 col-md-12 col-sm-12">
											<label>
												Schema:<span className="required">*</span>
											</label>
											<div className="form-group">
												<textarea
													id="structured_data"
													name="structured_data"
													type="text"
													className="form-control"
													placeholder="Schema "
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													value={formik.values.structured_data}
												/>
												{formik.touched.structured_data &&
												formik.errors.structured_data ? (
													<div className={classes.invalidDataError}>
														{formik.errors.structured_data}
													</div>
												) : null}
											</div>
										</div>

										{/* close and update button */}
										<div className="col-12 submit-btn">
											<button
												type="button"
												className="btn btn-secondary"
												data-dismiss="modal"
												onClick={() => navigate('/admin/category')}
											>
												Close
											</button>
											<button
												type="submit"
												className="btn btn-primary float-right ml-2"
											>
												Update
											</button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</form>
				</div>
			</div>
		</div>
	);
};

export default EditCategory;
