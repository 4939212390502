import React from 'react'
import { Link } from 'react-router-dom'

 function EventsAndAchievements(props) {

    return (
        <div className='nm_home'>
            <div className='d-flex justify-content-evenly events-achievements-container'>
                {/* Achievement section */}
                {props?.data?.newsSection?.map((res,index) =>
                    <div className='achievement-container' key={index}>
                        <div className='achievement-img-container'>
                            <img src={res?.newsImage} className='achievement-img' alt="Achievements" loading='lazy' />
                        </div>
                        <div className='detail-container'>
                            <h5 className='achievement-heading'>{res?.Achievementheading}</h5>
                            <p className='achievement-detail'>{res?.newscontent.slice(0, 100)+"..."}</p>
                            <div className='achievement-btn-container'>
                                <Link to='/leadership'><button className='achievements-more-btn  '>Explore</button></Link>
                            </div>

                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}

export default React.memo(EventsAndAchievements) //EventsAndAchievements