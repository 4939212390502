import { useFormik } from "formik";
import React, { useContext, useEffect, useState } from "react";
import { FaGreaterThan } from "react-icons/fa";
import { Link, useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import { showErrorToast } from "../../../Toster";
import AlertContext from "../../../context/Alert/AlertContext";
import axiosPrivate from "../../../hooks/axiosPrivate";
import classes from "./ProductSTyle.module.css";
import LoaderContext from "../../../context/loader/LoaderContext";

const validationRules = {
  weight: Yup.string().required("Weight is required"),
  unit: Yup.string().required("Unit is required"),
  product_in_box: Yup.string().required("Product Qty is required"),
};

const EditProduct = () => {
  const { setIsLoading } = useContext(LoaderContext);
  let { id } = useParams();
  const { setAlertMessage } = useContext(AlertContext);
  const navigate = useNavigate();
  const [unitList, setUnitList] = useState([]);
  const [defaultData, setDefaultData] = useState({});

  //  product unit list
  useEffect(() => {
    setIsLoading(true);
    axiosPrivate
      .get("stocks/unit/list")
      .then((unit) => {
        setUnitList(unit?.data?.data);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
      });
  }, []);
  // product by id
  useEffect(() => {
    axiosPrivate.get(`stocks/get/${id}`).then((response) => {
      if (response.data.code === 200) {
        setDefaultData({ ...response.data.data });
      }
    });
  }, [unitList]);

  // check validation after update formik
  const formik = useFormik({
    initialValues: {
      weight: "",
      unit: "",
      product_in_box: "",
    },
    enableReinitialize: true,
    validationSchema: Yup.object(validationRules),
    onSubmit: async (values, { setSubmitting, setFieldError }) => {
      axiosPrivate
        .patch(`stocks/update/${id}`, values)
        .then((response) => {
          setAlertMessage({ message: "Product Updated Successfully" });
          navigate("/admin/product");
        })
        .catch((error) => {
          if (typeof error.response.data.errors == "array") {
            showErrorToast(error.response.data.errors[0].msg);
          } else {
            showErrorToast(error.response.data.errors.msg);
          }
        });
    },
  });

  // set default data for input
  useEffect(() => {
    if (defaultData != "") {
      formik.setValues({
        weight: defaultData.weight,
        unit: defaultData.unit,
        product_in_box: defaultData.product_in_box,
      });
    }
  }, [defaultData]);


  const numberFilter = (event) => {
    const inputValue = event.target.value;

    const numericValue = inputValue.replace(/\D/g, "");
    formik.setFieldValue(event.target.name, numericValue);
  };

  

  return (
    <div className="section-body">
      <div className="container-fluid">
        {/* hearder breadcrumb */}
        <div className="card-header">
          <strong className="top-left-heading">Edit Product </strong>

          <ol className="headerroutes breadcrumb m-0 bg-none ">
            <li className="item color-gray">|</li>
            <li className="item">
              <Link to="/admin/dashboard" className="route-heading item mx-2">
                Dashboard
              </Link>
            </li>
            <li className="item">
              <FaGreaterThan className="grater-than-route" />
            </li>
            <li className="route-heading item mx-2 mt-1">
              <Link to="/admin/product">Product</Link>
            </li>
            <li className="item">
              <FaGreaterThan className="grater-than-route" />
            </li>

            <li className="route-heading item mx-2 mt-1">Edit Product</li>
          </ol>
        </div>
        <div className="tab-content">
          <form onSubmit={formik.handleSubmit}>
            <div
              className="tab-pane fade show active"
              id="user-add"
              role="tabpanel"
            >
              <div className="card">
                <div className="card-body">
                  <div className="row clearfix">
                    {/* weight */}
                    <div className="col-lg-6 col-md-6 col-sm-6">
                      <label>
                        Weight :<span className="required">*</span>
                      </label>
                      <div className="form-group">
                        <input
                          id="weight"
                          name="weight"
                          type="text"
                          className="form-control"
                          placeholder="Weight *"
                          onChange={(event) => {
                            numberFilter(event);
                          }}
                          onBlur={formik.handleBlur}
                          value={formik.values.weight}
                        />
                        {formik.touched.weight && formik.errors.weight ? (
                          <div className={classes.invalidDataError}>
                            {formik.errors.weight}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    {/* unit list*/}
                    <div className="col-md-4 col-sm-12">
                      <label>
                        Unit :<span className="required">*</span>
                      </label>

                      <div className="form-group">
                        <select
                          className="form-control show-tick"
                          id="unit"
                          name="unit"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.unit}
                          placeholder="Select unit"
                        >
                          <option selected value={null}>
                            Please Select{" "}
                          </option>
                          {unitList.length > 0 &&
                            unitList.map((role, key) => (
                              <option key={key + role.key} value={role.value}>
                                {role.value}
                              </option>
                            ))}
                        </select>
                        {formik.touched.unit && formik.errors.unit ? (
                          <div className={classes.invalidDataError}>
                            {formik.errors.unit}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    {/* product in cartoon */}
                    <div className="col-lg-6 col-md-6 col-sm-6">
                      <label>
                        Products in carton / box :
                        <span className="required">*</span>
                      </label>
                      <div className="form-group">
                        <input
                          id="product_in_box"
                          name="product_in_box"
                          type="text"
                          className="form-control"
                          placeholder="Product in box *"
                          onChange={(event) => {
                            numberFilter(event);
                          }}
                          onBlur={formik.handleBlur}
                          value={formik.values.product_in_box}
                        />
                        {formik.touched.product_in_box &&
                        formik.errors.product_in_box ? (
                          <div className={classes.invalidDataError}>
                            {formik.errors.product_in_box}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    {/* button */}
                    <div className="col-12 submit-btn">
                      <button
                        type="button"
                        className="btn btn-secondary"
                        data-dismiss="modal"
                        onClick={() => navigate("/admin/product")}
                      >
                        Close
                      </button>
                      <button type="submit" className="btn btn-primary ml-2">
                        Update
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default EditProduct;
