import { useFormik } from 'formik';
import React, { useContext } from 'react';
import { FaGreaterThan } from 'react-icons/fa';
import { Link, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { showErrorToast } from '../../../Toster';
import AlertContext from '../../../context/Alert/AlertContext';
import axiosPrivate from '../../../hooks/axiosPrivate';
import classes from './Gallery.module.css';

const validationRules = {
	title: Yup.string()
		.max(120, 'Name must not exceed 120 characters')
		.required('Title is required'),
	alt: Yup.string().required('Alt is required'),
	image: Yup.string().required('Image is required'),
};

const AddGallery = () => {
	const { setAlertMessage } = useContext(AlertContext);
	const navigate = useNavigate();

	// formik(add)
	const formik = useFormik({
		initialValues: {
			title: '',
			alt: '',
			image: '',
		},
		validationSchema: Yup.object(validationRules),
		onSubmit: async (values, { setSubmitting, setFieldError }) => {
			var formData = new FormData();
			formData.append('title', values.title);
			formData.append('alt', values.alt);
			formData.append('image', values.image);

			axiosPrivate
				.post('gallery/store', formData)
				.then((response) => {
					setAlertMessage({ message: 'New  Gallery Created Successfully' });
					navigate('/admin/gallery');
				})
				.catch((error) => {
					if (typeof error.response.data.errors == 'array') {
						showErrorToast(error.response.data.errors[0].msg);
					} else {
						showErrorToast(error.response.data.errors.msg);
					}
				});
		},
	});

	return (
		<>
			<div className="section-body ">
				<div className="container-fluid">
					{/* hearder breadcrumb */}
					<div className="card-header">
						<strong className="top-left-heading">Add Gallery</strong>

						<ol className="headerroutes breadcrumb m-0 bg-none ">
							<li className="item color-gray">|</li>
							<li className="item">
								<Link to="/admin/dashboard" className="route-heading item mx-2">
									Dashboard
								</Link>
							</li>
							<li className="item">
								<FaGreaterThan className="grater-than-route" />
							</li>
							<li className="route-heading item mx-2 mt-1">
								<Link to="/admin/gallery">Gallery</Link>
							</li>
							<li className="item">
								<FaGreaterThan className="grater-than-route" />
							</li>
							<li className="route-heading item mx-2 mt-1">Add Gallery</li>
						</ol>
					</div>

					<div className="tab-content ">
						<form onSubmit={formik.handleSubmit}>
							<div
								className="tab-pane fade show active"
								id="gallery-add"
								role="tabpanel"
							>
								<div className="card">
									<div className="card-body">
										<div className="row clearfix">
											{/* title */}
											<div className="col-lg-6 col-md-6 col-sm-12">
												<label>
													Title:<span className="required">*</span>
												</label>
												<div className="form-group">
													<input
														id="title"
														name="title"
														type="text"
														className="form-control"
														placeholder="Title "
														onChange={formik.handleChange}
														onBlur={formik.handleBlur}
														value={formik.values.title}
													/>
													{formik.touched.title && formik.errors.title ? (
														<div className={classes.invalidDataError}>
															{formik.errors.title}
														</div>
													) : null}
												</div>
											</div>

											{/* alt */}

											<div className="col-lg-6 col-md-6 col-sm-12">
												<label>
													Alt :<span className="required">*</span>
												</label>
												<div className="form-group">
													<input
														id="alt"
														name="alt"
														type="text"
														className="form-control"
														placeholder="Alt "
														onChange={formik.handleChange}
														onBlur={formik.handleBlur}
														value={formik.values.alt}
													/>
													{formik.touched.alt && formik.errors.alt ? (
														<div className={classes.invalidDataError}>
															{formik.errors.alt}
														</div>
													) : null}
												</div>
											</div>

											{/* Images*/}

											<div className="col-lg-6 col-md-6 col-sm-12">
												<label>
													Image:<span className="required">*</span>
												</label>
												<div className="form-group">
													<input
														id="image"
														name="image"
														type="file"
														// accept="image/*"
														className="form-control"
														placeholder="Image *"
														onChange={(e) =>
															formik.setFieldValue(
																'image',
																e.currentTarget.files[0]
															)
														}
														onBlur={formik.handleBlur}
													/>
													{formik.touched.image && formik.errors.image ? (
														<div className={classes.invalidDataError}>
															{formik.errors.image}
														</div>
													) : null}
												</div>
											</div>

											{/* button */}
											<div className="col-12 submit-btn">
												<button
													type="button"
													className="btn btn-secondary"
													data-dismiss="modal"
													onClick={() => navigate('/admin/gallery')}
												>
													Close
												</button>
												<button type="submit" className="btn btn-primary ml-2">
													Submit
												</button>
											</div>
										</div>
									</div>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
		</>
	);
};

export default AddGallery;
