import { useContext, useEffect, useState } from "react";
import { BiEdit } from "react-icons/bi";
import { FaGreaterThan } from "react-icons/fa";
import { RiDeleteBinLine } from "react-icons/ri";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import AlertContext from "../../../context/Alert/AlertContext";
import AuthContext from "../../../context/Auth/AuthContext";
import DataTable2 from "../../../context/components/DataTable2";
import axiosPrivate from "../../../hooks/axiosPrivate";
import LoaderContext from "../../../context/loader/LoaderContext";
import styles from "./dealer.module.css";
import { showErrorToast, showSuccessToast } from "../../../Toster";

const Dealer = ({ authPermission }) => {
  const { setIsLoading } = useContext(LoaderContext);
  const navigate = useNavigate();
  const { checkPermissionExists, authPermissions } = useContext(AuthContext);
  const { alertMessage, setAlertMessage } = useContext(AlertContext);
  const [dealerState, setDealerState] = useState([]);
  const [isSync, setIsSync] = useState(false);
  // header
  const headers = [
    { name: "DEALER NAME", field: "name", sortable: true, classKey: "w250" },
    { name: "EMAIL", field: "email", sortable: true, classKey: "" },
    { name: "CONTACT NO.", field: "mobile", sortable: true, classKey: "" },
    { name: "ROLE", field: "roles", sortable: true, classKey: "" },

    { name: "ACTION", field: "action", classKey: "" },
  ];

  function toTitleCase(str) {
    str = str.toLowerCase().split(" ");
    for (var i = 0; i < str.length; i++) {
      str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1);
    }
    return str.join(" ");
  }

  const searchItems = ["name", "roles", "email", "mobile"];
  // dealer list

  const dealersList = () =>{
    setIsLoading(true);
    const options = {
      headers: { "content-type": "application/json" },
    };
    axiosPrivate
      .post("dealers", options)
      .then((dealers) => {
        const dealersData = dealers?.data?.data?.map((value, key) => {
          let buttons = [];
          if (true && authPermissions.includes("Dealer-Edit"))
            buttons.push(
              <Link
                key="editButton##1"
                type="button"
                to={`/admin/dealer/edit/${value.id}`}
                className="btn btn-icon"
                title="Edit"
              >
                <BiEdit style={{ color: "green" }} size={18} />
              </Link>
            );
          // if (true && authPermissions.includes('Dealer-Delete'))
          // 	buttons.push(
          // 		<button
          // 			key="deleteButton##1"
          // 			type="button"
          // 			data-id={value.id}
          // 			onClick={() => dealerDeleteHandler(value.id)}
          // 			className="btn btn-icon js-sweetalert"
          // 			title="Delete"
          // 		>
          // 			<RiDeleteBinLine style={{ color: 'red' }} size={17} />
          // 		</button>
          // 	);
          value["action"] = buttons.length > 0 ? buttons : "-";
          value["roles"] = toTitleCase(value.roles);
          return value;
        });

        setDealerState(dealersData);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
      });
  }
  useEffect(() => {
    dealersList();
  }, [authPermissions]);

  // alert
  useEffect(() => {
    if (alertMessage != null) {
      toast(alertMessage.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      setAlertMessage(null);
    }
  }, [alertMessage]);

  //delete function
  const dealerDeleteHandler = async (id) => {
    axiosPrivate.delete(`dealers/delete/${id}`).then(async (response) => {
      if (response.data.code === 200) {
        setDealerState([]);
        setAlertMessage({ message: "Dealer deleted successfully" });
        await refreshTable();
      }
    });
  };

  // refreshTable (after delete data)
  const refreshTable = () => {
    return new Promise((resolve, reject) => {
      const options = {
        headers: { "content-type": "application/json" },
      };
      axiosPrivate.post("dealers", options).then((dealers) => {
        const dealersData = dealers.data.data.map((value, key) => {
          let buttons = [];
          if (true)
            buttons.push(
              <button
                key="editButton##1"
                type="button"
                className="btn btn-icon"
                title="Edit"
              >
                <BiEdit style={{ color: "green" }} size={18} />
              </button>
            );
          if (true)
            buttons.push(
              <button
                key="deleteButton##1"
                type="button"
                data-id={value.id}
                onClick={() => dealerDeleteHandler(value.id)}
                className="btn btn-icon js-sweetalert"
                title="Delete"
              >
                <RiDeleteBinLine style={{ color: "red" }} size={17} />
              </button>
            );
          value["action"] = buttons.length > 0 ? buttons : "-";
          return value;
        });
        setDealerState(dealersData);
        resolve(true);
      });
    });
  };


  
  const syncDealerFromTally = async () => {
    setIsLoading(true); // Show loader
    setIsSync(true); // Show loader
    await axiosPrivate.post("tally/fetch/dealers/from/tally").then((response) =>{
      showSuccessToast("Dealers synced successfully")
    dealersList()
    }).catch((error) => {
      if (Array.isArray(error.response.data.errors)) {
        showErrorToast(error.response.data.errors[0]?.msg);
      } else {
        showErrorToast(error.response.data.errors?.msg);
      }
    }).finally(() =>{
      setIsLoading(false); // Hide loader
      setIsSync(false);
    });
  };

  return (
    <>
      <div className="section-body">
        <div className="container-fluid d-flex justify-content-between">
          {/* hearder breadcrumb */}
          <div className="card-header">
            <strong className="top-left-heading">Dealers</strong>

            <ol className="headerroutes breadcrumb m-0 bg-none ">
              <li className="item color-gray">|</li>
              <li className="item">
                <Link to="/admin/dashboard" className="route-heading item mx-2">
                  Dashboard
                </Link>
              </li>
              <li className="item">
                <FaGreaterThan className="grater-than-route" />
              </li>
              <li className="route-heading item mx-2 mt-1">Dealers</li>
            </ol>
          </div>

          {isSync && (
        <div
          className={styles.overlayMessage}
        >
          Dealers syncing from Tally...
        </div>
      )}
          <button
            type="button"
            className={`btn btn-primary add_btn ${styles?.syncTallyButton}`}
            data-toggle="modal"
            data-target="#exampleModal"
            onClick={syncDealerFromTally}
          >
            Sync Tally
          </button>
        </div>
        <div className="section-body ">
          <div className="container-fluid">
            <div className="tab-content">
              <div
                className="tab-pane fade show active"
                id="dealer-list"
                role="tabpanel"
              >
                <div className="card">
                  <div className="card-body">
                    <div className="table-responsive">
                      {
                        <DataTable2
                          lists={dealerState}
                          headers={headers}
                          searchItems={searchItems}
                        />
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dealer;
