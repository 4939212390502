import { useContext, useEffect, useState } from 'react';
import { BiEdit } from 'react-icons/bi';
import { FaGreaterThan } from 'react-icons/fa';
import { RiDeleteBinLine } from 'react-icons/ri';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
import { showErrorToast } from '../../../Toster';
import AlertContext from '../../../context/Alert/AlertContext';
import DataTable2 from '../../../context/components/DataTable2';
import axiosPrivate from '../../../hooks/axiosPrivate';
import DeleteConfirm from '../../../delete-confirmation/DeleteConfirm';
const Blog = () => {
	const { alertMessage, setAlertMessage } = useContext(AlertContext);
	const [blogState, setBlogState] = useState([]);
	const [confirm, setConfirm] = useState(false)
	const [id, setId] = useState("")
	// header
	const headers = [
		{
			name: 'BLOG TITLE',
			field: 'title',
			sortable: true,
			classKey: '',
		},
		{ name: 'SLUG', field: 'slug', sortable: true, classKey: '' },

		{ name: 'STATUS', field: 'status', sortable: true, classKey: '' },
		{
			name: 'PUBLISH DATE',
			field: 'publish_date',
			sortable: true,
			classKey: '',
		},

		{ name: 'ACTION', field: 'action', classKey: '' },
	];
	// search item
	const searchItems = ['title', 'slug', 'status', 'publish_date'];
	//  blogs data list
	useEffect(() => {
		const options = {
			headers: { 'content-type': 'application/json' },
		};
		axiosPrivate.get('blogs', options).then((blogs) => {
			const blogData = blogs?.data?.data?.map((value, key) => {
				let buttons = [];
				if (true)
					buttons.push(
						<Link
							key="editButton##1"
							type="button"
							to={`/admin/blog/edit/${value.id}`}
							className="btn btn-icon"
							title="Edit"
						>
							<BiEdit style={{ color: 'green' }} size={18} />
						</Link>
					);
				if (true)
					buttons.push(
						<button
							key="deleteButton##1"
							type="button"
							data-id={value.id}
							onClick={() =>{
								setId(value.id)
								setConfirm(true)
							}}
							className="btn btn-icon js-sweetalert"
							title="Delete"
						>
							<RiDeleteBinLine style={{ color: 'red' }} size={17} />
						</button>
					);
				value['action'] = buttons.length > 0 ? buttons : '-';

				return value;
			});

			setBlogState(blogData);
		});
	}, []);

	// alert
	useEffect(() => {
		if (alertMessage != null) {
			toast(alertMessage.message, {
				position: 'top-right',
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				theme: 'light',
			});
			setAlertMessage(null);
		}
	}, [alertMessage]);

	// delete function

	const blogDeleteHandler = async () => {
		axiosPrivate
			.delete(`blogs/${id}`)
			.then(async (response) => {
				if (response.data.code == 200) {
					setBlogState([]);
					setAlertMessage({ message: 'Blog  Deleted Successfully!' });
					await refreshTable();
				}
			})
			.catch((error) => {
				if (typeof error.response.data.errors == 'array') {
					showErrorToast(error.response.data.errors[0].msg);
				} else {
					showErrorToast(error.response.data.errors.msg);
				}
			});
	};
	// refreshTable (delete data after get list)
	const refreshTable = () => {
		return new Promise((resolve, reject) => {
			const options = {
				headers: { 'content-type': 'application/json' },
			};
			axiosPrivate.get('blogs', options).then((blogs) => {
				const blogData = blogs.data.data.map((value, key) => {
					let buttons = [];
					if (true)
						buttons.push(
							<button
								key="editButton##1"
								type="button"
								className="btn btn-icon"
								title="Edit"
							>
								<BiEdit style={{ color: 'green' }} size={18} />
							</button>
						);
					if (true)
						buttons.push(
							<button
								key="deleteButton##1"
								type="button"
								data-id={value.id}
								onClick={() =>{
									setId(value.id)
									setConfirm(true)
								}}
								className="btn btn-icon js-sweetalert"
								title="Delete"
							>
								<RiDeleteBinLine style={{ color: 'red' }} size={17} />
							</button>
						);
					value['action'] = buttons.length > 0 ? buttons : '-';
					return value;
				});
				setBlogState(blogData);
				resolve(true);
			});
		});
	};

	return (
		<>
		{
			confirm && <DeleteConfirm deleteMethod = {blogDeleteHandler} setConfirm = {setConfirm} text = {"By clicking 'Yes', this blog will be permanently deleted."} success = {"Blog has been deleted."} cancel={"Blog deletion cancelled."}/>
		}
			<div className="section-body">
				<div className="container-fluid">
					<div className="d-flex justify-content-between align-items-center ">
						{/* hearder breadcrumb */}
						<div className="card-header">
							<strong className="top-left-heading">Blogs</strong>

							<ol className="headerroutes breadcrumb m-0 bg-none ">
								<li className="item color-gray">|</li>
								<li className="item">
									<Link
										to="/admin/dashboard"
										className="route-heading item mx-2"
									>
										Dashboard
									</Link>
								</li>
								<li className="item">
									<FaGreaterThan className="grater-than-route" />
								</li>
								<li className="route-heading item mx-2 mt-1">Blogs</li>
							</ol>
						</div>
						{/* right side add button */}
						<div className="header-action">
							<Link to="/admin/blog/add" className="text-decoration-none">
								<button
									type="button"
									className="btn btn-primary"
									data-toggle="modal"
									data-target="#exampleModal"
								>
									<i className="fe fe-plus mr-2" />
									Add
								</button>
							</Link>
						</div>
					</div>
				</div>
				<div className="section-body">
					<div className="container-fluid">
						<div className="tab-content">
							<div
								className="tab-pane fade show active"
								id="user-list"
								role="tabpanel"
							>
								<div className="card">
									<div className="card-body">
										<div className="table-responsive">
											{
												<DataTable2
													lists={blogState}
													headers={headers}
													searchItems={searchItems}
												/>
											}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Blog;
