import { useFormik } from 'formik';
import React, { useContext, useEffect, useState } from 'react';
import { FaGreaterThan } from 'react-icons/fa';
// import { useNavigate } from 'react-router';
import { Link, useParams,useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { showErrorToast,showSuccessToast } from '../../../Toster';
import AlertContext from '../../../context/Alert/AlertContext';
import axiosPrivate from '../../../hooks/axiosPrivate';
const aboutTemplateValidation = Yup.object({
	pagename: Yup.string().required('Page name is required'),
	slug: Yup.string().required('Slug is required'),
	bannerImage: Yup.string().required('Banner image is required'),
});

const AddAboutTemplate = () => {
	const { id } = useParams();
	const { setAlertMessage } = useContext(AlertContext);
	const [showLoader, setShowLoader] = useState(false);
	const [image, setImage] = useState('');
	const [submitButton, setSubmitButton] = useState('Submit');
	const [template, setTemplate] = useState('Add About Template');

	var initialValues = {
		pagename: '',
		slug: '',
		bannerImage: '',

		chemical_free_section: {
			chemical_heading: '',
			chemical_subheading: '',
			chemical_content: '',
			chemical_banner_image: '',
			extra_data: [
				{
					counter_name: '',
					counter_count: '',
				},
				{
					counter_name: '',
					counter_count: '',
				},
				{
					counter_name: '',
					counter_count: '',
				},
				{
					counter_name: '',
					counter_count: '',
				},
			],
		},

		partners_section: {
			partners_heading: '',
			partners_content: '',
			bedge_data: {
				bedge_heading: '',
				bedge_content: '',
				bedge_image: '',
			},
			partners_image: [
				{
					image: '',
				},
				{
					image: '',
				},
				{
					image: '',
				},
				{
					image: '',
				},
			],
		},

		leadership_section: {
			leadership_heading: '',
			leader_name: '',
			about_leader: '',
			leader_image: '',
		},

		biotech_team_section: {
			biotech_heading: '',
			biotech_team: [
				{
					team_member_image: '',
					team_member_name: '',
					team_member_role: '',
				},
				{
					team_member_image: '',
					team_member_name: '',
					team_member_role: '',
				},
				{
					team_member_image: '',
					team_member_name: '',
					team_member_role: '',
				},
			],
		},
	};

	const setSlug = (e) => {
		const { name, value } = e.target;
		if (name == 'pagename') {
			let slug = e.target.value;
			setFieldValue(
				'slug',
				slug
					.toLowerCase()
					.trim()
					.replace(/[^\w\s-]/g, '')
					.replace(/[\s_-]+/g, '-')
					.replace(/^-+|-+$/g, '')
			);
		}

		handleBlur(e);
	};

	const onFileUpload = (event) => {
		let formData = new FormData();
		formData.append('image', event.target.files[0]);
		axiosPrivate
			.post('pages/file-upload', formData)
			.then((res) => {
				setFieldValue(event.target.name, res?.data?.data);
			})
			.catch((error) => {
				setFieldValue(event.target.name, '');
			});
		handleChange(event);
	};

	const {
		values,
		errors,
		touched,
		handleSubmit,
		handleChange,
		handleBlur,
		setFieldValue,
	} = useFormik({
		initialValues: initialValues,
		enableReinitialize: true,
		validationSchema: aboutTemplateValidation,
		onSubmit: (values, { setFieldError }) => {
			let formData = {
				name: values.pagename,
				slug: values.slug,
				page_json: JSON.stringify(values),
				template_type: 'about',
			};
			if (id) {
				axiosPrivate
					.put('pages/update/' + id, formData)
					.then((res) => {
						showSuccessToast("Updation successful");
						navigate('/admin/about-template');
					})
					.catch((error) => {
						if (typeof error?.response?.data?.errors == 'array') {
							showErrorToast(error?.response?.data?.errors[0].msg);
						} else {
							showErrorToast(error?.response?.data?.errors?.msg);
						}
					});
			} else {
				axiosPrivate
					.post('pages/create', formData)
					.then((res) => {
						showSuccessToast('Successfully created');
						navigate('/admin/about-template');
					})
					.catch((error) => {
						if (typeof error?.response?.data?.errors == 'array') {
							showErrorToast(error?.response?.data?.errors[0].msg);
						} else {
							showErrorToast(error?.response?.data?.errors.msg);
						}
					});
			}
		},
	});

	useEffect(() => {
		if (id) {
			axiosPrivate
				.get('pages/' + id)
				.then((res) => {
					const parsedValues = JSON.parse(res.data?.data?.page_json);
					Object.keys(parsedValues).forEach((key) => {
						setFieldValue(key, parsedValues[key]);
					});

					setSubmitButton('Update');
				})
				
				.catch((error) => {
					if (typeof error?.response?.data?.errors == 'array') {
						showErrorToast(error?.response?.data?.errors[0]?.msg);
					} else {
						showErrorToast(error?.response?.data?.errors?.msg);
					}
				});
				setTemplate('Edit About Template');
		}
	}, []);

	const navigate = useNavigate();

	const nameFilter = (event) => {
		const inputValue = event.target.value;
		if (/^[A-Za-z\s'-]+$/.test(inputValue) || inputValue === "") {
		  setFieldValue(event.target.name, inputValue);
		}
	  };

	return (
		<>
			{showLoader ? <div className="loading">Loading&#8230;</div> : <div></div>}
			<div className="section-body website-template">
				<div className="container-fluid">
					{/* hearder breadcrumb */}
					<div className="card-header">
						<strong className="top-left-heading">{template}</strong>

						<ol className="headerroutes breadcrumb m-0 bg-none ">
							<li className="item color-gray">|</li>
							<li className="item">
								<Link to="/admin/dashboard" className="route-heading item mx-2">
									Dashboard
								</Link>
							</li>
							<li className="item">
								<FaGreaterThan className="grater-than-route" />
							</li>
							<li className="route-heading item mx-2 mt-1">
								<Link to="/admin/about-template"> About Template</Link>
							</li>

							<li className="item">
								<FaGreaterThan className="grater-than-route" />
							</li>
							<li className="route-heading item mx-2 mt-1">
								Add About Template{' '}
							</li>
						</ol>
					</div>

					<div className="tab-content">
						<form onSubmit={handleSubmit}>
							<div
								className="tab-pane fade show active"
								id="home-template"
								role="tabpanel"
							>
								<div className="card">
									<div className="card-body">
										<div className="row bordered-row">
											{/*  Page Name */}
											<div className="col-lg-6 col-md-6 col-sm-12">
												<label>
													Page Name:<span className="required">*</span>
												</label>
												<div className="form-group">
													<input
														id="pagename"
														name="pagename"
														type="text"
														className="form-control"
														placeholder="Page Name"
														onChange={nameFilter}
														onBlur={setSlug}
														value={values.pagename}
													/>

													{touched.pagename && errors.pagename ? (
														<>
														<div className="invalidDataError">
															{errors.pagename}
														</div>
														</>
													) : null}
												</div>
											</div>
											<div className="col-lg-6 col-md-6 col-sm-12">
												<label>
													Slug:<span className="required">*</span>
												</label>
												<div className="form-group">
													<input
														id="slug"
														name="slug"
														placeholder="Slug"
														onChange={handleChange}
														onBlur={handleBlur}
														value={values.slug}
														className="form-control"
													/>
													{touched.slug && errors.slug ? (
														<div className="invalidDataError">
															{errors.slug}
														</div>
													) : null}
												</div>
											</div>
											<div className="col-lg-6 col-md-6 col-sm-12">
												<label>
													Banner Image:<span className="required">*</span>
												</label>
												<div className="form-group">
													<input
														id="bannerImage"
														name="bannerImage"
														type="file"
														className="form-control"
														onChange={onFileUpload}
														onBlur={handleBlur}
													/>

													{typeof values.bannerImage == 'string' &&
													values.bannerImage != '' ? (
														<img
															style={{ width: '100px', height: '100px' }}
															className="mt-2"
															src={values.bannerImage}
														/>
													) : (
														''
													)}
													{touched.bannerImage && errors.bannerImage ? (
														<div className="invalidDataError">
															{errors.bannerImage}
														</div>
													) : null}
												</div>
											</div>
										</div>

										<h6>Overview Section</h6>
										<div className="row bordered-row">
											<div className="col-lg-6 col-md-6 col-sm-12">
												<label>
													Overview Banner Image:
												</label>
												<div className="form-group">
													<input
														id="chemical_free_section.chemical_banner_image"
														name="chemical_free_section.chemical_banner_image"
														type="file"
														className="form-control"
														onChange={onFileUpload}
														onBlur={handleBlur}
													/>
													{typeof values.chemical_free_section
														.chemical_banner_image == 'string' &&
													values.chemical_free_section.chemical_banner_image !=
														'' ? (
														<img
															style={{ width: '100px', height: '100px' }}
															className="mt-2"
															src={
																values.chemical_free_section
																	.chemical_banner_image
															}
														/>
													) : (
														''
													)}
												</div>
											</div>
											<div className="col-lg-6 col-md-6 col-sm-12">
												<label>
													Overview Heading:
												</label>
												<div className="form-group">
													<input
														id="chemical_heading"
														name="chemical_free_section.chemical_heading"
														type="text"
														className="form-control"
														placeholder="Overview Heading"
														onChange={handleChange}
														onBlur={handleBlur}
														value={
															values.chemical_free_section.chemical_heading
														}
													/>
												</div>
											</div>
											<div className="col-lg-6 col-md-6 col-sm-12">
												<label>
													Overview Subheading:
												</label>
												<div className="form-group">
													<textarea
														id="chemicalcontent"
														name="chemical_free_section.chemical_subheading"
														type="text"
														className="form-control"
														placeholder="Overview Subheading"
														onChange={handleChange}
														onBlur={handleBlur}
														value={
															values.chemical_free_section.chemical_subheading
														}
													/>
												</div>
											</div>
											<div className="col-lg-6 col-md-6 col-sm-12">
												<label>
													Overview Content:
												</label>
												<div className="form-group">
													<textarea
														id="chemicalcontent"
														name="chemical_free_section.chemical_content"
														type="text"
														className="form-control"
														placeholder="Overview Content"
														onChange={handleChange}
														onBlur={handleBlur}
														value={
															values.chemical_free_section.chemical_content
														}
													/>
												</div>
											</div>

											<div className="col-lg-12 mt-2">
												<h6>Counter Section</h6>
												<div className="row">
													{values.chemical_free_section.extra_data.map(
														(res, index) => (
															<>
																<div
																	className="col-lg-6 col-md-6 col-sm-12"
																	key={index}
																>
																	<div className="form-group">
																		<input
																			type="text"
																			id={`chemical_free_section.extra_data[${index}].counter_name`}
																			name={`chemical_free_section.extra_data[${index}].counter_name`}
																			className="form-control"
																			placeholder="Counter Name"
																			onChange={handleChange}
																			onBlur={handleBlur}
																			value={
																				values.chemical_free_section.extra_data[
																					index
																				].counter_name
																			}
																		/>
																	</div>
																</div>
																<div className="col-lg-6 col-md-6 col-sm-12">
																	<div className="form-group">
																		<input
																			type="text"
																			id={`chemical_free_section.extra_data[${index}].counter_count`}
																			name={`chemical_free_section.extra_data[${index}].counter_count`}
																			className="form-control"
																			placeholder="Counter Count"
																			onChange={handleChange}
																			onBlur={handleBlur}
																			value={
																				values.chemical_free_section.extra_data[
																					index
																				].counter_count
																			}
																		/>
																	</div>
																</div>
															</>
														)
													)}
												</div>
											</div>
										</div>

										<h6>Partner Section</h6>
										<div className="row bordered-row">
											<div className="col-lg-6 col-md-6 col-sm-12">
												<label>
													Partners Heading:
												</label>
												<div className="form-group">
													<input
														id="partners_section.partners_heading"
														name="partners_section.partners_heading"
														type="text"
														className="form-control"
														placeholder="Overview Heading"
														onChange={handleChange}
														onBlur={handleBlur}
														value={values.partners_section.partners_heading}
													/>
												</div>
											</div>
											<div className="col-lg-6 col-md-6 col-sm-12">
												<label>
													Partners Content:
												</label>
												<div className="form-group">
													<textarea
														id="partners_section.partners_content"
														name="partners_section.partners_content"
														type="text"
														className="form-control"
														placeholder="Product Section Content"
														onChange={handleChange}
														onBlur={handleBlur}
														value={values.partners_section.partners_content}
													/>
												</div>
											</div>

											<div className="col-lg-12 mt-2">
												{/* <h6>Bedge Section</h6> */}
												<div className="row">
													<div className="col-lg-6 col-md-6 col-sm-12">
														<label>
															Bedge Heading:
														</label>
														<div className="form-group">
															<input
																id="partners_section.bedge_data.bedge_heading"
																name="partners_section.bedge_data.bedge_heading"
																type="text"
																className="form-control"
																placeholder="Bedge Section Heading"
																onChange={handleChange}
																onBlur={handleBlur}
																value={
																	values.partners_section.bedge_data
																		.bedge_heading
																}
															/>
														</div>
													</div>
													<div className="col-lg-6 col-md-6 col-sm-12">
														<label>
															Bedge Content:
														</label>
														<div className="form-group">
															<textarea
																id="partners_section.bedge_data.bedge_content"
																name="partners_section.bedge_data.bedge_content"
																type="text"
																className="form-control"
																placeholder="Bedge Content"
																onChange={handleChange}
																onBlur={handleBlur}
																value={
																	values.partners_section.bedge_data
																		.bedge_content
																}
															/>
														</div>
													</div>
													<div className="col-lg-6 col-md-6 col-sm-12">
														<label>
															Bedge Images:
														</label>
														<div className="form-group">
															<input
																type="file"
																id="partners_section.bedge_data.bedge_image"
																name="partners_section.bedge_data.bedge_image"
																className="form-control"
																onChange={onFileUpload}
																onBlur={handleBlur}
															/>
															{typeof values.partners_section.bedge_data
																.bedge_image == 'string' &&
															values.partners_section.bedge_data.bedge_image !=
																'' ? (
																<img
																	style={{ width: '100px', height: '100px' }}
																	className="mt-2"
																	src={
																		values.partners_section.bedge_data
																			.bedge_image
																	}
																/>
															) : (
																''
															)}
														</div>
													</div>
												</div>
											</div>

											<div className="col-lg-12 mt-3">
												<h6>Partners Images</h6>
												<div className="row">
													{values.partners_section.partners_image.map(
														(res, index) => (
															<div
																className="col-lg-6 col-md-6 col-sm-12 form-group"
																key={index}
															>
																<input
																	id={`partners_section.partners_image.[${index}].image`}
																	name={`partners_section.partners_image.[${index}].image`}
																	type="file"
																	className="form-control"
																	onChange={onFileUpload}
																	onBlur={handleBlur}
																/>
																{typeof values.partners_section.partners_image[
																	index
																].image == 'string' &&
																values.partners_section.partners_image[index]
																	.image != '' ? (
																	<img
																		style={{ width: '100px', height: '100px' }}
																		className="mt-2"
																		src={
																			values.partners_section.partners_image[
																				index
																			].image
																		}
																	/>
																) : (
																	''
																)}
															</div>
														)
													)}
												</div>
											</div>
										</div>

										<h6>Leadership Section</h6>
										<div className="row bordered-row">
											<div className="col-lg-6 col-md-6 col-sm-12">
												<label>
													Leadership heading:
												</label>
												<div className="form-group">
													<input
														id="leadership_section.leadership_heading"
														name="leadership_section.leadership_heading"
														type="text"
														className="form-control"
														placeholder="Leadership Heading"
														onChange={handleChange}
														onBlur={handleBlur}
														value={values.leadership_section.leadership_heading}
													/>
												</div>
											</div>
											<div className="col-lg-6 col-md-6 col-sm-12">
												<label>
													Leader Name:
												</label>
												<div className="form-group">
													<input
														id="leadership_section.leader_name"
														name="leadership_section.leader_name"
														type="text"
														className="form-control"
														placeholder="Leader Name"
														onChange={handleChange}
														onBlur={handleBlur}
														value={values.leadership_section.leader_name}
													/>
												</div>
											</div>
											<div className="col-lg-6 col-md-6 col-sm-12">
												<label>
													Leadership Content:
												</label>
												<div className="form-group">
													<textarea
														className="form-control"
														name="leadership_section.about_leader"
														id="leadership_section.about_leader"
														placeholder="Leadership Content"
														onChange={handleChange}
														onBlur={handleBlur}
														value={values.leadership_section.about_leader}
													></textarea>
												</div>
											</div>
											<div className="col-lg-6 col-md-6 col-sm-12">
												<label>
													Leader Image:
												</label>
												<div className="form-group">
													<input
														id="leadership_section.leader_image"
														name="leadership_section.leader_image"
														type="file"
														className="form-control"
														onChange={onFileUpload}
														onBlur={handleBlur}
													/>
													{typeof values.leadership_section.leader_image ==
														'string' &&
													values.leadership_section.leader_image != '' ? (
														<img
															style={{ width: '100px', height: '100px' }}
															className="mt-2"
															src={values.leadership_section.leader_image}
														/>
													) : (
														''
													)}
												</div>
											</div>
										</div>

										<h6>Biotech Team</h6>
										<div className="row bordered-row">
											<div className="col-lg-6 col-md-6 col-sm-12">
												<label>
													Biotech Team heading:
												</label>
												<div className="form-group">
													<input
														id="biotech_heading"
														name="biotech_team_section.biotech_heading"
														type="text"
														className="form-control"
														placeholder="Biotech Team Heading"
														onChange={handleChange}
														onBlur={handleBlur}
														value={values.biotech_team_section.biotech_heading}
													/>
												</div>
											</div>
											{/* <div className="col-lg-6 col-md-6 col-sm-12">
                            <label>Leader Name:<span className="required">*</span></label>
                            <div className="form-group">
                              <input
                                id="leadership_sub_heading"
                                name="leadership_sub_heading"
                                type="text"
                                className="form-control"
                                placeholder="Page Name"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.leadership_section.leader_name}
                              />
                            </div>
                          </div> */}

											<div className="col-lg-12 mt-3">
												<h6 className="mb-2">Bio Tech Team</h6>
												{values.biotech_team_section.biotech_team.map(
													(res, index) => (
														<div className="row mt-2" key={index}>
															<div className="col-lg-6 col-md-6 col-sm-12">
																<label>
																	Team Member Name:
																</label>
																<div className="form-group">
																	<input
																		className="form-control"
																		name={`biotech_team_section.biotech_team[${index}].team_member_name`}
																		id="{`biotech_team_section.biotech_team[${index}].team_member_name`}"
																		placeholder="Team Member name"
																		onChange={handleChange}
																		onBlur={handleBlur}
																		value={
																			values.biotech_team_section.biotech_team[
																				index
																			].team_member_name
																		}
																	/>
																</div>
															</div>
															<div className="col-lg-6 col-md-6 col-sm-12">
																<label>
																	Team Member Image:
																</label>
																<div className="form-group">
																	<input
																		id={`biotech_team_section.biotech_team.[${index}].team_member_image`}
																		name={`biotech_team_section.biotech_team.[${index}].team_member_image`}
																		type="file"
																		className="form-control"
																		onChange={onFileUpload}
																		onBlur={handleBlur}
																	/>
																	{typeof values.biotech_team_section
																		.biotech_team[index].team_member_image ==
																		'string' &&
																	values.biotech_team_section.biotech_team[
																		index
																	].team_member_image != '' ? (
																		<img
																			style={{
																				width: '100px',
																				height: '100px',
																			}}
																			className="mt-2"
																			src={
																				values.biotech_team_section
																					.biotech_team[index].team_member_image
																			}
																		/>
																	) : (
																		''
																	)}
																</div>
															</div>
															<div className="col-lg-6 col-md-6 col-sm-12">
																<label>
																	Team Member Role:
																</label>
																<div className="form-group">
																	<input
																		className="form-control"
																		id={`biotech_team_section.biotech_team[${index}].team_member_role`}
																		name={`biotech_team_section.biotech_team[${index}].team_member_role`}
																		placeholder="Team Member name"
																		onChange={handleChange}
																		onBlur={handleBlur}
																		value={
																			values.biotech_team_section.biotech_team[
																				index
																			].team_member_role
																		}
																	/>
																</div>
															</div>
														</div>
													)
												)}
											</div>
										</div>

										<div className="row">
											<div className="col-12 submit-btn">
												<button
													type="button"
													className="btn btn-secondary"
													data-dismiss="modal"
													onClick={() => navigate('/admin/about-template')}
												>
													Close
												</button>
												<button type="submit" className="btn btn-primary ml-2">
													{submitButton}
												</button>
											</div>
										</div>
									</div>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
		</>
	);
};

export default AddAboutTemplate;
