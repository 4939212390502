import React from 'react';
import ReactApexChart from 'react-apexcharts';

const DonutChart = ({ data }) => {
    // Create an object to store state_name and corresponding quantities
    const stateQuantityMap = {};

    // Process the data and accumulate quantities
    data.forEach(item => {
        const stateName = item.state_name.toUpperCase();
        let totalQuantity = 0;
    
        if (item.group_items) {
            item.group_items.forEach(groupItem => {
                if (groupItem.order_items) {
                    groupItem.order_items.forEach(orderItem => {
                        totalQuantity += parseInt(orderItem?.remaining_quantity) || 0;
                    });
                }
            });
        }
    
        if (stateQuantityMap[stateName]) {
            stateQuantityMap[stateName] += totalQuantity;
        } else {
            stateQuantityMap[stateName] = totalQuantity;
        }
    });

    // Convert the object data to arrays for ApexCharts
    const labels = Object.keys(stateQuantityMap);
    const series = Object.values(stateQuantityMap);

    const chartOptions = {
        chart: {
            type: 'donut',
        },
        labels: labels,
        fill: {
            colors: ['#004583', '#6f6593', '#8075aa', '#a192d9']
        },
        legend: {
            position: 'bottom',
            markers: {
                fillColors: ['#004583', '#6f6593', '#8075aa', '#a192d9']
            }
        },
        responsive: [{
            breakpoint: 480,
            options: {
                chart: {
                    width: 200
                },
                legend: {
                    position: 'bottom'
                }
            }
        }]
    };

    return (
        <div>
            <div id="chart">
                <ReactApexChart options={chartOptions} series={series} type="donut" />
            </div>
        </div>
    );
};

export default DonutChart;
