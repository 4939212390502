import { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { BiEdit } from 'react-icons/bi';
import 'react-toastify/dist/ReactToastify.css';
import DataTable2 from '../../../context/components/DataTable2';
import TopNavBar from '../../../context/components/Pages/Users/TopNavBar';
import AlertContext from '../../../context/Alert/AlertContext';
import axiosPrivate from '../../../hooks/axiosPrivate';
import LoaderContext from '../../../context/loader/LoaderContext';

const Userrole = (props) => {
	const { alertMessage, setAlertMessage } = useContext(AlertContext);
	const [users, setUsers] = useState([]);
	const { setIsLoading } = useContext(LoaderContext);

	const headers = [
		{ name: '#', field: 'user_id', sortable: true, classKey: 'w60' },
		{ name: 'USER NAME', field: 'user_name', sortable: true, classKey: '' },
		{ name: 'Role', field: 'role_name', sortable: true, classKey: '' },

		{ name: 'ACTION', field: 'action', classKey: '' },
	];

	const searchItems = ['user_name','role_name'];

	useEffect(() => {
		setIsLoading(true);
		const options = {
			headers: { 'content-type': 'application/json' },
		};
		axiosPrivate
			.get('user-roles', options)
			.then((users) => {
				setIsLoading(false);
				const usersData = users.data.data.map((value, key) => {
					let buttons = [];
					if (true)
						buttons.push(
							<Link
								key="editButton##1"
								type="button"
								to={`/admin/userrole/edit/${value.user_id}`}
								className="btn btn-icon"
								title="Edit"
							>
							<BiEdit style={{ color: 'green' }} size={18} />

							</Link>
						);
					value['action'] = buttons.length > 0 ? buttons : '-';
					return value;
				});

				setUsers(usersData);
			})
			.catch((error) => {
				setIsLoading(false);
			});
	}, []);

	// 	};
	// 	axiosPrivate
	// 		.get('user-roles', options)
	// 		.then((usersroles) => {

	// 			const usersData = users.data.data.map((value, key) => {
	// 				let buttons = [];
	// 				if (true)
	// 					buttons.push(
	// 						<Link
	// 							key="editButton##1"
	// 							type="button"
	// 							to={`/admin/userrole/edit/${value.id}`}
	// 							className="btn btn-icon"
	// 							title="Edit"
	// 						>
	// 							<i className="fa fa-edit"></i>
	// 						</Link>
	// 					);
	// 				value['action'] = buttons.length > 0 ? buttons : '-';
	// 				return value;
	// 			});

	// 			setUsers(usersData);
	// 		})
	// 		.catch((error) => {
	// 		});
	// }, []);

	return (
		<div>
			{/* <TopNavBar links={{ list: '/admin/userrole', add: '/admin/userrole/add' }} /> */}
			<div className="section-body mt-3">
				<div className="container-fluid">
					<div className="tab-content mt-3">
						<div
							className="tab-pane fade show active"
							id="user-list"
							role="tabpanel"
						>
							<div className="card">
								<div className="card-header">
									<h3 className="card-title">USER ROLE LIST</h3>
								</div>
								<div className="card-body">
									<div className="table-responsive">
										{
											<DataTable2
												lists={users}
												headers={headers}
												searchItems={searchItems}
											/>
										}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Userrole;
