import React, { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";
import axiosPrivate from "../../../hooks/axiosPrivate";

function ColumnWithMarkers(props) {
  const [series, setSeries] = useState([]);

  useEffect(() => {
    const userCredentials = JSON.parse(localStorage.getItem("userDetails"));
    const { name } = userCredentials;

    if (name) {
      const username = name; // Get the username from credentials
      const userSpecificData = props?.ageWiseData?.filter(
        (item) => item.party_name === username
      );
      const newData = processFetchedData(userSpecificData);
      setSeries(newData);
    }
  }, [props?.ageWiseData]);

  const processFetchedData = (data) => {
    const dueDayRanges = [
      { min: 0, max: 7 },
      { min: 8, max: 15 },
      { min: 16, max: 30 },
      { min: 31, max: 60 },
      { min: 61, max: 120 },
      { min: 121, max: Infinity },
    ];

    const totalAmounts = dueDayRanges.map((_) => 0);
    // Calculate total amounts for each range
    data.forEach((item) => {
      const dueDays = parseInt(item.over_due_days, 10);
      for (let i = 0; i < dueDayRanges.length; i++) {
        const { min, max } = dueDayRanges[i];
        if (dueDays >= min && dueDays <= max) {
          totalAmounts[i] += parseFloat(item.due_amount);
          break;
        }
      }
    });

    return [
      {
        name: "Actual",
        data: totalAmounts.map((amount, index) => {
          if (index === dueDayRanges.length - 1) {
            return {
              x: "Above 120 days",
              y: amount.toFixed(2),
              goals: [
                {
                  name: "Expected",
                  value: 0,
                  strokeHeight: 5,
                  strokeColor: "#775DD0",
                },
              ],
            };
          }
          return {
            x: `${dueDayRanges[index].min}-${dueDayRanges[index].max} days`,
            y: amount.toFixed(2),
            goals: [
              {
                name: "Expected",
                value: 0,
                strokeHeight: 5,
                strokeColor: "#775DD0",
              },
            ],
          };
        }),
      },
    ];
  };

  const options = {
    chart: {
      height: 350,
      type: "bar",
    },
    plotOptions: {
      bar: {
        columnWidth: "60%",
      },
    },
    colors: ["#00E396"],
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: true,
      showForSingleSeries: true,
      customLegendItems: ["Outstanding Amount", "Expected Amount"],
      markers: {
        fillColors: ["#00E396", "#775DD0"],
      },
    },
  };

  return (
    <div id="chart">
      <ReactApexChart
        options={options}
        series={series}
        type="bar"
        height={350}
      />
    </div>
  );
}

export default ColumnWithMarkers;
