import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { FaGreaterThan } from 'react-icons/fa';
import { GrView } from 'react-icons/gr'
import DataTable2 from "../../../context/components/DataTable2";
import TopNavBar from "../../../context/components/Pages/Users/TopNavBar";
import axiosPrivate from "../../../hooks/axiosPrivate";
import { showErrorToast } from "../../../Toster";

function ReturnOrders() {

	const [ReturnorderState, setReturnorderState] = useState([])

	const headers = [
		// { name: 'RETURN ORDER ID', field: 'id', sortable: true, classKey: '' },
		{
			name: 'RETURN NO',
			field: 'return_no',
			sortable: true,
			classKey: '',
		},
		{
			name: 'ORDER STATUS',
			field: 'status',
			sortable: true,
			classKey: '',
		},
		{
			name: 'REASON',
			field: 'reason',
			sortable: true,
			classKey: '',
		},


		{
			name: 'Return Initiation Date',
			field: 'date',
			sortable: true,
			classKey: '',
		},
	];

	const searchItems = ['new_return_no', 'status', 'reason', 'date'];

	// get All  ordrs lits api
	useEffect(() => {
		const options = {
			headers: { 'content-type': 'application/json' },
		};
		axiosPrivate
			.get('/return-orders', options)
			.then((order) => {
				const orderData = order?.data?.data?.map((value, key) => {
					let buttons = [];

					if (true)
						buttons.push(
							<Link
								key={`viewButton##${key}`}
								type="button"
								to={`/admin/returnorders/view/${value.id}`}
								className="btn btn-icon"
								title="View"
							>
								<GrView style={{ color: 'blue' }} size={18} />
							</Link>
						);

					value['action'] = buttons.length > 0 ? buttons : '-';
					const formattedDate = new Date(value.order_date).toLocaleDateString(
						'en-US',
						{
							year: 'numeric',
							month: 'long',
							day: 'numeric',
						}
					);

					return {
						...value,
						order_date: formattedDate,

						return_no: (
							<Link
								to={`view/${value.id}`}
								style={{ textDecoration: 'underline' }}
							>
								{value.return_no}
							</Link>
						),

						new_return_no: value.return_no,
					};
				});

				setReturnorderState(orderData);
			})
			.catch((error) => {
				if (typeof error.response.data.errors == "array") {
					showErrorToast(error.response.data.errors[0].msg);
				} else {
					showErrorToast(error.response.data.errors.msg);
				}
			})
	}, []);
	return (
		<>
			<TopNavBar links={{ list: '/admin/Ecom-orders' }} />
			<div className="section-body ">
				<div className="container-fluid">
					{/* hearder breadcrumb */}
					<div className="card-header">
						<strong className="top-left-heading">Return-Orders</strong>

						<ol className="headerroutes breadcrumb m-0 bg-none ">
							<li className="item color-gray">|</li>
							<li className="item">
								<Link to="/admin/dashboard" className="route-heading item mx-2">
									Dashboard
								</Link>
							</li>
							<li className="item">
								<FaGreaterThan className="grater-than-route" />
							</li>
							<li className="route-heading item mx-2 mt-1">Return-Orders</li>
						</ol>
					</div>

					<div className="tab-content ">
						<div
							className="tab-pane fade show active"
							id="orders-list"
							role="tabpanel"
						>
							<div className="card">
								<div className="card-body">
									<div className="table-responsive">
										{
											<DataTable2
												lists={ReturnorderState}
												headers={headers}
												searchItems={searchItems}
											/>
										}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export default ReturnOrders;