import { useFormik } from 'formik';
import React, { useContext, useEffect, useState } from 'react';
import { GrAddCircle, GrSubtractCircle } from 'react-icons/gr';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import AlertContext from '../../../context/Alert/AlertContext';
import ConfirmationModal from "../../../context/components/Modals/ConfirmationModal";
import axiosPrivate from '../../../hooks/axiosPrivate';
import classes from './Logistic.module.css';

const validationRules = {
    order_logistic_items: Yup.array().of(Yup.object().shape({
        logistic_quantity: Yup.string().required('Quantity is required'),
        dispatch_user_id: Yup.string().required('Dispatch user is required'),
        transporter_name: Yup.string().required('Transporter name is required'),
        driver_name: Yup.string().required('Driver name is required'),
        driver_phone: Yup.string().required('Driver phone is required'),
        vehicle_no: Yup.string().required('Vehicle no is required'),
        dispatch_date: Yup.date().required('Dispatch date is required'),
    })).min(1)
};

const AddLogisticItems = () => {
  
    let { order_id, item_id } = useParams();
    const dearlerDropDown = true;

    const navigate = useNavigate();
    const [products, setProducts] = useState([]);
    const [getData, setGetData] = useState({});
    const [itemDetail, setItemDetail] = useState([]);
    const [dispatchUserList, setDispatchUserList] = useState([]);
    const [order, setOrder] = useState([]);
    const [shippingAddress, setShippingAddress] = useState([]);
    const [showModal, setShowModal ] = useState(false);
    const [eventForDelete, setEventForDelete] = useState();
    const [tableData, setTableData] = useState([
        {
            logistic_quantity: '',
            dispatch_user_id: '',
            dispatch_date: '',
            transporter_name: '',
            driver_name: '',
            driver_phone: '',
            vehicle_no: '',
        },
    ]);
    const { alertMessage, setAlertMessage } = useContext(AlertContext);

    // getting order items information start
    useEffect(() => {
        //Order items start
        axiosPrivate
        .get(`/logistics/items/view/${order_id}/${item_id}`)
        .then((response) => {
            if(response.data.data != undefined) {
                formik.setFieldValue('stock_id',response.data.data.stock_id);
                formik.setFieldValue('shipping_id',response.data.data.shipping_address_id);
                setItemDetail(response.data.data);
                formik.setFieldValue('order_logistic_items',[response.data.data]);
            }
        })
        .catch((error) => {
        });
        //Order items end

        //Logistic items start
        // axiosPrivate
        // .get(`/logistics/items/${order_id}/${item_id}`)
        // .then((response) => {
        //     if(response.data.data != undefined) {
        //         formik.setFieldValue('order_logistic_items',response.data.data);
        //     }
        // })
        // .catch((error) => {
        // });
        //Logistic items end

    }, [item_id, order_id]);
    // getting order items information end

    // getting order information start
    useEffect(() => {
        axiosPrivate
            .get(`orders/${order_id}`)
            .then((response) => {
                if (response.data.code === 200) {
                    setGetData({ ...response.data.data });
                    setOrder(response.data.data);
                }
            })
            .catch((error) => {
            });
    }, [order_id]);
    // getting order information end

    useEffect(() => {
        axiosPrivate
        .get(`users/get/addresses/${order.dealer_id}`)
        .then((response) => {
            if (response.data.code === 200) {
                setShippingAddress(response?.data?.data);
            }
        })
        .catch((error) => {
        });

        if(order.id != undefined) {
            formik.setFieldValue('order_id',order.id);
        }
        if(item_id != undefined) {
            formik.setFieldValue('order_item_id',order.id);
        }
    }, [order]);

    useEffect(() => {
        axiosPrivate
            .get(`users/get/user/dispatch_team`)
            .then((response) => {
                setDispatchUserList(response?.data?.data);
            })
            .catch((error) => {
            });
    }, []);

    // product list
    useEffect(() => {
        axiosPrivate
            .get('orders/get/products/list')
            .then((order) => {
                setProducts(order?.data?.data);
            })
            .catch((error) => {
            });
    }, []);

    const addNewRow = (eve) => {
        eve.preventDefault();
        const order_logistic_items = formik.values.order_logistic_items;
        formik.setFieldValue('order_logistic_items',[
            ...order_logistic_items,
            {
                logistic_quantity: '',
                dispatch_user_id: '',
                dispatch_date: '',
                transporter_name: '',
                driver_name: '',
                driver_phone: '',
                vehicle_no: '',
                shipping_address: ''
            },
        ]);
    };

    const removeRow = (index, eve) => {
        eve.preventDefault();
        const order_logistic_items = formik.values.order_logistic_items;
        if (order_logistic_items.length > 1) {
            let filteredRows = order_logistic_items.filter((_, i) => i !== index);
            formik.setFieldValue('order_logistic_items',filteredRows);
        }
        return true;
    };

    const openModal = (event,index,order_logistic_items) => {
        event.preventDefault();
        setEventForDelete({"event":event,"index":index,"order_logistic_items":order_logistic_items});
        setShowModal(true);
    };

    const removeLogisticItem = async (id) => {
        await axiosPrivate
        .delete(`/logistics/items/${id}`)
        .then((response) => {
            if(response.data.code == 200) {
                setAlertMessage({ message: 'Logistic Items deleted Successfully' });
            }
        })
        .catch((error) => {
        });
    }

    const closeModal = () => {
        setShowModal(false);
    };

    const handleConfirm = async () => {
        await removeLogisticItem(eventForDelete.order_logistic_items);
        removeRow(eventForDelete.index,eventForDelete.event);
        closeModal();
        navigate(`/admin/logistics`);
    };

    useEffect(() => {
        if (alertMessage != null) {
            toast(alertMessage.message, {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'light',
            });
            setAlertMessage(null);
        }
    }, [alertMessage]);

    const formik = useFormik({
        initialValues: {
            order_id: '',
            order_item_id: '',
            stock_id: '',
            shipping_id: '',
            order_logistic_items: [{
                logistic_quantity: '',
                dispatch_user_id: '',
                transporter_name: '',
                driver_name: '',
                driver_phone: '',
                vehicle_no: '',
                shipping_address: ''
            }]
        },
        validationSchema: Yup.object(validationRules),
        onSubmit: async (values, { setSubmitting, setFieldError }) => {
            var newValues = {
                order_id: order_id,
                order_item_id: item_id,
                stock_id: values.stock_id,
                shipping_id: values.shipping_id,
                logistics: values.order_logistic_items
            };

            // axiosPrivate
            // .post(`/logistics/store`, newValues)
            // .then((response) => {
            //     setAlertMessage({ message: 'Logistic Items Update Successfully' });
            //     navigate('/admin/logistics');
            // })
            // .catch((error) => {
            //     if (error.response) {
            //         if (error.response.status !== 200) {
            //             for (let i = 0; i < error.response.data.errors.length; i++) {
            //                 setFieldError(
            //                     error.response.data.errors[i].param,
            //                     error.response.data.errors[i].msg
            //                 );
            //             }
            //         }
            //     }
            // });
        },
    });

    const quantityAddHandler = (event,index) => {
        const numRejex = /^[0-9\b]+$/;
        // let currentQuantity = 0;  
        // if (event.target.value === '' || numRejex.test(event.target.value) && event.target.value.length <= 10) {
        //     if(event.target.value === '') {
        //         event.target.value = '';
        //         formik.handleChange(event);
        //     } else {
        //         if(formik.values.order_logistic_items.length > 0) {
        //             for(let i = 0; i < formik.values.order_logistic_items.length; i++) {
        //                 if(i == index) {
        //                     currentQuantity += parseInt(event.target.value);
        //                 } else {
        //                     currentQuantity += parseInt(formik.values.order_logistic_items[i].logistic_quantity);
        //                 }  
        //             }
        //         }
        //         if(currentQuantity <= parseInt(itemDetail.quantity)) {
        //             formik.handleChange(event);
        //         }
        //     }
           
        // } 
        if (event.target.value === '' || numRejex.test(event.target.value) && event.target.value.length <= 10) {
            formik.handleChange(event);
        }
        return true;
    }


    return (
        <div className="section-body mt-3">
            <div className="container-fluid">
                <div className="tab-content mt-3">
                    <form onSubmit={formik.handleSubmit}>
                        <div
                            className="tab-pane fade show active"
                            id="user-add"
                            role="tabpanel"
                        >
                            <div className="card">
                                <div
                                    className="card-header"
                                    style={{ fontWeight: '700', fontSize: '18px' }}
                                >
                                    <strong>View Logistic Item</strong>
                                </div>
                                <ConfirmationModal
                                isOpen={showModal}
                                title="Confirmation"
                                message="Are you sure you want to proceed?"
                                onConfirm={handleConfirm}
                                onCancel={closeModal}
                                />
                                <div className="row mx-3">
                                    {dearlerDropDown ? (
                                        <>
                                            <div className="col-lg-6 col-md-6 col-sm-12">
                                                <div className="form-group">
                                                    <label>
                                                        Product :
                                                    </label>
                                                    <input
                                                        id="order_id"
                                                        type="hidden"
                                                        className="form-control"
                                                        name="order_id"
                                                        value={itemDetail.stock_id}
                                                        hidden
                                                    />
                                                    <select
                                                        id="stock_id"
                                                        className="form-control"
                                                        name="stock_id"
                                                        value={itemDetail.stock_id}
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        disabled
                                                    >
                                                        <option value="">Select Product</option>
                                                        {products.map((stock_id) => (
                                                            <option key={stock_id.id} value={stock_id.id}>
                                                                {stock_id.tally_name}
                                                            </option>
                                                        ))}
                                                    </select>
                                                    {formik.touched.stock_id &&
                                                        formik.errors.stock_id ? (
                                                        <div className={classes.invalidDataError}>
                                                            {formik.errors.stock_id}
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </div>
                                        </>
                                    ) : (
                                        ''
                                    )}
                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                        <div className="form-group">
                                            <label>
                                                Total Quantity :
                                            </label>
                                            <input
                                                id="order_no"
                                                type="number"
                                                className="form-control"
                                                name="order_no"
                                                readOnly
                                                value={itemDetail.quantity}
                                            />
                                        </div>
                                    </div>
                                </div>
                                {/* table */}
                                <div className={`card-body ${classes.tableWrapper}`}>
                                    <table
                                        className="table table-hover"
                                        style={{ width: '100%' }}
                                    >
                                        <thead>
                                            {/* table heading */}
                                            <tr>
                                                <th>
                                                    <label>
                                                        Quantity:<span className="required">*</span>
                                                    </label>
                                                </th>
                                                <th>
                                                    <label>
                                                        Dispatch User:<span className="required">*</span>
                                                    </label>
                                                </th>
                                                <th>
                                                    <label>
                                                        Dispatch Date:<span className="required">*</span>
                                                    </label>
                                                </th>
                                                <th>
                                                    <label>
                                                        Transporter Name:<span className="required">*</span>
                                                    </label>
                                                </th>
                                                <th>
                                                    <label>
                                                        Driver Name:<span className="required">*</span>
                                                    </label>
                                                </th>
                                                <th>
                                                    <label>
                                                        Driver Phone No:<span className="required">*</span>
                                                    </label>
                                                </th>
                                                <th>
                                                    <label>
                                                        Vechile No:<span className="required">*</span>
                                                    </label>
                                                </th>
                                                <th>
                                                    <label>
                                                        Shipping Address:<span className="required">*</span>
                                                    </label>
                                                </th>
                                                <th>
                                                    <label>
                                                        {/* Action:<span className="required">*</span> */}
                                                    </label>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {formik.values.order_logistic_items.map((_, index) => (
                                                <tr key={index}>
                                                    {/* Quantity */}
                                                    <td>
                                                        <input
                                                            id="logistic_quantity"
                                                            type="number"
                                                            className="form-control"
                                                            name={`order_logistic_items[${index}].logistic_quantity`}
                                                            placeholder="Enter"
                                                            value={formik.values.order_logistic_items[index].logistic_quantity}
                                                            onChange={event => quantityAddHandler(event,index)}
                                                            disabled
                                                        />
                                                        {formik.touched && formik.errors.order_logistic_items != undefined  && formik.errors.order_logistic_items.length > 0 && formik.errors.order_logistic_items[index] != undefined && formik.errors.order_logistic_items[index].logistic_quantity ? (
                                                            <div className={classes.invalidDataError}>
                                                                {formik.errors.order_logistic_items[index].logistic_quantity}
                                                            </div>
                                                        ) : null}
                                                    </td>
                                                    {/* dispatch user dropdown */}
                                                    <td>
                                                        <select
                                                            id="dispatch_user_id"
                                                            className="form-control"
                                                            name={`order_logistic_items[${index}].dispatch_user_id`}
                                                            value={formik.values.order_logistic_items[index].dispatch_user_id}
                                                            onChange={(event) => {
                                                                formik.handleChange(event);
                                                            }}
                                                            onBlur={formik.handleBlur}
                                                            disabled
                                                        >
                                                            <option value="">Select User</option>
                                                            {dispatchUserList.map((dispatch_user_id) => (
                                                                <option key={dispatch_user_id.id} value={dispatch_user_id.id}>
                                                                    {dispatch_user_id.name}
                                                                </option>
                                                            ))}
                                                        </select>
                                                        {formik.touched && formik.errors.order_logistic_items != undefined  && formik.errors.order_logistic_items.length > 0 && formik.errors.order_logistic_items[index] != undefined &&
                                                            formik.errors.order_logistic_items[index].dispatch_user_id != undefined ? (
                                                            <div className={classes.invalidDataError}>
                                                                {formik.errors.order_logistic_items[index].dispatch_user_id}
                                                            </div>
                                                        ) : null}
                                                    </td>
                                                    {/* Dispatch date */}
                                                    <td>
                                                        <input
                                                            id="dispatch_date"
                                                            type="date"
                                                            className="form-control"
                                                            min={new Date(new Date().getFullYear(), new Date().getMonth(), 1)
                                                                .toISOString()
                                                                .slice(0, 10)}
                                                            name={`order_logistic_items[${index}].dispatch_date`}
                                                            value={formik.values.order_logistic_items[index].dispatch_date != "" && formik.values.order_logistic_items[index].dispatch_date != undefined ? new Date(formik.values.order_logistic_items[index].dispatch_date).toISOString().slice(0,10) : '' }
                                                            onChange={(event) => {
                                                                formik.handleChange(event); // Call handleChange with the event
                                                            }}
                                                            disabled
                                                        />
                                                        {formik.touched && formik.errors.order_logistic_items != undefined  && formik.errors.order_logistic_items.length > 0 && formik.errors.order_logistic_items[index] != undefined && formik.errors.order_logistic_items[index].dispatch_date != undefined ? (
                                                            <div className={classes.invalidDataError}>
                                                                {formik.errors.order_logistic_items[index].dispatch_date}
                                                            </div>
                                                        ) : null}
                                                    </td>
                                                    {/* Transporter name */}
                                                    <td>
                                                        <input
                                                            id="transporter_name"
                                                            type="text"
                                                            className="form-control"
                                                            name={`order_logistic_items[${index}].transporter_name`}
                                                            placeholder="Enter"
                                                            value={formik.values.order_logistic_items[index].transporter_name}
                                                            onChange={(event) => {
                                                                formik.handleChange(event); // Call handleChange with the event
                                                            }}
                                                            disabled
                                                        />
                                                        {formik.touched && formik.errors.order_logistic_items != undefined  && formik.errors.order_logistic_items.length > 0 && formik.errors.order_logistic_items[index] != undefined && formik.errors.order_logistic_items[index].transporter_name != undefined ? (
                                                            <div className={classes.invalidDataError}>
                                                                {formik.errors.order_logistic_items[index].transporter_name}
                                                            </div>
                                                        ) : null}
                                                    </td>
                                                    {/* Driver name */}
                                                    <td>
                                                        <input
                                                            id="driver_name"
                                                            type="text"
                                                            className="form-control"
                                                            name={`order_logistic_items[${index}].driver_name`}
                                                            placeholder="Enter"
                                                            value={formik.values.order_logistic_items[index].driver_name}
                                                            onChange={(event) => {
                                                                formik.handleChange(event); // Call handleChange with the event
                                                            }}
                                                            disabled
                                                        />
                                                        {formik.touched && formik.errors.order_logistic_items != undefined  && formik.errors.order_logistic_items.length > 0 && formik.errors.order_logistic_items[index] != undefined && formik.errors.order_logistic_items[index].driver_name != undefined ? (
                                                            <div className={classes.invalidDataError}>
                                                                {formik.errors.order_logistic_items[index].driver_name}
                                                            </div>
                                                        ) : null}
                                                    </td>
                                                    {/* Driver phone */}
                                                    <td>
                                                        <input
                                                            id="driver_phone"
                                                            type="number"
                                                            className="form-control"
                                                            name={`order_logistic_items[${index}].driver_phone`}
                                                            placeholder="Enter"
                                                            value={formik.values.order_logistic_items[index].driver_phone}
                                                            onChange={event =>{
                                                                const numRejex = /^[0-9\b]+$/;
                                                                if (event.target.value === '' || numRejex.test(event.target.value) && event.target.value.length <= 10) {
                                                                    formik.handleChange(event)
                                                                }
                                                            }}
                                                            disabled
                                                        />
                                                        {formik.touched && formik.errors.order_logistic_items != undefined  && formik.errors.order_logistic_items.length > 0 && formik.errors.order_logistic_items[index] != undefined && formik.errors.order_logistic_items[index].driver_phone != undefined ? (
                                                            <div className={classes.invalidDataError}>
                                                                {formik.errors.order_logistic_items[index].driver_phone}
                                                            </div>
                                                        ) : null}
                                                    </td>
                                                    {/* Vehicle No. */}
                                                    <td>
                                                        <input
                                                            id="vehicle_no"
                                                            type="text"
                                                            className="form-control"
                                                            name={`order_logistic_items[${index}].vehicle_no`}
                                                            placeholder="Enter"
                                                            value={formik.values.order_logistic_items[index].vehicle_no}
                                                            onChange={(event) => {
                                                                formik.handleChange(event); // Call handleChange with the event
                                                            }}
                                                            disabled
                                                        />
                                                        {formik.touched && formik.errors.order_logistic_items != undefined  && formik.errors.order_logistic_items.length > 0 && formik.errors.order_logistic_items[index] != undefined && formik.errors.order_logistic_items[index].vehicle_no != undefined ? (
                                                            <div className={classes.invalidDataError}>
                                                                {formik.errors.order_logistic_items[index].vehicle_no}
                                                            </div>
                                                        ) : null}
                                                    </td>
                                                    {/* Shipping Address */}
                                                    <td>
                                                        <input
                                                            id="vehicle_no"
                                                            type="text"
                                                            className="form-control"
                                                            name={`order_logistic_items[${index}].ship_address`}
                                                            placeholder="Enter"
                                                            value={formik.values.order_logistic_items[index].shipping_address}
                                                            onChange={(event) => {
                                                                formik.handleChange(event); // Call handleChange with the event
                                                            }}
                                                            disabled
                                                        />
                                                        {formik.touched && formik.errors.order_logistic_items != undefined  && formik.errors.order_logistic_items.length > 0 && formik.errors.order_logistic_items[index] != undefined && formik.errors.order_logistic_items[index].shi != undefined ? (
                                                            <div className={classes.invalidDataError}>
                                                                {formik.errors.order_logistic_items[index].shi}
                                                            </div>
                                                        ) : null}
                                                    </td>
                                                    <td>
                                                        <div className={classes.actionBtns}>
                                                            
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                                <div className="col-12 submit-btn my-2">
                                    <hr className="mt-4" />
                                    <div className="table-responsive">
                                        <table className="table table-striped"></table>
                                    </div>
                                    <button
                                        type="button"
                                        className="btn btn-secondary"
                                        data-dismiss="modal"
                                        onClick={() => navigate(`/admin/logistics`)}
                                    >
                                        Close
                                    </button>
                                    
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default AddLogisticItems