import React, { useEffect, useState } from "react";
import AddressForm from "./AddressForm";
import axiosPrivate from "../hooks/axiosPrivate";
import { useFormik } from "formik";
import { useRef } from "react";
import * as Yup from "yup";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { json, useAsyncError, useNavigate } from "react-router-dom";
import { useContext } from "react";
import { toast } from "react-toastify";
import AlertContext from "./../context/Alert/AlertContext";
import AuthContext from "./../context/Auth/AuthContext";
import CartCountContext from "../context/CartCount/CartCountContext";
import { showErrorToast } from "../Toster";

const ProductDetail = () => {
  const authContext = useContext(AuthContext);
  const [cart_items, setCartItems] = useState([]);
  const [shipping_addresses, setShippingAddresses] = useState([]);
  const [cart_updation, updateCartItems] = useState(0);
  const [subTotal, setsubTotal] = useState(0);
  const [shipping, setShippingTotal] = useState(0);
  const [taxes, setTaxes] = useState(0);
  const [orderTotal, setOrderTotal] = useState(0);
  const [totalSaving, setTotalSaving] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [shippingboolean, setShippingForm] = useState(false);
  const [refreshState, setRefreshstate] = useState(false);
  const [visible, setVisible] = useState(false);
  const [shippingDelivery, setShippingDelivery] = useState({});
  const [showPaymentMethod, setPaymentMethod] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [couponValue, setCouponValue] = useState("");
  const [seletedCouponId, setSelectedCouponId] = useState("");
  const [addressHeading, setAddressName] = useState(
    "Billing & Shipping Details"
  );
  const buttonRef = useRef(null);
  const buttonRef1 = useRef(null);
  const navigate = useNavigate();
  const { alertMessage, setAlertMessage } = useContext(AlertContext);
  const [couponList, setCouponList] = useState([]);
  const [discountValue, setDiscountValue] = useState("");
  const [sendEditData, setSendEditData] = useState({});
  const { user, setUser, userDetails, setUserDetails } =
    useContext(AuthContext);
  const { cartCountCheck, setCartCountCheck } = useContext(CartCountContext);

  useEffect(() => {
    if (alertMessage != null) {
      toast(alertMessage.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      setAlertMessage(null);
    }
  }, [alertMessage]);

  //address forms for login users
  const closeDiv = (data) => {
    setVisible(false);
    updateCartItems(cart_updation + 1);
  };

  //address forms for guest users
  const shippingForm = {
    name: "",
    email: "",
    mobile: "",
    shipping_state: "",
    shipping_address: "",
    shipping_city: "",
    shipping_zip: "",
  };

  const billingForm = {
    name: "",
    email: "",
    mobileNo: "",
    address: "",
    state: "",
    city: "",
    zip: "",
  };

  const shippingFormSchema = Yup.object({
    name: Yup.string()
    .max(120, "Name must not exceed 120 characters")
    .required("Name is required"),

    email: Yup.string()
      .matches(
        /^[\w.-]+@[a-zA-Z\d.-]+\.[a-zA-Z]{2,}$/,
        "Please enter a valid email!"
      )
      .required("Email is required"),
  
      mobile: Yup.string().test('starts-not-with-0-to-5', 'Mobile is not valid', function(value) {
     
      if (!value) return true; 
      return !/^[0-5]/.test(value);
    }).required("Mobile is required"),
    shipping_state: Yup.string().required("State is required"),
    shipping_city: Yup.string().required("City is required"),
    shipping_zip: Yup.string().required("Zip is required"),
  });

  const billingFormSchema = Yup.object({
   
    name: Yup.string()
    .max(120, "Name must not exceed 120 characters")
    .required("Name is required"),

    email: Yup.string()
      .matches(
        /^[\w.-]+@[a-zA-Z\d.-]+\.[a-zA-Z]{2,}$/,
        "Please enter a valid email!"
      )
      .required("Email is required"),
  
      mobileNo: Yup.string().test('starts-not-with-0-to-5', 'Mobile no is not valid', function(value) {
     
      if (!value) return true; 
      return !/^[0-5]/.test(value);
    }).required("Mobile no is required"),
    state: Yup.string().required("State is required"),
    city: Yup.string().required("City is required"),
    zip: Yup.string().required("Zip is required"),
  });

  // form1 submit
  const form1Submit = (values) => {
    if (!shippingboolean) {
      Object.keys(form2Values).forEach((key) => {
        form2SetFieldValue(key, values[key]);
      });
      setShippingDelivery(values);
      if (showPaymentMethod) {
        proceed_to_pay();
      }
    } else {
      buttonRef1.current.click();
    }
  };

  //form2 submit
  const form2Submit = (values) => {
    setShippingDelivery(values);
    proceed_to_pay();
  };

  //form
  const {
    values: form1Values,
    handleSubmit: form1HandleSubmit,
    handleBlur: form1HandleBlur,
    handleChange: form1HandleChange,
    validateForm: form1Validate,
    touched: form1Touched,
    errors: form1Errors,
    setFieldValue: setFieldValue1
  } = useFormik({
    initialValues: billingForm,
    validationSchema: billingFormSchema,
    onSubmit: form1Submit,
  });

  const {
    values: form2Values,
    handleSubmit: form2HandleSubmit,
    handleBlur: form2HandleBlur,
    handleChange: form2HandleChange,
    validateForm: form2Validate,
    setFieldValue: form2SetFieldValue,
    touched: form2Touched,
    errors: form2Errors,
    setFieldValue: setFieldValue2
  } = useFormik({
    initialValues: shippingForm,
    validationSchema: shippingFormSchema,
    onSubmit: form2Submit,
  });

  //showShippingForm
  function showShippingForm() {
    let boolean = shippingboolean;
    if (!boolean) {
      Object.keys(form2Values).forEach((key) => {
        form2SetFieldValue(key, "");
      });
    }
    setShippingForm(!boolean);
    setAddressName(!boolean ? "Billing Details" : "Billing & Shipping Details");
  }

  let userLogin = localStorage.getItem("userData");

  //submit both shipping and billing form
  const submitForm = () => {
    if (cart_items?.length > 0) {
      if (!userLogin) {
        buttonRef.current.click();
        if (shippingboolean) {
          buttonRef1.current.click();
        }
        if (showPaymentMethod) {
          setErrorMsg("");
        } else {
          setAlertMessage({ message: "Please Choose Payment Method!" });
          setErrorMsg("*Please Choose Payment Method");
        }
      } else {
        if (showPaymentMethod) {
          if (Object.keys(shippingDelivery)?.length > 0) {
            setErrorMsg("");
            proceed_to_pay();
          } else {
            setAlertMessage({ message: "Please Select Shipping Address!" });
            setErrorMsg("*Please Select Shipping Address");
          }
        } else {
          setAlertMessage({ message: "Please Choose Payment Method!" });
          setErrorMsg("*Please Choose Payment Method");
        }
      }
    } else {
      setAlertMessage({ message: "Please Add Product To Cart!" });
    }
  };
  async function proceed_to_pay() {
    if (!userLogin) {
      let object = {
        name: form1Values.name,
        mobile: form1Values.mobileNo,
        role: String(8),
        email: form1Values.email,
        password: form1Values.mobileNo,
      };

      try {
        const response = await axiosPrivate.post("/register", object);

        localStorage.setItem("userData", JSON.stringify(response.data.token));
        localStorage.setItem("userDetails", JSON.stringify(response.data));

        // authContext.login(response.data);

        saveBillingShippingDetails();

        // if (localStorage.getItem("userData")) {
        //   saveBillingShippingDetails();
        // }
      } catch (error) {
      }
    } else {
      let orderData = {
        shipping_amount: 0,
        payment_mode: showPaymentMethod,
        payment_status: showPaymentMethod === "COD" ? "paid" : "pending",
        bill_name: shipping_addresses[0].name,
        bill_mobile: shipping_addresses[0]?.mobile,
        bill_address: shipping_addresses[0].shipping_address,
        bill_pincode: shipping_addresses[0].shipping_zip,
        bill_state: shipping_addresses[0].shipping_state,
        bill_city: shipping_addresses[0].shipping_city,
        ship_name: shippingDelivery?.name,
        ship_mobile: shippingDelivery?.mobile,
        ship_address: shippingDelivery?.shipping_address,
        ship_pincode: shippingDelivery?.shipping_zip,
        ship_state: shippingDelivery?.shipping_state,
        ship_city: shippingDelivery?.shipping_city,
        order_status: "Pending",
        coupon_id: seletedCouponId ?? "",
        gst_no: "test123test",
        successPage_url:'http://nmindiafrontend.webanixsolutions.in/thank-you/',
      };

      try {
        const orderResponse = await axiosPrivate.post(
          "ecom/orders/create",
          orderData
        )

        if (showPaymentMethod === "Online") {
          const queryString = {
            access_code:'AVGN23KJ18BL53NGLB',
            encryptedRequest: orderResponse?.data?.encryptedRequest,
          };
          // Construct the URL with the query string
          const iframeSrc = `https://secure.ccavenue.com/transaction/transaction.do?command=initiateTransaction&access_code=${queryString?.access_code}&encRequest=${queryString?.encryptedRequest}`;
          // window.location.href = iframeSrc;
          // let test = `https://test.ccavenue.com/transaction/transaction.do?command=initiateTransaction&merchant_id=2648982&encRequest=${queryString.encryptedRequest}&access_code=${queryString.access_code}`;
          // setPaymentMethod(test)
          window.location.href=iframeSrc;
          return true;
        }
        if(showPaymentMethod === "COD"){
        navigate(`/thank-you/${orderResponse.data.orderId}`);
        }
      } catch (error) {
          showErrorToast('Invalid Coupon');
      }
    }
  }

  // Call initiateOnlinePayment function when online payment is selected

  async function saveBillingShippingDetails() {
    if (localStorage.getItem("userDetails")) {
      var userDetails = JSON.parse(localStorage.getItem("userDetails"));
    }

    let shipping_billing_details = {
      billing_address: {
        user_id: userDetails.id,
        name: form1Values.name,
        mobile: form1Values.mobileNo,
        email: form1Values.email,
        address: form1Values.address,
        state: form1Values.state,
        city: form1Values.city,
        zip: form1Values.zip,
      },
      shipping_address: {
        user_id: userDetails.id,
        name: !shippingboolean
          ? form1Values.name
          : shippingDelivery.name ?? form2Values.name,
        mobile: !shippingboolean
          ? form1Values.mobileNo
          : shippingDelivery.mobile ?? form2Values.mobile,
        email: !shippingboolean
          ? form1Values.email
          : shippingDelivery.email ?? form2Values.email,
        shipping_address: !shippingboolean
          ? form1Values.address
          : shippingDelivery.shipping_address ?? form2Values.shipping_address,
        shipping_state: !shippingboolean
          ? form1Values.state
          : shippingDelivery.shipping_state ?? form2Values.shipping_state,
        shipping_city: !shippingboolean
          ? form1Values.city
          : shippingDelivery.shipping_city ?? form2Values.shipping_city,
        shipping_zip: !shippingboolean
          ? form1Values.zip
          : shippingDelivery.shipping_zip ?? form2Values.shipping_zip,
      },
      coupon_id: seletedCouponId,
      cart: cart_items,
      payment_mode: showPaymentMethod,
      order_status: "Pending",
    };

    axiosPrivate
      .post("/carts/add-details", shipping_billing_details)
      .then((res) => {
        localStorage.removeItem("cart_items");
        if (showPaymentMethod === "Online") {
          const queryString = {
            access_code:'AVGN23KJ18BL53NGLB',
            encryptedRequest: res?.data?.encryptedRequest,
          };
          // Construct the URL with the query string
          const iframeSrc = `https://secure.ccavenue.com/transaction/transaction.do?command=initiateTransaction&access_code=${queryString?.access_code}&encRequest=${queryString?.encryptedRequest}`;
          // window.location.href = iframeSrc;
          window.location.href=iframeSrc;
          return true;
        }
        if(showPaymentMethod === "COD"){
        navigate(`/thank-you/${res?.data?.orderId}`);
        }
      })

      .catch((error) => {
      });
  }
  const getCartItems=()=>{
    if (userLogin) {
      if (localStorage.getItem("userDetails")) {
        var userDetails = JSON.parse(localStorage.getItem("userDetails"));
      }
      axiosPrivate
        .get("carts/get")
        .then((res) => {
          setCartItems(res.data.data);
        })

        .catch((error) => {
          setCartItems([]);
          // navigate('/admin/about-template/add')
        });

      axiosPrivate
        .get(
          "users/get/addresses/" +
            (userDetails?.id ? userDetails?.id : userDetails?.userId)
        )
        .then((res) => {
          setShippingAddresses(res.data.data);
        })

        .catch((error) => {
          // navigate('/admin/about-template/add')
        });
    } else {
      let cart_local_array = localStorage.getItem("cart_items");
      if (cart_local_array) {
        let parsed_data = JSON.parse(cart_local_array);
        setCartItems(parsed_data);
      }
    }
  }
  useEffect(() => {
    getCartItems();
  }, [cart_updation]);

  // add remove quantity
  function updateQuantity(res, string) {
    if (!userLogin) {
      if (localStorage.getItem("cart_items")) {
        let cart_products = JSON.parse(localStorage.getItem("cart_items"));
        cart_products.forEach((element) => {
          if (element.product_id == res.product_id) {
            if (string == "add") {
              element.quantity = element.quantity + 1;
            } else {
              if (element.quantity > 1) {
                element.quantity = element.quantity - 1;
              }
            }
          }
        });
        localStorage.setItem("cart_items", JSON.stringify(cart_products));
        updateCartItems(cart_updation + 1);
      }
    } else {
      let object = {
        product_id: res.product_id,
        type: string == "add" ? "plus" : "minus",
        quantity: 1,
        variant_id: res?.variant_id,
      };
      axiosPrivate
        .put("carts/update/" + res?.id, object)
        .then((res) => {
          updateCartItems(cart_updation + 1);
        })

        .catch((error) => {
          // navigate('/admin/about-template/add')
        });
    }
  }

  // delete items from cart
  function deleteItem(res, index) {
    if (!userLogin) {
      cart_items.splice(index, 1);
      localStorage.setItem("cart_items", JSON.stringify(cart_items));
      let cart_count = cart_updation + 1;
      getCartItems()
      setRefreshstate(true);
      window.location.reload();
      updateCartItems(cart_count);
    } else {
      axiosPrivate
        .delete("carts/" + res.id)
        .then((res) => {
          updateCartItems(cart_updation + 1);
        })

        .catch((error) => {
          // navigate('/admin/about-template/add')
          // if(error.data.code == 404) {
          //   setCartItems(error.data.data);
          // }
        });
    }
    setCartCountCheck(!cartCountCheck);
  }

  //get cart items
 
  //get total
  useEffect(() => {
    let subTotal = 0;
    cart_items.forEach((res) => {
      subTotal = subTotal + res.rate * res.quantity;
    });
    setsubTotal(subTotal);
    setTotalAmount(totalSaving + orderTotal + taxes + shipping + subTotal);
  }, [cart_items]);

  function selectShippingAddress(res) {
    setShippingDelivery(res);
  }

  useEffect(() => {
    axiosPrivate
      .get("coupons")
      .then((res) => {
        setCouponList(
          res.data.data?.filter((res) => res.is_active === "Active")
        );
      })
      .catch((error) => {
      });
  }, []);

  const applyCoupons = (coupon) => {
    setCouponValue(coupon.coupon_code);
  };

  function calculateCouponValue() {
    if (couponValue) {
      axiosPrivate
        .get(`coupons/get/${couponValue}`)
        .then((res) => {
          const discount = res?.data?.data?.discount;
          if (res?.data?.data?.cart_min_amount <= subTotal) {
            const couponTypeIds = res.data?.data?.type_id; // Assuming it's an array
            const matches = cart_items?.some((item) =>
              couponTypeIds.includes(
                item?.product_id || item?.category_id || item?.user_id
              )
            );
            if (matches) {
              let calculatedDiscount =
                (discount / 100) * subTotal <= res?.data?.data?.offer_max_amount
                  ? (discount / 100) * subTotal
                  : res?.data?.data?.offer_max_amount;
              const newTotal =
                totalSaving +
                orderTotal +
                taxes +
                shipping +
                subTotal -
                calculatedDiscount;
              setDiscountValue(calculatedDiscount);
              setTotalAmount(newTotal);
              setSelectedCouponId(res.data?.data?.id);
            } else {
              showErrorToast(
                "Coupon doesn't apply to any product in your cart"
              );
            }
          } else
            showErrorToast(
              `Minimum Cart Amount Should be ${res?.data?.data?.cart_min_amount}`
            );
        })
        .catch((error) => {
        });
    }
  }
  useEffect(() => {
    calculateCouponValue(); // Call the function immediately
  }, [couponValue, totalSaving, orderTotal, taxes, shipping, subTotal]);

  const openAddressFormForEdit = (res) => {
    setVisible(true);
    setSendEditData(res);
  };

  const getAllAddressAgain = () => {
    axiosPrivate
      .get(
        "users/get/addresses/" +
          (userDetails?.id ? userDetails?.id : userDetails?.userId)
      )
      .then((res) => {
        setShippingAddresses(res.data.data);
      });
  };


  const numberFilter1 = (event) => {
    const inputValue = event.target.value;

    const numericValue = inputValue.replace(/\D/g, "");
    setFieldValue1(event.target.name, numericValue);
  };

  const nameFilter1 = (event) => {
    const inputValue = event.target.value;
    if (/^[A-Za-z\s'-]+$/.test(inputValue) || inputValue === "") {
      setFieldValue1(event.target.name, inputValue);
    }
  };

  const numberFilter2 = (event) => {
    const inputValue = event.target.value;

    const numericValue = inputValue.replace(/\D/g, "");
    setFieldValue2(event.target.name, numericValue);
  };

  const nameFilter2 = (event) => {
    const inputValue = event.target.value;
    if (/^[A-Za-z\s'-]+$/.test(inputValue) || inputValue === "") {
      setFieldValue2(event.target.name, inputValue);
    }
  };

  return (
    <>
      <>
        <Dialog
          header="Address Form"
          visible={visible}
          style={{ width: "50vw" }}
          onHide={() => {
            setVisible(false);
            getAllAddressAgain();
          }}
        >
          <AddressForm getData={closeDiv} sendData={sendEditData} />
        </Dialog>
      </>
      <div className="product-page">
        <div className="banner-image">
          <img src="img/product-banner.png" />
        </div>
        <div className="product-content">
          <div className="text-center">
            <p>Shop</p>
            <span>
              Home/<span style={{ color: "#50AE31" }}>Shop</span>
            </span>
          </div>
        </div>
      </div>
      <div className="shipping-page">
        <div className="first_section">
          {userLogin ? (
            <>
              <h4>Billing Details</h4>
              <div>
                {shipping_addresses?.length > 0 ? (
                  <div className="first_row_card row">
                    <div className="col-lg-6">
                      <div className="card_3 shipping-card">
                        <h4>Your Billing Detail</h4>
                        <span>{shipping_addresses[0]?.mobile}</span>
                        <p className="mt-1 order_address">
                          {shipping_addresses[0]?.shipping_address}
                          {shipping_addresses[0]?.shipping_city},{" "}
                          {shipping_addresses[0]?.shipping_state}{" "}
                          {shipping_addresses[0]?.shipping_zip}
                        </p>
                        <p
                          className="edit_button mt-1"
                          style={{ cursor: "pointer" }}
                          onClick={() =>
                            openAddressFormForEdit(shipping_addresses[0])
                          }
                        >
                          EDIT
                        </p>
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
              <h4>Shipping Details</h4>
              <div>
                <div className="first_row_card row">
                  <div className="col-lg-6">
                    <div
                      className="card_1 shipping-card"
                      onClick={() => {
                        setVisible(true);
                        setSendEditData({});
                      }}
                    >
                      <div style={{ cursor: "pointer" }}>
                        <span className="plus-icon">+</span>
                        <br></br>
                        <span className="new-address-icon">
                          Add New Address
                        </span>
                        <br></br>
                        <img src="img/iconoir_delivery-truck.svg" />
                      </div>
                    </div>
                  </div>
                  {shipping_addresses.map((res, index) => (
                    <>
                      {res?.address_type == "shipping_address" ? (
                        <div className="col-lg-6">
                          <div className="card_3 shipping-card">
                            <div style={{ textAlign: "right" }}>
                              <button
                                className={`${
                                  shippingDelivery?.id == res?.id
                                    ? "setBackground"
                                    : ""
                                }`}
                                onClick={() => selectShippingAddress(res)}
                              >
                                Deliver Here
                              </button>
                            </div>
                            <h4>Your Shipping Detail</h4>
                            <span>{res.mobile}</span>
                            <p className="mt-1 order_address">
                              {res.shipping_address}
                              {res.shipping_city} {res.shipping_state}{" "}
                              {res.shipping_zip}
                            </p>
                            <p
                              className="edit_button mt-1"
                              style={{ cursor: "pointer" }}
                              onClick={() => openAddressFormForEdit(res)}
                            >
                              EDIT
                            </p>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </>
                  ))}
                </div>
              </div>
            </>
          ) : (
            <>
              <h4>{addressHeading}</h4>
              <form onSubmit={form1HandleSubmit}>
                <div className="row">
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label>Name *</label>
                      <input
                        className="form-control"
                        id="name"
                        name="name"
                        type="text"
                        placeholder="Name"
                        onChange={nameFilter1}
                        onBlur={form1HandleBlur}
                        value={form1Values.name}
                      />
                      {form1Touched.name && form1Errors.name ? (
                        <div className="invalidDataError">
                          {form1Errors.name}
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label>Email Address *</label>
                      <input
                        className="form-control"
                        id="email"
                        name="email"
                        type="text"
                        placeholder="Email Address"
                        onChange={form1HandleChange}
                        onBlur={form1HandleBlur}
                        value={form1Values.email}
                      />
                      {form1Touched.email && form1Errors.email ? (
                        <div className="invalidDataError">
                          {form1Errors.email}
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label>Mobile No. *</label>
                      <input
                        className="form-control"
                        id="mobileNo"
                        name="mobileNo"
                        type="text"
                        placeholder="Mobile No."
                        onChange={numberFilter1}
                        onBlur={form1HandleBlur}
                        value={form1Values.mobileNo}
                        maxLength={10}
                      />
                      {form1Touched.mobileNo && form1Errors.mobileNo ? (
                        <div className="invalidDataError">
                          {form1Errors.mobileNo}
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label>Address *</label>
                      <input
                        className="form-control"
                        id="address"
                        name="address"
                        type="text"
                        placeholder="Address"
                        onChange={form1HandleChange}
                        onBlur={form1HandleBlur}
                        value={form1Values.address}
                      />
                      {/* {form1Touched.address && form1Errors.address ? (
														<div className="invalidDataError">
															{form1Errors.address}
														</div>
													) : null} */}
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label>State *</label>
                      <input
                        className="form-control"
                        id="state"
                        name="state"
                        type="text"
                        placeholder="State"
                        onChange={form1HandleChange}
                        onBlur={form1HandleBlur}
                        value={form1Values.state}
                      />
                      {form1Touched.state && form1Errors.state ? (
                        <div className="invalidDataError">
                          {form1Errors.state}
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label>City *</label>
                      <input
                        className="form-control"
                        id="city"
                        name="city"
                        type="text"
                        placeholder="City"
                        onChange={form1HandleChange}
                        onBlur={form1HandleBlur}
                        value={form1Values.city}
                      />
                      {form1Touched.city && form1Errors.city ? (
                        <div className="invalidDataError">
                          {form1Errors.city}
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label>Zipcode *</label>
                      <input
                        className="form-control"
                        id="zip"
                        name="zip"
                        type="text"
                        placeholder="Zipcode"
                        onChange={numberFilter1}
                        onBlur={form1HandleBlur}
                        value={form1Values.zip}
                        maxLength={6}
                      />
                      {form1Touched.zip && form1Errors.zip ? (
                        <div className="invalidDataError">
                          {form1Errors.zip}
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>

                <button type="submit" hidden ref={buttonRef}>
                  submit
                </button>
              </form>

              <p style={{ display: "flex", alignItems: "center" }}>
                <input
                  onClick={() => showShippingForm()}
                  className="mr-2"
                  type="checkbox"
                />
                Ship to Different Address
              </p>

              {shippingboolean ? (
                <>
                  <h4 className="mt-4">Shipping Details</h4>
                  <form onSubmit={form2HandleSubmit}>
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="form-group">
                          <label>Name *</label>
                          <input
                            className="form-control"
                            id="name"
                            name="name"
                            type="text"
                            placeholder="Name"
                            onChange={nameFilter2}
                            onBlur={form2HandleBlur}
                            value={form2Values.name}
                          />
                          {form2Touched.name && form2Errors.name ? (
                            <div className="invalidDataError">
                              {form2Errors.name}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group">
                          <label>Email Address *</label>
                          <input
                            className="form-control"
                            id="email"
                            name="email"
                            type="text"
                            placeholder="Email Address"
                            onChange={form2HandleChange}
                            onBlur={form2HandleBlur}
                            value={form2Values.email}
                          />
                          {form2Touched.email && form2Errors.email ? (
                            <div className="invalidDataError">
                              {form2Errors.email}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group">
                          <label>Mobile No. *</label>
                          <input
                            className="form-control"
                            id="mobile"
                            name="mobile"
                            type="text"
                            placeholder="Mobile No."
                            onChange={numberFilter2}
                            onBlur={form2HandleBlur}
                            value={form2Values.mobile}
                            maxLength={10}
                          />
                          {form2Touched.mobile && form2Errors.mobile ? (
                            <div className="invalidDataError">
                              {form2Errors.mobile}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group">
                          <label>State *</label>
                          <input
                            className="form-control"
                            id="shipping_state"
                            name="shipping_state"
                            type="text"
                            placeholder="State"
                            onChange={form2HandleChange}
                            onBlur={form2HandleBlur}
                            value={form2Values.shipping_state}
                          />
                          {form2Touched.shipping_state &&
                          form2Errors.shipping_state ? (
                            <div className="invalidDataError">
                              {form2Errors.shipping_state}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group">
                          <label>City *</label>
                          <input
                            className="form-control"
                            id="shipping_city"
                            name="shipping_city"
                            type="text"
                            placeholder="City"
                            onChange={form2HandleChange}
                            onBlur={form2HandleBlur}
                            value={form2Values.shipping_city}
                          />
                          {form2Touched.shipping_city &&
                          form2Errors.shipping_city ? (
                            <div className="invalidDataError">
                              {form2Errors.shipping_city}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group">
                          <label>Zipcode *</label>
                          <input
                            className="form-control"
                            id="shipping_zip"
                            name="shipping_zip"
                            type="text"
                            placeholder="Zipcode"
                            onChange={numberFilter2}
                            onBlur={form2HandleBlur}
                            value={form2Values.shipping_zip}
                            maxLength={6}
                          />
                          {form2Touched.shipping_zip &&
                          form2Errors.shipping_zip ? (
                            <div className="invalidDataError">
                              {form2Errors.shipping_zip}
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    <button type="submit" hidden ref={buttonRef1}>
                      submit
                    </button>
                  </form>
                </>
              ) : (
                ""
              )}
            </>
          )}
        </div>
        <div className="second_section">
          <h4>Your Order ({cart_items.length} Item)</h4>
          <div className="div_section">
            <div className="detail_section table-responsive">
              <table className="table">
                <tbody>
                  {cart_items.map((res, index) => (
                    <tr>
                      <td>
                        <div className="background_div">
                          {userLogin ? (
                            <div className="img_div">
                              <img
                                src={
                                  process.env.REACT_APP_COMMON_FILE_URL +
                                  "product-variants/" +
                                  res?.variant_images[0]?.image
                                }
                              />
                            </div>
                          ) : (
                            <>
                              {(
                                process.env.REACT_APP_COMMON_FILE_URL +
                                res.image
                              ).includes("product-variants") ? (
                                <div className="img_div">
                                  <img
                                    src={
                                      process.env.REACT_APP_COMMON_FILE_URL +
                                      res.image
                                    }
                                  />
                                </div>
                              ) : (
                                <div className="img_div">
                                  <img
                                    src={
                                      process.env.REACT_APP_COMMON_FILE_URL +
                                      res.image
                                    }
                                  />
                                </div>
                              )}
                            </>
                          )}
                        </div>
                      </td>
                      <td>
                        <div className="product_additional_detail">
                          <h5>{res.tally_name ?? res.product_name}</h5>
                          <span>{res?.description}</span>
                          <p>₹ {res.rate}</p>
                          <p>
                            {res?.variant}
                            {res?.base_unit}
                          </p>
                        </div>
                      </td>
                      <td>
                        <div className="quantity">
                          <button onClick={() => updateQuantity(res, "remove")}>
                            -
                          </button>
                          <span>{res.quantity}</span>
                          <button onClick={() => updateQuantity(res, "add")}>
                            +
                          </button>
                          <img
                            src="img/delete.svg"
                            style={{ cursor: "pointer" }}
                            onClick={() => deleteItem(res, index)}
                          />
                        </div>
                      </td>
                    </tr>
                  ))}
                  {cart_items.length == 0 ? "No Products Found" : ""}
                </tbody>
              </table>
            </div>
          </div>
          <div className="coupon-section">
            <div className="input_div">
              <img src="img/edit-icon.svg" />
              <input
                className="add_coupon"
                name="coupon_code"
                type="text"
                placeholder="Add coupon or gift card"
                value={couponValue}
                onChange={(e) => setCouponValue(e.target.value)}
              />
            </div>
            <button onClick={(event) => calculateCouponValue()}>Apply</button>
          </div>
          {couponList?.length > 0 && (
            <>
              <p className="coupon_list_p">Coupons</p>
              <p className="row">
                {!userLogin &&
                  couponList
                    .filter((res) => res?.coupon_type === "Global")
                    ?.map((res) => (
                      <button
                        className="btn btn-primary  mr-1 mb-1"
                        onClick={(event) => applyCoupons(res)}
                        key={res.id}
                      >
                        {res.name}
                      </button>
                    ))}
                {userLogin &&
                  couponList?.map((res) => (
                    <button
                      className="btn btn-primary mr-1 mb-1"
                      onClick={(event) => applyCoupons(res)}
                      key={res.id}
                    >
                      {res.name}
                    </button>
                  ))}
              </p>
            </>
          )}
          <div className="total_amount">
            <ul className="ul_class d-flex justify-content-between">
              <li>Subtotal</li>
              <li>₹ {subTotal}</li>
            </ul>
            <ul className="ul_class d-flex justify-content-between">
              <li>Estimated shipping</li>
              {/* <li>₹ {shipping}</li> */}
              <li>Free</li>
            </ul>
            {/* <ul className="ul_class d-flex justify-content-between">
              <li>Estimated Taxes</li>
              <li>₹ 0</li>
            </ul> */}
            <ul className="ul_class d-flex justify-content-between">
              <li>Estimated order total</li>
              <li>₹ {subTotal}</li>
            </ul>
            {discountValue ? (
              <ul className="ul_class d-flex justify-content-between">
                <li>Coupon Discount</li>
                <li> - ₹ {discountValue.toFixed(2)}</li>
              </ul>
            ) : (
              ""
            )}
            {/* <ul className="ul_class d-flex justify-content-between">
              <li>Total savings</li>
              <li>₹ 0</li>
            </ul> */}
            <ul className="ul_class d-flex justify-content-between">
              <li>Total amount </li>
              <li>₹ {totalAmount}</li>
            </ul>
          </div>

          <div className="payment_methods_sections">
            <h5 className="my-2 payment_heading">Choose Payment Method</h5>
            <div className="methods">
              <div className="d-flex mb-3">
                <input
                  type="radio"
                  onClick={() => setPaymentMethod("COD")}
                  name="payment_type"
                />
                <p className="mb-0 mx-2 heading">COD (Cash on delivery)</p>
                <br></br>
              </div>
              {/* <p className="sub-content">
                Safe money transfer using your bank account, Visa, maestro,
                discover, american express.<br></br>
              </p> */}
              <div className="d-flex">
                <input
                  type="radio"
                  onClick={() => setPaymentMethod("Online")}
                  name="payment_type"
                />
                <p className="mb-0 mx-2 heading">Online</p>
                <br></br>
              </div>
              <p className="sub-content">
                Safe money transfer using your bank account,UPI, Visa, maestro,
                discover, american express.<br></br>
              </p>
            </div>
          </div>
          <div
            className="proceed_to_pay_button"
            onClick={submitForm}
            style={{ cursor: "pointer" }}
          >
            <button type="submit" disabled={cart_items?.length == 0}>
              Proceed to Pay
            </button>
          </div>
          {errorMsg ? <div className="invalidDataError">{errorMsg}</div> : ""}
        </div>
      </div>
    </>
  );
};

export default React.memo(ProductDetail);
