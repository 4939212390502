import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { MdArrowDropUp } from 'react-icons/md';
import axiosPrivate from '../hooks/axiosPrivate';
import { addressSchema } from './schema';
import { showErrorToast, showSuccessToast } from '../Toster';

const initialValues = {
	name:"",
	mobile:"",
	email:"",
	shipping_address:"",
	shipping_city:"",
	shipping_state:"",
	shipping_zip:"",
};

function AddressForm({ getData, sendData }) {
	const [searchQuery, setSearchQuery] = useState('');
	if(localStorage.getItem('userDetails')){
		var {id} = JSON.parse(localStorage.getItem('userDetails'));
	}

	useEffect(() => {
		if(sendData != null) {
			setValues(sendData);
		}
	}, [])

	const { values, errors, handleBlur, handleChange, handleSubmit, setFieldValue, setValues } = useFormik({
		initialValues: initialValues,
		validationSchema: addressSchema,
		onSubmit: (values) => {
			if(Object.keys(sendData).length === 0) {
				axiosPrivate
					.post(`users/address/create/${id}`, values)
					.then((response) => {
						getData(false)
						showSuccessToast("Address Created Successfully")
					})
					.catch((error) => {
						getData(false)
						showErrorToast("Request Failed")
						// Handle the error appropriately
					});
			}
			else {
				axiosPrivate.post(`/users/address/update/${sendData.id}/?_method=PUT`, values).then((res)=> {
					getData(false)
				   showSuccessToast("Address Updated")
				}).catch((error) => {
					getData(false)
					showErrorToast("Request Failed")
				})
			}
		},
	});

	const states = [
		{
			id: 1,
			name: 'Rajasthan',
		},
		{
			id: 2,
			name: 'Gujarat',
		},
		{
			id: 3,
			name: 'Maharashtra',
		},
		{
			id: 4,
			name: 'Kerala',
		},
		{
			id: 5,
			name: 'Madhya Pradesh',
		},
		{
			id: 6,
			name: 'Karnataka',
		},
		{
			id: 7,
			name: 'Jharkhandh',
		},
		{
			id: 8,
			name: 'Uttar Pradesh',
		},
		{
			id: 9,
			name: 'Himachal Pradesh',
		},
		{
			id: 10,
			name: 'Hariyana',
		},
		{
			id: 11,
			name: 'Goa',
		},
	];
	const handleState = (id) => {
		let data = states.filter((dataItem) => dataItem.id === id);
		setSearchQuery(data[0].name);
	};


	const numberFilter = (event) => {
		const inputValue = event.target.value;
	
		const numericValue = inputValue.replace(/\D/g, "");
		setFieldValue(event.target.name, numericValue);
	  };
	
	  const nameFilter = (event) => {
		const inputValue = event.target.value;
		if (/^[A-Za-z\s'-]+$/.test(inputValue) || inputValue === "") {
		  setFieldValue(event.target.name, inputValue);
		}
	  };

	return (
		<div className="address-form-container">
			{/* <button type="button" className="btn btn-primary " data-bs-toggle="modal" data-bs-target="#myModal">
        Add address
      </button> */}

			{/* <The Modal */}
			<div className="modal">
				<div className="modal-dialog">
					<div className="modal-content">
						{/* Modal Header */}
						{/* <div className="close-btn-container">
							<button
								type="button"
								className="btn-close"
								onClick={closeModal}
							></button>
						</div> */}

						{/* <h2 className="shipping-address">Add Address</h2> */}
						{/* <p className="address_underline" /> */}

						{/* Modal body */}
						<div className="modal-body">
							<form onSubmit={handleSubmit} className="address-form">
								{/* name */}
								<div className="field-margin">
									<label htmlFor="name">Name</label>
									{/* <span className="text-danger">*</span> */}
									<input
										type="text"
										name="name"
										value={values?.name}
										id="name"
										placeholder="Enter Name"
										className="address-name-field address-input"
										onChange={(event) => {
											nameFilter(event);
										  }}
										onBlur={handleBlur}
									/>
									<p className="validation-color m-0">{errors?.name}</p>
								</div>
								<div className="d-flex justify-content-between field-margin">
									{/* mobile */}
									<div className="address-field">
										<label htmlFor="mobile">Mobile</label>
										{/* <span className="text-danger">*</span> */}
										<input
											type="text"
											name="mobile"
											value={values?.mobile}
											id="mobile"
											placeholder="Mobile"
											className="address-input"
											onChange={(event) => {
												numberFilter(event);
											  }}
											onBlur={handleBlur}
											maxLength={10}
										/>
										<p className="validation-color m-0">{errors?.mobile}</p>
									</div>
									{/* email */}
									<div className="address-field">
										<label htmlFor="email1">Email</label>
										<input
											type="email"
											name="email"
											value={values?.email}
											id="email1"
											placeholder="Email"
											className="address-input"
											onChange={handleChange}
											onBlur={handleBlur}
										/>
										<p className="validation-color m-0">{errors?.email}</p>
									</div>
								</div>
								<div className="d-flex justify-content-between field-margin">
									{/* address line 1 */}
									<div className="address-field ">
										<label htmlFor="address1">Address</label>
										<span className="text-danger">*</span>
										<input
											type="text"
											name="shipping_address"
											id="shipping_address"
											value={values?.shipping_address}
											placeholder="Shipping Address 1"
											className="address-input"
											onChange={handleChange}
											onBlur={handleBlur}
										/>
										<p className="validation-color m-0">
											{errors?.shipping_address}
										</p>
									</div>
									{/* address line 2 */}
									<div className="address-field ">
										<label htmlFor="shipping_state">Shipping State</label>
										<div className="">
											<input
												type="text"
												className="address-input"
												placeholder="State"
												name='shipping_state'
												value={values?.shipping_state}
												onChange={(event) => {
													nameFilter(event);
												  }}
											/>
											<br />

											{/* <MdArrowDropUp className="dropup-icon" /> */}

											{/* <ul className="dropdown-menu">
												{states
													.filter((shipping_state) =>
														shipping_state.name
															.toLowerCase()
															.includes(searchQuery.toLowerCase())
													)
													.map((element, index) => {
														return (
															<li
																key={index}
																onClick={() => handleState(element.id)}
															>
																{element.name}
															</li>
														);
													})}
											</ul> */}
										</div>
										<p className="validation-color m-0">{errors?.shipping_state}</p>
									</div>
									{/* <div className="address-field ">
										<label htmlFor="address2">Address Line 2</label>
										<input
											type="text"
											name="shipping_address2"
											id="shipping_address2"
											value={values.shipping_address2}
											placeholder="Shipping Address 2"
											className="address-input"
											onChange={handleChange}
											onBlur={handleBlur}
										/>
										<p className="validation-color m-0">
											{errors.shipping_address2}
										</p>
									</div> */}
								</div>

								<div className="d-flex justify-content-between field-margin">
									{/* shipping_city */}
									<div className="shipping_city-address">
										<label htmlFor="shipping_city">
											Shipping City
										</label>
										<input
											type="text"
											name="shipping_city"
											id="shipping_city"
											value={values?.shipping_city}
											placeholder="shipping_city/Town/Village"
											className="address-input"
											onChange={(event) => {
												nameFilter(event);
											  }}
											onBlur={handleBlur}
										/>
										<p className="validation-color m-0">{errors?.shipping_city}</p>
									</div>
									{/* ḍistrict */}
									{/* <div className="shipping_city-address mx-4">
										<label htmlFor="district">District</label>
										<span className="text-danger">*</span>
										<input
											type="text"
											name="district"
											id="district"
											value={values.district}
											placeholder="District"
											className="address-input"
											onChange={handleChange}
											onBlur={handleBlur}
										/>
										<p className="validation-color m-0">{errors.district}</p>
									</div> */}
									{/* shipping_zip */}
									<div className="shipping_city-address">
										<label htmlFor="shipping_zip">Shipping Zip</label>
										<span className="text-danger">*</span>
										<input
											type="text"
											name="shipping_zip"
											id="shipping_zip"
											value={values?.shipping_zip}
											placeholder="Zip"
											className="address-input"
											onChange={(event) => {
												numberFilter(event);
											  }}
											onBlur={handleBlur}
											maxLength={6}
										/>
										<p className="validation-color m-0">{errors?.shipping_zip}</p>
									</div>
								</div>



								<div className="address-submit-btn">
									<button type="submit">Submit</button>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default React.memo(AddressForm); //AddressForm;
