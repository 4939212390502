import { Fragment, useContext, useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AuthContext from "../../Auth/AuthContext";
import LoaderContext from "../../loader/LoaderContext";
import Footer from "./Footer";
import Header from "./Header";
import SideBar from "./SideBar";
import "./main.css";
import LoaderComponent from "../../../loader/loader";

const Main = (props) => {
  const navigate = useNavigate();
  let history = useLocation();

  const { user, userDetails, authPermissions, changeAuthPermissions } =
    useContext(AuthContext);
  const { isLoading, setIsLoading } = useContext(LoaderContext);

  useEffect(() => {
    if (!localStorage.getItem("userData")) {
      navigate("/user-panel/signin");
    } else {
      setIsLoading(false);
      if (userDetails.id != undefined) {
        changeAuthPermissions();
      }
    }
    // else {
    //   const lastLocation = localStorage.getItem("lastLocation");
    //   if (lastLocation) {
    //     localStorage.removeItem("lastLocation");
    //     navigate(lastLocation);
    //   } else {
    //     navigate("/admin", { replace: true });
    //   }
    // }
  }, [user, userDetails]);

  // useEffect(() => {
  //   function handleBeforeUnload() {
  //     localStorage.setItem("lastLocation", history.pathname);
  //   }
  //   function getLastLocation() {
  //     localStorage.setItem("lastLocation", history.pathname);
  //   }
  //   handleBeforeUnload();
  // }, [history]);

  //   useEffect(() => {
  //     const lastLocation = localStorage.getItem("lastLocation");
  //     if (lastLocation) {
  //       localStorage.removeItem("lastLocation");
  //       navigate(lastLocation);
  //     }
  //   }, [history]);

  //   if (!user.token) {
  //     return null;
  //   }

  return (
    <Fragment>
      <div className="font-montserrat">
        <div id="main_content">
          {isLoading ? <LoaderComponent /> : null}
          <div className="">
            <div>
              <SideBar />
            </div>
            <div id="page" className="page">
              <Header />
              <Outlet authPermission={authPermissions} />
              <Footer />
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </Fragment>
  );
};

export default Main;
