import { useContext, useEffect, useState } from 'react';
import { BiEdit } from 'react-icons/bi';
import { FaGreaterThan } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { showErrorToast } from '../../../Toster';
import AlertContext from '../../../context/Alert/AlertContext';
import DataTable2 from '../../../context/components/DataTable2';
import axiosPrivate from '../../../hooks/axiosPrivate';

export default function Settings() {
	const { alertMessage, setAlertMessage } = useContext(AlertContext);
	const [settingsState, setSettingsState] = useState([]);

	const headers = [
		{ name: 'META KEY', field: 'meta_key', sortable: true, classKey: 'w160' },
		{
			name: 'META VALUE',
			field: 'meta_value',
			sortable: true,
			classKey: 'w160',
		},

		{ name: 'ACTION', field: 'action', classKey: '' },
	];

	const searchItems = ['meta_key'];
	// get setting list
	useEffect(() => {
		const options = {
			headers: { 'content-type': 'application/json' },
		};
		axiosPrivate
			.get('settings/get', options)
			.then((data) => {
				const settingsData = data.data.data.map((value, key) => {
					let buttons = [];
					if (true)
						buttons.push(
							<Link
								key="editButton##1"
								type="button"
								to={`/admin/settings/edit/${value.id}`}
								className="btn btn-icon"
								title="Edit"
							>
								<BiEdit style={{ color: 'green' }} size={18} />
							</Link>
						);
					value['action'] = buttons.length > 0 ? buttons : '-';
					return value;
				});

				setSettingsState(settingsData);
			})
			.catch((error) => {
				if (error.response) {
					//response status is an error code
					showErrorToast('Internal server error');
				}
			});
	}, []);

	return (
		<>
			<div className="section-body ">
            <div className="container-fluid">

				{/* hearder breadcrumb */}
				<div className="card-header">
					<strong className="top-left-heading">Setting</strong>

					<ol className="headerroutes breadcrumb m-0 bg-none ">
						<li className="item color-gray">|</li>
						<li className="item">
							<Link to="/admin/dashboard" className="route-heading item mx-2">
								Dashboard
							</Link>
						</li>
						<li className="item">
							<FaGreaterThan className="grater-than-route" />
						</li>
						<li className="route-heading item mx-2 mt-1">Setting</li>
					</ol>
				</div>
				<div className="container-fluid">
					<div className="tab-content ">
						<div
							className="tab-pane fade show active"
							id="setting-list"
							role="tabpanel"
						>
							<div className="card">
								<div className="card-body">
									<div className="table-responsive">
										{
											<DataTable2
												lists={settingsState}
												headers={headers}
												searchItems={searchItems}
											/>
										}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
            </div>
		</>
	);
}
