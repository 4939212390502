import React, { useState, useEffect } from "react";
import { Carousel } from "primereact/carousel";
import { Link } from "react-router-dom";
import axiosPrivate from './../../hooks/axiosPrivate';

 function BasicDemo(props) {
  const fileURL = process.env.REACT_APP_COMMON_FILE_URL;
  const [products, setProducts] = useState([]);
  
  const responsiveOptions = [
    {
      breakpoint: "2560px",
      numVisible: 4,
      numScroll: 1,
    },
    {
      breakpoint: "1440px",
      numVisible: 4,
      numScroll: 1,
    },
    {
      breakpoint: "1199px",
      numVisible: 4,
      numScroll: 1,
    },
    {
      breakpoint: "768px",
      numVisible: 2,
      numScroll: 1,
    },
    {
      breakpoint: "525px",
      numVisible: 1,
      numScroll: 1,
    },
  ];

  const getSeverity = (product) => {
    switch (product.inventoryStatus) {
      case "INSTOCK":
        return "success";
      case "LOWSTOCK":
        return "warning";
      case "OUTOFSTOCK":
        return "danger";
      default:
        return null;
    }
  };

  useEffect(() => {
    axiosPrivate.get('product-details')
      .then((res) => {
        setProducts(res?.data?.data);
      })
      .catch((error) => {
      });
  }, []);

  const productTemplate = (product) => {
    return (
      <div className='nm_home'>
        <div className="border-1 surface-border text-center">
          <div className="mb-3 product_image_div">
            {product?.product_images?.length > 0 &&
              product?.product_images?.map((res, index) => (
                <img
                  key={index}
                  src={fileURL + 'product-details/' + res?.image}
                  alt={res.alt}
                  className="shadow-2"
                />
              ))}
            <div>
              <div className="text-light-product d-flex">
                <h4 className="mb-1 mt-1">{product.name}</h4>
                <Link to={"/productdetail/" + product.slug}>
                  <img src="img/vector.svg" alt="" />
                </Link>
              </div>
              <div className="mt-5 flex flex-wrap gap-2 justify-content-center"></div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className='nm_home'>
      <div
        className="product-carousel-card"
        style={{ backgroundImage: "url('img/bg-pattan2.png')" }}
      >
        <div className="product-card-heading">
        <h2 className="main_heading">{props?.data?.productheading}</h2>
        <h4 className="sub_heading">{props?.data?.productsubheading}</h4>
        <p className="product_content">
          {props?.data?.productcontent}
        </p>
        </div>
        <Carousel
          value={products}
          responsiveOptions={responsiveOptions}
          itemTemplate={productTemplate}
          showNavigators={false}
          circular // Enable infinite looping
          numVisible={4}
          numScroll={1}
          autoplayInterval={3000}
          autoPlay // Enable autoplay
        />
      </div>
    </div>
  );
}
export default React.memo(BasicDemo)