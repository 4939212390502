import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";

const DispatchPieChart = ({spilitedItems,mergedItems}) => {
   const [series, setSeries] =useState([0,0]);
 

   useEffect(() => {
    setSeries([spilitedItems.length, mergedItems.length]);
  }, [spilitedItems,mergedItems]);

  const [options] =useState({
    chart: {
      width: 380,
      type: "pie",
    },
    labels: ["Splited Items","Merged Items"],
    legend: {
      position: "bottom",
      markers: {
        fillColors: ["#6495ed", "	#ff7f50"],
      },
    },
    fill: {
      colors: ["#6495ed", "#ff7f50"],
    },
    dataLabels: {
      enabled: false,
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: "100%",
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  });

  return (
    <div>
      <div id="chart">
        <ReactApexChart
          options={options}
          series={series}
          type="pie"
          width="100%"
        />
      </div>
    </div>
  );
};

export default DispatchPieChart;
