import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { BsCurrencyRupee } from "react-icons/bs";
import axiosPrivate from "../../hooks/axiosPrivate";
import moment from "moment";
import { showErrorToast, showSuccessToast } from "../../Toster";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Dialog } from "primereact/dialog";
import LoaderContext from "../../context/loader/LoaderContext";
import { useContext } from "react";

function DashboardOrders() {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [orders, setUserOrders] = useState([]);
  const [returnOrders, setReturnOrders] = useState([]);
  const [formData, setFormData] = useState({
    reason: "",
    message: "",
    status: "Return Requested",
  });
  const [orderDetails, setOrderDetails] = useState({});
  const { setIsLoading } = useContext(LoaderContext);
  const [selectedOption, setSelectedOption] = useState("");

  const handleConfirmation = (e, res) => {
    e.preventDefault();
    if (selectedOption === "COD") {
      axiosPrivate
        .get(
          `ecom/orders/update-payment-status/${res?.order_id}/${selectedOption}`
        )
        .then((response) => {
          navigate(`/thank-you/${res?.order_id}`);
        })
        .catch((err) => {
          showErrorToast("Reqest Failed");
        });
    } else if (selectedOption === "Online") {
        const queryString = {
          access_code:'AVGN23KJ18BL53NGLB',
          encryptedRequest: res?.encryptedRequest,
        };
        // Construct the URL with the query string
        const iframeSrc = `https://secure.ccavenue.com/transaction/transaction.do?command=initiateTransaction&access_code=${queryString?.access_code}&encRequest=${queryString?.encryptedRequest}`;
        // window.location.href = iframeSrc;
        window.location.href=iframeSrc;
    } else {
      showErrorToast("Please Select Payment Method");
    }
  };

  if (localStorage.getItem("userDetails")) {
    var { name, id } = JSON.parse(localStorage.getItem("userDetails"));
  }

  const getOrders = () => {
    axiosPrivate
      .get("/ecom/orders/get")
      .then((res) => {
        setUserOrders(res.data.data);
      })
      .catch((error) => {
        setUserOrders([]);
      });

    // return orders

    axiosPrivate
      .get(`return-orders/${id}`)
      .then((res) => {
        setReturnOrders(res.data.data);
      })
      .catch((error) => {
        setReturnOrders([]);
      });
  };

  useEffect(() => {
    getOrders();
  }, []);

  function getVarientImage(varients, id) {
    let image_varient = varients.filter((res) => res.model_id == id);
    return (
      process.env.REACT_APP_COMMON_FILE_URL +
      "/product-variants/" +
      image_varient[0]?.image
    );
  }

  function getDate(date) {
    return moment(date).format("LL");
  }

  function cancelOrder(e, res) {
    axiosPrivate
      .get("ecom/orders/change-status/" + res.id + "/cancelled")
      .then((resp) => {
        showSuccessToast("Order Cancelled");
        getOrders();
      })

      .catch((error) => {});
  }
  const handleButtonClick = (e, res) => {
    setOrderDetails(res);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleReturnFormSubmit = (e, res) => {
    e.preventDefault();
    let data = {
      order_id: orderDetails?.order_id,
      product_id: orderDetails?.order_items[0]?.product_id,
      ...formData,
    };
    axiosPrivate
      .post("return-orders/create", data)
      .then((resp) => {
        setIsModalOpen(false);
        showSuccessToast("Return Requested");
        getOrders();
      })
      .catch((error) => {
        showErrorToast("Return Request Failed | Please Try Again Later");
      });
  };

  const invoiceDownloadHandler = (e, res) => {
    e.preventDefault();
    let data = {
      order_id: res?.order_id,
    };
    axiosPrivate
      .post("/ecom/orders/ecom-invoice", data)
      .then((res) => {
        window.location.href = `${process.env.REACT_APP_COMMON_URL}ecominvoices/${res.data?.data[0]?.invoice}.pdf`;
      })
      .catch((err) => {
        showErrorToast("Reqest Failed");
      });
  };

  return (
    <div className="dashboard_order_container">
      <h1 className="dashboard_main_heading">Welcome {name}</h1>
      <div className="box_shadow">
        <h2 className="order_heading">My Orders</h2>
        <hr className="horizontal_line" />
        <div className="outer-container">
          {/* Order detail */}
          {orders
            ?.filter((res) => res?.order_items[0]?.status === null)
            ?.map((res) => (
              <>
                <div className="ordered_product_container">
                  <div className="dispatch_div">
                    {res.order_status === "Pending" && (
                      <p className="pending_status_dot"></p>
                    )}
                    {res.order_status === "Delivered" && (
                      <p className="status_dot"></p>
                    )}
                    {res.order_status === "cancelled" && (
                      <p className="cancelled_status_dot"></p>
                    )}
                    <p className="order_status_name">
                      {res.order_status},{" "}
                      <span className="order_id">
                        Order Id: #{res.order_id}
                      </span>
                    </p>
                  </div>
                  {res?.order_items?.map((resp) => (
                    <div className="ordered_product_div">
                      <div className="order-img-div">
                        <img
                          src={getVarientImage(
                            resp.variant_images,
                            resp.variant_id
                          )}
                          alt="product"
                          loading="lazy"
                        />
                      </div>
                      <div className="d-flex justify-content-between align-items-end m-4 order-detail">
                        <div>
                          <h4 className="ordered-product-name">
                            {resp.product_name}
                          </h4>
                          <p className="ordered-product-detail">
                            {resp.product_description}
                          </p>
                          <div className="ordered-product-price d-flex align-items-center">
                            <BsCurrencyRupee />
                            <p>{res.total_amount}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                  {}
                  {res.order_status == "delivered" ? (
                    <p className="delivered_date">Delivered On 30 June, 2023</p>
                  ) : (
                    <p></p>
                  )}
                </div>

                {/* Delivered Address */}
                <div className="product_delivery_detail">
                  <div className="address">
                    <h3 className="order_sub_heading">Address</h3>

                    <div className="card_2 shipping-card">
                      <span className="phone_number">{res.ship_mobile}</span>
                      <p className="mt-1 order_address">
                        {res.address} {res.ship_city} {res.ship_state}{" "}
                        {res.ship_pincode}
                      </p>
                    </div>
                    <h5>Payment Method</h5>
                    <p>{res.payment_mode}</p>
                    {res.payment_mode === "Online" &&
                      (res?.payment_status === "failed" ||
                        res?.payment_status === "pending") && (
                        <div>
                          <h5>Payment Status</h5>
                          <p>
                            {res.payment_status === "failed"
                              ? "Failed"
                              : "Pending"}
                          </p>{" "}
                        </div>
                      )}
                  </div>

                  {/* // Buttons */}

                  <div className="deliver_div">
                    <p className="delivered_date">
                      Placed On {getDate(res.order_date)}
                    </p>
                    <button
                      className="deliver_btn"
                      onClick={(e) => invoiceDownloadHandler(e, res)}
                    >
                      Download Invoice
                    </button>

                    {res.order_status != "Delivered" ? (
                      <button
                        className={`deliver_btn ${
                          res.order_status === "cancelled" && "disabled"
                        } `}
                        disabled={res.order_status === "cancelled"}
                        onClick={(e) => cancelOrder(e, res)}
                      >
                        Cancel Order
                      </button>
                    ) : (
                      ""
                    )}
                    {res.order_status == "Delivered" ? (
                      <button
                        className={`deliver_btn ${
                          res.order_status === "cancelled" && "disabled"
                        } `}
                        disabled={res.order_status === "cancelled"}
                        onClick={(e) => handleButtonClick(e, res)}
                      >
                        Return Order
                      </button>
                    ) : (
                      ""
                    )}
                    {res.payment_mode === "Online" &&
                      (res?.payment_status === "failed" ||
                        res?.payment_status === "pending") && (
                        <div>
                          <select
                            value={selectedOption}
                            onChange={(e) => setSelectedOption(e.target.value)}
                            className={`deliver_btn`}
                            style={{ background: "whitesmoke", color: "black" }}
                          >
                            <option>Choose Payment Method</option>
                            <option value="COD">Cash on Delivery</option>
                            <option value="Online">Online Payment</option>
                          </select>
                          <button
                            className={`deliver_btn`}
                            onClick={(e) => handleConfirmation(e, res)}
                          >
                            Payment Failed ? Confirm Order
                          </button>
                        </div>
                      )}
                  </div>
                  <Dialog
                    visible={isModalOpen}
                    onHide={handleCloseModal}
                    header="Return Request Form"
                  >
                    <form onSubmit={handleReturnFormSubmit}>
                      <div className="p-field p-2">
                        <label htmlFor="reason">
                          Reason<span style={{ color: "red" }}>*</span> :{" "}
                        </label>
                        <InputText
                          id="reason"
                          name="reason"
                          value={formData.reason}
                          onChange={handleInputChange}
                          required
                        />
                      </div>
                      <div className="p-field p-2">
                        <label htmlFor="message">
                          Message<span style={{ color: "red" }}>*</span> :{" "}
                        </label>
                        <InputText
                          id="message"
                          name="message"
                          value={formData.message}
                          onChange={handleInputChange}
                          required
                        />
                      </div>
                      <div className="p-field text-right">
                        <button
                          label="Submit"
                          className="btn btn-primary"
                          type="submit"
                        >
                          Submit Request
                        </button>
                      </div>
                    </form>
                  </Dialog>
                </div>
              </>
            ))}
        </div>
        {/* Cancelled product */}
        {/* <hr className='horizontal_line' />
                <div className='cancelled_div'>
                    <div className='dispatch_div'>
                        <p className='status_dot text-danger'></p>
                        <p className='order_status_name'>Cancelled</p>
                    </div>

                    <div className='cancelled_outer_container'>
                        <div className='ordered_product_div'>
                            <div className='order-img-div'>
                                <img src="img/image62.png" alt="product" loading='lazy' />
                            </div>
                            <div className='d-flex justify-content-between align-items-end m-4 order-detail'>
                                <div>
                                    <h4 className='ordered-product-name'>GeoPhos+(1kg)</h4>
                                    <p className='ordered-product-detail'>Phosphate Solubilizing Bacteria</p>
                                    <div className='ordered-product-price d-flex align-items-center'>
                                        <BsCurrencyRupee />
                                        <p>349</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
      </div>
      <div className="box_shadow">
        <h2 className="order_heading">My Return Orders</h2>
        <hr className="horizontal_line" />
        <div className="order-outer-container">
          {/* Order detail */}
          {returnOrders?.map((res) => (
            <>
              <div className="ordered_product_container">
                <div className="dispatch_div">
                  <p className="status_dot"></p>
                  <p className="order_status_name">
                    {res?.status},{" "}
                    <span className="order_id">Order Id: #{res.order_id}</span>
                  </p>
                </div>
                {res.order_items?.map((resp) => (
                  <div className="ordered_product_div">
                    <div className="order-img-div">
                      <img
                        src={getVarientImage(
                          resp.variant_images,
                          resp.variant_id
                        )}
                        alt="product"
                        loading="lazy"
                      />
                    </div>
                    <div className="d-flex justify-content-between align-items-end m-4 order-detail">
                      <div>
                        <h4 className="ordered-product-name">
                          {res.product_name}
                        </h4>
                        <p className="ordered-product-detail">
                          {res.product_description}
                        </p>
                        <div className="ordered-product-price d-flex align-items-center">
                          <BsCurrencyRupee />
                          <p>{resp.price}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
                {}
                {res.order_status == "delivered" ? (
                  <p className="delivered_date">Delivered On 30 June, 2023</p>
                ) : (
                  <p></p>
                )}
              </div>

              {/* Delivered Address */}
              <div className="product_delivery_detail">
                <div className="address">
                  <h3 className="order_sub_heading">Delivered at</h3>

                  <div className="card_2 shipping-card">
                    <span className="phone_number">{res.shipping_mobile}</span>
                    <p className="mt-1 order_address">
                      {res.shipping_address} {res.shipping_city}{" "}
                      {res.shipping_state} {res.shipping_pincode}
                    </p>
                  </div>
                  <h3 className="order_sub_heading">Payment Method</h3>
                  <p className="order_address">{res.payment_mode}</p>
                </div>
              </div>
            </>
          ))}
        </div>
      </div>
    </div>
  );
}

export default React.memo(DashboardOrders);
