import React from 'react'
import { Link } from 'react-router-dom'



function FooterBar() {
    const currentYear = new Date().getFullYear();
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth', // You can use 'auto' for instant scrolling
      });
    };
  return (
    <div className="nm_footer_bar">
    <div className='d-flex flex-sm-row flex-column footer-bar justify-content-between'>
    <Link to='/'><p className='footer-bar-content'>NM India Biotech. © {currentYear}. All Rights Reserved</p></Link>
    {/* <Link to='/'><p className='footer-bar-content'>Terms & Conditions / Privacy policy / Refund Policy</p></Link> */}
    <Link to='https://webanixsolutions.com/' target='_blank'><p className='footer-bar-content'>Desgined by WEBaniX</p></Link>
    </div>
    </div>
  )
}

export default React.memo(FooterBar) //FooterBar
