import { useFormik } from 'formik';
import React, { useContext, useEffect } from 'react';
import { FaGreaterThan } from 'react-icons/fa';
import { Link, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { showErrorToast } from '../../../Toster';
import AlertContext from '../../../context/Alert/AlertContext';
import axiosPrivate from '../../../hooks/axiosPrivate';
import classes from './Season.module.css';

// validation
const validationRules = {
	// season_title: Yup.string()
	// 	.max(120, 'Name must not exceed 120 characters')
	// 	.required('Season is required'),
	// discount_percentage: Yup.string().required('Discount is required'),
	from_date: Yup.string().required('Date is required'),
	to_date: Yup.string().required('Date is required'),
};

const AddSeason = () => {
	const { setAlertMessage } = useContext(AlertContext);
	const navigate = useNavigate();

	// formik(add)
	const formik = useFormik({
		initialValues: {
			season_title: '',
			discount_percentage: '',
			from_date: '',
			to_date: '',
		},
		validationSchema: Yup.object(validationRules),
		onSubmit: async (values, { setSubmitting, setFieldError }) => {
			axiosPrivate
				.post('seasons/store', values)
				.then((response) => {
					setAlertMessage({ message: 'New Season Created Successfully' });
					navigate('/admin/season');
				})
				.catch((error) => {
					if (typeof error.response.data.errors == 'array') {
						showErrorToast(error.response.data.errors[0].msg);
					} else {
						showErrorToast(error.response.data.errors.msg);
					}
				});
		},
	});

	useEffect(() => {
		const from_date = document.getElementById('from_date');
		const to_date = document.getElementById('to_date');
		const today = new Date().toISOString().split('T')[0];
		from_date.setAttribute('min', today);
		to_date.setAttribute('min', today);
	});

	return (
		<>
			<div className="section-body ">
				<div className="container-fluid">
					{/* hearder breadcrumb */}
					<div className="card-header">
						<strong className="top-left-heading">Add Season</strong>

						<ol className="headerroutes breadcrumb m-0 bg-none ">
							<li className="item color-gray">|</li>
							<li className="item">
								<Link to="/admin/dashboard" className="route-heading item mx-2">
									Dashboard
								</Link>
							</li>
							<li className="item">
								<FaGreaterThan className="grater-than-route" />
							</li>
							<li className="route-heading item mx-2 mt-1">
								<Link to="/admin/season">Season</Link>
							</li>
							<li className="item">
								<FaGreaterThan className="grater-than-route" />
							</li>

							<li className="route-heading item mx-2 mt-1">Add Season</li>
						</ol>
					</div>
					<div className="tab-content">
						<form onSubmit={formik.handleSubmit}>
							<div
								className="tab-pane fade show active"
								id="user-add"
								role="tabpanel"
							>
								<div className="card">
									<div className="card-body">
										<div className="row clearfix">
											{/* season title */}
											<div className="col-lg-6 col-md-6 col-sm-12">
												<label>
													Title:<span className="required">*</span>
												</label>
												<div className="form-group">
													<input
														id="season_title"
														name="season_title"
														type="text"
														className="form-control"
														placeholder="Season Title "
														onChange={formik.handleChange}
														onBlur={formik.handleBlur}
														value={formik.values.season_title}
													/>
													{formik.touched.season_title &&
													formik.errors.season_title ? (
														<div className={classes.invalidDataError}>
															{formik.errors.season_title}
														</div>
													) : null}
												</div>
											</div>
											{/* season discount */}

											<div className="col-lg-6 col-md-6 col-sm-12">
												<label>
													Discount %:<span className="required">*</span>
												</label>
												<div className="form-group">
													<input
														id="discount_percentage"
														name="discount_percentage"
														type="number"
														className="form-control"
														placeholder="Discount "
														onChange={formik.handleChange}
														onBlur={formik.handleBlur}
														value={formik.values.discount_percentage}
													/>
													{formik.touched.discount_percentage &&
													formik.errors.discount_percentage ? (
														<div className={classes.invalidDataError}>
															{formik.errors.discount_percentage}
														</div>
													) : null}
												</div>
											</div>

											{/* season start date */}

											<div className="col-lg-6 col-md-6 col-sm-12">
												<label>
													Form:<span className="required">*</span>
												</label>

												<div className="form-group">
													<input
														id="from_date"
														name="from_date"
														type="date"
														className="form-control"
														placeholder="Start Date"
														onChange={formik.handleChange}
														onBlur={formik.handleBlur}
														value={formik.values.from_date}
													/>
													{formik.touched.from_date &&
													formik.errors.from_date ? (
														<div className={classes.invalidDataError}>
															{formik.errors.from_date}
														</div>
													) : null}
												</div>
											</div>

											{/* season end date */}

											<div className="col-lg-6 col-md-6 col-sm-12">
												<label>
													To:<span className="required">*</span>
												</label>

												<div className="form-group">
													<input
														id="to_date"
														name="to_date"
														type="date"
														className="form-control"
														placeholder=" End date "
														onChange={formik.handleChange}
														onBlur={formik.handleBlur}
														value={formik.values.to_date}
													/>
													{formik.touched.to_date && formik.errors.to_date ? (
														<div className={classes.invalidDataError}>
															{formik.errors.to_date}
														</div>
													) : null}
												</div>
											</div>

											<div className="col-12 submit-btn">
												<button
													type="button"
													className="btn btn-secondary"
													data-dismiss="modal"
													onClick={() => navigate('/admin/season')}
												>
													Close
												</button>
												<button type="submit" className="btn btn-primary ml-2">
													Submit
												</button>
											</div>
										</div>
									</div>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
		</>
	);
};

export default AddSeason;
