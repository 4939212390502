import { useFormik } from "formik";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FaGreaterThan } from "react-icons/fa";
import { Link, useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import { showErrorToast } from "../../../Toster";
import AlertContext from "../../../context/Alert/AlertContext";
import axiosPrivate from "../../../hooks/axiosPrivate";
import LoaderContext from "../../../context/loader/LoaderContext";

export default function EditSalesTarget() {
  const { setIsLoading } = useContext(LoaderContext);
  const { id, year } = useParams();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    user_id: "",
    year: "",
    targets: [],
  });
  const { setAlertMessage } = useContext(AlertContext);
  const [selectedUserId, setSelectedUserId] = useState("");
  const [listData, setlistData] = useState([]);
  const [selectedYear, setSelectedYear] = useState(null);
  const [TargetData, setTargetData] = useState([]);
  const [matchedId, setMatchedId] = useState(id); // Example matched ID
  const validationSchema = Yup.object({
    user_id: Yup.string().required("Sales Officer Name is required"),
    year: Yup.string().required("Year is required"),
  });

  // get all sales person
  useEffect(() => {
    setIsLoading(true);
    axiosPrivate.get(`/users/get/area/manager`)
      .then((response) => {
        setlistData(response.data.data);
        const matchingUser = response.data.data.find(
          (user) => user.user_id === id
        );
        if (matchingUser) {
          formik.setFieldValue("user_id", matchingUser.name);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
      });
  }, []);

  // get all sales target
  useEffect(() => {
    setIsLoading(true);
    axiosPrivate
      .get(`sales-target/get/${id}/${year}`)
      .then((response) => {
        setTargetData(response.data.data);
        if (response.data.code === 200) {
          const data = response.data.data;
          setFormData({
            user_id: data.user_id,
            year: data.financial_year,
            targets: data.map((target) => ({
              target_date: moment(target.target_date).format("YYYY-MM-DD"),
              month_label: target.month_label,
              target_amount: target.target_amount,
            })),
          });
        }
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
      });
  }, [id, year, selectedUserId]);

  useEffect(() => {
    if (year) {
      const defaultYear = new Date(year).getFullYear();
      setSelectedYear(new Date(defaultYear, 0, 1)); // Set the default year
    }
  }, [year]);

  const handleDataChange = async (event) => {
    setSelectedUserId(event.target.value);
    return true;
  };

  const handleChange = (value) => {
    const dateObject = new Date(decodeURIComponent(value));
    const year = dateObject?.getFullYear();
    axiosPrivate.get(`/sales-target/get/${id}/${year}`).then((response) => {
      setTargetData(response.data.data);
    });
  };
  const handleInputChange = (index, value) => {
    const updatedData = [...TargetData]; // Create a copy of the TargetData array
    updatedData[index] = { ...updatedData[index], target_amount: value }; // Update the target_amount property of the specified index
    setTargetData(updatedData); // Update the TargetData state with the modified array
  };
  const handleSubmit = (event) => {
    setIsLoading(true);

    const userLoggedInId = JSON.parse(localStorage.getItem("userDetails"));
    formik.validateForm(event).then(() => {
      navigate('/admin/sales-target');
      event.preventDefault();
      // Prepare the data object to be sent to the API
      const sendData = {
        created_user_id: userLoggedInId.id,
        financial_year: selectedYear.getFullYear().toString(),
        user_id: id,
        tag: "",
        sales_month: TargetData.map((target) => ({
          target_date: target.target_date,
          month_label: target.month_label,
          target_amount: target.target_amount,
          modified_user_id:id,
        })),
      };
      // Call the API to submit the data
      axiosPrivate
        .post("/sales-target/update", sendData)
        .then((response) => {
          if (response.data.code === 200) {
            setAlertMessage({ message: response.data.message });
            setIsLoading(false);
            navigate("/admin/sales-target");
          }
          // Handle the success response
        })
        .catch((error) => {
          if (typeof error.response.data.errors == "array") {
            showErrorToast(error.response.data.errors[0].msg);
          } else {
            showErrorToast(error.response.data.errors.msg);
          }
          setIsLoading(false);
        });
    });
  };

  const formik = useFormik({
    initialValues: {
      user_id: matchedId,
      year: "",
      targets: formData.targets.map((target) => ({
        month_label: target.month_label,
        target_amount: target.target_amount,
      })),
    },
    validationSchema: validationSchema,
    onSubmit: handleSubmit,
  });
  return (
    <div className="section-body ">
      <div className="container-fluid">
        {/* hearder breadcrumb */}
        <div className="card-header">
          <strong className="top-left-heading">Edit Sales Target</strong>

          <ol className="headerroutes breadcrumb m-0 bg-none ">
            <li className="item color-gray">|</li>
            <li className="item">
              <Link to="/admin/dashboard" className="route-heading item mx-2">
                Dashboard
              </Link>
            </li>
            <li className="item">
              <FaGreaterThan className="grater-than-route" />
            </li>
            <li className="route-heading item mx-2 mt-1">
              <Link to="/admin/sales-target">Sales Targets</Link>
            </li>
            <li className="item">
              <FaGreaterThan className="grater-than-route" />
            </li>

            <li className="route-heading item mx-2 mt-1">Edit Sales Target</li>
          </ol>
        </div>

        <div className="tab-content ">
          <form onSubmit={handleSubmit}>
            <div
              className="tab-pane fade show active"
              id="user-add"
              role="tabpanel"
            >
              <div className="card">
                <div className="card-body">
                  <div className="row clearfix">
                    {/* sales officer name */}
                    <div className="col-lg-6 col-md-12 col-sm-12">
                      <div className="form-group">
                        <label>
                        Area Manager Name:<span className="required">*</span>
                        </label>
                        <select
                          id="user_id"
                          name="user_id"
                          className="form-control"
                          onChange={(event) => {
                            formik.handleChange(event);
                            handleDataChange(event);
                          }}
                          onBlur={formik.handleBlur}
                          value={formik.values.user_id}
                        >
                          <option value="">Select Area Manager Name</option>
                          {listData.map((list) => (
                            <option key={list.name} value={formik.values.name}>
                              {list.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>

                    {/* year */}
                    <div className="col-lg-6 col-md-12 col-sm-12">
                      <div className="form-group">
                        <label>
                          Select Year:<span className="required">*</span>
                        </label>
                        <br />
                        <DatePicker
                          selected={selectedYear}
                          className="form-control"
                          setFieldValue={year}
                          // value={year}
                          onChange={(date) => {
                            if (date != null) {
                              setSelectedYear(date);
                              formik.setFieldValue(
                                "year",
                                date.getFullYear().toString()
                              );
                            } else {
                              setSelectedYear(null);
                              formik.setFieldValue("year", null);
                            }
                            handleChange(date);
                          }}
                          dateFormat="yyyy"
                          showYearPicker
                          isClearable
                          placeholderText="Select Year"
                        />
                      </div>
                    </div>
                    {/* month and amount */}
                    <div className="col-12">
                      <div className="form-group">
                        <div className="d-flex">
                          <p
                            style={{ color: "#004684", fontWeight: 700 }}
                            className="col-lg-4 mt-2 mb-2"
                          >
                            Month
                          </p>
                          <p
                            style={{ color: "#004684", fontWeight: 700 }}
                            className="col-lg-8 mt-2 mb-2"
                          >
                            Amount(₹)
                          </p>
                        </div>
                        <hr />
                        {TargetData.map((target, index) => (
                          <div key={index} className="row">
                            <div className="col-lg-4 mt-2 mb-2">
                              <label>{target.month_label}</label>
                            </div>
                            <div className="col-lg-8 mt-2 mb-2">
                              <input
                                id={`targets.${index}.target_amount`}
                                name={`targets.${index}.target_amount`}
                                type="number"
                                value={target.target_amount || ""}
                                className="form-control"
                                onChange={(e) => {
                                  handleInputChange(index, e.target.value);
                                  formik.handleChange(e);
                                }}
                              />
                              {/* <input
                                                                className="form-control"
                                                                type="number"
                                                                value={target.target_amount || ''}
                                                                onChange={(e) => handleInputChange(index, e.target.value)}
                                                            /> */}
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                    {/* button */}
                    <div className="col-12 submit-btn">
                      <hr className="mt-4" />
                      <div className="table-responsive">
                        <table className="table table-striped"></table>
                      </div>
                      <button
                        type="button"
                        className="btn btn-secondary"
                        data-dismiss="modal"
                        onClick={() => navigate("/admin/sales-target")}
                      >
                        Close
                      </button>
                      <button type="submit" className="btn btn-primary ml-2">
                        Update
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
