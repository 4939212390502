import React from "react";
import axiosPrivate from "./../../hooks/axiosPrivate";
import { useEffect } from "react";
import { useState } from "react";

function Gallery() {
  const [gallery, setGallery] = useState([]);

  useEffect(() => {
    const getAllImages = () => {
      axiosPrivate
        .get("gallery")
        .then((res) => {
          setGallery(res?.data?.data);
        })
        .catch((error) => {
        });
    };

    getAllImages();
  }, []);

  // const galleryImages = [
  //     {
  //         id: 1,
  //         image: 'img/Rectangle76.png'
  //     },
  //     {
  //         id: 2,
  //         image: 'img/Rectangle77.png'
  //     },
  //     {
  //         id: 3,
  //         image: 'img/Rectangle78.png'
  //     },
  //     {
  //         id: 4,
  //         image: 'img/Rectangle79.png'
  //     },
  //     {
  //         id: 5,
  //         image: 'img/Rectangle80.png'
  //     },
  //     {
  //         id: 5,
  //         image: 'img/Rectangle81.png'
  //     },
  // ]
  return (
    <div className="row row-cols-3 g-0 w-100">
      {gallery?.slice(0, 6).map((image, index) => {
        let mediaType = image?.image?.split(".");
        if (
          mediaType[1] == "jpg" ||
          mediaType[1] == "webp" ||
          mediaType[1] == "png"
        ) {
          return (
            <img
              src={
                process.env.REACT_APP_COMMON_FILE_URL + "gallery/" + image.image
              }
              alt="NM india"
              loading="lazy"
              className="gallery-img img-fluid"
              key={index}
            />
          );
        }
      })}
    </div>
  );
}

export default React.memo(Gallery);
