import React, { useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import { BiSearch } from "react-icons/bi";
import { RiAccountCircleFill } from "react-icons/ri";
import { HiShoppingCart } from "react-icons/hi";
import { useState } from "react";
import { RxCross2 } from "react-icons/rx";
import axiosPrivate from "./../hooks/axiosPrivate";
import { useNavigate } from "react-router-dom";
import CartCountContext from "../context/CartCount/CartCountContext";
import { AutoComplete } from "primereact/autocomplete";
import { showErrorToast } from "../Toster";

function Navbar() {
  const navigate = useNavigate();
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [results, setResults] = useState([]);

  function redirectToAnother() {
    const authDeatils = JSON.parse(localStorage.getItem("userDetails"));
    if (localStorage.getItem("userData")) {
      if (authDeatils?.role == 8) {
        navigate("/user/dashboard");
      } else {
        navigate("admin/dashboard");
      }
    } else {
      navigate("/user-panel/signin");
    }
  }
  //handle Search

  const fetchResults = async () => {
    try {
      const response = await axiosPrivate.get(`/logs/search/${searchTerm}`);
      const data = await response?.data?.data;
      setResults(data);
    } catch (err) {
      showErrorToast(
        err?.response?.data?.message ||
          err?.response?.data?.errors[0]?.msg ||
          err?.response?.data?.errors?.msg
      );
    }
  };

  useEffect(() => {
    if (searchTerm) {
      fetchResults();
    } else {
      setResults([]); // Clear the results if the search term is empty
    }
  }, [searchTerm]);

  const handleInputChange = (event) => {
    setSearchTerm(event.target?.value);
  };

  const searchSuggestions = (event) => {
    const query = event?.query;
    // Filter and return the suggestions based on the query
    const filteredResults = results.filter((result) =>
      result.product_name?.toLowerCase().includes(query?.toLowerCase())
    );

    return filteredResults;
  };

  const itemTemplate = (item) => {
    if (item.result_type === "product") {
      return (
        <a
          href={`/productdetail/${item.product_slug}`}
          className="dropdown-item"
        >
          {item.product_name}
        </a>
      );
    } 
  };

  const handleSearchToggle = () => {
    setIsSearchOpen(!isSearchOpen);
    if (isSearchOpen) {
      let showSearchIcon = document.querySelector("#bi-search");
      showSearchIcon.style.display = "block";
    } else {
      let showSearchIcon = document.querySelector("#bi-search");
      showSearchIcon.style.display = "none";
    }
  };

  const [menus, setMenus] = useState([]);
  const { cartCount } = useContext(CartCountContext);

  useEffect(() => {
    const getAllMenus = () => {
      axiosPrivate
        .get("menus")
        .then((res) => {
          const menu = res.data.data;
          setMenus(
            menu.sort((a, b) =>
              a.sequence_no.localeCompare(b.sequence_no, "en", {
                numeric: true,
              })
            )
          );
        })
        .catch((error) => {});
    };
    getAllMenus();
  }, []);

  return (
    <div className="nm_header">
      <div className="navbar-menues">
        <ul>
          <div className="d-flex">
            {menus?.map((res,index) => (
              <li className="nav-menu-item" key={index}>
                <Link
                  to={"/" + res?.slug}
                  className="nav-menu-link active"
                  aria-current="page"
                >
                  {res?.title}
                </Link>
              </li>
            ))}
          </div>
          <div
            className={` justify-content-center align-items-center search-container ${
              isSearchOpen ? "d-flex" : "d-none"
            }`}
          >
            <AutoComplete
              value={searchTerm}
              suggestions={results}
              completeMethod={searchSuggestions} // Change this line
              itemTemplate={itemTemplate}
              field="product_name"
              onChange={(e) => handleInputChange(e)}
              placeholder="Search..."
              color="black"
              className="search"
            />
            <RxCross2
              className="nav-search-icon"
              onClick={handleSearchToggle}
            />
          </div>
          <div className="d-lg-flex d-none text-light align-items-center px-2 nav-account">
            <BiSearch
              className="search-icon-button"
              id="bi-search"
              onClick={handleSearchToggle}
            />
            {/* <Link
              className=" active text-light text-decoration-none"
              aria-current="page"
            > */}{" "}
            <span
              className="d-flex align-items-center account"
              style={{ cursor: "pointer" }}
              onClick={redirectToAnother}
            >
              <RiAccountCircleFill
                className="ml-3 mr-1"
                style={{ fontSize: "25px" }}
                onClick={redirectToAnother}
              />
              Account
            </span>
            {/* </Link> */}
            <Link
              className=" active text-light text-decoration-none"
              aria-current="page"
              to="/cart"
            >
              <span className="d-flex align-items-center account">
                <HiShoppingCart
                  className="ml-3 mr-1"
                  style={{ fontSize: "25px" }}
                />
                Cart
                {cartCount > 0 ? (
                  <div className="cart_count">{cartCount}</div>
                ) : (
                  ""
                )}
              </span>
            </Link>
          </div>
        </ul>
      </div>
    </div>
  );
}

export default React.memo(Navbar);
