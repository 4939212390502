import { useFormik } from "formik";
import React, { Fragment, useContext, useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as Yup from "yup";
import slider1 from "../../assets/images/slider1.svg";
import slider2 from "../../assets/images/slider2.svg";
import slider3 from "../../assets/images/slider3.svg";
import AlertContext from "../../context/Alert/AlertContext";
import AuthContext from "../../context/Auth/AuthContext";
import axiosPrivate from "../../hooks//axiosPrivate";
import classes from "./SignUp.module.css";
import logo from "./../../../src/assets/img/logo.png";
import LoaderContext from "../../context/loader/LoaderContext";
import { showErrorToast, showSuccessToast } from "../../Toster";

const SignIn = () => {
  const { user, setUser, userDetails, setUserDetails } =
    useContext(AuthContext);

  const { setIsLoading } = useContext(LoaderContext);
  const { setAlertMessage } = useContext(AlertContext);
  const [loginScreen, setLoginScreen] = useState(true);
  const [loginMobileNumber, setLoginMobileNumber] = useState("");
  const [otpNumber, setOtpNumber] = useState("");
  const [otpErrorNumber, setOtpErrorNumber] = useState("");
  const navigate = useNavigate();
  const [timer, setTimer] = useState(59); // Timer for OTP countdown
  const [showResendOtp, setShowResendOtp] = useState(false); // To show/hide resend OTP button
  const [startTimer, setStartTimer] = useState(true);
  useEffect(() => {
    setIsLoading(false);
  }, []);

  const validationRules = {
    mobile: Yup.string()
      .required("Mobile number is required")
      .matches(/^[6-9]\d{9}$/, {
        message: "Please enter valid number.",
        excludeEmptyString: false,
      })
      .min("10", "Mobile number must be of 10 digits")
      .max("10", "Mobile number must be of 10 digits"),
  };

  const getUserDetails = (id) => {
    axiosPrivate
      .get(`users/get/${id}`)
      .then((response) => {
        if (response.data.code == 200) {
          localStorage.setItem(
            "userDetails",
            JSON.stringify(response.data.data)
          );
          setUserDetails(response.data.data);
          if (response.data.data.role == 8) {
            navigate("/user/dashboard");
          } else {
            navigate("/admin/dashboard");
          }
        }
      })
      .catch((error) => {});
  };

  const setLoginCredentials = (mobile) => {
    setLoginMobileNumber(mobile);
    setLoginScreen(false);
    return true;
  };

  const formik = useFormik({
    initialValues: {
      mobile: "",
    },
    validationSchema: Yup.object(validationRules),
    onSubmit: async (values, { setSubmitting, setFieldError }) => {
      // if(values?.mobile) {
      axiosPrivate
        .post("/login-otp", values)
        .then((response) => {
          if (response.data.code == 200) {
            setLoginCredentials(values.mobile);
            showSuccessToast("OTP send successfully.");
          }
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.status === 401) {
              showErrorToast(error.response.data.message);
            } else if (error.response.status === 422) {
              for (let i = 0; i < error.response.data.errors.length; i++) {
                setFieldError(
                  error.response.data.errors[i].param,
                  error.response.data.errors[i].msg
                );
              }
            } else {
              showErrorToast("Invalid mobile number");
            }
          } else {
            showErrorToast("Error occurred");
          }
        });
      // }
    },
  });

  const resendOtpHandler = async () => {
    await axiosPrivate
      .post("/login-otp", { mobile: loginMobileNumber })
      .then(() => {
        showSuccessToast("OTP resend successfully.");

        setTimer(59); // Reset the timer
        setShowResendOtp(false); // Hide the "Resend OTP" button
        setStartTimer(!startTimer);
      })
      .catch((error) => {
        showErrorToast("Failed to resend OTP. Please try again.");
      });
  };

  const handleOtpFormSubmit = async (event) => {
    event.preventDefault();

    let newValuesForLogin = {
      mobile: loginMobileNumber,
      otp: otpNumber,
    };
    if (otpNumber) {
      setOtpErrorNumber("");

      await axiosPrivate
        .post("/login", newValuesForLogin)
        .then((response) => {
          setIsLoading(true);
          const token = response?.data?.token;
          const refreshToken = response?.data?.refresh_token;
          localStorage.setItem("userData", JSON.stringify(token));
          localStorage.setItem(
            "userRefreshToken",
            JSON.stringify(refreshToken)
          );
          setUser(token);
          getUserDetails(response.data.id);
        })
        .catch((error) => {
          showErrorToast(error?.response?.data?.message);
        });
    } else {
      setOtpErrorNumber("OTP is Required");
    }
  };

  // Start the timer when the OTP screen is displayed
  useEffect(() => {
    let interval;
    if (!loginScreen) {
      interval = setInterval(() => {
        setTimer((prev) => {
          if (prev === 0) {
            clearInterval(interval); // Clear the interval when the timer reaches 0
            setShowResendOtp(true); // Show the "Resend OTP" button
            return prev;
          }
          return prev - 1;
        });
      }, 1000); // Decrement every second
    }

    // Cleanup interval on component unmount or when switching screens
    return () => clearInterval(interval);
  }, [loginScreen, startTimer]);

  return (
    <Fragment>
      <div className="auth_left">
        <div className="card">
          <div className="text-center mb-2">
            <Link className="header-brand" to="/">
              <img src={logo} />
            </Link>
          </div>
          <div className="card-body">
            {loginScreen ? (
              // Login screen start
              <form key={1} onSubmit={formik.handleSubmit}>
                <div className="card-title">
                  <label className="form-label">Login to your account </label>
                </div>

                <div className="form-group">
                  <label className="form-label">Mobile</label>
                  <input
                    type="text"
                    name="mobile"
                    className="form-control"
                    onChange={(event) => {
                      const numRejex = /^[0-9\b]+$/;
                      if (
                        event.target.value === "" ||
                        (numRejex.test(event.target.value) &&
                          event.target.value.length <= 10)
                      ) {
                        formik.handleChange(event);
                      }
                    }}
                    onBlur={formik.handleBlur}
                    value={formik.values.mobile}
                    placeholder="Mobile number"
                  />

                  {formik.touched.mobile && formik.errors.mobile ? (
                    <div className={classes.invalidDataError}>
                      {formik.errors.mobile}
                    </div>
                  ) : null}
                </div>
                <div className="form-footer">
                  <input
                    type="submit"
                    id="login-submit"
                    className="btn btn-primary btn-block"
                    value="login"
                  />
                </div>
              </form>
            ) : (
              // Login screen end
              // Otp screen start
              <form onSubmit={handleOtpFormSubmit}>
                <div className="card-title">
                  <label className="form-label">
                    Please enter OTP to Login{" "}
                  </label>
                </div>
                <div className="form-group">
                  <label className="form-label">OTP</label>
                  <input
                    type="text"
                    name="otp"
                    className="form-control"
                    onChange={(event) => {
                      const numRejex = /^[0-9\b]+$/;
                      if (
                        event.target.value === "" ||
                        (numRejex.test(event.target.value) &&
                          event.target.value.length <= 6)
                      ) {
                        setOtpNumber(event.target.value);
                      }
                    }}
                    value={otpNumber}
                    placeholder="Please enter OTP to login"
                  />
                  {otpErrorNumber != "" ? (
                    <div className={classes.invalidDataError}>
                      {otpErrorNumber}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="form-footer">
                  {showResendOtp ? (
                    <input
                      type="button"
                      onClick={resendOtpHandler}
                      className="btn btn-primary btn-block"
                      id="login-verify"
                      value="Resend OTP"
                    />
                  ) : (
                    <input
                      type="submit"
                      className="btn btn-primary btn-block"
                      id="login-verify"
                      value="Verify"
                    />
                  )}
                </div>

                {/* Resend OTP Section */}
                <div className="mt-2 d-flex justify-content-end">
                  {!showResendOtp && <span>Resend OTP in {timer}s</span>}
                </div>
              </form>
              // Otp screen end
            )}
          </div>
        </div>
      </div>
      <div className="auth_right">
        <div
          className="carousel slide"
          data-ride="carousel"
          data-interval={3000}
        >
          <div className="carousel-inner">
            <div className="carousel-item active">
              <img src={slider1} className="img-fluid" alt="login page" />
              <div className="px-4 mt-4">
                <h4>Fully Responsive</h4>
                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
              </div>
            </div>
            <div className="carousel-item">
              <img src={slider2} className="img-fluid" alt="login page" />
              <div className="px-4 mt-4">
                <h4>Quality Code and Easy Customizability</h4>
                <p>
                  There are many variations of passages of Lorem Ipsum
                  available.
                </p>
              </div>
            </div>
            <div className="carousel-item">
              <img src={slider3} className="img-fluid" alt="login page" />
              <div className="px-4 mt-4">
                <h4>Cross Browser Compatibility</h4>
                <p>
                  Overview We're a group of women who want to learn JavaScript.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default SignIn;
