import { Formik, useFormik } from "formik";
import { Calendar } from "primereact/calendar";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import { showErrorToast, showSuccessToast } from "../../../Toster";
import axiosPrivate from "../../../hooks/axiosPrivate";
import { Link } from "react-router-dom";
import { FaGreaterThan } from "react-icons/fa";
const blogTemplateValidation = Yup.object().shape({
  pagename: Yup.string().required("Page name is required"),
  slug: Yup.string().required("Slug is required"),
  // bannerImage: Yup.mixed().required('Banner Image is Required'),
  // blogSection: Yup.array().of(
  //     Yup.object().shape({
  // blogImg: Yup.mixed().required('Blog Image is Required'),
  // blogHeading: Yup.string().required('Blog Heading is Required'),
  // blogContent: Yup.string().required('Blog Content is Required'),
  // blogDate: Yup.string().required('Blog Date is Required'),
  //     })
  // ),
});

const BlogCreation = () => {
  const navigate = useNavigate();
  const [submitButton, setSubmitButton] = useState("Submit");
  const [template, setTemplate] = useState("Add Blog Template");
  const { id } = useParams();

  const initialValues = {
    pagename: "",
    slug: "",
    bannerImage: "",
    // blogSection: [
    // 	{
    // 		blogImg: '',
    // 		blogHeading: '',
    // 		blogContent: '',
    // 		blogDate: '',
    // 	},
    // ],
  };

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: blogTemplateValidation,
    onSubmit: (values, { setFieldError }) => {
      let formData = {
        name: values.pagename,
        slug: values.slug,
        page_json: JSON.stringify(values),
        template_type: "blog",
      };
      if (id) {
        axiosPrivate
          .put("pages/update/" + id, formData)
          .then((res) => {
            showSuccessToast("Updation successful");
            navigate("/admin/blog-listing");
          })
          .catch((error) => {
            if (typeof error?.response?.data?.errors == "array") {
              showErrorToast(error?.response?.data?.errors[0].msg);
            } else {
              showErrorToast(error?.response?.data?.errors?.msg);
            }
          });
      } else {
        axiosPrivate
          .post("pages/create", formData)
          .then((res) => {
            showSuccessToast("Successfully created");
            navigate("/admin/blog-listing");
          })
          .catch((error) => {
            if (typeof error.response.data.errors == "array") {
              showErrorToast(error.response.data.errors[0].msg);
            } else {
              showErrorToast(error.response.data.errors.msg);
            }
          });
      }
    },
  });

  useEffect(() => {
    if (id) {
      axiosPrivate
        .get("pages/" + id)
        .then((res) => {
          const parsedValues = JSON.parse(res.data?.data?.page_json);
          Object.keys(parsedValues).forEach((key) => {
            setFieldValue(key, parsedValues[key]);
          });

          setSubmitButton("Update");
        })

        .catch((error) => {
          if (typeof error?.response?.data?.errors == "array") {
            showErrorToast(error?.response?.data?.errors[0]?.msg);
          } else {
            showErrorToast(error?.response?.data?.errors?.msg);
          }
        });
      setTemplate("Edit Blog Template");
    }
  }, []);
  const onPageNameBlur = (event) => {
    const { name, value } = event.target;
    if (name == "pagename") {
      let slug = value
        .toLowerCase()
        .trim()
        .replace(/[^\w\s-]/g, "")
        .replace(/[\s_-]+/g, "-")
        .replace(/^-+|-+$/g, "");
      setFieldValue("slug", slug);
    }
    handleBlur(event);
  };

  const onFileUpload = (event) => {
    let formData = new FormData();
    formData.append("image", event.target.files[0]);
    axiosPrivate
      .post("pages/file-upload", formData)
      .then((res) => {
        setFieldValue(event.target.name, res.data.data);
      })
      .catch((error) => {
        setFieldValue(event.target.name, "");
      });
    handleChange(event);
  };

  const onAddRow = (check) => {
    // if (check) {
    // 	let newObj = {
    // 		blogImg: '',
    // 		blogHeading: '',
    // 		blogContent: '',
    // 	};
    // 	let rowData = values?.blogSection;
    // 	rowData.push(newObj);
    // 	setFieldValue('blogSection', rowData);
    // } else {
    // 	let rowsData = values?.blogSection;
    // 	if (rowsData?.length > 1) {
    // 		rowsData?.splice(rowsData?.length - 1, 1);
    // 		setFieldValue('blogSection', rowsData);
    // 	}
    // }
  };

  const nameFilter = (event) => {
    const inputValue = event.target.value;
    if (/^[A-Za-z\s'-]+$/.test(inputValue) || inputValue === "") {
      setFieldValue(event.target.name, inputValue);
    }
  };

  return (
    <>
      <div>
        <div className="section-body mt-3 website-template">
          <div className="container-fluid">
            <div className="card-header">
              {id ? (
                <>
                  <strong className="top-left-heading">
                    Edit Blog Template
                  </strong>

                  <ol className="headerroutes breadcrumb m-0 bg-none ">
                    <li className="item color-gray">|</li>
                    <li className="item">
                      <Link
                        to="/admin/dashboard"
                        className="route-heading item mx-2"
                      >
                        Dashboard
                      </Link>
                    </li>
                    <li className="item">
                      <FaGreaterThan className="grater-than-route" />
                    </li>
                    <li className="route-heading item mx-2 mt-1">
                      Edit Blog Template
                    </li>
                  </ol>
                </>
              ) : (
                <>
                  <strong className="top-left-heading">
                    Add Blog Template
                  </strong>

                  <ol className="headerroutes breadcrumb m-0 bg-none ">
                    <li className="item color-gray">|</li>
                    <li className="item">
                      <Link
                        to="/admin/dashboard"
                        className="route-heading item mx-2"
                      >
                        Dashboard
                      </Link>
                    </li>
                    <li className="item">
                      <FaGreaterThan className="grater-than-route" />
                    </li>
                    <li className="route-heading item mx-2 mt-1">
                      Add Blog Template
                    </li>
                  </ol>
                </>
              )}
            </div>
            <div className="tab-content mt-3">
              <form onSubmit={handleSubmit}>
                <div
                  className="tab-pane fade show active"
                  id="home-template"
                  role="tabpanel"
                >
                  <div className="card">
                    <div className="card-body">
                      <div className="row bordered-row">
                        {/*  Tax Name */}
                        <div className="col-lg-6 col-md-6 col-sm-12">
                          <label>
                            Page Name:<span className="required">*</span>
                          </label>
                          <div className="form-group">
                            <input
                              id="pagename"
                              name="pagename"
                              type="text"
                              className="form-control"
                              placeholder="Page Name"
                              onChange={nameFilter}
                              onBlur={onPageNameBlur}
                              value={values.pagename}
                            />
                            {touched.pagename && errors.pagename ? (
                              <div className="invalidDataError">
                                {errors.pagename}
                              </div>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12">
                          <label>
                            Slug:<span className="required">*</span>
                          </label>
                          <div className="form-group">
                            <input
                              id="slug"
                              name="slug"
                              type="text"
                              className="form-control"
                              placeholder="Slug"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.slug}
                            />
                            {touched.slug && errors.slug ? (
                              <div className="invalidDataError">
                                {errors.slug}
                              </div>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12">
                          <label>Banner Image:</label>
                          <div className="form-group">
                            <input
                              id="bannerImage"
                              name="bannerImage"
                              type="file"
                              className="form-control"
                              onChange={onFileUpload}
                              onBlur={handleBlur}
                              // value={values.bannerImage}
                            />
                            {typeof values.bannerImage == "string" &&
                            values.bannerImage != "" ? (
                              <img
                                style={{ width: "100px", height: "100px" }}
                                className="mt-2"
                                src={values.bannerImage}
                              />
                            ) : (
                              ""
                            )}
                            {touched.bannerImage && errors.bannerImage ? (
                              <div className="invalidDataError">
                                {errors.bannerImage}
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                      <div
                        className="row bordered-row"
                        style={{ display: "none" }}
                      >
                        {values?.blogSection?.map((res, index) => (
                          <div className="row w-100 m-0" key={index}>
                            <div className="col-lg-4 col-md-6 col-sm-12">
                              <label>
                                Blog Image:<span className="required">*</span>
                              </label>
                              <div className="form-group">
                                <input
                                  id={"blogImg" + index}
                                  name={`blogSection[${index}].blogImg`}
                                  type="file"
                                  className="form-control"
                                  onChange={onFileUpload}
                                  onBlur={handleBlur}
                                  // value={values.category[index].categoryicon}
                                />
                                {touched.category &&
                                touched.category[index] &&
                                errors.category &&
                                errors.category[index] &&
                                errors.category[index].categoryicon ? (
                                  // <ErrorMessage name={`blogSection[${index}].blogImg`} component="div" />
                                  <div
                                    className="invalidDataError"
                                    name={`blogSection[${index}].blogImg`}
                                  >
                                    {errors.category[index].categoryicon}
                                  </div>
                                ) : null}
                              </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-12">
                              <label>
                                Blog Heading:<span className="required">*</span>
                              </label>
                              <div className="form-group">
                                <input
                                  id={"blogHeading" + index}
                                  name={`blogSection[${index}].blogHeading`}
                                  type="text"
                                  className="form-control"
                                  placeholder="Blog Heading"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.blogSection[index].blogHeading}
                                />
                                {/* {touched.category[index] && errors.category && errors.category[index] && errors.category[index].categoryheading ? (
                                                                    <div className="invalidDataError">
                                                                        {errors.category[index].categoryheading}
                                                                    </div>
                                                                ) : null} */}
                              </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-12">
                              <label>
                                Blog Content:<span className="required">*</span>
                              </label>
                              <div className="form-group">
                                <textarea
                                  id={index + "blogContent"}
                                  name={`blogSection[${index}].blogContent`}
                                  type="text"
                                  className="form-control"
                                  placeholder="Blog Content"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.blogSection[index].blogContent}
                                />
                                {/* {formik.touched.name && formik.errors.name ? (
                                                            <div className={classes.invalidDataError}>
                                                                {formik.errors.name}
                                                            </div>
                                                        ) : null} */}
                              </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-12">
                              <label>
                                Blog Date:<span className="required">*</span>
                              </label>
                              <div className="form-group">
                                <Calendar
                                  name={`blogSection[${index}].blogDate`}
                                  className="form-control"
                                  value={values.blogSection[index].blogDate}
                                  onChange={(event) =>
                                    setFieldValue(
                                      `blogSection[${index}].blogDate`,
                                      event.target.value
                                    )
                                  }
                                  dateFormat="dd/M/yy"
                                />
                                {/* <Calendar value={date} onChange={(e) => setDate(e.value)} dateFormat="dd/M/yy" /> */}
                                {/* <input
                                                                    id="blogDate"
                                                                    name="pagename"
                                                                    type="text"
                                                                    className="form-control"
                                                                    placeholder="Page Name"
                                                                    onChange={handleChange}
                                                                    onBlur={onPageNameBlur}
                                                                    value={values.pagename}
                                                                /> */}
                                {/* {touched.pagename && errors.pagename ? (
                                                                    <div className="invalidDataError">
                                                                        {errors.pagename}
                                                                    </div>
                                                                ) : null} */}
                              </div>
                            </div>
                          </div>
                        ))}
                        <div className="action_btn_section">
                          <button
                            onClick={() => {
                              onAddRow(true);
                            }}
                            type="button"
                          >
                            +
                          </button>
                          <button
                            onClick={() => {
                              onAddRow(false);
                            }}
                            type="button"
                          >
                            -
                          </button>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12 submit-btn">
                          <button
                            type="button"
                            className="btn btn-secondary"
                            data-dismiss="modal"
                            onClick={() => navigate("/admin/blog-listing")}
                          >
                            Close
                          </button>
                          <button
                            type="submit"
                            className="btn btn-primary ml-2"
                          >
                            {submitButton}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BlogCreation;
