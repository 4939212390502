import { useFormik } from "formik";
import { Dropdown } from "primereact/dropdown";
import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { FaGreaterThan } from "react-icons/fa";
// import { useNavigate } from "react-router";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { showErrorToast, showSuccessToast } from "../../../Toster";
import axiosPrivate from "../../../hooks/axiosPrivate";

const homeTemplateValidation = Yup.object().shape({
  pagename: Yup.string().required("Page name is required"),
  slug: Yup.string().required("Slug is required"),

  bannerImage: Yup.mixed().required("Banner image is required"),
  bannerheading1: Yup.string().required("Banner heading is required"),
  bannerheading2: Yup.string().required("Banner sub heading is required"),
  secondSectionBanner: Yup.mixed().required(
    "Second section banner image is required"
  ),
  aboutheading: Yup.string().required("About heading is required"),
  aboutsubheading: Yup.string().required("About sub heading is required"),
  aboutcontent: Yup.string().required("About content is required"),
  category: Yup.array().of(
    Yup.object().shape({
      categoryicon: Yup.mixed().required("Category icon is required"),
      categoryheading: Yup.string().required("Category heading is required"),
      categorysubheading: Yup.string().required(
        "Category sub heading is required"
      ),
    })
  ),
  productheading: Yup.string().required("Product heading is required"),
  productsubheading: Yup.string().required("Product sub heading is required"),
  productcontent: Yup.string().required("Product content is required"),
  certifiedheading: Yup.string().required("Certified heading is required"),
  certifiedcontent: Yup.string().required("Certified content is required"),
  certifiedImgs: Yup.array().of(
    Yup.object().shape({
      certifiedImage: Yup.mixed().required("Certified image is required"),
    })
  ),
  newsSection: Yup.array().of(
    Yup.object().shape({
      newsImage: Yup.mixed().required("News image is required"),
      Achievementheading: Yup.string().required(
        "Achievement heading is required"
      ),
      newscontent: Yup.string().required("News content is required"),
    })
  ),
});

const AddHomeTemplate = () => {
  const initialValues = {
    pagename: "",
    slug: "",
    bannerImage: "",
    bannerheading1: "",
    bannerheading2: "",
    secondSectionBanner: "",
    aboutheading: "",
    aboutsubheading: "",
    aboutcontent: "",
    category: [
      {
        categoryicon: "",
        categoryheading: "",
        categorysubheading: "",
      },
    ],
    productheading: "",
    productsubheading: "",
    productcontent: "",
    certifiedheading: "",
    certifiedsubheading: "",
    certifiedcontent: "",
    certifiedMainImage: "",
    certifiedImgs: [
      {
        certifiedImage: "",
      },
    ],
    newsSection: [
      {
        newsImage: "",
        Achievementheading: "",
        newscontent: "",
      },
    ],
  };

  const onPageNameBlur = (event) => {
    const { name, value } = event.target;
    // setFieldValue(name, value);

    if (name == "pagename") {
      let slug = value
        .toLowerCase()
        .trim()
        .replace(/[^\w\s-]/g, "")
        .replace(/[\s_-]+/g, "-")
        .replace(/^-+|-+$/g, "");
      setFieldValue("slug", slug);
    }

    handleBlur(event);
  };

  const onFileUpload = (event) => {
    let formData = new FormData();
    formData.append("image", event.target.files[0]);
    axiosPrivate
      .post("pages/file-upload", formData)
      .then((res) => {
        setFieldValue(event.target.name, res.data.data);
      })
      .catch((error) => {
        setFieldValue(event.target.name, "");
      });
    handleChange(event);
  };

  const {
    values,
    errors,
    touched,
    handleSubmit,
    handleBlur,
    handleChange,
    setFieldValue,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: homeTemplateValidation,
    onSubmit: (values, { setFieldError }) => {
      let formData = {
        name: values.pagename,
        slug: values.slug,
        page_json: JSON.stringify(values),
        template_type: "home",
      };
      if (id) {
        axiosPrivate
          .put("pages/update/" + id, formData)
          .then((res) => {
            showSuccessToast("Update successful");
            navigate("/admin/home-template");
          })
          .catch((error) => {
            if (error.response) {
              if (error.response.status === 422) {
                for (let i = 0; i < error.response.data.errors.length; i++) {
                  setFieldError(
                    error.response.data.errors[i].param,
                    error.response.data.errors[i].msg
                  );
                }
              }
            }
          });
      } else {
        axiosPrivate
          .post("pages/create", formData)
          .then((res) => {
            showSuccessToast("Successfully created");
            navigate("/admin/home-template");
          })
          .catch((error) => {
            if (error.response) {
              if (error.response.status == 422) {
                for (let i = 0; i < error.response.data.errors.length; i++) {
                  setFieldError(
                    error.response.data.errors[i].param,
                    error.response.data.errors[i].msg
                  );
                }
              }
            }
          });
      }
    },
  });

  const onAddRow = (name, check) => {
    if (name == "category") {
      if (check) {
        let newObj = {
          categoryicon: "",
          categoryheading: "",
          categorysubheading: "",
        };
        let rowsData = values.category;
        if (rowsData?.length < 4) {
          rowsData.push(newObj);
          setFieldValue("category", rowsData);
        }
      } else {
        let rowsData = values.category;
        if (rowsData?.length > 1) {
          rowsData?.splice(rowsData?.length - 1, 1);
          setFieldValue("category", rowsData);
        }
      }
    } else if (name == "certified") {
      if (check) {
        let newObj = {
          certifiedImage: "",
        };
        let certifiedRow = values.certifiedImgs;
        if (certifiedRow?.length < 3) {
          certifiedRow.push(newObj);
          setFieldValue("certifiedImgs", certifiedRow);
        }
      } else {
        let certifiedRow = values.certifiedImgs;
        if (certifiedRow?.length > 1) {
          certifiedRow?.splice(certifiedRow?.length - 1, 1);
          setFieldValue("certifiedImgs", certifiedRow);
        }
      }
    } else if (name == "news") {
      if (check) {
        let newObj = {
          newsImage: "",
          Achievementheading: "",
          newscontent: "",
        };
        let newsRow = values.newsSection;
        if (newsRow?.length < 3) {
          newsRow.push(newObj);
          setFieldValue("newsSection", newsRow);
        }
      } else {
        let newsRow = values.newsSection;
        if (newsRow?.length > 1) {
          newsRow?.splice(newsRow?.length - 1, 1);
          setFieldValue("newsSection", newsRow);
        }
      }
    }
  };

  const navigate = useNavigate();

  const { id } = useParams();
  const [submitButton, setSubmitButton] = useState("Submit");
  const [template, setTemplate] = useState("Add Home Template");

  useEffect(() => {
    if (id) {
      axiosPrivate
        .get("/pages/" + id)
        .then((res) => {
          const parsedValue = JSON.parse(res?.data?.data?.page_json);
          Object.keys(parsedValue).forEach((key) => {
            setFieldValue(key, parsedValue[key]);
          });
          setSubmitButton("Update");
        })
        .catch((error) => {
          navigate("/admin/home-template/add");
        });
      setTemplate("Add Home Template");
    }
  }, [id]);

  const nameFilter = (event) => {
    const inputValue = event.target.value;
    if (/^[A-Za-z\s'-]+$/.test(inputValue) || inputValue === "") {
      setFieldValue(event.target.name, inputValue);
    }
  };
  return (
    <>
      <div className="section-body website-template">
        <div className="container-fluid">
          {/* hearder breadcrumb */}
          <div className="card-header">
            {id ? (
              <>
                <strong className="top-left-heading">Edit Home Template</strong>

                <ol className="headerroutes breadcrumb m-0 bg-none ">
                  <li className="item color-gray">|</li>
                  <li className="item">
                    <Link
                      to="/admin/dashboard"
                      className="route-heading item mx-2"
                    >
                      Dashboard
                    </Link>
                  </li>
                  <li className="item">
                    <FaGreaterThan className="grater-than-route" />
                  </li>
                  <li className="route-heading item mx-2 mt-1">
                    <Link to="/admin/home-template">Home Template</Link>
                  </li>

                  <li className="item">
                    <FaGreaterThan className="grater-than-route" />
                  </li>
                  <li className="route-heading item mx-2 mt-1">
                    Edit Home Template
                  </li>
                </ol>
              </>
            ) : (
              <>
                <strong className="top-left-heading">{template}</strong>

                <ol className="headerroutes breadcrumb m-0 bg-none ">
                  <li className="item color-gray">|</li>
                  <li className="item">
                    <Link
                      to="/admin/dashboard"
                      className="route-heading item mx-2"
                    >
                      Dashboard
                    </Link>
                  </li>
                  <li className="item">
                    <FaGreaterThan className="grater-than-route" />
                  </li>
                  <li className="route-heading item mx-2 mt-1">
                    <Link to="/admin/home-template">Home Template</Link>
                  </li>

                  <li className="item">
                    <FaGreaterThan className="grater-than-route" />
                  </li>
                  <li className="route-heading item mx-2 mt-1">
                    Add Home Template
                  </li>
                </ol>
              </>
            )}
          </div>

          <div className="tab-content mt-3">
            <form onSubmit={handleSubmit}>
              <div
                className="tab-pane fade show active"
                id="home-template"
                role="tabpanel"
              >
                <div className="card">
                  <div className="card-body">
                    <div className="row bordered-row">
                      {/*  Tax Name */}
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <label>
                          Page Name:<span className="required">*</span>
                        </label>
                        <div className="form-group">
                          <input
                            id="pagename"
                            name="pagename"
                            type="text"
                            className="form-control"
                            placeholder="Page Name"
                            onChange={nameFilter}
                            onBlur={onPageNameBlur}
                            value={values.pagename}
                          />
                          {touched.pagename && errors.pagename ? (
                            <>
                              <div className="invalidDataError">
                                {errors.pagename}
                              </div>
                            </>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <label>
                          Slug:<span className="required">*</span>
                        </label>
                        <div className="form-group">
                          <input
                            id="slug"
                            name="slug"
                            type="text"
                            className="form-control"
                            placeholder="Page Slug"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.slug}
                          />
                          {touched.slug && errors.slug ? (
                            <div className="invalidDataError">
                              {errors.slug}
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>

                    <h6>Banner Section</h6>
                    <div className="row bordered-row">
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <label>Banner Image:</label>
                        <div className="form-group">
                          <input
                            id="bannerImage"
                            name="bannerImage"
                            type="file"
                            className="form-control"
                            onChange={onFileUpload}
                            onBlur={handleBlur}
                            // value={values.bannerImage}
                          />
                          {typeof values.bannerImage == "string" &&
                          values.bannerImage != "" ? (
                            <img
                              style={{ width: "100px", height: "100px" }}
                              className="mt-2"
                              src={values.bannerImage}
                            />
                          ) : (
                            ""
                          )}
                          {touched.bannerImage && errors.bannerImage ? (
                            <div className="invalidDataError">
                              {errors.bannerImage}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <label>Banner Heading:</label>
                        <div className="form-group">
                          <input
                            id="bannerheading1"
                            name="bannerheading1"
                            type="text"
                            className="form-control"
                            placeholder="Banner Heading"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.bannerheading1}
                          />
                          {touched.bannerheading1 && errors.bannerheading1 ? (
                            <div className="invalidDataError">
                              {errors.bannerheading1}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <label>Banner Sub Heading:</label>
                        <div className="form-group">
                          <input
                            id="bannerheading2"
                            name="bannerheading2"
                            type="text"
                            className="form-control"
                            placeholder="Banner Sub Heading"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.bannerheading2}
                          />
                          {touched.bannerheading2 && errors.bannerheading2 ? (
                            <div className="invalidDataError">
                              {errors.bannerheading2}
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    <h6>About Section</h6>
                    <div className="row bordered-row">
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <label>About Banner Image:</label>
                        <div className="form-group">
                          <input
                            id="secondSectionBanner"
                            name="secondSectionBanner"
                            type="file"
                            className="form-control"
                            onChange={onFileUpload}
                            onBlur={handleBlur}
                            // value={values.secondSectionBanner}
                          />
                          {typeof values.secondSectionBanner == "string" &&
                          values.secondSectionBanner != "" ? (
                            <img
                              style={{ width: "100px", height: "100px" }}
                              className="mt-2"
                              src={values.secondSectionBanner}
                            />
                          ) : (
                            ""
                          )}
                          {touched.secondSectionBanner &&
                          errors.secondSectionBanner ? (
                            <div className="invalidDataError">
                              {errors.secondSectionBanner}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <label>About Heading:</label>
                        <div className="form-group">
                          <input
                            id="aboutheading"
                            name="aboutheading"
                            type="text"
                            className="form-control"
                            placeholder="About Heading"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.aboutheading}
                          />
                          {touched.aboutheading && errors.aboutheading ? (
                            <div className="invalidDataError">
                              {errors.aboutheading}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <label>About Sub Heading:</label>
                        <div className="form-group">
                          <input
                            id="aboutsubheading"
                            name="aboutsubheading"
                            type="text"
                            className="form-control"
                            placeholder="About Sub Headings"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.aboutsubheading}
                          />
                          {touched.aboutsubheading && errors.aboutsubheading ? (
                            <div className="invalidDataError">
                              {errors.aboutsubheading}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <label>About Content:</label>
                        <div className="form-group">
                          <textarea
                            id="aboutcontent"
                            name="aboutcontent"
                            type="text"
                            className="form-control"
                            placeholder="About Content"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.aboutcontent}
                          />
                          {touched.aboutcontent && errors.aboutcontent ? (
                            <div className="invalidDataError">
                              {errors.aboutcontent}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      {values.category.map((res, index) => (
                        <div className="row w-100 m-0" key={index}>
                          <div className="col-lg-4 col-md-6 col-sm-12">
                            <label>Category Icon:</label>
                            <div className="form-group">
                              <input
                                id={"categoryicon" + index}
                                name={`category[${index}].categoryicon`}
                                type="file"
                                className="form-control"
                                onChange={onFileUpload}
                                onBlur={handleBlur}
                                // value={values.category[index].categoryicon}
                              />
                              {typeof res.categoryicon == "string" &&
                              res.categoryicon != "" ? (
                                <img
                                  style={{ width: "100px", height: "100px" }}
                                  className="mt-2"
                                  src={res.categoryicon}
                                />
                              ) : (
                                ""
                              )}

                              {touched.category &&
                              touched.category[index] &&
                              errors.category &&
                              errors.category[index] &&
                              errors.category[index].categoryicon ? (
                                <div className="invalidDataError">
                                  {errors.category[index].categoryicon}
                                </div>
                              ) : null}
                            </div>
                          </div>
                          <div className="col-lg-4 col-md-6 col-sm-12">
                            <label>Category Heading:</label>
                            <div className="form-group">
                              <input
                                id={"categoryheading" + index}
                                name={`category[${index}].categoryheading`}
                                type="text"
                                className="form-control"
                                placeholder="Category Heading"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.category[index].categoryheading}
                              />
                              {touched.category &&
                              touched.category[index] &&
                              touched.category[index].categoryheading &&
                              errors.category &&
                              errors.category[index] &&
                              errors.category[index].categoryheading ? (
                                <div className="invalidDataError">
                                  {errors.category[index].categoryheading}
                                </div>
                              ) : null}
                            </div>
                          </div>
                          <div className="col-lg-4 col-md-6 col-sm-12">
                            <label>Category Sub Heading:</label>
                            <div className="form-group">
                              <input
                                id={index + "categorysubheading"}
                                name={`category[${index}].categorysubheading`}
                                type="text"
                                className="form-control"
                                placeholder="Category Sub Heading"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={
                                  values.category[index].categorysubheading
                                }
                              />

                              {touched.category &&
                              touched.category[index] &&
                              touched.category[index].categorysubheading &&
                              errors.category &&
                              errors.category[index] &&
                              errors.category[index].categorysubheading ? (
                                <div className="invalidDataError">
                                  {errors.category[index].categorysubheading}
                                </div>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      ))}
                      <div className="action_btn_section">
                        <button
                          type="button"
                          onClick={() => {
                            onAddRow("category", true);
                          }}
                        >
                          +
                        </button>
                        <button
                          type="button"
                          onClick={() => {
                            onAddRow("category", false);
                          }}
                        >
                          -
                        </button>
                      </div>
                    </div>
                    <h6>Product Section</h6>
                    <div className="row bordered-row">
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <label>Product Section Heading:</label>
                        <div className="form-group">
                          <input
                            id="productheading"
                            name="productheading"
                            type="text"
                            className="form-control"
                            placeholder="Product Section Heading"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.productheading}
                          />
                          {touched.productheading && errors.productheading ? (
                            <div className="invalidDataError">
                              {errors.productheading}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <label>Product Section Sub Heading:</label>
                        <div className="form-group">
                          <input
                            id="productsubheading"
                            name="productsubheading"
                            type="text"
                            className="form-control"
                            placeholder="Product Section Sub Heading"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.productsubheading}
                          />
                          {touched.productsubheading &&
                          errors.productsubheading ? (
                            <div className="invalidDataError">
                              {errors.productsubheading}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <label>Product Content:</label>
                        <div className="form-group">
                          <textarea
                            id="productcontent"
                            name="productcontent"
                            type="text"
                            className="form-control"
                            placeholder="Product Section Content"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.productcontent}
                          />
                          {touched.productcontent && errors.productcontent ? (
                            <div className="invalidDataError">
                              {errors.productcontent}
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    <h6>Certified Section</h6>
                    <div className="row bordered-row">
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <label>Certified Section Heading:</label>
                        <div className="form-group">
                          <input
                            id="certifiedheading"
                            name="certifiedheading"
                            type="text"
                            className="form-control"
                            placeholder="Certified Section Heading"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.certifiedheading}
                          />
                          {touched.certifiedheading &&
                          errors.certifiedheading ? (
                            <div className="invalidDataError">
                              {errors.certifiedheading}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <label>Certified Section Sub Heading:</label>
                        <div className="form-group">
                          <input
                            id="certifiedsubheading"
                            name="certifiedsubheading"
                            type="text"
                            className="form-control"
                            placeholder="Certified Section Sub Heading"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.certifiedsubheading}
                          />
                          {touched.certifiedsubheading &&
                          errors.certifiedsubheading ? (
                            <div className="invalidDataError">
                              {errors.certifiedsubheading}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <label>Certified Content:</label>
                        <div className="form-group">
                          <textarea
                            id="certifiedcontent"
                            name="certifiedcontent"
                            type="text"
                            className="form-control"
                            placeholder="Certified Section Content"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.certifiedcontent}
                          />
                          {touched.certifiedcontent &&
                          errors.certifiedcontent ? (
                            <div className="invalidDataError">
                              {errors.certifiedcontent}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <label>Certified Main Image:</label>
                        <div className="form-group">
                          <input
                            id="certifiedMainImage"
                            name="certifiedMainImage"
                            type="file"
                            className="form-control"
                            onChange={onFileUpload}
                            onBlur={handleBlur}
                            // value={values.certifiedImgs[index]?.certifiedImage}
                          />
                          {typeof values.certifiedMainImage == "string" &&
                          values.certifiedMainImage != "" ? (
                            <img
                              style={{ width: "100px", height: "100px" }}
                              className="mt-2"
                              src={values.certifiedMainImage}
                            />
                          ) : (
                            ""
                          )}

                          {/* {formik.touched.name && formik.errors.name ? (
                                                            <div className={classes.invalidDataError}>
                                                                {formik.errors.name}
                                                            </div>
                                                        ) : null} */}
                        </div>
                      </div>

                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <label>Certified Images:</label>
                        {values?.certifiedImgs?.map((res, index) => (
                          <div className="form-group">
                            <input
                              id={index + "certifiedImage"}
                              name={`certifiedImgs[${index}].certifiedImage`}
                              type="file"
                              className="form-control"
                              onChange={onFileUpload}
                              onBlur={handleBlur}
                              // value={values.certifiedImgs[index]?.certifiedImage}
                            />
                            {typeof res.certifiedImage == "string" &&
                            res.certifiedImage != "" ? (
                              <img
                                style={{ width: "100px", height: "100px" }}
                                className="mt-2"
                                src={res.certifiedImage}
                              />
                            ) : (
                              ""
                            )}

                            {touched.certifiedImgs &&
                            touched.certifiedImgs[index] &&
                            touched.certifiedImgs[index].certifiedImage &&
                            errors.certifiedImgs &&
                            errors.certifiedImgs[index] &&
                            errors.certifiedImgs[index].certifiedImage ? (
                              <div className="invalidDataError">
                                {errors.certifiedImgs[index].certifiedImage}
                              </div>
                            ) : null}
                          </div>
                        ))}
                        <div className="action_btn_section">
                          <button
                            type="button"
                            onClick={() => {
                              onAddRow("certified", true);
                            }}
                          >
                            +
                          </button>
                          <button
                            type="button"
                            onClick={() => {
                              onAddRow("certified", false);
                            }}
                          >
                            -
                          </button>
                        </div>
                      </div>
                    </div>
                    <h6>News & Achievements Section</h6>
                    <div className="row bordered-row">
                      {values?.newsSection?.map((res, index) => (
                        <div className="m-0 row w-100">
                          <div className="col-lg-4 col-md-6 col-sm-12">
                            <label>Achievement Card Image:</label>
                            <div className="form-group">
                              <input
                                id={index + "newsImage"}
                                name={`newsSection.[${index}].newsImage`}
                                type="file"
                                className="form-control"
                                onChange={onFileUpload}
                                onBlur={handleBlur}
                                // value={values.newsSection[index].newsImage}
                              />
                              {typeof res.newsImage == "string" &&
                              res.newsImage != "" ? (
                                <img
                                  style={{ width: "100px", height: "100px" }}
                                  className="mt-2"
                                  src={res.newsImage}
                                />
                              ) : (
                                ""
                              )}
                              {touched.newsSection &&
                              touched.newsSection[index] &&
                              touched.newsSection[index].newsImage &&
                              errors.newsSection &&
                              errors.newsSection[index] &&
                              errors.newsSection[index].newsImage ? (
                                <div className="invalidDataError">
                                  {errors.newsSection[index].newsImage}
                                </div>
                              ) : null}
                            </div>
                          </div>
                          <div className="col-lg-4 col-md-6 col-sm-12">
                            <label>Achievement Card Heading:</label>
                            <div className="form-group">
                              <input
                                id={index + "Achievementheading"}
                                name={`newsSection.[${index}].Achievementheading`}
                                type="text"
                                className="form-control"
                                placeholder="Achievement Card Heading"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={
                                  values.newsSection[index].Achievementheading
                                }
                              />
                              {touched.newsSection &&
                              touched.newsSection[index] &&
                              touched.newsSection[index].Achievementheading &&
                              errors.newsSection &&
                              errors.newsSection[index] &&
                              errors.newsSection[index].Achievementheading ? (
                                <div className="invalidDataError">
                                  {errors.newsSection[index].Achievementheading}
                                </div>
                              ) : null}
                            </div>
                          </div>
                          <div className="col-lg-4 col-md-6 col-sm-12">
                            <label>Achievement Card Content:</label>
                            <div className="form-group">
                              <textarea
                                id="newscontent"
                                name={`newsSection.[${index}].newscontent`}
                                type="text"
                                className="form-control"
                                placeholder="Achievement card Content"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.newsSection[index].newscontent}
                              />
                              {touched.newsSection &&
                              touched.newsSection[index] &&
                              touched.newsSection[index].newscontent &&
                              errors.newsSection &&
                              errors.newsSection[index] &&
                              errors.newsSection[index].newscontent ? (
                                <div className="invalidDataError">
                                  {errors.newsSection[index].newscontent}
                                </div>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      ))}
                      <div
                        className="action_btn_section"
                        style={{ justifyContent: "start" }}
                      >
                        <button
                          type="button"
                          onClick={() => {
                            onAddRow("news", true);
                          }}
                        >
                          +
                        </button>
                        <button
                          type="button"
                          onClick={() => {
                            onAddRow("news", false);
                          }}
                        >
                          -
                        </button>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-12 submit-btn">
                        <button
                          type="button"
                          className="btn btn-secondary"
                          data-dismiss="modal"
                          onClick={() => navigate("/admin/home-template")}
                        >
                          Close
                        </button>
                        <button type="submit" className="btn btn-primary ml-2">
                          {submitButton}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddHomeTemplate;
