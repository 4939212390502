import { Formik, useFormik } from 'formik';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import AlertContext from '../../../context/Alert/AlertContext';
import axiosPrivate from '../../../hooks/axiosPrivate';
import classes from './Permission.module.css';

const validationRules = {
	permission_name: Yup.string()
		.max(120, 'Name must not exceed 120 characters')
		.required('Permission  Name is required'),

	permission_module: Yup.string()
		.max(120, 'Name must not exceed 120 characters')
		.required('Permission  module Name is required'),
};

const EditPermission = () => {
	let { id } = useParams();
	const { setAlertMessage } = useContext(AlertContext);
	const navigate = useNavigate();
	//   const [roles, setRoles] = useState([]);
	const [getData, setGetData] = useState({});
	const [initialValues, setInitialValues] = useState({
		permission_name: '',
		permission_module: '',
	});

	useEffect(() => {
		axiosPrivate
			.get(`permissions/${id}`)
			.then((response) => {
				if (response.data.code == 200) {
					setGetData({ ...response.data.data });
				}
			})
			.catch((error) => {
			});
	}, []);

	useEffect(() => {
		if (getData != '') {
			formik.setValues({ ...getData });
		}
	}, [getData]);

	const formik = useFormik({
		initialValues: initialValues,
		enableReinitialize: true,
		validationSchema: Yup.object(validationRules),
		onSubmit: async (values, { setSubmitting, setFieldError }) => {
			axiosPrivate
				.put(`permissions/${id}`, values)
				.then((response) => {
					setAlertMessage({ message: 'permission updated successfully' });
					navigate('/admin/users');
				})
				.catch((error) => {
					if (error.response) {
						if (error.response.status == 422) {
							for (let i = 0; i < error.response.data.errors.length; i++) {
								setFieldError(
									error.response.data.errors[i].param,
									error.response.data.errors[i].msg
								);
							}
						}
					}
				});
		},
	});

	return (
		<div className="section-body mt-3">
			<div className="container-fluid">
				<div className="tab-content mt-3">
					<form onSubmit={formik.handleSubmit}>
						<div
							className="tab-pane fade show active"
							id="user-add"
							role="tabpanel"
						>
							<div className="card">
								<div className="card-header">
									<strong>Edit Permission</strong>
								</div>
								<div className="card-body">
									<div className="row clearfix">
										<div className="col-lg-12 col-md-12 col-sm-12">
											<div className="form-group">
												<input
													id="permission_name"
													name="permission_name"
													type="text"
													className="form-control"
													placeholder="Permission Name *"
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													value={formik.values.permission_name}
												/>
												{formik.touched.permission_name &&
												formik.errors.permission_name ? (
													<div className={classes.invalidDataError}>
														{formik.errors.permission_name}
													</div>
												) : null}
											</div>
										</div>

										<div className="col-lg-12 col-md-12 col-sm-12">
											<div className="form-group">
												<input
													id="permission_module"
													name="permission_module"
													type="text"
													className="form-control"
													placeholder="Permission module *"
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													value={formik.values.permission_module}
												/>
												{formik.touched.permission_module &&
												formik.errors.permission_module ? (
													<div className={classes.invalidDataError}>
														{formik.errors.permission_module}
													</div>
												) : null}
											</div>
										</div>

										<div className="col-12">
											<hr className="mt-4" />
											<div className="table-responsive">
												<table className="table table-striped"></table>
											</div>
											<button type="submit" className="btn btn-primary">
												Update
											</button>
											<button
												type="button"
												className="btn btn-secondary ml-2"
												data-dismiss="modal"
												onClick={() => navigate('/admin/permission')}
											>
												CLOSE
											</button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</form>
				</div>
			</div>
		</div>
	);
};

export default EditPermission;
