import { useContext, useEffect, useState } from 'react';
import { BiEdit } from 'react-icons/bi';
import { FaGreaterThan } from 'react-icons/fa';
import { RiDeleteBinLine } from 'react-icons/ri';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { showErrorToast } from '../../../Toster';
import AlertContext from '../../../context/Alert/AlertContext';
import DataTable2 from '../../../context/components/DataTable2';
import axiosPrivate from '../../../hooks/axiosPrivate';
import Swal from 'sweetalert2';
import DeleteConfirm from '../../../delete-confirmation/DeleteConfirm';
const Customer = () => {
	const { alertMessage, setAlertMessage } = useContext(AlertContext);
	const [customerState, setCustomerState] = useState([]);
	const [confirm, setConfirm] = useState(false)
	const [id, setId] = useState("")
	const headers = [
		{ name: 'CUSTOMER NAME', field: 'name', sortable: true, classKey: '' },
		{ name: 'EMAIL', field: 'email', sortable: true, classKey: '' },
		{ name: 'CONTACT NO.', field: 'mobile', sortable: true, classKey: '' },

		{ name: 'ACTION', field: 'action', classKey: '' },
	];

	const searchItems = ['name', 'email', 'mobile'];
	// get All cusromer list
	useEffect(() => {
		const options = {
			headers: { 'content-type': 'application/json' },
		};
		axiosPrivate.post('users/customer/list').then((users) => {
			const usersData = users?.data?.data
				?.filter((value) => value.role === 8)
				.map((value) => {
					let buttons = [];
					// if (true)
					// 	buttons.push(
					// 		<Link
					// 			key="editButton##1"
					// 			type="button"
					// 			to={`/admin/customer/edit/${value.id}`}
					// 			className="btn btn-icon"
					// 			title="Edit"
					// 		>
					// 			<BiEdit style={{ color: 'green' }} size={18} />
					// 		</Link>
					// 	);
					if (true)
						buttons.push(
							<button
								key="deleteButton##1"
								type="button"
								data-id={value.id}
								onClick={() =>{
									setId(value.id)
									setConfirm(true)
								}}
								className="btn btn-icon js-sweetalert"
								title="Delete"
							>
								<RiDeleteBinLine style={{ color: 'red' }} size={17} />
							</button>
						);
					value['action'] = buttons.length > 0 ? buttons : '-';
					return value;
				});

			setCustomerState(usersData);
		}).catch((error)=>{})
	}, []);

	//alert message
	useEffect(() => {
		if (alertMessage != null) {
			toast(alertMessage.message, {
				position: 'top-right',
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				theme: 'light',
			});
			setAlertMessage(null);
		}
	}, [alertMessage]);

	// delete  user function
	const customerDeleteHandler = async () => {
			axiosPrivate
			.delete(`users/delete/${id}`)
			.then(async (response) => {
				if (response.data.code === 200) {
					setCustomerState([]);
					setAlertMessage({ message: 'Customer Deleted Successfully!' });
					await refreshTable();
				}
			})
			.catch((error) => {
				if (typeof error.response.data.errors == 'array') {
					showErrorToast(error.response.data.errors[0].msg);
				} else {
					showErrorToast(error.response.data.errors.msg);
				}
			});
	};


	
	
	//after delete.... refresh cusromer list
	const refreshTable = () => {
		return new Promise((resolve, reject) => {
			const options = {
				headers: { 'content-type': 'application/json' },
			};
			axiosPrivate.post('users/customer/list', options).then((users) => {
				const usersData = users?.data?.data?.map((value, key) => {
					let buttons = [];
					
					if (true)
						buttons.push(
							<button
								key="deleteButton##1"
								type="button"
								data-id={value.id}
								onClick={() =>{
									setId(value.id)
									setConfirm(true)
								}}
								className="btn btn-icon js-sweetalert "
								title="Delete"
							>
								<RiDeleteBinLine style={{ color: 'red' }} />
							</button>
						);
					value['action'] = buttons.length > 0 ? buttons : '-';
					return value;
				});
				setCustomerState(usersData);
				resolve(true);
			});
		});
	};

	return (
		<>
		{
			confirm && <DeleteConfirm deleteMethod = {customerDeleteHandler} setConfirm = {setConfirm} text = {"By clicking 'Yes', this customer will be permanently deleted."} success = {"Customer has been deleted."} cancel={"Customer deletion cancelled."}/>
		}
			<div className="section-body">
				<div className="container-fluid">
					{/* hearder breadcrumb */}
					<div className="card-header ml-4">
						<strong className="top-left-heading">Customers</strong>

						<ol className="headerroutes breadcrumb m-0 bg-none ">
							<li className="item color-gray">|</li>
							<li className="item">
								<Link to="/admin/dashboard" className="route-heading item mx-2">
									Dashboard
								</Link>
							</li>
							<li className="item">
								<FaGreaterThan className="grater-than-route" />
							</li>
							<li className="route-heading item mx-2 mt-1">Customers</li>
						</ol>
					</div>

					<div className="section-body ">
						<div className="container-fluid">
							<div className="tab-content ">
								<div
									className="tab-pane fade show active"
									id="user-list"
									role="tabpanel"
								>
									<div className="card">
										<div className="card-body">
											<div className="table-responsive">
												{
													<DataTable2
														lists={customerState}
														headers={headers}
														searchItems={searchItems}
													/>
												}
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Customer;
