import { useFormik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import * as Yup from "yup";

const PaymentIntegration = () => {
  const paymentbuttonRef = useRef(null);
  const [successMsg, setSuccessMsg] = useState(false);

  useEffect(() => {
    paymentbuttonRef.current.click();
  }, []);

  const initialValues = {
    access_code: "AVUJ87KG22AW52JUWA",
    // encRequest : ''
    encRequest:
      "1b8610bd5510611a8724aa5e2dc61f7642e635c43d7631dcdb015ec0ae45d1eb8349bd693cf131f755f174bd7f0779fae6de97b09d08c087b3edf3d8599fc0b9b593f9c08496639e77e2bf1adff313e827c8f1cec0ad8832f7450aa40060dcb3c7b70fe3c9292c1192b266d905ff888fcc09fd4e9758388101387cc40565c992fc6a359a046ac82a82deb9563c2eb5eec684d46d7816acfeefb6783634452eeb57bc6cde3948e2ea6f588df13d00a0d9baa084ce9a819dae2594546f9f15dc70c4d88ad10c08a825d20dbf7c411cd0b5b4a008535817445b03077133f17edd349349fd062e80bf1c26004ad747f110c2576932fae1d44e6a8b310494b44d4c23acdefac1bf40d6cf7636cfdb75317dde76b14de7c97c1ef0ef15a042c57cb5fd2986df9b51e51c1055006eaf99bd34185986bf813786f26ef61283650e1dc9953b0e443e928cc695874cd73944bbaa3b9b9267df4d562129f044d8fd79872791fe764aac87a72877f50c52d821792cc1",
  };

  const {
    values,
    touched,
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    handleReset,
  } = useFormik({
    initialValues: initialValues,
    onSubmit: (values, { setFieldError }) => {
    },
  });

  return (
    <div className="">
      <div className="d-flex justify-content-between contact-page-container">
        <form name="redirect" id="nonseamless"
          action="https://test.ccavenue.com/transaction/transaction.do?command=initiateTransaction"
          method="POST">
          <div className="row row-cols-sm-2 row-cols-1 form-container g-0 justify-content-between">
            <div className="col-sm-6 col-12 contact_field">
              <input
                type="text"
                value={values?.access_code}
                name="access_code"
                id="access_code"
                hidden
              />
            </div>
            <div className="col-sm-6 col-12 contact_field">
              <input
                type="text"
                value={values?.encRequest}
                name="encRequest"
                id="encRequest"
                hidden
              />
            </div>
          </div>
          <div className="submit-btn-container col-12">
            <button
              type="submit"
              className="form-submit-btn"
              hidden
              ref={paymentbuttonRef}
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default React.memo(PaymentIntegration); //PaymentIntegration;
