import { useFormik } from "formik";
import React, { useContext, useEffect, useState } from "react";
import { FaGreaterThan } from "react-icons/fa";
import { Link, useNavigate, useParams } from "react-router-dom";
import axiosPrivate from '../../../hooks/axiosPrivate';
import { Calendar } from 'primereact/calendar';
import moment from 'moment';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import AlertContext from './../../../context/Alert/AlertContext'

const validationForVariant = {
    variant: Yup.number('Variant must be a number')
    .typeError("Variant must be a valid number").required('Variant is required'),
    base_unit: Yup.string().required('Base Unit is required'),
    // applicable_from: Yup.string().required('Field is required'),
    rate: Yup.mixed().required('Variant Rate is required'),
    product_image: Yup.string().required('Image is required')
}

const AddProductVariant = () => {

    const today = new Date();

    const slug = useParams();

    const navigate = useNavigate();

    const [productVariants, setProductVariants] = useState([]);

    const { alertMessage, setAlertMessage } = useContext(AlertContext);

    const [units, setUnits] = useState([]);

    const [heading, setHeading] = useState('Add');

    const [variantId, setVariantId] = useState('');
    const initialValues = {
        product_id: slug?.id,
        variant: '',
        base_unit: '',
        applicable_from: '',
        rate: '',
        product_image: '',
        alt: ''
    }

    const { values, errors, touched, handleBlur, handleReset, handleChange, handleSubmit, setFieldValue } = useFormik({
        initialValues: initialValues,
        validationSchema: Yup.object(validationForVariant),
        onSubmit: (values) => {
            let formData = new FormData();
            formData.append('product_id', slug?.id);
            formData.append('variant', values.variant);
            formData.append('base_unit', values.base_unit);
            if(values.applicable_from) {
                formData.append('applicable_from', values.applicable_from ? moment(values.applicable_from).format('YYYY-MM-DD') : '');
            }
            formData.append('rate', values.rate);
            formData.append('product_image', values.product_image);
            formData.append('alt', values?.alt);
            if (heading == 'Add') {
                axiosPrivate.post('product-variants/create', formData).then((res) => {
                    handleReset();
                    getProductVariants();
                    setAlertMessage({ message: 'Product Variant Added Successfully!' });
                }).catch((error) => { });
            }
            else {
                if (typeof (values.product_image) != 'object') {
                    formData.delete('product_image');
                    formData.delete('alt');
                }
                axiosPrivate.post(`product-variants/update/${variantId}?_method=PUT`, formData).then((res) => {
                    handleReset();
                    getProductVariants();
                    setHeading('Add');
                    setAlertMessage({ message: 'Product Variant Updated Successfully!' });
                }).catch((error) => { });
            }
        }
    });

    const getProductVariants = () => {
        axiosPrivate.get(`product-variants/get/${slug?.id}`).then((res) => {
            setProductVariants(res.data.data);
        }).catch((error) => { });
    }

    useEffect(() => {
        if (alertMessage != null) {
            toast(alertMessage.message, {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'light',
            });
            setAlertMessage(null);
        }
    }, [alertMessage]);

    useEffect(() => {
        axiosPrivate.get('stocks/unit/list').then((res) => {
            setUnits(res.data.data);
        }).catch((error) => {

        })
        getProductVariants();
    }, [])

    const editProductVariant = (value) => {
        setVariantId(value.id);
        setFieldValue('variant', value.variant);
        setFieldValue('base_unit', value.base_unit);
        setFieldValue('applicable_from', moment(value.applicable_from, moment.defaultFormat).toDate());
        setFieldValue('rate', value.rate);
        setFieldValue('product_image', value.product_images[0]?.image);
        setFieldValue('alt', value?.product_images[0]?.alt);
        setHeading('Edit');
        window.scroll({
            top: 0,
            behavior: 'smooth'
        })
    }

    const variantDeleteHandler = (id) => {
        axiosPrivate.delete(`product-variants/${id}`).then((res) => {
            getProductVariants();
            setAlertMessage({ message: 'Product Variant Deleted Successfully!' });
        }).catch((error) => { })
    }


    const numberFilter = (event) => {
        const inputValue = event.target.value;
    
        const numericValue = inputValue.replace(/\D/g, "");
        setFieldValue(event.target.name, numericValue);
      };

    return (
        <>
            <div className="section-body">
                <div className="container-fluid">
                    {/* hearder breadcrumb */}
                    <div className="card-header">
                        <strong className="top-left-heading">{heading} Product Variant</strong>

                        <ol className="headerroutes breadcrumb m-0 bg-none ">
                            <li className="item color-gray">|</li>
                            <li className="item">
                                <Link to="/admin/dashboard" className="route-heading item mx-2">
                                    Dashboard
                                </Link>
                            </li>
                            <li className="item">
                                <FaGreaterThan className="grater-than-route" />
                            </li>
                            <li className="route-heading item mx-2 mt-1">
                                <Link to="/admin/productdetail"> Product Details</Link>
                            </li>

                            <li className="item">
                                <FaGreaterThan className="grater-than-route" />
                            </li>
                            <li className="route-heading item mx-2 mt-1">
                                {heading} Product Variant
                            </li>
                        </ol>
                    </div>

                    <div className="tab-content ">
                        <form onSubmit={handleSubmit}>
                            <div className="tab-pane fade show active" id="productdetail-add" role="tabpanel">
                                <div className="card">
                                    {/* heading */}
                                    <div className="card-header">
                                        <strong style={{ fontWeight: '700', fontSize: '18px' }}>
                                            {heading} Product Variant
                                        </strong>
                                    </div>
                                    <div className="card-body">
                                        <div className="row clearfix">
                                            <div className="col-lg-4 col-md-4 col-sm-12">
                                                <label>
                                                    Variant:<span className="required">*</span>
                                                </label>
                                                <div className="form-group">
                                                    <input
                                                        id="variant"
                                                        name="variant"
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Product Variant"
                                                        onChange={numberFilter}
                                                        onBlur={handleBlur}
                                                        value={values.variant}
                                                    />
                                                    {touched.variant && errors.variant ? (
                                                        <div className="invalidDataError">
                                                            {errors.variant}
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-4 col-sm-12">
                                                <div className="form-group">
                                                    <label>
                                                        Base Unit :<span className="required">*</span>
                                                    </label>
                                                    <select
                                                        id="base_unit"
                                                        className="form-control"
                                                        name="base_unit"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.base_unit}
                                                    >
                                                        <option value="">Select Base Unit </option>
                                                        {units.map((role, index) => (
                                                            <option key={index} value={role.value}>
                                                                {role.value}
                                                            </option>
                                                        ))}
                                                    </select>
                                                    {touched.base_unit &&
                                                        errors.base_unit ? (
                                                        <div className="invalidDataError">
                                                            {errors.base_unit}
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-4 col-sm-12">
                                                <label>
                                                    Variant Rate:<span className="required">*</span>
                                                </label>
                                                <div className="form-group">
                                                    <input
                                                        id="rate"
                                                        name="rate"
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Variant Rate"
                                                        onChange={numberFilter}
                                                        onBlur={handleBlur}
                                                        value={values.rate}
                                                    />
                                                    {touched.rate && errors.rate ? (
                                                        <div className="invalidDataError">
                                                            {errors.rate}
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-4 col-sm-12">
                                                <div className="form-group">
                                                    <label>
                                                        Applicable From :
                                                    </label>
                                                    <Calendar minDate={today} value={values.applicable_from} name="applicable_from" onChange={(e) => setFieldValue('applicable_from', e.target.value)} onBlur={handleBlur} dateFormat="dd/M/yy" />
                                                    {/* {touched.applicable_from &&
                                                        errors.applicable_from ? (
                                                        <div className="invalidDataError">
                                                            {errors.applicable_from}
                                                        </div>
                                                    ) : null} */}
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-4 col-sm-12">
                                                <label>
                                                    Variant Image:<span className="required">*</span>
                                                </label>
                                                <div className="form-group">
                                                    <input
                                                        id="product_image"
                                                        name="product_image"
                                                        type="file"
                                                        accept="image/*"
                                                        className="form-control"
                                                        placeholder="Product Image"
                                                        onChange={(e) =>
                                                            setFieldValue(
                                                                'product_image',
                                                                e.target.files[0]
                                                            )
                                                        }
                                                        onBlur={handleBlur}
                                                    />
                                                    {heading == 'Edit' ?
                                                        <Link to={process.env.REACT_APP_COMMON_FILE_URL + 'product-variants/' + values?.product_image} target="_blank" style={{ textDecoration: 'underline' }}>
                                                            Uploaded Image
                                                        </Link>
                                                        : ""}
                                                    {touched.product_image && errors.product_image ? (
                                                        <div className="invalidDataError">
                                                            {errors.product_image}
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-4 col-sm-12">
                                                <label>
                                                    Alt :
                                                </label>
                                                <div className="form-group">
                                                    <input
                                                        id="alt"
                                                        name="alt"
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Product Image Alt"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values?.alt}
                                                    />
                                                    {/* {touched.alt && errors.alt ? (
                                                        <div className="invalidDataError">
                                                            {errors.alt}
                                                        </div>
                                                    ) : null} */}
                                                </div>
                                            </div>
                                            <div className="col-12 submit-btn">
                                                <hr className="mt-4" />
                                                <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={() => navigate('/admin/productdetail')}>
                                                    Close
                                                </button>
                                                <button type="submit" className="btn btn-primary float-right ml-2">
                                                    Submit
                                                </button>
                                            </div>

                                            <div className="col-12 table-div" style={{ overflowX: "scroll" }}>
                                                <table className="table table-bordered mt-5">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">Variant</th>
                                                            <th scope="col">Unit</th>
                                                            <th scope="col">Applicable From</th>
                                                            <th scope="col">Rate</th>
                                                            <th scope="col">Image</th>
                                                            <th scope="col">Alt</th>
                                                            <th scope="col">Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {productVariants?.length != 0 && productVariants?.map((value, key) => {
                                                            return (
                                                                <tr key={key}>
                                                                    <td>{value.variant}</td>
                                                                    <td>{value.base_unit}</td>
                                                                    <td>{moment(value.applicable_from).format('DD/MM/YYYY')}</td>
                                                                    <td>{value.rate}</td>
                                                                    <td><img src={process.env.REACT_APP_COMMON_FILE_URL + 'product-variants/' + value.product_images[0]?.image} style={{ width: "100px", height: "100px" }} /></td>
                                                                    <td>{value?.product_images[0]?.alt}</td>
                                                                    <td>
                                                                        <button
                                                                            type="button"
                                                                            className="btn"
                                                                            onClick={() => editProductVariant(value)}
                                                                        >
                                                                            <i className="fa fa-edit"></i>
                                                                        </button>
                                                                        <button
                                                                            key="deleteButton##1"
                                                                            type="button"
                                                                            data-id={value.id}
                                                                            onClick={() => variantDeleteHandler(value.id)}
                                                                            className="btn btn-icon js-sweetalert"
                                                                            title="Delete"
                                                                        >
                                                                            <i className="fa fa-trash-o"></i>
                                                                        </button>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })}
                                                        {productVariants?.length == 0 ?
                                                            <tr>
                                                                No Variant Found
                                                            </tr>
                                                            : ""}
                                                    </tbody>
                                                </table>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
}

export default AddProductVariant;