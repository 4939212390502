import React, { useState } from 'react'
import { BsFillArrowUpRightCircleFill } from 'react-icons/bs';
import { Link } from 'react-router-dom';
import { MdEmail } from 'react-icons/md';
import axiosPrivate from './../../hooks/axiosPrivate'
import { useFormik } from "formik";
import * as Yup from "yup";
function AboutUsPage(props) {
    const { pageData } = props;
    const initialValues = pageData;
    const [customMsg, setCustomMsg] = useState(false);
    const postNewsLetter = () => {
        // debugger;
        let email = document.getElementById('email');
        let formData = {
            email: email.value
        }
        axiosPrivate.post('/news-letter/', formData).then((res) => {
            email.value = '';
            setCustomMsg(true);
            setTimeout(() => {
                setCustomMsg(false);
            }, 4000);
        }).catch((error) => {
        })
    }


    const formSchema = Yup.object({
        email: Yup.string().required("Please enter a email address!").matches(
          /^[\w.-]+@[a-zA-Z\d.-]+\.[a-zA-Z]{2,}$/,
          "Please enter a valid email!"
        ),
      });
    
      const formValues = {
        email: "",
      };
    
      const {
        values,
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldValue,
        errors,
        touched,
      } = useFormik({
        initialValues: formValues,
        validationSchema: formSchema,  
        onSubmit: (data, { resetForm, setSubmitting, setFieldError }) => {
            axiosPrivate.post('/news-letter/', data).then((res) => {
                
                setCustomMsg(true);
                setTimeout(() => {
                    setCustomMsg(false);
                    resetForm();
                }, 4000);
            }).catch((error) => {
            })
          },
      });
    // const initialValues = {
    //     pagename: "About Us",
    //     slug: "/about-us",
    //     bannerImage: "/img/aboutuspage/image51.png",

  //     chemical_free_section: {
  //         chemical_heading: "Chemical Free",
  //         chemical_subheading: "NM India Biotech Overview",
  //         chemical_content: "NM India Biotech is involved in the development, manufacturing and marketing of chemical free agri inputs. We belong to the Sunlight Group which has a diverse business portfolio in sectors like mining, logistics, petroleum & food processing since 2003. Our rich agricultural background and the knowledge gained over generations have helped us to understand the pros and cons of the current agri-farming situation. We are all set to make our contribution towards agriculture and animal nutrition in order to ensure a sustainable development.",
  //         chemical_banner_image: "/img/bg-pattan1.png",
  //         extra_data: [
  //             {
  //                 counter_name: "Client review",
  //                 counter_count: "10K",
  //             },
  //             {
  //                 counter_name: "Available in cities",
  //                 counter_count: "25+",
  //             },
  //             {
  //                 counter_name: "Years Experience",
  //                 counter_count: "25+",
  //             },
  //             {
  //                 counter_name: "Happy Customers",
  //                 counter_count: "25K+",
  //             }
  //         ],
  //     },

  //     partners_section: {
  //         partners_heading: "Our Partners",
  //         partners_content: "Lorem Ipsum is simply dummy text of the printing and typesetting industry Lorem Ipsum has been the industry's standard.",
  //         bedge_data: {
  //             bedge_heading: "Quality Policy",
  //             bedge_content: "We are committed to ensure quality in whatever we think, make and do and to establish quality as the most important parameter in all aspects of business.",
  //             bedge_image: "img/aboutuspage/image54.png",
  //         },
  //         partners_image: [
  //             {
  //                 image: "img/aboutuspage/image56.png",
  //             },
  //             {
  //                 image: "img/aboutuspage/image57.png",
  //             },
  //             {
  //                 image: "img/aboutuspage/image58.png",
  //             },
  //             {
  //                 image: "img/aboutuspage/image59.png",
  //             },
  //         ],
  //     },

  //     leadership_section: {
  //         leadership_heading: "Leadership",
  //         leader_name: "Mr. Parmanand Patidar",
  //         about_leader: "NM India biotech came into being with the idea of a sustainable development. The growth of India depends on growth of agriculture which is its biggest and most potential sector. With the dream to contribute in the growth of agriculture, NM India Biotech has developed a cutting edge infrastructure facility lead by a team of research associates to lead the manufacturing activities and to create a niche in the industry. We are committed for a chemical free production in order to preserve the environment and achieve a sustainable future.The organization is fuelled by young, professional and self-motivated individuals who aim to work collectively as a team adhering to the vision and mission of the organization.",
  //         leader_image: "img/aboutuspage/image53.png",
  //     },

  //     biotech_team_section: {
  //         biotech_heading: "NM India Biotech Team",
  //         biotech_team: [
  //             {
  //                 team_member_image: "img/aboutuspage/Rectangle118.png",
  //                 team_member_name: "Dr. Ramesh Chandra Ojha",
  //                 team_member_role: "Technical Director",
  //             },
  //             {
  //                 team_member_image: "img/aboutuspage/Rectangle116.png",
  //                 team_member_name: "Mr. Nitesh Jasani",
  //                 team_member_role: "Product Innovation & Process",
  //             },
  //             {
  //                 team_member_image: "img/aboutuspage/Rectangle117.png",
  //                 team_member_name: "Dr. Anil Kothari",
  //                 team_member_role: "Technical & Training",
  //             },
  //         ],
  //     },
  // };
  const [isShow, setIsShow] = useState("hide");

  const hideIcon = (e) => {
    document.getElementById("email-icon").style.display = "none";
  };
  const scroll = () => {
    window.scrollTo(0, 0);
  };
  return (
    <>
      {/* Aboutus Banner section */}
      <div className="about-container" id="about-us">
        <div className="about-banner-outer-container">
          {/* <img src="img/product-banner.png" /> */}
          <div className="about-banner-inner-container">
            <h1 className="about-heading">{initialValues.pagename}</h1>
            <p className="about-home-text">
              Home/
              <span className="about-us-text">{initialValues.pagename}</span>
            </p>
          </div>
        </div>
      </div>

      {/* Quality policy section */}
      <div className="quality-policy-container">
        <div className="d-flex main-container">
          <div
            className={`quality-container ${
              isShow ? "hide-badge-detail" : "show-badge-detail"
            }`}
          >
            <h2 className="quality-heading">
              {initialValues.partners_section.bedge_data.bedge_heading}
            </h2>
            <p className="quality-detail">
              {initialValues.partners_section.bedge_data.bedge_content}
            </p>
          </div>
          <div
            className="badge-container"
            onClick={() => {
              setIsShow(!isShow);
            }}
          >
            <img
              className="quality-badge"
              src={initialValues.partners_section.bedge_data.bedge_image}
              alt={initialValues.partners_section.bedge_data.bedge_heading}
              loading="lazy"
            />
          </div>
        </div>
      </div>

      {/* Biotech overview setion */}
      <div className="overview-container">
        <div className="content-container">
          <h2 className="overview-chemical-free-heading">
            {initialValues.chemical_free_section.chemical_heading}
          </h2>
          <h3 className="overview-heading">
            {initialValues.chemical_free_section.chemical_subheading}
          </h3>
          <p className="overview-detail">
            {initialValues.chemical_free_section.chemical_content}
          </p>
        </div>
        <div className="row row-cols-sm-4 row-cols-2">
          {initialValues?.chemical_free_section?.extra_data !== undefined &&
            initialValues.chemical_free_section.extra_data.length !== 0 &&
            initialValues.chemical_free_section.extra_data.map(
              (value, index) => {
                return (
                  <div
                    className="text-center col-sm-3 col-6 data-container"
                    key={index}
                  >
                    <h2 className="data">{value.counter_count}</h2>
                    <p className="client-data">{value.counter_name}</p>
                  </div>
                );
              }
            )}
        </div>
      </div>

      {/* Partners section */}
      <div className="partners-container">
        <div className="partners-data-container text-center">
          <h2 className="partners">
            {initialValues.partners_section.partners_heading}
          </h2>
          <p className="partners-detail">
            {initialValues.partners_section.partners_content}
          </p>
        </div>
        <div className="d-flex flex-sm-row flex-column justify-content-between aboutus-image-container">
          {initialValues?.partners_section?.partners_image !== undefined &&
            initialValues.partners_section.partners_image.length !== 0 &&
            initialValues.partners_section.partners_image.map((image, id) => {
              return (
                <div className="img-div" key={image.id}>
                  <img src={image.image} alt="ACACIA" loading="lazy" />
                </div>
              );
            })}
        </div>
      </div>
      {/* Leadership section */}
      <div className="d-flex about-leadership-container overview-container">
        <div className="about-leadership-detail">
          <h2 className="leadership-heading about-leadership-heading">
            {initialValues.leadership_section.leadership_heading}
          </h2>
          <span className="leadership-underline" />

          <h3 className="leadership-sub-heading">
            {initialValues.leadership_section.leader_name}
          </h3>
          <p className="leadership-detail">
            {initialValues.leadership_section.about_leader}
          </p>
        </div>
        <div className="about-founder-img">
          <img
            src={initialValues.leadership_section.leader_image}
            alt={initialValues.leadership_section.leader_name}
            loading="lazy"
          />
        </div>
      </div>

      {/* NM team section */}
      <div className="nm-team-container">
        <div>
          <h2 className="nm-team-heading">
            {initialValues.biotech_team_section.biotech_heading}
          </h2>
          <span className="leadership-underline d-block team-heading-underline" />
        </div>
        <div className="d-flex justify-content-between team-img-container">
          {initialValues?.biotech_team_section?.biotech_team !== undefined &&
            initialValues.biotech_team_section.biotech_team.length !== 0 &&
            initialValues.biotech_team_section.biotech_team.map(
              (member, index) => {
                return (
                  <div className="team-detail" key={index}>
                    <Link
                      to="/leadership"
                      onClick={() => {
                        scroll();
                      }}
                    >
                      <img
                        src={member.team_member_image}
                        alt={member.team_member_name}
                        loading="lazy"
                      />
                      <div className="outer-img-container">
                        <Link
                          to="/leadership"
                          onClick={() => {
                            scroll();
                          }}
                        >
                          <BsFillArrowUpRightCircleFill className="img-pointer" />
                        </Link>
                        <div className="team-member-detail">
                          <h3 className="team-name">
                            {member.team_member_name}
                          </h3>
                          <p className="member-post">
                            {member.team_member_role}
                          </p>
                        </div>
                      </div>
                    </Link>
                  </div>
                );
              }
            )}
        </div>
      </div>

            {/* Newsletter section */}
            <div className='newsletter-section'>
                <p className='newsletter-p'>Join Our Newsletter</p>
                <div className='d-flex justify-content-between newsletter-outer-container'>
                    <div className='newsletter-container'>
                        <h2 className='subscribe-heading'>Subscribe today, and get our private sale deal info instantly</h2>
                    </div>
                    {/* <div className='d-flex justify-content-end subscribe-container'>
                        <input type="email" name="email" id="email" placeholder='Email Address' onChange={(e) => hideIcon(e)} />
                        <MdEmail className='email-icon-ph' id='email-icon' />
                        <button className='subscribe-btn' onClick={() => postNewsLetter()}>Subscribe</button>
                    </div> */}
                    <form
            onSubmit={handleSubmit}
            className="formContainer"
          >
            <div className="subContainer" style={{position:'relative', top:'0px', left:'0px', bottom:'0px', right:'0px'}}>
            <input
              type="email"
              name="email"
              id="email"
              placeholder="Email Address"
              onChange={(e) => {
                hideIcon(e)
                handleChange(e)
              }}
              value={values.email}
            />
            
            <MdEmail className="email-icon-ph" id="email-icon" />
          
              <button type="submit" className="subscribe-btn">
                Subscribe
              </button>
              
              
         
            </div>
           
            {touched.email && errors.email ? (
              <div style={{ color: "red" }}>{errors.email}</div>
            ) : null}
          </form>
                </div>
                {customMsg ?
                    <p className='subscribe-p text-center'>Your Submission has been received and a confirmation mail has been sent to your email</p>
                    : ''}
            </div>

        </>
    )
}

export default React.memo(AboutUsPage);
