import { useContext, useEffect, useState } from 'react';
import { BiEdit } from 'react-icons/bi';
import { RiDeleteBinLine } from 'react-icons/ri';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { showErrorToast } from '../../../Toster';
import AlertContext from '../../../context/Alert/AlertContext';
import DataTable2 from '../../../context/components/DataTable2';
import axiosPrivate from '../../../hooks/axiosPrivate';

const Tax = () => {
	const { alertMessage, setAlertMessage } = useContext(AlertContext);
	const [taxState, setTaxState] = useState([]);
	// header
	const headers = [
		{
			name: 'Name',
			field: 'name',
			sortable: true,
			classKey: '',
		},
		{ name: 'TAX AMOUNT', field: 'tax_amount', sortable: true, classKey: '' },

		{ name: 'ACTION', field: 'action', classKey: '' },
	];

	// search item
	const searchItems = ['name'];

	//  tax list
	useEffect(() => {
		const options = {
			headers: { 'content-type': 'application/json' },
		};
		axiosPrivate
			.get('tax', options)
			.then((tax) => {
				const taxData = tax.data.data.map((value, key) => {
					let buttons = [];
					if (true)
						buttons.push(
							<Link
								key="editButton##1"
								type="button"
								to={`/admin/tax/edit/${value.id}`}
								className="btn btn-icon"
								title="Edit"
							>
								<BiEdit style={{ color: 'green' }} size={18} />
							</Link>
						);
					if (true)
						buttons.push(
							<button
								key="deleteButton##1"
								type="button"
								data-id={value.id}
								onClick={() => taxDeleteHandler(value.id)}
								className="btn btn-icon js-sweetalert"
								title="Delete"
							>
								<RiDeleteBinLine style={{ color: 'red' }} size={17} />
							</button>
						);
					value['action'] = buttons.length > 0 ? buttons : '-';
					return value;
				});

				setTaxState(taxData);
			})
			.catch((error) => {
				if (error.response) {
					//response status is an error code
					showErrorToast('Internal server error');
				} else if (error.request) {
					//response not received though the request was sent
					showErrorToast('Internal server error');
				} else {
					//an error occurred when setting up the request
					showErrorToast('Internal server error');
				}
			});
	}, []);

	// alert
	useEffect(() => {
		if (alertMessage != null) {
			toast(alertMessage.message, {
				position: 'top-right',
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				theme: 'light',
			});
			setAlertMessage(null);
		}
	}, [alertMessage]);

	// delete function

	const taxDeleteHandler = async (id) => {
		axiosPrivate
			.delete(`tax/${id}`)
			.then(async (response) => {
				if (response.data.code == 200) {
					setTaxState([]);
					setAlertMessage({ message: 'tax Deleted Successfully!' });
					await refreshTable();
				}
			})
			.catch((error) => {
				if (error.response) {
					//response status is an error code
					showErrorToast('Internal server error');
				} else if (error.request) {
					//response not received though the request was sent
					showErrorToast('Internal server error');
				} else {
					//an error occurred when setting up the request
					showErrorToast('Internal server error');
				}
			});
	};
	// refreshTable (delete data after get list)
	const refreshTable = () => {
		return new Promise((resolve, reject) => {
			const options = {
				headers: { 'content-type': 'application/json' },
			};
			axiosPrivate
				.get('tax', options)
				.then((tax) => {
					const taxData = tax.data.data.map((value, key) => {
						let buttons = [];
						if (true)
							buttons.push(
								<button
									key="editButton##1"
									type="button"
									className="btn btn-icon"
									title="Edit"
								>
									<BiEdit style={{ color: 'green' }} size={18} />
								</button>
							);
						if (true)
							buttons.push(
								<button
									key="deleteButton##1"
									type="button"
									data-id={value.id}
									onClick={() => taxDeleteHandler(value.id)}
									className="btn btn-icon js-sweetalert"
									title="Delete"
								>
									<RiDeleteBinLine style={{ color: 'red' }} size={17} />
								</button>
							);
						value['action'] = buttons.length > 0 ? buttons : '-';
						return value;
					});
					setTaxState(taxData);
					resolve(true);
				})
				.catch((error) => {
					reject(error);
					if (error.response) {
						//response status is an error code
						showErrorToast('Internal server error');
					} else if (error.request) {
						//response not received though the request was sent
						showErrorToast('Internal server error');
					} else {
						//an error occurred when setting up the request
						showErrorToast('Internal server error');
					}
				});
		});
	};

	return (
		<div>
			<div className="mx-5">
				{/* heading */}
				<div className="d-flex justify-content-between align-items-center mb-3">
					<div className="card-header">
						<h3
							className="card-title"
							style={{ fontWeight: '700', fontSize: '18px' }}
						>
							Tax
						</h3>
					</div>
					<div className="header-action">
						<Link to="/admin/tax/add" className="text-decoration-none">
							<button
								type="button"
								className="btn btn-primary"
								data-toggle="modal"
								data-target="#exampleModal"
							>
								<i className="fe fe-plus mr-2" />
								Add
							</button>
						</Link>
					</div>
				</div>
			</div>
			<div className="section-body mt-3">
				<div className="container-fluid">
					<div className="tab-content mt-3">
						<div
							className="tab-pane fade show active"
							id="user-list"
							role="tabpanel"
						>
							<div className="card">
								<div className="card-body">
									<div className="table-responsive">
										{
											<DataTable2
												lists={taxState}
												headers={headers}
												searchItems={searchItems}
											/>
										}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Tax;
