import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { FaGreaterThan } from 'react-icons/fa';
import { Link, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { showErrorToast } from '../../../Toster';
import AlertContext from '../../../context/Alert/AlertContext';
import axiosPrivate from '../../../hooks/axiosPrivate';

const validationRules = {
	name: Yup.string()
		.max(120, 'Name must not exceed 120 characters')
		.required('Name is required'),
};
export default function AddSalesTarget() {
	const { setAlertMessage } = useContext(AlertContext);
	const navigate = useNavigate();
	const [selectedUserId, setSelectedUserId] = useState('');
	const [listData, setlistData] = useState([]);
	const [selectedYear, setSelectedYear] = useState(null);
	const [TargetData, setTargetData] = useState([]);
	const [errors, setErrors] = useState({});

	useEffect(() => {
		axiosPrivate.get(`/users/get/area/manager`).then((response) => {
			setlistData(response.data.data);
		});
	}, []);

	const handleDataChange = (event) => {
		// const value = event.target;
		setSelectedUserId(event.target.value);
	};

	const handleChange = (value, date) => {
		const dateObject = new Date(decodeURIComponent(value));
		const year = dateObject?.getFullYear();
		axiosPrivate
			.get(`/sales-target/get/${selectedUserId}/${year}`)
			.then((response) => {
				setTargetData(response.data.data);
			});
	};

	const handleInputChange = (index, value) => {
		const updatedTargetData = [...TargetData]; // Create a copy of the targetData array
		updatedTargetData[index].target_amount = value; // Update the target_amount property of the specified index
		setTargetData(updatedTargetData); // Update the targetData state with the modified array
	};
	const handleSubmit = (event) => {
		// formik.validateForm(event).then(() => {
		const userLoggedInId = JSON.parse(localStorage.getItem('userDetails'));
		event.preventDefault();
		// Perform custom validation
		const formErrors = validateForm();

		// If there are errors, set them in state and prevent form submission
		if (Object.keys(formErrors).length > 0) {
			setErrors(formErrors);
			return;
		}
		// Prepare the data object to be sent to the API
		const formData = {
			user_id: selectedUserId,
			tag: '',
			created_user_id: userLoggedInId.id,
			financial_year: selectedYear.getFullYear().toString(),
			sales_month: TargetData.map((target) => ({
				target_date: moment(target.target_date).format('YYYY-MM-DD'),
				month_label: target.month_label,
				target_amount: target.target_amount,
				modified_user_id:selectedUserId,
			})),
		};

		// Call the API to submit the data
		axiosPrivate
			.post('/sales-target/store', formData)
			.then((response) => {
				setAlertMessage({ message: response.data.message });
				navigate('/admin/sales-target');
				// Handle any further actions after successful submission
			})
			.catch((error) => {
				if (typeof error.response.data.errors == 'array') {
					showErrorToast(error.response.data.errors[0].msg);
				} else {
					showErrorToast(error.response.data.errors.msg);
				}
			});
	};
	const validateForm = () => {
		// Create an object to hold the errors
		const formErrors = {};

		// Perform your custom validation logic here
		// Example: Validate the sales officer name
		if (!selectedUserId) {
			formErrors.user_id = 'Please select area manager.';
		}

		// Example: Validate the selected year
		if (!selectedYear) {
			formErrors.year = 'Please select a year.';
		}

		// Return the errors object
		return formErrors;
	};

	return (
		<div className="section-body ">
			<div className="container-fluid">
				{/* hearder breadcrumb */}
				<div className="card-header">
					<strong className="top-left-heading">Add Sales Target</strong>

					<ol className="headerroutes breadcrumb m-0 bg-none ">
						<li className="item color-gray">|</li>
						<li className="item">
							<Link to="/admin/dashboard" className="route-heading item mx-2">
								Dashboard
							</Link>
						</li>
						<li className="item">
							<FaGreaterThan className="grater-than-route" />
						</li>
						<li className="route-heading item mx-2 mt-1">
							<Link to="/admin/sales-target">Sales Targets</Link>
						</li>
						<li className="item">
							<FaGreaterThan className="grater-than-route" />
						</li>

						<li className="route-heading item mx-2 mt-1">Add Sales Target</li>
					</ol>
				</div>
				<div className="tab-content ">
					<form onSubmit={handleSubmit}>
						<div
							className="tab-pane fade show active"
							id="user-add"
							role="tabpanel"
						>
							<div className="card">
								<div className="card-body">
									<div className="row clearfix">
										<div className="col-lg-6 col-md-12 col-sm-12">
											<div className="form-group">
												<label>
													Area Manager Name:<span className="required">*</span>
												</label>
												<select
													name="user_id"
													className="form-control"
													onChange={handleDataChange}
												>
													<option value="">Select Area Manager Name</option>
													{listData.map((list) => (
														<option key={list.name} value={list.user_id}>
															{list.name}
														</option>
													))}
												</select>
												{errors.user_id && (
													<div className="invalidDataError">
														{errors.user_id}
													</div>
												)}
												
											</div>
										</div>
										<div className="col-lg-6 col-md-12 col-sm-12">
											{
												selectedUserId !== '' && (
													<div className="form-group">
												<label>
													Select Year:<span className="required">*</span>
												</label><br/>
												<DatePicker
													selected={selectedYear}
													className="form-control"
													// onChange={(date) => setSelectedYear(date)}
													onChange={(date) => {
														setSelectedYear(date);
														handleChange(date);
													}}
													dateFormat="yyyy"
													showYearPicker
													isClearable
													placeholderText="Select Year"
												/>
												{errors.year && (
													<div className="invalidDataError">{errors.year}</div>
												)}

												
											</div>
												)
											}
										</div>
										<div className="col-12">
											<div className="form-group">
												<div className="d-flex">
													<p
														style={{ color: '#004684', fontWeight: 700 }}
														className="col-lg-4 mt-2 mb-2"
													>
														Month
													</p>
													<p
														style={{ color: '#004684', fontWeight: 700 }}
														className="col-lg-8 mt-2 mb-2"
													>
														Amount(₹)
													</p>
												</div>
												<hr />
												{TargetData.map((target, index) => (
													<div key={index} className="d-flex">
														<div className="col-lg-4 mt-2 mb-2">
															<label>{target.month_label}</label>
														</div>
														<div className="col-lg-8 mt-2 mb-2">
															<input
																className="form-control"
																value={
																	target.target_amount == '0'
																		? ''
																		: target.target_amount
																}
																type="number"
																onChange={(e) =>
																	handleInputChange(index, e.target.value)
																}
															/>
														</div>
													</div>
												))}
											</div>
										</div>
										<div className="col-12 submit-btn">
											<hr className="mt-4" />
											<div className="table-responsive">
												<table className="table table-striped"></table>
											</div>
											<button
												type="button"
												className="btn btn-secondary"
												data-dismiss="modal"
												onClick={() => navigate('/admin/sales-target')}
											>
												Close
											</button>
											<button type="submit" className="btn btn-primary ml-2">
												Submit
											</button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</form>
				</div>
			</div>
		</div>
	);
}
