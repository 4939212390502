import { useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { showErrorToast } from '../../Toster';
import AlertContext from '../../context/Alert/AlertContext';
import axiosPrivate from '../../hooks/axiosPrivate';
import moment from "moment";
import DataTable2 from '../../context/components/DataTable2';
import LoaderContext from '../../context/loader/LoaderContext';

const AuditLogs = () => {
	const { alertMessage, setAlertMessage } = useContext(AlertContext);
	const [auditState, setAuditState] = useState([]);
	const { setIsLoading } = useContext(LoaderContext);
	// header
	const headers = [
        { name: 'User Name', field: 'user_name', classKey: '' },
		{
			name: 'Modules',
			field: 'model',
			sortable: true,
			classKey: '',
		},
		{ name: 'Actions', field: 'action', sortable: true, classKey: '' },
		{ name: 'Action Date', field: 'created_at', sortable: true, classKey: '' }
	];

	// search item
	const searchItems = ['user_name' , 'model' , 'action'];

	//  tax list
	useEffect(() => {
		setIsLoading(true);
		axiosPrivate
			.get('logs')
			.then((audit) => {
				setIsLoading(false);
				let values = audit.data.data.map((res)=>{
				res['created_at'] = moment(res.created_at).format('DD/MM/YYYY')
					return res;
				})
				setAuditState(values);
			})
			.catch((error) => {
				setIsLoading(false);
				if (error.response) {
					//response status is an error code
					showErrorToast('Internal server error');
				} else if (error.request) {
					//response not received though the request was sent
					showErrorToast('Internal server error');
				} else {
					//an error occurred when setting up the request
					showErrorToast('Internal server error');
				}
			});
	}, []);

	// alert
	useEffect(() => {
		if (alertMessage != null) {
			toast(alertMessage.message, {
				position: 'top-right',
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				theme: 'light',
			});
			setAlertMessage(null);
		}
	}, [alertMessage]);

	return (
		<div>
			<div className="mx-5">
				{/* heading */}
				<div className="d-flex justify-content-between align-items-center mb-3">
					<div className="card-header" style={{paddingLeft:'0px'}}>
						<h3
							// className="card-title"
							style={{ fontWeight: '700', fontSize: '18px' }}
						>
							Audit Logs
						</h3>
					</div>
				</div>
			</div>
			<div className="section-body mt-3">
				<div className="container-fluid">
					<div className="tab-content mt-3">
						<div
							className="tab-pane fade show active"
							id="user-list"
							role="tabpanel"
						>
							<div className="card">
								<div className="card-body">
									<div className="table-responsive">
										{
											<DataTable2
												lists={auditState}
												headers={headers}
												searchItems={searchItems}
											/>
										}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default AuditLogs;
