import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";

const Piechart = ({ orders }) => {
   const [series, setSeries] =useState([0,0]);
   useEffect(() => {
    
    const pendingOrders = orders.filter((order) => order?.order_status !== "confirmed");
    const successOrders = orders.filter((order) => order?.order_status === "confirmed");

   

    setSeries([ pendingOrders.length, successOrders.length]);
  }, [orders]);

  const [options] =useState({
    chart: {
      width: 380,
      type: "pie",
    },
    labels: [ "Pending", "Confirmed"],
    legend: {
      position: "bottom",
      markers: {
        fillColors: ["#fccc55", "#719924"],
      },
    },
    fill: {
      colors: ["#fccc55", "#719924"],
    },
    dataLabels: {
      enabled: false,
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: "100%",
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  });

  return (
    <div>
      <div id="chart">
        <ReactApexChart
          options={options}
          series={series}
          type="pie"
          width="100%"
        />
      </div>
    </div>
  );
};

export default Piechart;
