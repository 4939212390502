import React, { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";

function Stackedchart(props) {
  const [series, setSeries] = useState([]);
  const [options, setOptions] = useState({
    chart: {
      type: "bar",
      height: 350,
    },
    xaxis: {
      categories: [],
    },
  });

  useEffect(() => {
    const filteredData = props.data.filter(
      (entry) => entry.order_status?.props?.children === "Confirmed"
    );

    // Sort the filtered data by order date
    filteredData.sort((a, b) => new Date(a.order_date) - new Date(b.order_date));

    const monthlyData = {};

    filteredData.forEach((entry) => {
      const monthIndex = new Date(entry.order_date).getMonth();
      const revenue = parseFloat(entry.order_amount);

      if (!monthlyData[monthIndex]) {
        monthlyData[monthIndex] = 0;
      }

      monthlyData[monthIndex] += revenue;
    });

    const currentDate = new Date();
    const lastSixMonths = Array.from({ length: 6 }, (_, i) => {
      const monthIndex = (currentDate.getMonth() - i + 12) % 12;
      return monthIndex;
    });

    const monthlyRevenue = lastSixMonths
      .map((monthIndex) => monthlyData[monthIndex] || 0)
      .reverse(); // Reverse the array

    const monthNames = lastSixMonths
      .map((monthIndex) =>
        new Date(0, monthIndex).toLocaleString("default", { month: "short" })
      )
      .reverse(); // Reverse the array

    setSeries([
      {
        name: "Monthly Revenue",
        data: monthlyRevenue,
      },
    ]);

    setOptions({
      ...options,
      xaxis: {
        categories: monthNames,
       
      },
     
    });
  }, [props.data]);

  return (
    <div>
      <div id="chart">
        <ReactApexChart
          options={options}
          series={series}
          type="bar"
          height={350}
        />
      </div>
    </div>
  );
}

export default Stackedchart;
