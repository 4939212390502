import { useFormik } from 'formik';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { FaGreaterThan } from 'react-icons/fa';
import { Link, useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { showErrorToast, showSuccessToast } from '../../../Toster';
import AlertContext from '../../../context/Alert/AlertContext';
import axiosPrivate from '../../../hooks/axiosPrivate';
import classes from './Orders.module.css';

// validation
const validationRules = {};

const EditOrders = () => {
	let { id } = useParams();
	const inputRef = useRef(null);
	const { setAlertMessage } = useContext(AlertContext);
	const navigate = useNavigate();
	const [getData, setGetData] = useState({});
	const [productDetail, setProductDetail] = useState([]);
	const [editModal, setEditModal] = useState(false);
	const [productList, setProductList] = useState([]);
	const [addProductModal, setAddProductModal] = useState(false);
	const [addProductValue, setAddProductValue] = useState({
		order_id: '',
		product_id: '',
		quantity: '',
		price: '',
	});

	const [addProductError, setAddProductError] = useState();
	const [editProductError, setEditProductError] = useState();

	const [orderID, setOrderId] = useState({});
	const [editOrderId, setEditOrderId] = useState();
	const [defaultProductEdit, setDefaultProductEdit] = useState({
		order_id: '',
		product_id: '',
		quantity: '',
		price: '',
	});
	const [calculatedValue, setCalculatedValue] = useState(0);

	// Edit Product Modal Open Function
	const modalOpenEdit = (item) => {
		setEditOrderId(item.id);
		setEditModal(true);
		getProductItemById(item.id);
	};

	// Edit Product Modal close Function
	const closeEditModal = () => {
		setEditModal(false);
	};

	//  Add Product Modal Open Function
	const addProductOpenModal = () => {
		// Reset the addProductValue and addProductError states when opening the form
		setAddProductValue({
			product_id: '',
			quantity: '',
			price: '',
		});
		setAddProductError({});

		// Open the modal form
		setAddProductModal(true);
	};

	//  Add Product Modal Open Function
	const addProductCloseModal = () => {
		setAddProductModal(false);
	};

	//Add Product onChange Function
	const addProductChangeFunction = (event) => {
		setAddProductError({ product_id: '' });

		inputRef.current.value = '';
		const selectedProductId = event.target.value;
		const selectedProduct = productList.find(
			(product) => product.id === selectedProductId
		);
		setAddProductValue((prevValue) => ({
			...prevValue,
			product_id: event.target.value,
		}));

		if (selectedProduct) {
			const selectedProductPrice = selectedProduct.rate;

			setCalculatedValue(selectedProductPrice);
		}
	};

	//Add Product Quantity onChange Function
	const addProductQuantityChange = (event) => {
		if (!addProductValue.product_id) {
			showErrorToast('Please select a product before adding quantity');
		} else {
			setAddProductValue((prevValue) => ({
				...prevValue,
				price: event.target.value * calculatedValue,
				quantity: event.target.value,
			}));

			setAddProductError({ quantity: '' });
		}
	};

	//Add Product  data Submit Function
	const addProductSubmit = (e) => {
		e.preventDefault();
		if (!addProductValue.product_id) {
			setAddProductError({ product_id: 'Product is Required' });
		} else if (!addProductValue.quantity) {
			setAddProductError({ quantity: 'Quantity is Required' });
		} else if (!addProductValue.price) {
			setAddProductError({ price: 'Price is Required' });
		} else {
			const value = {
				order_id: orderID,
				product_id: addProductValue.product_id,
				quantity: addProductValue.quantity,
				price: addProductValue.price,
			};

			axiosPrivate
				.post('ecom/order-items/create', value)
				.then((response) => {
					showSuccessToast({ message: 'Product Add Successfully' });
					setAddProductModal(false);
				})
				.catch((error) => {
					if (error.response) {
						showErrorToast('Internal server error');
					}
				})
				.finally(() => {
					// Reset the addProductValue and addProductError states
					setAddProductValue({
						product_id: '',
						quantity: '',
						price: '',
					});
					setAddProductError({});
				});
		}
	};

	//Edit Product onChange Function
	const editProductONChange = (event) => {
		setDefaultProductEdit((prevValue) => ({
			...prevValue,
			product_id: event.target.value,
			quantity: '', // Clear the quantity field
			price: '', //clear the price field
		}));
	};

	//Edit Product Quantity onChange Function
	const editProductQuantityONChange = (event) => {
		setEditProductError({ quantity: '' });
		const newQuantity = event.target.value;
		const selectedProductId = defaultProductEdit.product_id;
		const selectedProduct = productList.find(
			(product) => product.id === selectedProductId
		);

		if (!selectedProduct) {
			showErrorToast('Please select a product before adding quantity');
		} else {
			setDefaultProductEdit((prevValue) => ({
				...prevValue,
				quantity: newQuantity,
				price: newQuantity * selectedProduct.rate,
			}));
		}
	};

	//Edit Product  data Submit Function
	const editValueSubmit = (e) => {
		e.preventDefault();
		const errors = {};

		if (!defaultProductEdit.product_id) {
			errors.product_id = 'Product is required';
		}

		if (!defaultProductEdit.quantity) {
			errors.quantity = 'Quantity is required';
		}

		if (!defaultProductEdit.price) {
			errors.price = 'Price is required';
		}

		setEditProductError(errors);

		if (Object.keys(errors).length === 0) {
			const value = {
				order_id: orderID,
				product_id: defaultProductEdit.product_id,
				quantity: defaultProductEdit.quantity,
				price: defaultProductEdit.price,
			};

			axiosPrivate
				.put(`ecom/order-items/update/${editOrderId}`, value)
				.then((response) => {
					setEditModal(false);
				})
				.catch((error) => {
					if (error.response) {
						showErrorToast('Internal server error');
					}
				});
		}
	};

	//Product List
	useEffect(() => {
		axiosPrivate
			.post(`stocks`)
			.then((productList) => {
				if (productList.data.code === 200) {
					setProductList(productList.data.data);
				}
			})
			.catch((error) => {
				if (error.response) {
					//response status is an error code
					showErrorToast('Internal server error');
				}
			});
	}, []);

	//   Get order by id
	useEffect(() => {
		axiosPrivate
			.get(`ecom/orders/get/${id}`)
			.then((response) => {
				setOrderId(response.data.data.order_id);
				setProductDetail(response.data.data.order_items);
				if (response.data.code === 200) {
					setGetData({ ...response.data.data });
				}
			})
			.catch((error) => {
				if (error.response) {
					//response status is an error code
					showErrorToast('Internal server error');
				}
			});
	}, [editModal, addProductModal]);

	//  product edit item get by id
	const getProductItemById = (editOrderId) => {
		axiosPrivate
			.get(`ecom/order-items/get/${editOrderId}`)
			.then((orderItem) => {
				if (orderItem.data.code === 200) {
					setDefaultProductEdit(orderItem.data.data);
				}
			})
			.catch((error) => {
				if (error.response) {
					//response status is an error code
					showErrorToast('Internal server error');
				}
			});
	};

	useEffect(() => {
		if (getData != '') {
			formik.setValues({ ...getData });
		}
	}, [getData]);

	// formik (send data)
	const formik = useFormik({
		initialValues: {
			// user_id: '',
			order_amount: '',
			shipping_amount: '',
			discount_amount: '',
			total_amount: '',
			payment_mode: '',
			payment_status: '',
			bill_name: '',
			bill_mobile: '',
			bill_address: '',
			bill_pincode: '',
			bill_state: '',
			bill_city: '',
			ship_name: '',
			ship_mobile: '',
			ship_address: '',
			ship_pincode: '',
			ship_state: '',
			ship_city: '',
			order_status: '',
			coupon_id: '',
			coupon_discount: '',
			gst_no: '',
		},
		enableReinitialize: true,

		validationSchema: Yup.object(validationRules),
		onSubmit: async (values, { setSubmitting, setFieldError }) => {
			axiosPrivate
				.put(`ecom/orders/update/${id}`, values)
				.then((response) => {
					setAlertMessage({ message: 'Orders Updated successfully' });
					navigate('/admin/Ecom-orders');
				})
				.catch((error) => {
					if (typeof error.response.data.errors == 'array') {
						showErrorToast(error.response.data.errors[0].msg);
					} else {
						showErrorToast(error.response.data.errors.msg);
					}
				});
		},
	});

	return (
		<>
			<div className="section-body">
				<div className="container-fluid">
					{/* hearder breadcrumb */}
					<div className="card-header">
						<strong className="top-left-heading"> Edit Order</strong>

						<ol className="headerroutes breadcrumb m-0 bg-none ">
							<li className="item color-gray">|</li>
							<li className="item">
								<Link to="/admin/dashboard" className="route-heading item mx-2">
									Dashboard
								</Link>
							</li>
							<li className="item">
								<FaGreaterThan className="grater-than-route" />
							</li>
							<li className="route-heading item mx-2 mt-1">
								<Link to="/admin/Ecom-orders"> Order List </Link>
							</li>
							<li className="item">
								<FaGreaterThan className="grater-than-route" />
							</li>
							<li className="route-heading item mx-2 mt-1">Edit Order</li>
						</ol>
					</div>

					<div className="tab-content mt-3">
						<form onSubmit={formik.handleSubmit}>
							<div
								className="tab-pane fade show active"
								id="user-add"
								role="tabpanel"
							>
								<div className="card">
									<div className="card-body">
										<div className="row bordered-row">
											{/* user id*/}
											<div className="col-lg-12 col-md-12 col-sm-12">
												<label>
													User Name:<span className="required">*</span>
												</label>

												<div className="form-group">
													<input
														id="user_name"
														name="user_name"
														type="user_name"
														className="form-control"
														placeholder="User Name"
														onChange={formik.handleChange}
														onBlur={formik.handleBlur}
														value={formik.values.user_name}
														readOnly
													/>
												</div>
											</div>
										</div>

										{/* Product Section*/}
										<div className="d-flex justify-content-between align-items-center ">
											<div>
												<p
													// className="card-title"
													style={{ fontWeight: '700', fontSize: '16px' }}
												>
													Product Details
												</p>
											</div>
											<div className="header-action">
												<Button
													type="button"
													className="btn btn-primary my-2"
													data-toggle="modal"
													data-target="#exampleModal"
													onClick={addProductOpenModal}
													style={{ backgroundColor: ' #004684' }}
												>
													<i className="fe fe-plus mr-2" />
													Add More Product
												</Button>
											</div>
										</div>

										{/* product listing detail map   */}
										<table class="table table-hover">
											<thead>
												<tr>
													<th scope="col">Product Name</th>
													<th scope="col">Quantity</th>
													<th scope="col">Amount</th>
													<th scope="col">Action</th>
												</tr>
											</thead>
											<tbody>
												{productDetail.map((item, index) => {
													return (
														<>
															<tr>
																{/* product */}

																<td>
																	<input
																		id="product_name"
																		name="product_name"
																		type="text"
																		className="form-control"
																		placeholder="product name"
																		onChange={formik.handleChange}
																		onBlur={formik.handleBlur}
																		value={item.product_name}
																		readOnly
																	/>
																	{formik.touched.product_name &&
																	formik.errors.product_name ? (
																		<div className={classes.invalidDataError}>
																			{formik.errors.product_name}
																		</div>
																	) : null}
																</td>
																{/* Product Quantity */}

																<td>
																	<input
																		id="quantity"
																		name="quantity"
																		type="text"
																		className="form-control"
																		placeholder="Quantity"
																		onChange={formik.handleChange}
																		onBlur={formik.handleBlur}
																		value={item.quantity}
																		readOnly
																	/>
																	{formik.touched.quantity &&
																	formik.errors.quantity ? (
																		<div className={classes.invalidDataError}>
																			{formik.errors.quantity}
																		</div>
																	) : null}
																</td>
																{/* Product  Amount */}

																<td>
																	<input
																		id="price"
																		name="price"
																		type="text"
																		className="form-control"
																		placeholder="Quantity"
																		onChange={formik.handleChange}
																		onBlur={formik.handleBlur}
																		value={item.price}
																		readOnly
																	/>
																	{formik.touched.price &&
																	formik.errors.price ? (
																		<div className={classes.invalidDataError}>
																			{formik.errors.price}
																		</div>
																	) : null}
																</td>

																{/* button */}
																<td>
																	<Button
																		className="Edit-btn-none bg-none"
																		onClick={() => modalOpenEdit(item)}
																		type="button"
																		style={{
																			border: 'none',
																			color: 'green',
																			fontSize: '15px',
																		}}
																	>
																		Edit
																	</Button>
																</td>
															</tr>
														</>
													);
												})}
											</tbody>
										</table>

										{/* Add Product Modal code */}
										{addProductModal ? (
											<Dialog
												header="Add Product"
												visible={addProductModal}
												onHide={() => setAddProductModal(false)}
												style={{ width: '50vw' }}
												breakpoints={{ '960px': '75vw', '641px': '100vw' }}
											>
												<div className="row ">
													{/* product */}
													<div className="col-lg-12 col-md-12 col-sm-12">
														<label>
															Product Name:
															<span className="required">*</span>
														</label>

														<div className="form-group">
															<select
																className="form-control show-tick"
																id="product_id"
																name="product_id"
																onChange={(event) =>
																	addProductChangeFunction(event)
																}
															>
																<option>Select Product</option>
																{productList.length > 0 &&
																	productList.map((product, key) => (
																		<option
																			key={key + product.name}
																			value={product.id}
																		>
																			{product.tally_name}
																		</option>
																	))}
															</select>
															{addProductError ? (
																<p className={classes.invalidDataError}>
																	{addProductError.product_id}
																</p>
															) : (
																''
															)}
														</div>
													</div>

													{/* Product Quantity */}
													<div className="col-lg-12 col-md-12 col-sm-12">
														<label>
															Quantity:<span className="required">*</span>
														</label>

														<div className="form-group">
															<input
																id="quantity"
																name="quantity"
																type="text"
																className="form-control"
																placeholder="Quantity"
																onChange={(event) =>
																	addProductQuantityChange(event)
																}
																ref={inputRef}
																autoComplete="off" //disable suggestion
															/>
															{addProductError ? (
																<p className={classes.invalidDataError}>
																	{addProductError.quantity}
																</p>
															) : (
																''
															)}
														</div>
													</div>

													{/* Product  Amount */}
													<div className="col-lg-12 col-md-12 col-sm-12">
														<label>
															Amount:<span className="required">*</span>
														</label>

														<div className="form-group">
															<input
																id="price"
																name="price"
																type="text"
																className="form-control"
																placeholder="Amount"
																onChange={(event) =>
																	addProductChangeFunction(event)
																}
																value={addProductValue.price}
																readOnly
															/>
															{addProductError ? (
																<p className={classes.invalidDataError}>
																	{addProductError.price}
																</p>
															) : (
																''
															)}
														</div>
													</div>
												</div>

												{/* button */}
												<div className="submit-btn">
													<button
														type="button"
														class="btn btn-secondary mx-1"
														data-dismiss="modal"
														onClick={addProductCloseModal}
													>
														Close
													</button>
													<button
														type="submit"
														class="btn btn-primary"
														onClick={addProductSubmit}
													>
														Save Product
													</button>
												</div>
											</Dialog>
										) : (
											''
										)}
										{/* Edit Product Modal Code */}
										{editModal ? (
											<Dialog
												header="Edit Product"
												visible={editModal}
												onHide={() => setEditModal(false)}
												style={{ width: '50vw' }}
												breakpoints={{ '960px': '75vw', '641px': '100vw' }}
											>
												<div className="row ">
													{/* product */}
													<div className="col-lg-12 col-md-12 col-sm-12">
														<label>
															Product Name:
															<span className="required">*</span>
														</label>

														<div className="form-group">
															<select
																className="form-control show-tick"
																id="product_id"
																name="product_id"
																onChange={(event) => editProductONChange(event)}
																value={defaultProductEdit.product_id}
															>
																{productList.length > 0 &&
																	productList.map((role, key) => (
																		<option
																			key={key + role.name}
																			value={role.id}
																		>
																			{role.tally_name}
																		</option>
																	))}
															</select>
															{editProductError ? (
																<p className={classes.invalidDataError}>
																	{editProductError.product_id}
																</p>
															) : (
																''
															)}
														</div>
													</div>

													{/* Product Quantity */}
													<div className="col-lg-12 col-md-12 col-sm-12">
														<label>
															Quantity:<span className="required">*</span>
														</label>

														<div className="form-group">
															<input
																id="quantity"
																name="quantity"
																type="text"
																className="form-control"
																placeholder="Quantity"
																onChange={(event) =>
																	editProductQuantityONChange(event)
																}
																value={defaultProductEdit.quantity}
															/>
															{editProductError ? (
																<p className={classes.invalidDataError}>
																	{editProductError.quantity}
																</p>
															) : (
																''
															)}
														</div>
													</div>

													{/* Product  Amount */}
													<div className="col-lg-12 col-md-12 col-sm-12">
														<label>
															Amount:<span className="required">*</span>
														</label>

														<div className="form-group">
															<input
																id="price"
																name="price"
																type="text"
																className="form-control"
																placeholder="Amount"
																onChange={(event) => editProductONChange(event)}
																value={defaultProductEdit.price}
																autoComplete="off" //disable suggestion
																readOnly
															/>
															{editProductError ? (
																<p className={classes.invalidDataError}>
																	{editProductError.price}
																</p>
															) : (
																''
															)}
														</div>
													</div>
												</div>
												{/* button */}
												<div className="submit-btn">
													<button
														type="button"
														class="btn btn-secondary mx-1"
														data-dismiss="modal"
														onClick={closeEditModal}
													>
														Close
													</button>
													<button
														type="button"
														class="btn btn-primary"
														onClick={editValueSubmit}
													>
														Update
													</button>
												</div>
											</Dialog>
										) : (
											''
										)}

										{/*Billing Section*/}
										<div className="col-lg-12 col-md-12 col-sm-12 ">
											<p
												className="my-3"
												style={{ fontWeight: '700', fontSize: '16px' }}
											>
												Billing Address
											</p>
										</div>
										{/* billing section fields */}
										<div className="row bordered-row">
											{/* Bill Name*/}
											<div className="col-lg-6 col-md-6 col-sm-12">
												<label>
													Name:<span className="required">*</span>
												</label>

												<div className="form-group">
													<input
														id="bill_name"
														name="bill_name"
														type="text"
														className="form-control"
														placeholder="Bill Name"
														onChange={formik.handleChange}
														onBlur={formik.handleBlur}
														value={formik.values.bill_name}
													/>
													{formik.touched.bill_name &&
													formik.errors.bill_name ? (
														<div className={classes.invalidDataError}>
															{formik.errors.bill_name}
														</div>
													) : null}
												</div>
											</div>

											{/* Bill  Mobile*/}
											<div className="col-lg-6 col-md-6 col-sm-12">
												<label>
													Mobile:<span className="required">*</span>
												</label>

												<div className="form-group">
													<input
														id="bill_mobile"
														name="bill_mobile"
														type="number"
														className="form-control"
														placeholder="Bill  Mobile"
														onChange={formik.handleChange}
														onBlur={formik.handleBlur}
														value={formik.values.bill_mobile}
													/>
													{formik.touched.bill_mobile &&
													formik.errors.bill_mobile ? (
														<div className={classes.invalidDataError}>
															{formik.errors.bill_mobile}
														</div>
													) : null}
												</div>
											</div>

											{/* Bill  Address*/}
											<div className="col-lg-6 col-md-6 col-sm-12">
												<label>
													Address:<span className="required">*</span>
												</label>

												<div className="form-group">
													<input
														id="bill_address"
														name="bill_address"
														type="text"
														className="form-control"
														placeholder="Bill Address"
														onChange={formik.handleChange}
														onBlur={formik.handleBlur}
														value={formik.values.bill_address}
													/>
													{formik.touched.bill_address &&
													formik.errors.bill_address ? (
														<div className={classes.invalidDataError}>
															{formik.errors.bill_address}
														</div>
													) : null}
												</div>
											</div>

											{/* Bill pincode*/}
											<div className="col-lg-6 col-md-6 col-sm-12">
												<label>
													Pincode:<span className="required">*</span>
												</label>

												<div className="form-group">
													<input
														id="bill_pincode"
														name="bill_pincode"
														type="number"
														className="form-control"
														placeholder="Bill  Pincode"
														onChange={formik.handleChange}
														onBlur={formik.handleBlur}
														value={formik.values.bill_pincode}
													/>
													{formik.touched.bill_pincode &&
													formik.errors.bill_pincode ? (
														<div className={classes.invalidDataError}>
															{formik.errors.bill_pincode}
														</div>
													) : null}
												</div>
											</div>

											{/* bill_state*/}
											<div className="col-lg-6 col-md-6 col-sm-12">
												<label>
													State:<span className="required">*</span>
												</label>

												<div className="form-group">
													<input
														id="bill_state"
														name="bill_state"
														type="text"
														className="form-control"
														placeholder="Bill State"
														onChange={formik.handleChange}
														onBlur={formik.handleBlur}
														value={formik.values.bill_state}
													/>
													{formik.touched.bill_state &&
													formik.errors.bill_state ? (
														<div className={classes.invalidDataError}>
															{formik.errors.bill_state}
														</div>
													) : null}
												</div>
											</div>

											{/*  bill city*/}
											<div className="col-lg-6 col-md-6 col-sm-12">
												<label>
													City:<span className="required">*</span>
												</label>

												<div className="form-group">
													<input
														id="bill_city"
														name="bill_city"
														type="text"
														className="form-control"
														placeholder="Bill  City"
														onChange={formik.handleChange}
														onBlur={formik.handleBlur}
														value={formik.values.bill_city}
													/>
													{formik.touched.bill_city &&
													formik.errors.bill_city ? (
														<div className={classes.invalidDataError}>
															{formik.errors.bill_city}
														</div>
													) : null}
												</div>
											</div>
										</div>

										{/*Shiipping Section*/}
										<div className="col-lg-12 col-md-12 col-sm-12 ">
											<p
												className="my-3"
												style={{ fontWeight: '700', fontSize: '16px' }}
											>
												Shipping Address
											</p>
										</div>

										{/* shipping section fields */}
										<div className="row bordered-row">
											{/*  shipping name*/}
											<div className="col-lg-6 col-md-6 col-sm-12">
												<label>
													Name:<span className="required">*</span>
												</label>

												<div className="form-group">
													<input
														id="ship_name"
														name="ship_name"
														type="text"
														className="form-control"
														placeholder="Shipping Name"
														onChange={formik.handleChange}
														onBlur={formik.handleBlur}
														value={formik.values.ship_name}
													/>
													{formik.touched.ship_name &&
													formik.errors.ship_name ? (
														<div className={classes.invalidDataError}>
															{formik.errors.ship_name}
														</div>
													) : null}
												</div>
											</div>

											{/*  shipping  mobile*/}
											<div className="col-lg-6 col-md-6 col-sm-12">
												<label>
													Mobile:<span className="required">*</span>
												</label>

												<div className="form-group">
													<input
														id="ship_mobile"
														name="ship_mobile"
														type="number"
														className="form-control"
														placeholder="Shipping  Mobile"
														onChange={formik.handleChange}
														onBlur={formik.handleBlur}
														value={formik.values.ship_mobile}
													/>
													{formik.touched.ship_mobile &&
													formik.errors.ship_mobile ? (
														<div className={classes.invalidDataError}>
															{formik.errors.ship_mobile}
														</div>
													) : null}
												</div>
											</div>

											{/*  shipping address*/}
											<div className="col-lg-6 col-md-6 col-sm-12">
												<label>
													Address:<span className="required">*</span>
												</label>

												<div className="form-group">
													<input
														id="ship_address"
														name="ship_address"
														type="text"
														className="form-control"
														placeholder="Shipping Address"
														onChange={formik.handleChange}
														onBlur={formik.handleBlur}
														value={formik.values.ship_address}
													/>
													{formik.touched.ship_address &&
													formik.errors.ship_address ? (
														<div className={classes.invalidDataError}>
															{formik.errors.ship_address}
														</div>
													) : null}
												</div>
											</div>

											{/*  shipping  pincode*/}
											<div className="col-lg-6 col-md-6 col-sm-12">
												<label>
													Pincode:<span className="required">*</span>
												</label>

												<div className="form-group">
													<input
														id="ship_pincode"
														name="ship_pincode"
														type="number"
														className="form-control"
														placeholder="Shipping  Pincode"
														onChange={formik.handleChange}
														onBlur={formik.handleBlur}
														value={formik.values.ship_pincode}
													/>
													{formik.touched.ship_pincode &&
													formik.errors.ship_pincode ? (
														<div className={classes.invalidDataError}>
															{formik.errors.ship_pincode}
														</div>
													) : null}
												</div>
											</div>

											{/*  shipping   State*/}
											<div className="col-lg-6 col-md-6 col-sm-12">
												<label>
													State:<span className="required">*</span>
												</label>

												<div className="form-group">
													<input
														id="ship_state"
														name="ship_state"
														type="text"
														className="form-control"
														placeholder="Shipping   State"
														onChange={formik.handleChange}
														onBlur={formik.handleBlur}
														value={formik.values.ship_state}
													/>
													{formik.touched.ship_state &&
													formik.errors.ship_state ? (
														<div className={classes.invalidDataError}>
															{formik.errors.ship_state}
														</div>
													) : null}
												</div>
											</div>

											{/*  shipping    city*/}
											<div className="col-lg-6 col-md-6 col-sm-12">
												<label>
													City:<span className="required">*</span>
												</label>

												<div className="form-group">
													<input
														id="ship_city"
														name="ship_city"
														type="text"
														className="form-control"
														placeholder="Shipping City"
														onChange={formik.handleChange}
														onBlur={formik.handleBlur}
														value={formik.values.ship_city}
													/>
													{formik.touched.ship_city &&
													formik.errors.ship_city ? (
														<div className={classes.invalidDataError}>
															{formik.errors.ship_city}
														</div>
													) : null}
												</div>
											</div>
											{/* shipping amount*/}
											<div className="col-lg-6 col-md-6 col-sm-12">
												<label>
													Amount:<span className="required">*</span>
												</label>

												<div className="form-group">
													<input
														id="shipping_amount"
														name="shipping_amount"
														type="number"
														className="form-control"
														placeholder="Shipping Amount"
														onChange={formik.handleChange}
														onBlur={formik.handleBlur}
														value={formik.values.shipping_amount}
													/>
													{formik.touched.shipping_amount &&
													formik.errors.shipping_amount ? (
														<div className={classes.invalidDataError}>
															{formik.errors.shipping_amount}
														</div>
													) : null}
												</div>
											</div>
										</div>

										{/* Order section*/}
										<div className="col-lg-12 col-md-12 col-sm-12 ">
											<p
												className="my-3"
												style={{ fontWeight: '700', fontSize: '16px' }}
											>
												Payment
											</p>
										</div>

										{/* order section fields */}
										<div className="row ered-row">
											{/*    Payment Mode*/}
											<div className="col-lg-6 col-md-6 col-sm-12">
												<label>
													Payment Mode:<span className="required">*</span>
												</label>

												<div className="form-group">
													<input
														id="payment_mode"
														name="payment_mode"
														type="text"
														className="form-control"
														placeholder="Payment Mode"
														onChange={formik.handleChange}
														onBlur={formik.handleBlur}
														value={formik.values.payment_mode}
													/>
													{formik.touched.payment_mode &&
													formik.errors.payment_mode ? (
														<div className={classes.invalidDataError}>
															{formik.errors.payment_mode}
														</div>
													) : null}
												</div>
											</div>

											{/*    Payment  Status*/}
											<div className="col-lg-6 col-md-6 col-sm-12">
												<label>
													Payment Status:<span className="required">*</span>
												</label>

												<div className="form-group">
													<input
														id="payment_status"
														name="payment_status"
														type="text"
														className="form-control"
														placeholder="Payment Status"
														onChange={formik.handleChange}
														onBlur={formik.handleBlur}
														value={formik.values.payment_status}
													/>
													{formik.touched.payment_status &&
													formik.errors.payment_status ? (
														<div className={classes.invalidDataError}>
															{formik.errors.payment_status}
														</div>
													) : null}
												</div>
											</div>
											{/* order status */}
											<div className="col-lg-6 col-md-6 col-sm-12">
												<label>
													Order Status:<span className="required">*</span>
												</label>

												<div className="form-group">
													<input
														id="order_status"
														name="order_status"
														type="text"
														className="form-control"
														placeholder="Order Status"
														onChange={formik.handleChange}
														onBlur={formik.handleBlur}
														value={formik.values.order_status}
													/>
													{formik.touched.order_status &&
													formik.errors.order_status ? (
														<div className={classes.invalidDataError}>
															{formik.errors.order_status}
														</div>
													) : null}
												</div>
											</div>

											{/* Coupon id */}
											<div className="col-lg-6 col-md-6 col-sm-12">
												<label>
													Coupon:<span className="required">*</span>
												</label>

												<div className="form-group">
													<input
														id="coupon_id"
														name="coupon_id"
														type="text"
														className="form-control"
														placeholder="Coupon Id"
														onChange={formik.handleChange}
														onBlur={formik.handleBlur}
														value={formik.values.coupon_id}
													/>
													{formik.touched.coupon_id &&
													formik.errors.coupon_id ? (
														<div className={classes.invalidDataError}>
															{formik.errors.coupon_id}
														</div>
													) : null}
												</div>
											</div>

											{/* GST Number */}
											<div className="col-lg-6 col-md-6 col-sm-12">
												<label>
													GST Number :<span className="required">*</span>
												</label>

												<div className="form-group">
													<input
														id="gst_no"
														name="gst_no"
														type="text"
														className="form-control"
														placeholder="GST Number"
														onChange={formik.handleChange}
														onBlur={formik.handleBlur}
														value={formik.values.gst_no}
													/>
													{formik.touched.gst_no && formik.errors.gst_no ? (
														<div className={classes.invalidDataError}>
															{formik.errors.gst_no}
														</div>
													) : null}
												</div>
											</div>

											{/* order amount*/}
											<div className="col-lg-6 col-md-6 col-sm-12">
												<label>
													Order Amount:<span className="required">*</span>
												</label>

												<div className="form-group">
													<input
														id="order_amount"
														name="order_amount"
														type="number"
														className="form-control"
														placeholder="Order Amount"
														onChange={formik.handleChange}
														onBlur={formik.handleBlur}
														value={formik.values.order_amount}
													/>
													{formik.touched.order_amount &&
													formik.errors.order_amount ? (
														<div className={classes.invalidDataError}>
															{formik.errors.order_amount}
														</div>
													) : null}
												</div>
											</div>

											{/* coupon_discount */}
											<div className="col-lg-6 col-md-6 col-sm-12">
												<label>
													Coupon Discount :<span className="required">*</span>
												</label>

												<div className="form-group">
													<input
														id="coupon_discount"
														name="coupon_discount"
														type="number"
														className="form-control"
														placeholder="Coupon Discount"
														onChange={formik.handleChange}
														onBlur={formik.handleBlur}
														value={formik.values.coupon_discount}
													/>
													{formik.touched.coupon_discount &&
													formik.errors.coupon_discount ? (
														<div className={classes.invalidDataError}>
															{formik.errors.coupon_discount}
														</div>
													) : null}
												</div>
											</div>

											{/*  Discount amount*/}
											<div className="col-lg-6 col-md-6 col-sm-12">
												<label>
													Total Discount Amount:
													<span className="required">*</span>
												</label>

												<div className="form-group">
													<input
														id="discount_amount"
														name="discount_amount"
														type="number"
														className="form-control"
														placeholder="Discount Amount"
														onChange={formik.handleChange}
														onBlur={formik.handleBlur}
														value={formik.values.discount_amount}
													/>
													{formik.touched.discount_amount &&
													formik.errors.discount_amount ? (
														<div className={classes.invalidDataError}>
															{formik.errors.discount_amount}
														</div>
													) : null}
												</div>
											</div>

											{/*   Total amount*/}
											<div className="col-lg-6 col-md-6 col-sm-12">
												<label>
													Total Amount:<span className="required">*</span>
												</label>

												<div className="form-group">
													<input
														id="total_amount"
														name="total_amount"
														type="number"
														className="form-control"
														placeholder="Total Amount"
														onChange={formik.handleChange}
														onBlur={formik.handleBlur}
														value={formik.values.total_amount}
													/>
													{formik.touched.total_amount &&
													formik.errors.total_amount ? (
														<div className={classes.invalidDataError}>
															{formik.errors.total_amount}
														</div>
													) : null}
												</div>
											</div>
										</div>

										{/* update and close button */}
										<div className="col-12 submit-btn">
											<hr className="mt-4" />
											<div className="table-responsive">
												<table className="table table-striped"></table>
											</div>
											<button type="submit" className="btn btn-primary">
												Update
											</button>
											<button
												type="button"
												className="btn btn-secondary ml-2"
												data-dismiss="modal"
												onClick={() => navigate('/admin/Ecom-orders')}
											>
												Close
											</button>
										</div>
									</div>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
		</>
	);
};

export default EditOrders;
