import { Formik, useFormik } from 'formik';
import { Calendar } from 'primereact/calendar';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { showErrorToast, showSuccessToast } from '../../../Toster';
import axiosPrivate from '../../../hooks/axiosPrivate';

const blogTemplateValidation = Yup.object().shape({
    pagename: Yup.string().required('Page Name is Required'),
    slug: Yup.string().required('Slug is Required'),
    bannerImage: Yup.mixed().required('Banner Image is Required'),
});

const EditBlogTemplate = () => {
    const { id } = useParams();
    const [getData, setGetData] = useState([]);

    useEffect(() => {
        if (id) {
            axiosPrivate.get('pages/' + id)
                .then((res) => {
                    if (res.data.code == 200) {
                        setGetData({ ...res.data.data, name: JSON.parse(res?.data?.data?.page_json).pagename, slug: JSON.parse(res?.data?.data?.page_json).slug, image: JSON.parse(res?.data?.data?.page_json).bannerImage });
                    }

                })
        }
    }, []);

    useEffect(()=>{
      if(getData != ''){
        setFieldValue({...getData});
      }
    },[]);

    const initialValues = {
        pagename: '',
        slug: '',
        bannerImage: '',
    }

    const navigate = useNavigate();
    const {
        values,
        errors,
        touched,
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldValue,
        setValues
    } = useFormik({
        initialValues: initialValues,
        validationSchema: blogTemplateValidation,
        onSubmit: (values, { setFieldError }) => {
            var formData = new FormData();
			formData.append("pagename", values.pagename);
			formData.append("slug", values.slug);
			formData.append("bannerImage", values.bannerImage);
            // let formData = {
            //     name: values.pagename,
            //     slug: values.slug,
            //     page_json: JSON.stringify(values),
            //     template_type: 'blog',
            // };
            axiosPrivate
                .put(`blogs/update/${id}`, formData)
                .then((res) => {
                    showSuccessToast('Updation successful');
                    navigate('/admin/blog-listing');
                })
                .catch((error) => {
                    if (typeof error.response.data.errors == 'array') {
                        showErrorToast(error.response.data.errors[0].msg);
                    } else {
                        showErrorToast(error.response.data.errors.msg);
                    }
                });
        },
    });

    const onPageNameBlur = () => {

    }

    const onFileUpload = () => {

    }

    return (
        <>
            <div>
                <div className="section-body mt-3 website-template">
                    <div className="container-fluid">
                        <div className="tab-content mt-3">
                            <form onSubmit={handleSubmit}>
                                <div
                                    className="tab-pane fade show active"
                                    id="home-template"
                                    role="tabpanel"
                                >
                                    <div className="card">
                                        <div className="card-header">
                                            <strong style={{ fontWeight: '700', fontSize: '18px' }}>
                                                Edit Blog Template
                                            </strong>
                                        </div>
                                        <div className="card-body">
                                            <div className="row bordered-row">
                                                {/*  Tax Name */}
                                                <div className="col-lg-6 col-md-6 col-sm-12">
                                                    <label>
                                                        Page Name:<span className="required">*</span>
                                                    </label>
                                                    <div className="form-group">
                                                        <input
                                                            id="pagename"
                                                            name="pagename"
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="Page Name"
                                                            onChange={handleChange}
                                                            onBlur={onPageNameBlur}
                                                            value={values.pagename}
                                                        />
                                                        {touched.pagename && errors.pagename ? (
                                                            <>
                                                            <div className="invalidDataError">
                                                                {errors.pagename}
                                                            </div>
                                                            {showErrorToast(errors.pagename)}
															</>
                                                        ) : null}
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-md-6 col-sm-12">
                                                    <label>
                                                        Slug:<span className="required">*</span>
                                                    </label>
                                                    <div className="form-group">
                                                        <input
                                                            id="slug"
                                                            name="slug"
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="Page Slug"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            value={values.slug}
                                                        />
                                                        {touched.slug && errors.slug ? (
                                                            <div className="invalidDataError">
                                                                {errors.slug}
                                                            </div>
                                                        ) : null}


                                                        
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-md-6 col-sm-12">
                                                    <label>
                                                        Banner Image:<span className="required">*</span>
                                                    </label>
                                                    <div className="form-group">
                                                        <input
                                                            id="bannerImage"
                                                            name="bannerImage"
                                                            type="file"
                                                            className="form-control"
                                                            onChange={onFileUpload}
                                                            onBlur={handleBlur}
                                                        // value={values.bannerImage}
                                                        />
                                                        {touched.bannerImage && errors.bannerImage ? (
                                                            <>
                                                            <div className="invalidDataError">
                                                                {errors.bannerImage}
                                                            </div>
                                                            {showErrorToast(errors.bannerImage)}
                                                            </>
                                                        ) : null}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default EditBlogTemplate;