import { useFormik } from "formik";
import React, { useContext, useEffect, useState } from "react";
import { FaGreaterThan } from "react-icons/fa";
// import { useNavigate } from "react-router";
import { Link, useParams, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { showErrorToast, showSuccessToast } from "../../../Toster";
import AlertContext from "../../../context/Alert/AlertContext";
import axiosPrivate from "../../../hooks/axiosPrivate";
import { Calendar } from "primereact/calendar";

const aboutTemplateValidation = Yup.object({
  pageName: Yup.string().required("Page name is required"),
  slug: Yup.string().required("Slug is required"),
});

const AddNewsEventsTemplate = () => {
  const { id } = useParams();
  const { setAlertMessage } = useContext(AlertContext);
  const [showLoader, setShowLoader] = useState(false);
  const [image, setImage] = useState("");
  const [submitButton, setSubmitButton] = useState("Submit");
  const [date, setDate] = useState(null);
  const navigate = useNavigate();

  let initialValues = {
    pageName: "",
    banner_image: "",
    slug: "",
    news_events_section: [
      {
        img: "",
        event_heading: "",
        event_sub_heading: "",
        date: "",
        event_content: "",
      },
    ],
    certifiedImgs: [{ certifiedImage: "" }],
  };

  const setSlug = (e) => {
    const { name, value } = e.target;
    if (name == "pageName") {
      let slug = e.target.value;
      setFieldValue(
        "slug",
        slug
          .toLowerCase()
          .trim()
          .replace(/[^\w\s-]/g, "")
          .replace(/[\s_-]+/g, "-")
          .replace(/^-+|-+$/g, "")
      );
    }

    handleBlur(e);
  };

  const onFileUpload = (event) => {
    setShowLoader(true);
    let formData = new FormData();
    formData.append("image", event.target.files[0]);
    axiosPrivate
      .post("pages/file-upload", formData)
      .then((res) => {
        setShowLoader(false);
        setFieldValue(event.target.name, res.data.data);
      })
      .catch((error) => {
        setFieldValue(event.target.name, "");
        setShowLoader(false);
      });
    handleChange(event);
  };

  const onAddRow = (name, check) => {
    if (name == "news_events_section") {
      if (check) {
        let newObj = {
          img: "",
          event_heading: "",
          event_sub_heading: "",
          date: "",
          event_content: "",
        };
        let rowsData = values.news_events_section;
        if (rowsData?.length < 4) {
          rowsData.push(newObj);
          setFieldValue("news_events_section", rowsData);
        }
      } else {
        let rowsData = values.news_events_section;
        if (rowsData?.length > 1) {
          rowsData?.splice(rowsData?.length - 1, 1);
          setFieldValue("news_events_section", rowsData);
        }
      }
    }
  };

  const {
    values,
    errors,
    touched,
    handleSubmit,
    handleChange,
    handleBlur,
    setFieldValue,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: aboutTemplateValidation,
    onSubmit: (values, { setFieldError }) => {
      let formData = {
        name: values.pageName,
        slug: values.slug,
        page_json: JSON.stringify(values),
        template_type: "news-events",
      };
      if (id) {
        axiosPrivate
          .put("pages/update/" + id, formData)
          .then((res) => {
            showSuccessToast("Updation successful");
            navigate("/admin/news-events-template");
          })
          .catch((error) => {
            if (typeof error?.response?.data?.errors == "array") {
              showErrorToast(error?.response?.data?.errors[0]?.msg);
            } else {
              showErrorToast(error?.response?.data?.errors?.msg);
            }
          });
      } else {
        axiosPrivate
          .post("pages/create", formData)
          .then((res) => {
            showSuccessToast("Successfully created");
            navigate("/admin/news-events-template");
          })
          .catch((error) => {
            if (typeof error.response.data.errors == "array") {
              showErrorToast(error?.response?.data?.errors[0].msg);
            } else {
              showErrorToast(error?.response?.data?.errors.msg);
            }
          });
      }
    },
  });

  useEffect(() => {
    if (id) {
      axiosPrivate
        .get("pages/" + id)
        .then((res) => {
          const parsedValues = JSON.parse(res.data?.data?.page_json);
          Object.keys(parsedValues).forEach((key) => {
            setFieldValue(key, parsedValues[key]);
          });

          setSubmitButton("Update");
        })

        .catch((error) => {
          if (typeof error.response.data.errors == "array") {
            showErrorToast(error?.response?.data?.errors[0].msg);
          } else {
            showErrorToast(error?.response?.data?.errors?.msg);
          }
        });
    }
  }, []);

  const nameFilter = (event) => {
	const inputValue = event.target.value;
	if (/^[A-Za-z\s'-]+$/.test(inputValue) || inputValue === "") {
	  setFieldValue(event.target.name, inputValue);
	}
  };

  return (
    <>
      {showLoader ? <div className="loading">Loading&#8230;</div> : <div></div>}
      <div className="section-body website-template">
        <div className="container-fluid">
          {/* hearder breadcrumb */}
          <div className="card-header">
            {id ? (
              <>
                <strong className="top-left-heading">
                  Edit News & Events Template
                </strong>

                <ol className="headerroutes breadcrumb m-0 bg-none ">
                  <li className="item color-gray">|</li>
                  <li className="item">
                    <Link
                      to="/admin/dashboard"
                      className="route-heading item mx-2"
                    >
                      Dashboard
                    </Link>
                  </li>
                  <li className="item">
                    <FaGreaterThan className="grater-than-route" />
                  </li>
                  <li className="route-heading item mx-2 mt-1">
                    <Link to="/admin/news-events-template">
                      {" "}
                      News & Events Template{" "}
                    </Link>
                  </li>

                  <li className="item">
                    <FaGreaterThan className="grater-than-route" />
                  </li>
                  <li className="route-heading item mx-2 mt-1">
                    Edit News & Events Template{" "}
                  </li>
                </ol>
              </>
            ) : (
              <>
                <strong className="top-left-heading">
                  Add News & Events Template
                </strong>

                <ol className="headerroutes breadcrumb m-0 bg-none ">
                  <li className="item color-gray">|</li>
                  <li className="item">
                    <Link
                      to="/admin/dashboard"
                      className="route-heading item mx-2"
                    >
                      Dashboard
                    </Link>
                  </li>
                  <li className="item">
                    <FaGreaterThan className="grater-than-route" />
                  </li>
                  <li className="route-heading item mx-2 mt-1">
                    <Link to="/admin/news-events-template">
                      {" "}
                      News & Events Template{" "}
                    </Link>
                  </li>

                  <li className="item">
                    <FaGreaterThan className="grater-than-route" />
                  </li>
                  <li className="route-heading item mx-2 mt-1">
                    Add News & Events Template{" "}
                  </li>
                </ol>
              </>
            )}
          </div>

          <div className="tab-content">
            <form onSubmit={handleSubmit}>
              <div
                className="tab-pane fade show active"
                id="home-template"
                role="tabpanel"
              >
                <div className="card">
                  <div className="card-body">
                    <div className="row bordered-row">
                      {/*  Page Name */}
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <label>
                          Page Name:<span className="required">*</span>
                        </label>
                        <div className="form-group">
                          <input
                            id="pageName"
                            name="pageName"
                            type="text"
                            className="form-control"
                            placeholder="Page Name"
                            onChange={nameFilter}
                            onBlur={setSlug}
                            value={values.pageName}
                          />

                          {touched.pageName && errors.pageName ? (
                            <>
                              <div className="invalidDataError">
                                {errors.pageName}
                              </div>
                              {showErrorToast(errors.toastError)}
                            </>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <label>
                          Slug:<span className="required">*</span>
                        </label>
                        <div className="form-group">
                          <input
                            // readOnly
                            id="slug"
                            name="slug"
                            value={values.slug}
                            className="form-control"
							placeholder="Slug"
							onChange={handleChange}
							onBlur={handleBlur}
                          />
                          {touched.slug && errors.slug ? (
                            <div className="invalidDataError">
                              {errors.slug}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <label>
                          Banner Image:
                        </label>
                        <div className="form-group">
                          <input
                            id="banner_image"
                            name="banner_image"
                            type="file"
                            className="form-control"
                            onChange={onFileUpload}
                            onBlur={handleBlur}
                          />

                          {typeof values.banner_image == "string" &&
                          values.banner_image != "" ? (
                            <img
                              style={{ width: "100px", height: "100px" }}
                              className="mt-2"
                              src={values.banner_image}
                            />
                          ) : (
                            ""
                          )}
                          {touched.banner_image && errors.banner_image ? (
                            <div className="invalidDataError">
                              {errors.banner_image}
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>

                    <h6>News & Event Section</h6>
                    <div className="row bordered-row">
                      <div className="col-lg-12 mt-3">
                        {values.news_events_section.map((res, index) => (
                          <>
                            <div className="row mt-2" key={index}>
                              <div className="col-lg-6 col-md-6 col-sm-12">
                                <label>Event Heading :</label>
                                <div className="form-group">
                                  <input
                                    className="form-control"
                                    name={`news_events_section[${index}].event_heading`}
                                    id={`news_events_section[${index}].event_heading`}
                                    placeholder="Event Heading"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={
                                      values.news_events_section[index]
                                        .event_heading
                                    }
                                  />
                                </div>
                              </div>
                              <div className="col-lg-6 col-md-6 col-sm-12">
                                <label>Event Sub Heading :</label>
                                <div className="form-group">
                                  <input
                                    className="form-control"
                                    name={`news_events_section[${index}].event_sub_heading`}
                                    id={`news_events_section[${index}].event_sub_heading`}
                                    placeholder="Event Heading"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={
                                      values.news_events_section[index]
                                        .event_sub_heading
                                    }
                                  />
                                </div>
                              </div>
                              <div className="col-lg-6 col-md-6 col-sm-12">
                                <label>Image:</label>
                                <div className="form-group">
                                  <input
                                    id={`news_events_section.[${index}].img`}
                                    name={`news_events_section.[${index}].img`}
                                    type="file"
                                    className="form-control"
                                    onChange={onFileUpload}
                                    onBlur={handleBlur}
                                  />
                                  {typeof values.news_events_section[index]
                                    .img == "string" &&
                                  values.news_events_section[index].img !=
                                    "" ? (
                                    <img
                                      style={{
                                        width: "100px",
                                        height: "100px",
                                      }}
                                      className="mt-2"
                                      src={
                                        values.news_events_section[index].img
                                      }
                                    />
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                              <div className="col-lg-6 col-md-6 col-sm-12">
                                <label>Event Date:</label>
                                <div className="form-group">
                                  <Calendar
                                    dateFormat="dd/M/yy"
                                    value={
                                      values.news_events_section[index].date
                                    }
                                    minDate={new Date()}
                                    id={`news_events_section[${index}].date`}
                                    name={`news_events_section[${index}].date`}
                                    placeholder="Enter Event Date"
                                    onChange={(event) =>
                                      setFieldValue(
                                        `news_events_section[${index}].date`,
                                        event.target.value
                                      )
                                    }
                                    showIcon
                                  />
                                </div>
                              </div>
                              <div className="col-lg-6 col-md-6 col-sm-12">
                                <label>Event Content:</label>
                                <div className="form-group">
                                  <input
                                    className="form-control"
                                    id={`news_events_section[${index}].event_content`}
                                    name={`news_events_section[${index}].event_content`}
                                    placeholder="Event Content"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={
                                      values.news_events_section[index]
                                        .event_content
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="action_btn_section">
                              <button
                                type="button"
                                onClick={() => {
                                  onAddRow("news_events_section", true);
                                }}
                              >
                                +
                              </button>
                              <button
                                type="button"
                                onClick={() => {
                                  onAddRow("news_events_section", false);
                                }}
                              >
                                -
                              </button>
                            </div>
                          </>
                        ))}
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-12 submit-btn">
                        <button
                          type="button"
                          className="btn btn-secondary"
                          data-dismiss="modal"
                          onClick={() =>
                            navigate("/admin/news-events-template")
                          }
                        >
                          Close
                        </button>
                        <button type="submit" className="btn btn-primary ml-2">
                          {submitButton}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddNewsEventsTemplate;
