import * as Yup from 'yup';

export const addressSchema = Yup.object().shape({
	name: Yup.string().min(4).required('Please enter your name'),
	mobile: Yup.string().test('starts-not-with-0-to-5', 'Please enter a valid phone number', function(value) {
		// Check if the mobile number does not start from 0 to 5
		if (!value) return true; // If no value, let Yup.string().required handle it
		return !/^[0-5]/.test(value);
	  }).required("Phone number is required"),
	  email: Yup.string()
		.matches(
		  /^[\w.-]+@[a-zA-Z\d.-]+\.[a-zA-Z]{2,}$/,
		  "Please enter a valid Email!"
		)
		.required("Email is required"),

	shipping_address: Yup.string().required('Please enter Address Line 1'),
	shipping_city: Yup.string().required('Please enter your city'),
	shipping_zip: Yup.string()
		.matches(/^[0-9]{6}$/, 'Pincode is not valid')
		.required('Please enter your pincode'),
	shipping_state: Yup.string().required('Please select your state'),
	// district: Yup.string().required('Please enter your district'),
});
