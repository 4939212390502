import { Formik, useFormik } from "formik";
// import Multiselect from 'multiselect-react-dropdown';
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from "yup";
import AlertContext from "../../../context/Alert/AlertContext";
import axiosPrivate from "../../../hooks/axiosPrivate";
import classes from "./Rolepermission.module.css";

const validationRules = {
  // designation_name: Yup.string()
  // 	.max(120, 'Name must not exceed 120 characters')
  // 	.required('Name is required'),
  // department_id: Yup.string().required('Role is required'),
};

const EditRolerPermission = () => {
  let { id } = useParams();
  const { setAlertMessage } = useContext(AlertContext);
  const navigate = useNavigate();
  const [roles, setRoles] = useState([]);
  const [getData, setGetData] = useState({});
  const [initialValues, setInitialValues] = useState({
    // designation_name: '',
    // department_id: '',
  });
  const [selectData, setSelectData] = useState([]);
  const [sendPermission, setSendPermission] = useState([]);
  const [allPermissions, setallPermissions] = useState([]);
  const [multiSelect, setMultiSelect] = useState([]);

  const data = async () => {
    const empty = [];
    for (let i = 0; i < allPermissions.length; i++) {
      empty.push(allPermissions[i].permission_name);
    }
    // {roles.length >0 && roles.map((val) => {
    setSendPermission(empty);
    // })}
  };

  useEffect(() => {
    data();
  }, [allPermissions]);

  useEffect(() => {
    axiosPrivate
      .get("roles")
      .then((user_roles) => {
        setRoles(user_roles?.data?.data);
      })
      .catch((error) => {
      });
  }, []);

  useEffect(() => {
    axiosPrivate
      .get("permissions")
      .then((permissions) => {
        setallPermissions(permissions?.data?.data);
      })
      .catch((error) => {
      });
  }, []);

  useEffect(() => {
    axiosPrivate
      .get(`role-permissions/${id}`)
      .then((response) => {

        if (response.data.code == 200) {
          setGetData({ ...response.data.data });
          setMultiSelect(response.data.data.permission_name.split(","));
        }
      })
      .catch((error) => {
      });
  }, [roles]);

  useEffect(() => {
    if (getData != "") {
      formik.setValues({ ...getData });
    }
  }, [getData]);

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: Yup.object(validationRules),
    onSubmit: async (values, { setSubmitting, setFieldError }) => {
      var data = [];
      allPermissions.map((role) => {
        if (selectData.includes(role.permission_name)) {
          data.push(role.id);
        }
      });
      var values = {
        // role_id: values.id,
        permission_id: data,
      };

      axiosPrivate
        .put(`role-permissions/${id}`, values)
        .then((response) => {
          setAlertMessage({ message: "role permission updated successfully" });
          navigate("/admin/rolepermission");
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.status == 422) {

              for (let i = 0; i < error.response.data.errors.length; i++) {
                setFieldError(
                  error.response.data.errors[i].param,
                  error.response.data.errors[i].msg
                );
              }
            }
          }
        });
    },
  });

  return (
    <div className="section-body mt-3">
      <div className="container-fluid">
        <div className="tab-content mt-3">
          <form onSubmit={formik.handleSubmit}>
            <div
              className="tab-pane fade show active"
              id="user-add"
              role="tabpanel"
            >
              <div className="card">
                <div className="card-header">
                  <strong>Edit User Role Permission</strong>
                </div>
                <div className="card-body">
                  <div className="row clearfix">
                    <div className="col-lg-12 col-md-12 col-sm-12">
                      <div className="form-group">
                        <input
                          id="role_name"
                          name="role_name"
                          type="text"
                          className="form-control"
                          placeholder=" Role Name *"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.role_name}
                          readOnly
                        />
                        {formik.touched.role_name && formik.errors.role_name ? (
                          <div className={classes.invalidDataError}>
                            {formik.errors.role_name}
                          </div>
                        ) : null}
                      </div>
                    </div>

                    {/* select */}
                    {/* <div className="col-md-12 col-sm-12">
											<div className="form-group">
												<select
													className="form-control show-tick"
													id="role_name   "
													name="role_name"
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													value={formik.values.role_name}
                                                    required
                                                    
												>
													<option>Select Roles Type</option>
													{roles.map((role, key) => (
														<option key={key + role.role_name} value={role.id}>
															{role.name}
														</option>
													))}
												</select>
												{formik.touched.role_name && formik.errors.role_name ? (
													<div className={classes.invalidDataError}>
														{formik.errors.role_name}
													</div>
												) : null}
											</div>
										</div> */}

                    <div className="col-md-12 col-sm-12">
                      <div className="form-group">
                        {/* <Multiselect
													isObject={false}
													options={sendPermission}
													displayValue="name"
													showCheckbox
													onRemove={(eve) => {
													}}
													name="roles"
													value={roles}
													onSelect={(eve) => setSelectData(eve)}
													placeholder="Select Permission *"
                                                    selectedValues={multiSelect}
													required
												/> */}

                        {/* {formik.touched.department_id &&
												formik.errors.department_id ? (
													<div className={classes.invalidDataError}>
														{formik.errors.department_id}
													</div>
												) : null} */}
                      </div>
                    </div>

                    <div className="col-12">
                      <button type="submit" className="btn btn-primary">
                        Update
                      </button>
                      <button
                        type="button"
                        className="btn btn-secondary ml-2"
                        data-dismiss="modal"
                        onClick={() => navigate("/admin/rolepermission")}
                      >
                        CLOSE
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default EditRolerPermission;
