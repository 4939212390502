import React, { createContext, useState } from "react";
import LoaderContext from "./LoaderContext";

const LoaderState = (props) => {
  const [isLoading, setIsLoading] = useState(false);

  return (
    <LoaderContext.Provider value={{ isLoading, setIsLoading }}>
      {props.children}
    </LoaderContext.Provider>
  );
};

export default LoaderState;
