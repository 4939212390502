import { Fragment, useContext, useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import AuthContext from "../../Auth/AuthContext";
import Footer from "./Footer";
import Header from "./Header";
import SideBar from "./SideBar";
import LoaderContext from "../../loader/LoaderContext";
import LoaderComponent from "../../loader/LoaderState";

const AuthMain = (props) => {
  const navigate = useNavigate();
  const { user } = useContext(AuthContext);
  const { isLoading } = useContext(AuthContext);
  const login = localStorage.getItem("userData");

  useEffect(() => {
    // debugger;
    if (!login) {
      // navigate('/user-panel/signin');
    } else {
      // navigate('/');
      // navigate('/user-panel/signin');
      // const lastLocation = localStorage.getItem('lastLocation');
      // if(lastLocation) {
      //   localStorage.removeItem('lastLocation');
      //   navigate(lastLocation);
      // } else {
      //   navigate("/admin",{replace:true});
      // }
    }
  }, []);

  return (
    <Fragment>
      {isLoading ? <LoaderComponent /> : null}
      <div className="font-montserrat">
        <div className="auth">
          <Outlet />
        </div>
      </div>
    </Fragment>
  );
};

export default AuthMain;
