import { useContext, useEffect, useState } from "react";
import { BiEdit } from "react-icons/bi";
import { FaGreaterThan } from "react-icons/fa";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { showErrorToast } from "../../../Toster";
import AlertContext from "../../../context/Alert/AlertContext";
import DataTable2 from "../../../context/components/DataTable2";
import axiosPrivate from "../../../hooks/axiosPrivate";
import LoaderContext from "../../../context/loader/LoaderContext";

const Product = (props) => {
  const { setIsLoading } = useContext(LoaderContext);
  const { alertMessage, setAlertMessage } = useContext(AlertContext);
  const [productState, setProductState] = useState([]);

  const headers = [
    { name: "TALLY NAME", field: "tally_name", sortable: true, classKey: "" },
    {
      name: "GST",
      field: "gst_applicable",
      sortable: true,
      classKey: "w80",
    },
    { name: "Unit", field: "unit", sortable: true, classKey: "" },
    { name: "Weight", field: "weight", sortable: true, classKey: "" },
    {
      name: "Product in carton / box",
      field: "product_in_box",
      sortable: true,
      classKey: "w-cartoon",
    },
    { name: "Rate", field: "rate", sortable: true, classKey: "" },
    { name: "GST(%)", field: "igst_rate", sortable: true, classKey: "" },

    { name: "ACTION", field: "action", classKey: "" },
  ];

  const searchItems = ["tally_name", "unit", "weight", "rate", "igst_rate", "gst_applicable",  "product_in_box" ];

  // all product list
  useEffect(() => {
    setIsLoading(true);
    const options = {
      headers: { "content-type": "application/json" },
    };
    axiosPrivate
      .get("stocks", options)
      .then((stocks) => {
        const stocksData = stocks?.data?.data?.map((value, key) => {
          let buttons = [];
          if (true)
            buttons.push(
              <Link
                key="editButton##1"
                type="button"
                to={`/admin/product/edit/${value.id}`}
                className="btn btn-icon"
                title="Edit"
              >
                <BiEdit style={{ color: "green" }} size={18} />
              </Link>
            );
          value["action"] = buttons.length > 0 ? buttons : "-";
          return value;
        });

        setProductState(stocksData);
        setIsLoading(false);
      })
      .catch((error) => {
        if (error.response) {
          //response status is an error code
          showErrorToast("Internal server error");
        }
        setIsLoading(false);
      });
  }, []);

  // alert meaasge
  useEffect(() => {
    if (alertMessage != null) {
      toast(alertMessage.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      setAlertMessage(null);
    }
  }, [alertMessage]);

  return (
    <>
      <div className="section-body">
        <div className="container-fluid">
          {/* hearder breadcrumb */}
          <div className="card-header">
            <strong className="top-left-heading">Products</strong>

            <ol className="headerroutes breadcrumb m-0 bg-none ">
              <li className="item color-gray">|</li>
              <li className="item">
                <Link to="/admin/dashboard" className="route-heading item mx-2">
                  Dashboard
                </Link>
              </li>
              <li className="item">
                <FaGreaterThan className="grater-than-route" />
              </li>
              <li className="route-heading item mx-2 mt-1">Products </li>
            </ol>
          </div>
        </div>
        <div className="section-body">
          <div className="container-fluid">
            <div className="tab-content ">
              <div
                className="tab-pane fade show active"
                id="user-list"
                role="tabpanel"
              >
                <div className="card">
                  <div className="card-body">
                    <div className="table-responsive">
                      {
                        <DataTable2
                          lists={productState}
                          headers={headers}
                          searchItems={searchItems}
                        />
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Product;
