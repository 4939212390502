import React from "react";
import { useEffect } from "react";
// import { useParams } from "react-router";
import axiosPrivate from "../hooks/axiosPrivate";
import { useState } from "react";
import bannerImage from "../assets/img/product-banner.png";
import searchIcon from "../assets/img/search-white-icon.svg";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useContext } from "react";
import AlertContext from "./../context/Alert/AlertContext"
import CartCountContext from "../context/CartCount/CartCountContext";

const ProductDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const image_url = process.env.REACT_APP_COMMON_FILE_URL;
  const [productDetails, setProductDetails] = useState({});
  const [categories, setCategories] = useState([]);
  const [productList, setProducts] = useState([]);
  const [selectedVariant, setSelectedVariant] = useState('');
  const [productVariant, setProductVariant] = useState({});
  const [variant, setVariants] = useState([]);
  const { alertMessage, setAlertMessage } = useContext(AlertContext);
  const { cartCountCheck, setCartCountCheck } = useContext(CartCountContext)

  useEffect(() => {
    const fetchData = async () => {
      const getPageDesc = () => {
        axiosPrivate
          .get("/product-details/slug/" + id)
          .then((res) => {
            setProductDetails(res.data.data);
            setProductVariant({
              image: 'product-details/' + res.data.data?.product_images[0]?.image,
              rate: res.data.data?.product_variants[0]?.rate
            })
            setVariants(res.data?.data?.product_variants);
            setProducts(res?.data?.data?.related_products);
          })
          .catch((error) => {
          });
      };
      const getCategories = () => {
        axiosPrivate
          .get("/categories/get")
          .then((res) => {
            const object = [
              {
                id: "2ae8c0b4-10d2-11ee-aed3-e4b97a041d01",
                name: "Cattel Health",
                description:
                  "Lorem Ipsum is simply dummy text of the printing and typesetting industry",
                image: "img/Mask group (3).png",
                slug: "/cattel-health",
                meta_title: null,
                meta_description: null,
                meta_keywords: null,
                structured_data: null,
                parent_id: "null",
                is_active: 0,
                is_deleted: 0,
                created_at: null,
                updated_at: null,
              },
              {
                id: "2ae8c0b4-10d2-11ee-aed3-e4b97a041d97",
                name: "Plant Nutrition\r\n\r\n",
                description:
                  "Lorem Ipsum is simply dummy text of the printing and typesetting industry",
                image: "img/image 68.png",
                slug: "/plant-nutrition",
                meta_title: null,
                meta_description: null,
                meta_keywords: null,
                structured_data: null,
                parent_id: "null",
                is_active: 0,
                is_deleted: 0,
                created_at: null,
                updated_at: null,
              },
              {
                id: "2ae8c0b4-10d2-11ee-aed3-e4b97a041d98",
                name: "Organic Grain Storage",
                description:
                  "Lorem Ipsum is simply dummy text of the printing and typesetting industry",
                image: "img/Mask group (2).png",
                slug: "/organic-grain-storage",
                meta_title: null,
                meta_description: null,
                meta_keywords: null,
                structured_data: null,
                parent_id: "null",
                is_active: 0,
                is_deleted: 0,
                created_at: null,
                updated_at: null,
              },
              {
                id: "2ae8c0b4-10d2-11ee-aed3-e4b97a041d99",
                name: "Biologicals",
                description:
                  "Lorem Ipsum is simply dummy text of the printing and typesetting industry",
                image: "img/Mask group (1).png",
                slug: "/biologicals",
                meta_title: null,
                meta_description: null,
                meta_keywords: null,
                structured_data: null,
                parent_id: "null",
                is_active: 0,
                is_deleted: 0,
                created_at: null,
                updated_at: null,
              },
            ];
            setCategories(object);
          })
          .catch((error) => {
          });
      };

      // const getProductList = () => {
      //   axiosPrivate
      //     .get("/product-details")
      //     .then((res) => {
      //       setProducts(res.data.data);
      //     })
      //     .catch((error) => {
      //       setProducts([]);
      //     });
      // };

      getPageDesc();
      getCategories();
      // getProductList();
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (alertMessage != null) {
      toast(alertMessage.message, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
      setAlertMessage(null);
    }
  }, [alertMessage]);

  function addToCart(product_detail, checkForRelated) {
    let userLogin = localStorage.getItem("userData");
    let object = {
      product_id: product_detail.id,
      quantity: 1,
      variant_id: (checkForRelated == 'RelatedProduct') ? product_detail?.related_variant[0]?.id : selectedVariant
    }
    if ((checkForRelated == 'RelatedProduct') ? true : (selectedVariant)) {
      if (userLogin) {
        axiosPrivate
          .post("/carts/create", object)
          .then((res) => {
            // setProducts(res.data.data);
            window.scroll({
              top: 0,
              behavior: "smooth"
            });
            setAlertMessage({ message: 'Item Added in Cart!' });
          })
          .catch((error) => {
            // setProducts([]);
          });
      }
      else {
        object['product_name'] = product_detail.name;
        object['description'] = product_detail.description;
        if(checkForRelated == 'RelatedProduct') {
          object['rate'] = product_detail.related_variant[0].rate;
          object['variant'] = product_detail.related_variant[0].variant;
          object['base_unit'] = product_detail.related_variant[0].base_unit;
          object['image'] = product_detail.product_image[0].image;
        }
        else {
          object['rate'] = productVariant.rate;
          object['image'] = productVariant.image;
          object['variant'] = productVariant.variant;
          object['base_unit'] = productVariant.base_unit;
        }
        let cart_items = localStorage.getItem('cart_items');
        let cart_array = [];
        cart_array.push(object);
        if (!cart_items) {
          localStorage.setItem('cart_items', JSON.stringify(cart_array));
        }
        else {
          let cart_array = JSON.parse(cart_items);
          let boolean_cart = false;
          cart_array?.forEach(element => {
            if (object.variant_id == element.variant_id) {
              boolean_cart = true;
              element.quantity = element.quantity + 1;
            }
          });

          if (!boolean_cart) {
            cart_array.push(object);
          }

          localStorage.setItem('cart_items', JSON.stringify(cart_array));
        }
        window.scroll({
          top: 0,
          behavior: "smooth",
        });
        setAlertMessage({ message: "Item Added in Cart!" });
      }
    }
    else {
      setAlertMessage({ message: 'Please Select Product Variant!' })
    }
    setCartCountCheck(!cartCountCheck);
  }

  const changeVariant = (value) => {
    setSelectedVariant(value.id);
    setProductVariant({
      image: 'product-variants/' + value?.varinat_iamges[0].image,
      rate: value?.rate,
      base_unit : value.base_unit,
      variant : value.variant,
    });
  }

  return (
    <>
      <div className="product-page">
        <div className="banner-image">
          <img src={bannerImage} />
        </div>
        <div className="product-content">
          <div className="text-center">
            <p>Shop</p>
            <span>
              Home/<span style={{ color: "#50AE31" }}>Shop</span>
            </span>
          </div>
        </div>
      </div>
      <div className="product_detail detail-padding">
        <div className="first_section">
          {/* <div className="search">
            <input type="text" className="search-box" />
            <div className="search-white">
              <img src={searchIcon} />
            </div>
          </div> */}
          <div className="filter-heading">
            <h4>Categories</h4>
            <ul>
              {categories.map((res) => (
                <Link to="/products">
                  <li>{res.name}</li>
                </Link>
              ))}
            </ul>
          </div>
        </div>
        <div className="second-section">
          <div className="div_background">
            <div className="product_img">
              {productDetails ?
                <img
                  src={process.env.REACT_APP_COMMON_FILE_URL + productVariant?.image}
                  alt="product_image"
                />
                : ""}
            </div>
          </div>

          <div className="product_details">
            <h4>{productDetails.name}</h4>
            <p className="tag_line">{productDetails.description}</p>
            <p className="price">₹ {productVariant?.rate}</p>
            <p className="description">{productDetails.category_description}</p>

            {variant?.length > 0 ?
              <div className="variant_btn_section">
                {variant?.length > 0 && variant.map((res) =>
                  <>
                    <div className={`btn_div`}>
                      <button onClick={(event) => changeVariant(res)} className={`${res?.id == selectedVariant ? "focusVariant" : ""}`}>{res?.variant}{res?.base_unit}</button>
                    </div>
                  </>
                )}
              </div>
              : ''}

            <p className="location">Check Delivery Option at Your Location:</p>
            <input className="pincode" type="text" placeholder="Pincode" />
            <button className="check_button">Check</button>
            <div className="d-flex">
              {/* <div className="buttons">
                <button className="decrease_button">-</button>
                <input className="cart_count" value={1} type="text" />
                <button className="increase_button">+</button>
              </div> */}
              <button
                className="add_to_cart"
                onClick={() => addToCart(productDetails, 'DetailProduct')}
              >
                Add to Cart
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="related_product detail-padding">
        <div className="related_product_heading">
          <h4>Related Product</h4>
        </div>

        <div className="organic-products-details">
          <div className="product-list row">
            {productList.map((res) => (
              <div className="col-lg-3 col-md-4 col-sm-6">
                <Link to={"/productdetail/" + res.slug}>
                  <div className="product-list-card">
                    <div className="product-image">
                      <img
                        src={
                          image_url +
                          "product-details/" +
                          res?.product_image[0]?.image
                        }
                        alt={res.alt}
                      />
                    </div>
                    <div className="product-image-border"></div>
                    <div className="product-content">
                      <div>
                        <p className="product_name">{res.name}</p>
                        <p className="product_tag_line">{res.description}</p>
                        <div className="product_buttons">
                          <button onClick={() => addToCart(res, 'RelatedProduct')}>Add To Cart</button>
                          <p className="product_name">₹ {res?.related_variant[0]?.rate}</p>
                        </div>
                        <p className="product_variant">({res?.related_variant[0]?.variant}{res?.related_variant[0]?.base_unit})</p>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default React.memo(ProductDetail); //ProductDetail;
