import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";

const AccountInvoice = ({invoiceGenerated,invoiceNotGenerated}) => {
   const [series, setSeries] =useState([0,0]);
 

   useEffect(() => {
   setSeries([invoiceGenerated.length, invoiceNotGenerated.length]);
  }, [invoiceGenerated,invoiceNotGenerated]);

  const [options] =useState({
    chart: {
      width: 380,
      type: "pie",
    },
    labels: ["Generated","Not Generated"],
    legend: {
      position: "bottom",
      markers: {
        fillColors: ["#719924", "#f9c500"],
      },
    },
    fill: {
      colors: [ "#719924", "#f9c500"],
    },
    dataLabels: {
      enabled: false,
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: "100%",
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  });

  return (
    <div>
      <div id="chart">
        <ReactApexChart
          options={options}
          series={series}
          type="pie"
          width="100%"
        />
      </div>
    </div>
  );
};

export default AccountInvoice;
