import React from 'react';

const OrderView = () => {
	const invoiceData = {
		invoiceNumber: '123456',
		date: 'May 31, 2023',
		billTo: {
			name: 'John Doe',
			address: '1234 Example Road',
			city: 'City',
			state: 'State',
		},
		shipTo: {
			name: 'Jane Smith',
			address: '5678 Example Street',
			city: 'City',
			state: 'State',
		},
		items: [
			{ item: 'Item 1', quantity: 2, price: 10 },
			{ item: 'Item 2', quantity: 1, price: 15 },
			{ item: 'Item 3', quantity: 3, price: 5 },
		],
		cgst: 5,
		sgst: 5,
		total: 50,
	};

	const handleDownload = () => {
		// Implement download logic here
		// You can use libraries like FileSaver.js or generate a PDF using a server-side solution
	};

	return (
        <div className="invoice-bill container">
        <div className="invoice-header">
          <h2>Invoice</h2>
          <p>1234 Main Street, City, State</p>
        </div>
        <div className="invoice-details row">
          <div className="col-sm-6">
            <h3>Bill To:</h3>
            <p>{invoiceData.billTo.name}</p>
            <p>{invoiceData.billTo.address}</p>
            <p>{invoiceData.billTo.city}, {invoiceData.billTo.state}</p>
          </div>
          <div className="col-sm-6">
            <h3>Invoice Details:</h3>
            <p>Invoice Number: {invoiceData.invoiceNumber}</p>
            <p>Date: {invoiceData.date}</p>
          </div>
        </div>
        <table className="invoice-items table">
          <thead>
            <tr>
              <th>Item</th>
              <th>Quantity</th>
              <th>Price</th>
              <th>Total</th>
            </tr>
          </thead>
          <tbody>
            {invoiceData.items.map((item, index) => (
              <tr key={index}>
                <td>{item.item}</td>
                <td>{item.quantity}</td>
                <td>${item.price}</td>
                <td>${item.quantity * item.price}</td>
              </tr>
            ))}
          </tbody>
          <tfoot>
            <tr>
              <td colSpan="3" className="total-label">Subtotal:</td>
              <td>${invoiceData.total}</td>
            </tr>
            <tr>
              <td colSpan="3" className="total-label">CGST ({invoiceData.cgst}%):</td>
              <td>${(invoiceData.total * invoiceData.cgst) / 100}</td>
            </tr>
            <tr>
              <td colSpan="3" className="total-label">SGST ({invoiceData.sgst}%):</td>
              <td>${(invoiceData.total * invoiceData.sgst) / 100}</td>
            </tr>
            <tr>
              <td colSpan="3" className="total-label">Total:</td>
              <td>${invoiceData.total + (invoiceData.total * (invoiceData.cgst + invoiceData.sgst)) / 100}</td>
            </tr>
          </tfoot>
        </table>
        <button className="btn btn-primary" onClick={handleDownload}>Download</button>
      </div>
    );
  };
  
  



export default OrderView;
