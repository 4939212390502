import { useFormik } from 'formik';
import React, { useContext, useEffect, useState } from 'react';
import { FaGreaterThan } from 'react-icons/fa';
import { Link, useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { showErrorToast } from '../../../Toster';
import AlertContext from '../../../context/Alert/AlertContext';
import axiosPrivate from '../../../hooks/axiosPrivate';

const validationRules = {};

const EnquiryView = () => {
	let { id } = useParams();
	const { setAlertMessage } = useContext(AlertContext);
	const navigate = useNavigate();
	const [getData, setGetData] = useState({});

	//   enquairy get by id
	useEffect(() => {
		axiosPrivate
			.get(`businessenquiry/get/${id}`)
			.then((response) => {
				if (response.data.code == 200) {
					setGetData(response.data.data[0]);
				}
			})
			.catch((error) => {
			});
	}, []);

	//set buDefault data in input fields
	useEffect(() => {
		if (getData != '') {
			formik.setValues({ ...getData });
		}
	}, [getData]);

	// formik (send data)
	const formik = useFormik({
		initialValues: {
			season_title: '',
			discount_percentage: '',
			from_date: '',
			to_date: '',
		},
		enableReinitialize: true,
		validationSchema: Yup.object(validationRules),
		onSubmit: async (values, { setSubmitting, setFieldError }) => {
			axiosPrivate
				.patch(`seasons/update/${id}`, values)
				.then((response) => {
					setAlertMessage({ message: 'Season updated successfully' });
					navigate('/admin/season');
				})
				.catch((error) => {
					if (typeof error.response.data.errors == 'array') {
						showErrorToast(error.response.data.errors[0].msg);
					} else {
						showErrorToast(error.response.data.errors.msg);
					}
				});
		},
	});

	return (
		<div className="section-body ">
			<div className="container-fluid">
				{/* hearder breadcrumb */}
				<div className="card-header">
					<strong className="top-left-heading">View Inquiry</strong>

					<ol className="headerroutes breadcrumb m-0 bg-none ">
						<li className="item color-gray">|</li>
						<li className="item">
							<Link to="/admin/dashboard" className="route-heading item mx-2">
								Dashboard
							</Link>
						</li>

						<li className="item">
							<FaGreaterThan className="grater-than-route" />
						</li>
						<li className="route-heading item mx-2 mt-1">
							<Link to="/admin/enquiry">Inquiries</Link>
						</li>

						<li className="item">
							<FaGreaterThan className="grater-than-route" />
						</li>
						<li className="route-heading item mx-2 mt-1">View Inquiry</li>
					</ol>
				</div>

				<div className="tab-content ">
					<form onSubmit={formik.handleSubmit}>
						<div
							className="tab-pane fade show active"
							id="enquiry-add"
							role="tabpanel"
						>
							<div className="card">
								<div className="card-body">
									<div className="row clearfix">
										{/* Name*/}
										<div className="col-lg-4 col-md-4 col-sm-12">
											<label>
												Name:<span className="required"></span>
											</label>

											<div className="form-group">
												<input
													id="name"
													name="name"
													type="name"
													className="form-control"
													placeholder="Name "
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													value={formik.values.name}
													readOnly
												/>
											</div>
										</div>

										{/* Mobile Number */}
										<div className="col-lg-4 col-md-4 col-sm-12">
											<label>
												Mobile Number:<span className="required"></span>
											</label>

											<div className="form-group">
												<input
													id="mobile"
													name="mobile"
													type="number"
													className="form-control"
													placeholder="Mobile "
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													value={formik.values.mobile}
													readOnly
												/>
											</div>
										</div>

										{/* Email */}
										<div className="col-lg-4 col-md-4 col-sm-12">
											<label>
												Email:<span className="required"></span>
											</label>

											<div className="form-group">
												<input
													id="email"
													name="email"
													type="email"
													className="form-control"
													placeholder="Email "
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													value={formik.values.email}
													readOnly
												/>
											</div>
										</div>

										<div className="col-lg-4 col-md-4 col-sm-12">
											<label>
												Subject:<span className="required"></span>
											</label>

											<div className="form-group">
												<input
													id="subject"
													name="subject"
													type="text"
													className="form-control"
													placeholder="Subject"
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													value={formik.values.subject}
													readOnly
												/>
											</div>
										</div>

										{/* Company */}
										<div className="col-lg-4 col-md-4 col-sm-12">
											<label>
												Company:<span className="required"></span>
											</label>

											<div className="form-group">
												<input
													id="company"
													name="company"
													type="text"
													className="form-control"
													placeholder="Company "
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													value={formik.values.company}
													readOnly
												/>
											</div>
										</div>

										{/*  City */}
										<div className="col-lg-4 col-md-4 col-sm-12">
											<label>
												City:<span className="required"></span>
											</label>

											<div className="form-group">
												<input
													id="city"
													name="city"
													type="text"
													className="form-control"
													placeholder="City"
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													value={formik.values.city}
													readOnly
												/>
											</div>
										</div>

										{/*   State */}
										<div className="col-lg-4 col-md-4 col-sm-12">
											<label>
												State:<span className="required"></span>
											</label>

											<div className="form-group">
												<input
													id="state"
													name="state"
													type="text"
													className="form-control"
													placeholder="State "
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													value={formik.values.state}
													readOnly
												/>
											</div>
										</div>

										{/*   Country */}
										<div className="col-lg-4 col-md-4 col-sm-12">
											<label>
												Country:<span className="required"></span>
											</label>

											<div className="form-group">
												<input
													id="country"
													name="country"
													type="text"
													className="form-control"
													placeholder="Country "
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													value={formik.values.country}
													readOnly
												/>
											</div>
										</div>

										{/*    Comment */}
										<div className="col-lg-4 col-md-4 col-sm-12">
											<label>
												Comment:<span className="required"></span>
											</label>

											<div className="form-group">
												<input
													id="message"
													name="message"
													type="text"
													className="form-control"
													placeholder="Message "
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													value={formik.values.message}
													readOnly
												/>
											</div>
										</div>

										{/* close button */}
										<div className="col-12 submit-btn">
											<hr className="mt-4" />
											<div className="table-responsive">
												<table className="table table-striped"></table>
											</div>
											<button
												type="button"
												className="btn btn-secondary ml-2"
												data-dismiss="modal"
												onClick={() => navigate('/admin/enquiry')}
											>
												CLOSE
											</button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</form>
				</div>
			</div>
		</div>
	);
};

export default EnquiryView;
