import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import AlertContext from "../../../context/Alert/AlertContext";
import AuthContext from "../../../context/Auth/AuthContext";
import axiosPrivate from "../../../hooks/axiosPrivate";
import PermissionTableTd from "./PermissionTableTd";
import LoaderContext from "../../../context/loader/LoaderContext";
const validationRules = {};

const UserPermission = () => {
  const { setIsLoading } = useContext(LoaderContext);
  let { userId, roleId } = useParams();
  const { setAlertMessage } = useContext(AlertContext);
  const navigate = useNavigate();
  const [permissions, setPermissions] = useState([]);
  const [userPermissions, setUserPermissions] = useState([]);
  const [permissionDistinctName, setPermissionDistinctName] = useState([]);
  const [userPermissionsId, setUserPermissionsId] = useState([]);
  const { changeAuthPermissions } = useContext(AuthContext);

  useEffect(() => {
    axiosPrivate
      .get("permissions")
      .then((fetched_permissions) => {
        setPermissions(fetched_permissions?.data?.data);
      })
      .catch((error) => {
      });

    axiosPrivate
      .get("permissions/distinct/name")
      .then((distinctName) => {
        setPermissionDistinctName(distinctName?.data?.data);
      })
      .catch((error) => {
      });
  }, []);

  useEffect(() => {
    setIsLoading(true);
    axiosPrivate
      .get(`user-has-permissions/user/${userId}`)
      .then((fetched_user_permissions) => {
        setUserPermissions(fetched_user_permissions?.data?.data);
        if (fetched_user_permissions.data.data.length) {
          let userPermissionsArray = [];
          for (let m = 0; m < fetched_user_permissions.data.data.length; m++) {
            userPermissionsArray.push(
              fetched_user_permissions.data.data[m].permission_id
            );
          }
          setUserPermissionsId(userPermissionsArray);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
      });
  }, [userId]);

  useEffect(() => {
    axiosPrivate
      .get("permissions")
      .then((fetched_permissions) => {
        setPermissions(fetched_permissions?.data?.data);
      })
      .catch((error) => {
      });

    axiosPrivate
      .get("permissions/distinct/name")
      .then((distinctName) => {
        setPermissionDistinctName(distinctName?.data?.data);
      })
      .catch((error) => {
      });
  }, [userPermissionsId]);

  const modifyPermissionIdArray = async (permission_id, action) => {
    if (action === false) {
      const index = userPermissionsId.indexOf(permission_id);
      userPermissionsId.splice(index, 1);
    } else {
      userPermissionsId.push(permission_id);
    }
  };

  const submitHandler = async (event) => {
    event.preventDefault();
    let values = {
      user_id: userId,
      role_id: roleId,
      permission_ids: userPermissionsId,
    };
    setIsLoading(true);
    await axiosPrivate
      .post(`user-has-permissions`, values)
      .then((response) => {
        changeAuthPermissions();
        setAlertMessage({ message: "User Permission added successfully" });
        setIsLoading(false);
        navigate("/admin/users");
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status !== 201) {
          }
        }
        setIsLoading(false);
      });
  };

  const title = ["List", "Create", "Edit", "View", "Delete"];
  let checked = false;
  const rows = permissionDistinctName.map((distinct, index) => {
    return (
      <tr key={index}>
        <td>{distinct.name}</td>
        {permissions.map((permission, index1) => {
          if (userPermissionsId.includes(String(permission.id))) {
            checked = true;
          } else {
            checked = false;
          }
          if (permission.name === distinct.name) {
            if (permission.action === "List") {
              return (
                <PermissionTableTd
                  tdKey={index1}
                  key={index1}
                  id={permission.id}
                  checked={checked}
                  modifyPermissionIdArray={modifyPermissionIdArray}
                />
              );
            }
            if (permission.action === "Create") {
              return (
                <PermissionTableTd
                  tdKey={index1}
                  key={index1}
                  id={permission.id}
                  checked={checked}
                  modifyPermissionIdArray={modifyPermissionIdArray}
                />
              );
            }
            if (permission.action === "Edit") {
              return (
                <PermissionTableTd
                  tdKey={index1}
                  key={index1}
                  id={permission.id}
                  checked={checked}
                  modifyPermissionIdArray={modifyPermissionIdArray}
                />
              );
            }
            if (permission.action === "View") {
              return (
                <PermissionTableTd
                  tdKey={index1}
                  key={index1}
                  id={permission.id}
                  checked={checked}
                  modifyPermissionIdArray={modifyPermissionIdArray}
                />
              );
            }
            if (permission.action === "Delete") {
              return (
                <PermissionTableTd
                  tdKey={index1}
                  key={index1}
                  id={permission.id}
                  checked={checked}
                  modifyPermissionIdArray={modifyPermissionIdArray}
                />
              );
            }
          }
        })}
      </tr>
    );
  });

  return (
    <div className="section-body mt-3">
      <div className="container-fluid">
        <div className="tab-content mt-3">
          <form onSubmit={submitHandler}>
            <div
              className="tab-pane fade show active"
              id="user-permissions"
              role="tabpanel"
            >
              <div className="card">
                <div className="card-header">
                  <strong>User Permissions</strong>
                </div>
                <div className="card-body">
                  <div className="row clearfix">
                    <div className="col-12">
                      <div className="table-responsive">
                        <table className="table table-striped">
                          <thead>
                            <tr>
                              <th>MODULES</th>
                              {title.map((name, key) => (
                                <th key={key}>{name}</th>
                              ))}
                            </tr>
                          </thead>
                          <tbody>{rows}</tbody>
                        </table>
                      </div>
                      <button
                        type="button"
                        className="btn btn-secondary"
                        data-dismiss="modal"
                        onClick={() => navigate("/admin/users")}
                      >
                        CLOSE
                      </button>
                      <button
                        type="submit"
                        className="btn btn-primary float-right"
                      >
                        Update
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default UserPermission;
