import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import axiosPrivate from "../../../hooks/axiosPrivate";
import classes from "../../../Pages/hrms/Logistic/Logistic.module.css";
import { showSuccessToast } from "../../../Toster";

const vehicleNoRegex =
  /^[A-Z]{2}[ -][0-9]{1,2}(?: [A-Z])?(?: [A-Z]*)? [0-9]{4}$/;

//Formik Validation start
const validationRules = {
  dispatch_user_id: Yup.string().required("Dispatch user is required"),
  transporter_name: Yup.string().required("Transporter name is required"),
  driver_name: Yup.string().required("Driver name is required"),
  driver_phone: Yup.string()
    .test(
      "starts-not-with-0-to-5",
      "Driver phone no is not valid",
      function (value) {
        if (!value) return true;
        return !/^[0-5]/.test(value);
      }
    )
    .required("Driver phone no is required"),
  vehicle_no: Yup.string().required("Vehicle no is required"),
  dispatch_date: Yup.date().required("Dispatch date is required"),
  lr_no: Yup.string().required("LR no is required"),
  freight: Yup.string().required("Freight is required"),
  freight_amount: Yup.string().required("Freight amount is required"),
};
//Formik Validation end

// MergeModal component
const MergeModal = ({ isOpen, onConfirm, onCancel, orderItemsIds }) => {
  const [dispatchUserList, setDispatchUserList] = useState([]);
  const deafultValue = {
    dispatch_user_id: "",
    dispatch_date: "",
    transporter_name: "",
    driver_name: "",
    driver_phone: "",
    vehicle_no: "",
    lr_no: "",
    freight: "",
    freight_amount: 0,
  };

  const formik = useFormik({
    initialValues: {
      order_item_id_array: orderItemsIds,
      dispatch_user_id: "",
      transporter_name: "",
      driver_name: "",
      driver_phone: "",
      vehicle_no: "",
      dispatch_date: "",
      lr_no: "",
      freight: "",
      freight_amount: 0,
      status: "with_dispatch",
    },
    validationSchema: Yup.object(validationRules),
    onSubmit: async (values, { setSubmitting, setFieldError }) => {
      var newValues = {
        order_item_id_array: orderItemsIds,
        dispatch_user_id: values.dispatch_user_id,
        transporter_name: values.transporter_name,
        driver_name: values.driver_name,
        driver_phone: values.driver_phone,
        vehicle_no: values.vehicle_no,
        dispatch_date: values.dispatch_date,
        lr_no: values.lr_no,
        freight: values.freight,
        freight_amount: values.freight_amount,
        status: "with_dispatch",
      };

      axiosPrivate
        .post(`/logistics/merge/store`, newValues)
        .then((response) => {})
        .catch((error) => {
          if (error.response) {
            if (error.response.status !== 200) {
              for (let i = 0; i < error.response.data.errors.length; i++) {
                setFieldError(
                  error.response.data.errors[i].param,
                  error.response.data.errors[i].msg
                );
              }
            }
          }
        });
      resetForm();
      onConfirm();
    },
  });

  const numberFilter = (event) => {
    const inputValue = event.target.value;

    const numericValue = inputValue.replace(/\D/g, "");
    formik.setFieldValue(event.target.name, numericValue);
  };

  const nameFilter = (event) => {
    const inputValue = event.target.value;
    if (/^[A-Za-z\s'-]+$/.test(inputValue) || inputValue === "") {
      formik.setFieldValue(event.target.name, inputValue);
    }
  };

  useEffect(() => {
    if (isOpen) {
      resetForm();
    }
  }, [isOpen]);

  useEffect(() => {
    axiosPrivate
      .get(`users/get/user/dispatch_team`)
      .then((response) => {
        setDispatchUserList(response?.data?.data);
      })
      .catch((error) => {});
  }, []);

  //Formik reset form values
  const resetForm = () => {
    formik.resetForm({
      values: deafultValue,
      // you can also set the other form states here
    });
    return true;
  };

  if (!isOpen) return null;

  return ReactDOM.createPortal(
    <div
      id="exampleModalCenter"
      className="modal fade show"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="exampleModalCenterTitle"
      style={{
        position: "absolute",
        marginLeft: "10vw",
        width: "80vw",
        display: "block",
        height:'min-content',
        // backgroundColor: "rgba(0, 0, 0, 0.4)",
        // backdropFilter:'blur(5px)',
      }}
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalCenterTitle">
              Merge Order
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={onCancel}
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body" style={{ textAlign: "center" }}>
            <form onSubmit={formik.handleSubmit}>
              <div className="d-flex justify-content-center row">
                {/* dispatch user dropdown */}
                <div className="col-md-6 text-left">
                  <label>Dispatch Person *</label>
                  <select
                    id="dispatch_user_id"
                    className="form-control"
                    name={`dispatch_user_id`}
                    value={formik.values.dispatch_user_id}
                    onChange={(event) => {
                      formik.handleChange(event);
                    }}
                    onBlur={formik.handleBlur}
                  >
                    <option value="">Select User</option>
                    {dispatchUserList.map((dispatch_user_id) => (
                      <option
                        key={dispatch_user_id.id}
                        value={dispatch_user_id.id}
                      >
                        {dispatch_user_id.name}
                      </option>
                    ))}
                  </select>
                  {formik.touched.dispatch_user_id &&
                  formik.errors.dispatch_user_id ? (
                    <div className={classes.invalidDataError}>
                      {formik.errors.dispatch_user_id}
                    </div>
                  ) : null}
                </div>
                {/* Dispatch date */}
                <div className="col-md-6 text-left">
                  <label>Dispatch Date *</label>
                  <input
                    id="dispatch_date"
                    type="date"
                    className="form-control"
                    name={`dispatch_date`}
                    max="9999-12-31"
                    min={new Date().toISOString().slice(0, 10)}
                    value={
                      formik.values.dispatch_date != "" &&
                      formik.values.dispatch_date != undefined
                        ? new Date(formik.values.dispatch_date)
                            .toISOString()
                            .slice(0, 10)
                        : ""
                    }
                    onChange={(event) => {
                      formik.handleChange(event); // Call handleChange with the event
                    }}
                  />
                  {formik.touched.dispatch_date &&
                  formik.errors.dispatch_date ? (
                    <div className={classes.invalidDataError}>
                      {formik.errors.dispatch_date}
                    </div>
                  ) : null}
                </div>
                {/* Transporter name */}
                <div className="col-md-6 text-left">
                  <label>Transporter Name *</label>
                  <input
                    id="transporter_name"
                    type="text"
                    className="form-control"
                    name={`transporter_name`}
                    placeholder="Transporter Name"
                    value={formik.values.transporter_name}
                    onChange={(event) => {
                      const regex = /^[A-Za-z0-9\s]*$/; // Allow only alphanumeric characters
                      let value = event.target.value
                      if (regex.test(value)) {
                        formik.setFieldValue("transporter_name", value); // Update Formik value if valid
                      }
                    }}
                  />
                  {formik.touched.transporter_name &&
                  formik.errors.transporter_name ? (
                    <div className={classes.invalidDataError}>
                      {formik.errors.transporter_name}
                    </div>
                  ) : null}
                </div>
                {/* Driver name */}
                <div className="col-md-6 text-left">
                  <label>Driver Name *</label>
                  <input
                    id="driver_name"
                    type="text"
                    className="form-control"
                    name={`driver_name`}
                    placeholder="Driver Name *"
                    value={formik.values.driver_name}
                    onChange={(event) => {
                      nameFilter(event);
                    }}
                  />
                  {formik.touched.driver_name && formik.errors.driver_name ? (
                    <div className={classes.invalidDataError}>
                      {formik.errors.driver_name}
                    </div>
                  ) : null}
                </div>
                {/* Driver phone */}
                <div className="col-md-6 text-left">
                  <label>Driver Phone No*</label>
                  <input
                    id="driver_phone"
                    type="text"
                    className="form-control"
                    name={`driver_phone`}
                    placeholder="Driver Phone No"
                    value={formik.values.driver_phone}
                    onChange={(event) => {
                      numberFilter(event);
                    }}
                    maxLength={10}
                  />
                  {formik.touched.driver_phone && formik.errors.driver_phone ? (
                    <div className={classes.invalidDataError}>
                      {formik.errors.driver_phone}
                    </div>
                  ) : null}
                </div>
                {/* Vehicle No. */}
                <div className="col-md-6 text-left">
                  <label>Vehicle No *</label>
                  <input
                    id="vehicle_no"
                    type="text"
                    className="form-control"
                    name={`vehicle_no`}
                    placeholder="Enter Vehicle No(RJ06XX0000)."
                    value={formik.values.vehicle_no}
                    onChange={(event) => {
                      const regex = /^[A-Za-z0-9]*$/; // Allow only alphanumeric characters
                      let value = event.target.value.toUpperCase(); // Convert to uppercase
                      if (regex.test(value)) {
                        formik.setFieldValue("vehicle_no", value); // Update Formik value if valid
                      }
                    }}
                  />
                  {formik.touched.vehicle_no && formik.errors.vehicle_no ? (
                    <div className={classes.invalidDataError}>
                      {formik.errors.vehicle_no}
                    </div>
                  ) : null}
                </div>
                <div className="col-md-6 text-left">
                  <label>LR No*</label>
                  <input
                    id="lr_no"
                    type="text"
                    className="form-control"
                    name={`lr_no`}
                    placeholder="LR No"
                    value={formik.values.lr_no}
                    onChange={(event) => {
                      formik.handleChange(event); // Call handleChange with the event
                    }}
                  />
                  {formik.touched.lr_no && formik.errors.lr_no ? (
                    <div className={classes.invalidDataError}>
                      {formik.errors.lr_no}
                    </div>
                  ) : null}
                </div>
                <div className="col-md-6 text-left">
                  <label>Freight *</label>
                  <select
                    id="freight"
                    className="form-control"
                    name={`freight`}
                    value={formik.values.freight}
                    onChange={(event) => {
                      formik.handleChange(event);
                    }}
                    onBlur={formik.handleBlur}
                  >
                    <option value="">Select Freight Type</option>
                    <option key={"billing"} value={"billing"}>Billing</option>
                    <option key={"to_pay "} value={"to_pay"}>To Pay</option>
                  </select>
                  {formik.touched.freight &&
                  formik.errors.freight ? (
                    <div className={classes.invalidDataError}>
                      {formik.errors.freight}
                    </div>
                  ) : null}
                </div>
                {
                   formik?.values?.freight === "to_pay" && (
                    <>
                    <div className="col-md-6 text-left">
                  <label>Freight Amount *</label>
                  <input
                    id="freight_amount"
                    type="text"
                    className="form-control"
                    name={`freight_amount`}
                    placeholder="Freight Amount"
                    value={formik.values.freight_amount}
                    onChange={(event) => {
                      numberFilter(event);
                    }}
                    maxLength={10}
                  />
                  {formik.touched.freight_amount && formik.errors.freight_amount ? (
                    <div className={classes.invalidDataError}>
                      {formik.errors.freight_amount}
                    </div>
                  ) : null}
                </div>
                <div className="col-md-6 text-left"></div>
                    </>
                   )
                }
                <div className="col-md-12 mt-4">
                  <button type="submit" className="btn btn-success mr-1">
                    Confirm
                  </button>
                  <button
                    type="button"
                    className="btn btn-danger"
                    onClick={onCancel}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>,
    document.querySelector(".popup-merge-modal") // Root element to render the portal
  );
};

export default MergeModal;
