import { useFormik } from 'formik';
import React, { useContext, useEffect, useState } from 'react';
// import { useNavigate } from 'react-router';
import { useParams,useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { showErrorToast,showSuccessToast } from '../../../Toster';
import AlertContext from '../../../context/Alert/AlertContext';
import axiosPrivate from '../../../hooks/axiosPrivate';
import { Link } from 'react-router-dom';
import { FaGreaterThan } from 'react-icons/fa';
const aboutTemplateValidation = Yup.object({
	pagename: Yup.string().required('Page Name is Required'),
	slug: Yup.string().required('Slug is Required'),
	
});

const AddLeadershipTemplate = () => {
	const { setAlertMessage } = useContext(AlertContext);
	const { id } = useParams();
	const [submitButton, setSubmitButton] = useState('Submit');
	const [template, setTemplates] = useState('Add Leadership Template');

	const initialValues = {
		pagename: '',
		slug: '',
		main_heading: '',
		main_content: '',
		main_role: '',
		main_image: '',

		team_section: [
			{
				team_member_image: '',
				team_member_name: '',
				team_member_content: '',
				team_member_role: '',
			},
		],
	};

	const onPageNameBlur = (e) => {
		const { name, value } = e.target;
		if (name == 'pagename') {
			let slug = e.target.value;
			setFieldValue(
				'slug',
				slug
					.toLowerCase()
					.trim()
					.replace(/[^\w\s-]/g, '')
					.replace(/[\s_-]+/g, '-')
					.replace(/^-+|-+$/g, '')
			);
		}

		handleBlur(e);
	};

	const onFileUpload = (e, index) => {
		let upload_image = e.target.files[0];
		// if(e.target.name == 'main_image'){
		//   // values.main_image = e.target.files[0];
		// }

		// else{
		//     let uploaded_file = e.target.files[0];
		//     values.team_section[index].team_member_image = uploaded_file;
		//     values.main_image = uploaded_file;
		// }
		let formData = new FormData();
		formData.append('image', upload_image);
		axiosPrivate
			.post('/pages/file-upload', formData)
			.then((res) => {
				// values[e.target.name] = res.data[0];
				// setFieldValue(e.target.name , '');
				setFieldValue(e.target.name, res.data.data);
			})
			.catch((error) => {
				if (typeof error.response.data.errors == 'array') {
					showErrorToast(error.response.data.errors[0].msg);
				} else {
					showErrorToast(error.response.data.errors.msg);
				}
			});
		handleChange(e);
	};

	const {
		values,
		errors,
		touched,
		handleSubmit,
		handleBlur,
		handleChange,
		setFieldValue,
		getFieldProps,
	} = useFormik({
		initialValues: initialValues,
		validationSchema: aboutTemplateValidation,
		onSubmit: (values, { setFieldError }) => {
			let formData = {
				name: values.pagename,
				slug: values.slug,
				page_json: JSON.stringify(values),
				template_type: 'leadership',
			};
			if (id) {
				axiosPrivate
					.put('pages/update/' + id, formData)
					.then((res) => {
						showSuccessToast('Updation successful');
						navigate('/admin/leadership-template');
					})
					.catch((error) => {
						if (typeof error.response.data.errors == 'array') {
							showErrorToast(error.response.data.errors[0].msg);
						} else {
							showErrorToast(error.response.data.errors.msg);
						}
					});
			} else {
				axiosPrivate
					.post('pages/create', formData)
					.then((res) => {
						showSuccessToast('successfully created');
						navigate('/admin/leadership-template');
					})
					.catch((error) => {
						if (typeof error.response.data.errors == 'array') {
							showErrorToast(error.response.data.errors[0].msg);
						} else {
							showErrorToast(error.response.data.errors.msg);
						}
					});
			}
		},
	});

	useEffect(() => {
		if (id) {
			axiosPrivate.get('pages/' + id).then((res) => {
				const parsedValues = JSON.parse(res.data?.data?.page_json);
				Object.keys(parsedValues).forEach((key) => {
					setFieldValue(key, parsedValues[key]);
				});

				setSubmitButton('Update');
			});
			setTemplates('Edit Leadetship Template')
		}

	}, []);

	const onAddRow = (name, check) => {
		// debugger;
		if (name == 'team_member') {
			if (check) {
				let newObj = {
					team_member_image: '',
					team_member_name: '',
					team_member_role: '',
				};

				let rowsData = values.team_section;
				if(rowsData?.length<3){
					rowsData.push(newObj);
				}
				setFieldValue('team_section', rowsData);
			} else {
				let rowsData = values.team_section;
				if (rowsData?.length > 1) {
					rowsData?.splice(rowsData?.length - 1, 1);
					setFieldValue('team_section', rowsData);
				}
			}
		}
	};

	const navigate = useNavigate();

	const nameFilter = (event) => {
		const inputValue = event.target.value;
		if (/^[A-Za-z\s'-]+$/.test(inputValue) || inputValue === "") {
		  setFieldValue(event.target.name, inputValue);
		}
	  };

	return (
		<>
			<div>
				<div className="section-body mt-3 website-template">
					<div className="container-fluid">
					<div className="card-header">
					{id ? (
                <>
                  <strong className="top-left-heading">
								Edit Leadership Template
								</strong>

								<ol className="headerroutes breadcrumb m-0 bg-none ">
									<li className="item color-gray">|</li>
									<li className="item">
										<Link
											to="/admin/dashboard"
											className="route-heading item mx-2"
										>
											Dashboard
										</Link>
									</li>
									<li className="item">
										<FaGreaterThan className="grater-than-route" />
									</li>
									<li className="route-heading item mx-2 mt-1">
									Edit Leadership Template
									</li>
								</ol>
                </>
              ) : (
                <>
                  <strong className="top-left-heading">
								Add Leadership Template
								</strong>

								<ol className="headerroutes breadcrumb m-0 bg-none ">
									<li className="item color-gray">|</li>
									<li className="item">
										<Link
											to="/admin/dashboard"
											className="route-heading item mx-2"
										>
											Dashboard
										</Link>
									</li>
									<li className="item">
										<FaGreaterThan className="grater-than-route" />
									</li>
									<li className="route-heading item mx-2 mt-1">
									Add Leadership Template
									</li>
								</ol>
                </>
              )}
							</div>
						<div className="tab-content mt-3">
							<form onSubmit={handleSubmit}>
								<div
									className="tab-pane fade show active"
									id="home-template"
									role="tabpanel"
								>
									<div className="card">
										
										<div className="card-body">
											<div className="row bordered-row">
												{/*  Page Name */}
												<div className="col-lg-6 col-md-6 col-sm-12">
													<label>
														Page Name:<span className="required">*</span>
													</label>
													<div className="form-group">
														<input
															id="pagename"
															name="pagename"
															type="text"
															className="form-control"
															placeholder="Page Name"
															onChange={nameFilter}
															onBlur={onPageNameBlur}
															value={values.pagename}
														/>

														{touched.pagename && errors.pagename ? (
															<>
															<div className="invalidDataError">
																{errors.pagename}
															</div>
															</>
														) : null}
													</div>
												</div>
												<div className="col-lg-6 col-md-6 col-sm-12">
													<label>
														Slug:<span className="required">*</span>
													</label>
													<div className="form-group">
														<input
															// readOnly
															id="slug"
															name="slug"
															value={values.slug}
															placeholder='Slug'
															className="form-control"
															onChange={handleChange}
															onBlur={handleBlur}
														/>
														{touched.slug && errors.slug ? (
															<div className="invalidDataError">
																{errors.slug}
															</div>
														) : null}
													</div>
												</div>
											</div>
											<div className="row bordered-row">
												<div className="col-lg-6 col-md-6 col-sm-12">
													<label>Team Lead Name:</label>
													<div className="form-group">
														<input
															id="main_heading"
															name="main_heading"
															type="text"
															className="form-control"
															placeholder="Team Lead Name"
															onChange={handleChange}
															onBlur={handleBlur}
															value={values.main_heading}
														/>

														{touched.main_heading && errors.main_heading ? (
															<div className="invalidDataError">
																{errors.main_heading}
															</div>
														) : null}
													</div>
												</div>
												<div className="col-lg-6 col-md-6 col-sm-12">
													<label>Team Lead Role:</label>
													<div className="form-group">
														<input
															id="main_role"
															name="main_role"
															type="text"
															className="form-control"
															placeholder="Team Lead Role"
															onChange={handleChange}
															onBlur={handleBlur}
															value={values.main_role}
														/>

														{touched.main_role && errors.main_role ? (
															<div className="invalidDataError">
																{errors.main_role}
															</div>
														) : null}
													</div>
												</div>
												<div className="col-lg-6 col-md-6 col-sm-12">
													<label>About Team Lead:</label>
													<div className="form-group">
														<textarea
															id="main_content"
															name="main_content"
															type="text"
															className="form-control"
															placeholder="About Team Lead"
															onChange={handleChange}
															onBlur={handleBlur}
															value={values.main_content}
														/>

														{touched.main_content && errors.main_content ? (
															<div className="invalidDataError">
																{errors.main_content}
															</div>
														) : null}
													</div>
												</div>
												<div className="col-lg-6 col-md-6 col-sm-12">
													<label>Team Lead Image:</label>
													<div className="form-group">
														<input
															id="main_image"
															name="main_image"
															type="file"
															// onChange={(event) => setFieldValue("main_image" , event.target.files[0])}
															onChange={onFileUpload}
															className="form-control"
															onBlur={handleBlur}
														/>
														{typeof values.main_image == 'string' ? (
															<img
																className="mt-2"
																src={values.main_image}
																style={{ width: '100px', height: '100px' }}
															/>
														) : (
															''
														)}
													</div>
												</div>
											</div>

											<h6>Section 1</h6>
											<div className="row bordered-row">
												{values.team_section.map((res, index) => (
													<>
														<div
															className="col-lg-3 col-md-6 col-sm-12"
															key={index}
														>
															<label>Team Member Image:</label>
															<div className="form-group">
																<input
																	id={`team_member_image` + index}
																	name={`team_section.[${index}].team_member_image`}
																	type="file"
																	className="form-control"
																	onChange={onFileUpload}
																	onBlur={handleBlur}
																/>
																{typeof values.team_section[index]
																	.team_member_image == 'string' &&
																values.team_section[index].team_member_image !=
																	'' ? (
																	<img
																		className="mt-2"
																		src={
																			values.team_section[index]
																				.team_member_image
																		}
																		style={{ width: '100px', height: '100px' }}
																	/>
																) : (
																	''
																)}
															</div>
														</div>
														<div className="col-lg-3 col-md-6 col-sm-12">
															<label> Team Member Name:</label>
															<div className="form-group">
																<input
																	id="team_member_name"
																	name={`team_section.[${index}].team_member_name`}
																	type="text"
																	className="form-control"
																	placeholder="Team Member Name"
																	onChange={handleChange}
																	onBlur={handleBlur}
																	value={
																		values.team_section[index].team_member_name
																	}
																/>
															</div>
														</div>
														<div className="col-lg-3 col-md-6 col-sm-12">
															<label> Team Member Role:</label>
															<div className="form-group">
																<input
																	id="team_member_role"
																	name={`team_section.[${index}].team_member_role`}
																	type="text"
																	className="form-control"
																	placeholder="Team Member Role"
																	onChange={handleChange}
																	onBlur={handleBlur}
																	value={
																		values.team_section[index].team_member_role
																	}
																/>
															</div>
														</div>
														<div className="col-lg-3 col-md-6 col-sm-12">
															<label> About Team Member Content:</label>
															<div className="form-group">
																<textarea
																	id="section_content"
																	name={`team_section.[${index}].team_member_content`}
																	type="text"
																	className="form-control"
																	placeholder="Team Member Content"
																	onChange={handleChange}
																	onBlur={handleBlur}
																	value={
																		values.team_section[index]
																			.team_member_content
																	}
																/>
															</div>
														</div>
													</>
												))}
												<div className="col-12">
													<div className="buttons action_btn_section">
														<button
															type="button"
															onClick={() => {
																onAddRow('team_member', true);
															}}
															className="mr-2"
														>
															+
														</button>
														<button
															type="button action_btn_section"
															onClick={() => {
																onAddRow('team_member', false);
															}}
														>
															-
														</button>
													</div>
												</div>
											</div>

											<div className="row">
												<div className="col-12 submit-btn">
													<button
														type="button"
														className="btn btn-secondary"
														data-dismiss="modal"
														onClick={() =>
															navigate('/admin/leadership-template')
														}
													>
														Close
													</button>
													<button
														type="submit"
														className="btn btn-primary ml-2"
													>
														{submitButton}
													</button>
												</div>
											</div>
										</div>
									</div>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default AddLeadershipTemplate;
