import React from "react";
import { Link } from "react-router-dom";

const ChemicalFreeImage = (props) => {
  return (
    <div className="nm_home">
      <div
        className="chemical-free-image-container"
        style={{
          backgroundImage: `url(${props?.data?.bannerImage})`,
          backgroundSize: "cover",
          backgroundPosition: "right",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="content text-light text-center">
          {/* Banner Heading */}
          <div>
            <h1 className="chemical-free-heading m-0">
              {props?.data?.bannerheading1}
            </h1>
            <h2 className="chemical-free-heading2 ">
              {props?.data?.bannerheading2}
            </h2>
          </div>
          {/* Banner buttons */}
          <div>
            <Link to="/products">
              <button className="shop-now py-2 px-4 chemical-free-btn mx-2">
                Shop Now
              </button>
            </Link>
            <Link to="/about-us">
              {" "}
              <button className="about-btn chemical-free-btn py-2 px-4 mx-2">
                About NM India
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(ChemicalFreeImage);
